import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { InvoiceAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import {  toast } from "react-toastify";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { ACCOUNTING, INVOICE_ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss";
import {
  Button,
  Icon
} from "semantic-ui-react";
import InvoicesModal from "../../components/Modals/InvoicesModal";
import moment from "moment";
import BillDetailsModal from "../../components/Modals/BillDetailsModal";
import PaymentModal from "../../components/Modals/PaymentModal";
import axios from 'axios';
import getAuthToken from "../../store/auth/authUtility";

const invoiceClient = getClient(ACCOUNTING);

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      tableData: [],
      offset: 0,
      invoiceCache: [],
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Invoices",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  DateFormat = ( value ) => {
    return moment(value).format("l");
  };

  StatusFormat = (status) => {
    if (status === "approved") return "Approved";
    if (status === "created") return "Created";
    if (status === "issued") return "Issued";
    if (status === "paid") return "Paid";
    if (status === "partialPayment") return "Partially Paid";
    if (status === "voided") return "Voided";
    else return "";
  };

  download = (e, props) => {
    const url = new URL(INVOICE_ACCOUNTING);
    url.searchParams.append("invoice_id", props.props._id);
    url.searchParams.append("action", "invoice");
    axios({
      method: "get",
      url: url.href,
      headers: {
        authorization: getAuthToken(),
      },
      responseType: "blob", // important
    })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  success = () =>
  toast.success("Success!", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  failure = () =>
    toast.error("Something went wrong!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  getLedger = () => {
    try {
      this.setState({ loading: true });
      invoiceClient
        .query({
          query: InvoiceAccounting,
          variables: {
            location: this.props.selectedPrimary.node.id,
            credits: false,
            offset: this.state.offset,
            limit: 99999999,
          },
        })
        .then((res) => {
          if (res.data) {
            const fullData = get(res, "data.slLocationInvoices.data", []);
            const dataToPass = fullData.map((obj) => {
              const { amount, amountPayable, dueDate, invoiceDate, postedDate, status } = obj;
              const billDetailsModal = <BillDetailsModal bill={obj} user={this.props.user} location={this.props} invoice updateInvoice={this.props.updateInvoice} />;
              const uiStatus = status === null ? '' : this.StatusFormat(status);
              const uiAmount = amount === null ? '' : `$ ${Number.parseFloat(amount).toLocaleString('en')}`;
              const uiAmountPayable = amountPayable === null ? '' : `$ ${Number.parseFloat(amountPayable).toLocaleString('en')}`;
              const uiInvoiceDate = invoiceDate === null ? '' : this.DateFormat(invoiceDate);
              const uiPostedDate = postedDate === null ? '' : this.DateFormat(postedDate);
              const uiDueDate = dueDate === null ? '' : this.DateFormat(dueDate);
              const actions = (status !== "voided" && status !== "paid")
                ?
                  <>
                    <PaymentModal
                      location={this.props}
                      updateInvoice={this.props.updateInvoice}
                      success={this.success}
                      bills={false}
                      bill={obj}
                      user={this.props.user}
                    />
                    <Button size="tiny" className="ml-3 button-hover-universal" compact icon onClick={this.download} props={obj} >
                      <Icon name="file"/>
                    </Button>
                  </>
                :
                  <Button size="tiny" className="ml-3 button-hover-universal" compact icon onClick={this.download} props={obj} >
                    <Icon name="file"/>
                  </Button>;

              return { billDetailsModal, uiStatus, uiAmount, uiAmountPayable, uiInvoiceDate, uiPostedDate, uiDueDate, actions}
            })
            this.setState({
              tableData: dataToPass,
              totalItems: get(res, "data.slLocationInvoices.totalItems"),
            });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  dataPush = (response) => {
    this.setState((prevState) => ({
      invoiceCache: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.invoiceCache,
      ],
    }));
  };
  
  updateInvoice = (status, invoiceId) => {
    const { invoiceCache } = this.state;
    const array = [];
    if (status === "Delete") {
      this.setState({
        invoiceCache: invoiceCache.filter(
          (invoice) => invoice._id !== invoiceId
        ),
      });
    } else {
      invoiceCache.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      this.setState({ invoiceCache: array });
    }
  };

  handleOffset = (x) => {
    this.setState({offset: x});
  }

  render() {
    const { loading } = this.state;
    const fail = () =>
    toast.error("No agent with this ID exists in our system", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const success = () =>
    toast.success("Success!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    const mainHeader = ["Number", "Status", "Amount", "Amount Remaining", "Invoice", "Posted", "Due", "Actions"];
    // TODO: Style component.

    return (
      <>
      <div className="row mb-4">
          <div className="col-md-12">
            <div className="page-header">
              <div className="d-flex align-items-center">
                {/* <span className="page-back"> */}
                  {/* <img src={backIcon} alt="back" /> */}
                  
                {/* </span> */}
                <div>
                  <div className="page-header-title">Invoices</div>
                </div>
              </div>
              <div className="d-flex align-items-end">
              <InvoicesModal
              dataPush={this.dataPush}
              fail={fail}
              user={this.props.user}
              success={success}
              propertyId={
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.id
            }
            />
                <Button
                onClick={() => window.print()}
                  className="ml-4 noPrint noPrint-button"
                  compact
                  style={{
                    backgroundImage:
                      "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                  }}
                >
                  Print
                </Button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader text inTable />
        ) : (
          <GeneralAccountingTable
            mainHeader={mainHeader}
            mainCellData={this.state.tableData}
            updateInvoice={this.updateInvoice}
            getNewData={this.getNewData}
            property={
              this.props.selectedPrimary &&
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.customId
            }
            dataPush={this.dataPush}
            offset={this.state.offset}
            handleOffset={this.handleOffset}
            getLedger={this.getLedger}
            user={this.props.user}
            totalItems={this.state.totalItems && this.state.totalItems}
            propertyId={
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.id
            }
          />
        )}
      </>
    );
  }
}

export default withApollo(Invoices);
