import React from "react";
import { Button, Form, Modal, Grid } from "semantic-ui-react";
import { VendorCreateMutation } from "../../store/person/accounting";
import { ACCOUNTING } from "../../utils/constants";
import mixpanel from "mixpanel-browser";
import { getClient } from "../../init-apollo-googleFn";
const accountingClient = getClient(ACCOUNTING);
function VendorsModal(props) {
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState("");
  const [show, showMore] = React.useState(false);
  const [show2, showMore2] = React.useState(false);
  const [name, setName] = React.useState(false);
  const [checkName, setCheckName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [terms, setTerms] = React.useState("");
  const [EIN, setEIN] = React.useState("");
  const [addressLabel, setAddressLabel] = React.useState(null);
  const [address, setAddress] = React.useState(null);
  const [addressCity, setAddressCity] = React.useState(null);
  const [addressState, setAddressState] = React.useState(null);
  const [addressZip, setAddressZip] = React.useState(null);
  const [contactName, setContactName] = React.useState("");
  const [contactEmail, setContactEmail] = React.useState("");
  const [contactPhone, setContactPhone] = React.useState("");

  const createVendor = (e) => {
    setLoader(true)
    const Address = 
      {
        label: addressLabel,
        line1: address,
        city: addressCity,
        state: addressState,
        zip: addressZip,
        country: "US",
        isVerified: false,
      }
    ;
    const Contact = 
      {
        name: contactName,
        email:contactEmail,
        phone:contactPhone,
      }
    ;
    let Contacts = Object.fromEntries(Object.entries(Contact).filter(([_, v]) => v != ""));
    let Addresses = Object.fromEntries(Object.entries(Address).filter(([_, v]) => v != null));
    try {
      mixpanel.track("Manager Accounting Action", {
        sub: "Create New Vendor",
      });
      const inputs ={
        name: name,
        location: props.propertyId,
        nameOnCheck: checkName,
        accNumber: accountNumber,
        email: email,
        currency: "USD",
        terms: terms,
        EIN: EIN,
      }
      if(show){
        inputs["Addresses"] = [Addresses];
      }
      if(show2){
        inputs["Contacts"] = [Contacts];
      }
      accountingClient
        .mutate({
          mutation: VendorCreateMutation,
          variables: {
            input: inputs
          },
        })
        .then((response) => {
          props.getLedger()
          setLoader(false)
          setOpen(false);
        }).catch(() => {
          setLoader(false)
        });
    } catch (e) {
      setLoader(false)
      alert(e);
    }
  };


  const clearState = () => {
    setOpen(false);
  setLoader(false);
  showMore(false);
  setName(false);
  setCheckName("");
  setAccountNumber("");
 setEmail("");
  setTerms("");
  setEIN("");
  setAddressLabel("");
  setAddress("");
  setAddressCity("");
  setAddressState("");
  setAddressZip("");
  setContactName("");
  setContactEmail("");
  setContactPhone("");

  }

  const verificationSubmit = () => {
    if (!name || (name && !name.trim())) {
      return true
    }
    if(show){
      if(!addressLabel|| !addressCity || !addressState ||!addressZip ||!address){
        return true
      } 
    }
    if(show2){
      if(!contactName|| !contactEmail || !contactPhone ){
        return true
      } 
    }
    else return false
  }

  return (
    <Modal
      className="semanticModal"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button compact className="noPrint ml-3 button-hover-universal" onClick={()=> clearState()}><span>Add Vendor</span></Button>}
    >
      <Modal.Header>Add Vendor</Modal.Header>
      <Modal.Content style={{ margin: "15px" }}>
        <Form>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Name</label>
              <input
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>Name on Check</label>
              <input
                placeholder="Name on Check"
                onChange={(e) => setCheckName(e.target.value)}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>Account Number</label>
              <input
                placeholder="Account Number"
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>Email</label>
              <input
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>Terms</label>
              <input
                placeholder="Terms"
                onChange={(e) => setTerms(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>EIN/SSN</label>
              <input
                placeholder="EIN/SSN"
                onChange={(e) => setEIN(e.target.value)}
              />
            </Form.Field>
          </Form.Group>

          <Grid columns="2">
            <Grid.Column>
              {/* eslint-disable */}
              <a onClick={show ? () => showMore(false) : () => showMore(true)}>
                Add Address &#x2193;
              </a>
              {/* eslint-enable */}
              <br></br>
              <br></br>
            </Grid.Column>
            <Grid.Column>
              {/* eslint-disable */}
              <a onClick={show2 ? () => showMore2(false) : () => showMore2(true)}>
                Add Contact &#x2193;
              </a>
              {/* eslint-enable */}
              <br></br>
              <br></br>
            </Grid.Column>
          </Grid>
          <Grid columns='2'>
          {show && (
            <>
            <Grid.Column>
                <Form.Field required>
                  <label>Label</label>
                  <input
                    placeholder="Label"
                    onChange={(e) => setAddressLabel(e.target.value)}
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Address</label>
                  <input
                    placeholder="Address"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Form.Field>
                <Form.Field required>
                  <label>City</label>
                  <input
                    placeholder="City"
                    onChange={(e) => setAddressCity(e.target.value)}
                  />
                </Form.Field>
                <Form.Group widths="6">
                <Form.Field required>
                  <label>State</label>
                  <input
                    placeholder="State"
                    maxLength="2"
                    onChange={(e) => setAddressState(e.target.value)}
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Zip</label>
                  <input
                    placeholder="Zip"
                    onChange={(e) => setAddressZip(e.target.value)}
                  />
                </Form.Field>
              </Form.Group>
              </Grid.Column>
              </>
          )}
          {show2 && (
            <>
              <Grid.Column floated='right'>
                <Form.Field required>
                  <label>Name</label>
                  <input
                    placeholder="Name"
                    onChange={(e) => setContactName(e.target.value)}
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Email</label>
                  <input
                    placeholder="Email"
                    onChange={(e) => setContactEmail(e.target.value)}
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Phone</label>
                  <input
                    placeholder="Phone"
                    onChange={(e) => setContactPhone(e.target.value)}
                  />
                </Form.Field>

            </Grid.Column>
            </>
          )}
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {/* eslint-disable */}
        
        <Button
          onClick={() => createVendor()}
          className="ml-3 button-hover-universal"
          disabled={verificationSubmit()}
        >{
          loader?<span>Processing...<Button style={{paddingBottom:"0px"}} compact loading></Button></span>: <span>Submit</span>}</Button>
        
        {/* eslint-enable */}
      </Modal.Actions>
    </Modal>
  );
}

export default VendorsModal;