import React, { useMemo, useState, useEffect } from "react";
import ReconcileTable from "./ReconcileTable";
import { ACCOUNTING } from "../../utils/constants";
import { toastFailMsg } from "../../utils/common";
import ReconcileDetails from "../Modals/ReconcileDetails";
import ReconcileModal from "../Modals/ReconcileModal";
import { getClient } from "../../init-apollo-googleFn";
import { Icon, Container, Segment } from "semantic-ui-react";
import moment from "moment";
import { LocationJournal } from "../../store/person/accounting";

const ledgerClient = getClient(ACCOUNTING);

export function Table(props) {
  const [modal, modalOpen] = useState(false);
  const [RefNum, SelectedRef] = useState(false);
  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState();

  const defaultColumn = React.useMemo(() => ({ Filter: "" }), []);

  const DateFormat = ({ values }) => {
    return moment(values).format("l");
  }; 
  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        Header: "Reconcile",
        accessor: "reconcileId",
        Cell: ({ value }) => (value ? <div className="icon-container"><Icon name="check circle" size="small" /></div> : ""),
      },
      {
        Header: "Journal Number",
        accessor: (item) => (
          <ReconcileDetails
            bill={item}
            user={props.user}
            location={props}
            invoice
            updateInvoice={props.updateInvoice}
          />
        ),
      },
      {
        Header: "Status",
        accessor: "Journal.status",
        Cell: ({ value }) => (value ? <span style={{textTransform:'capitalize'}}>{value}</span> : ""),
      },
      {
        Header: "Unit",
        accessor: "Unit",
      },
      {
        Header: "Name",
        accessor: "Agent_name",
      },
      {
        Header: " Account Number",
        accessor: "LedgerAccount_number",
      },

      {
        Header: "Account SubType",
        accessor: "LedgerAccount_subtype",
      },
      {
        Header: "PX Date",
        accessor: "transactionDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ""),
      },
      {
        Header: "Post Date",
        accessor: "postedDate",
        Cell: ({ value }) => (value ? <DateFormat values={value} /> : ""),
      },
      {
        Header: "Reference",
        accessor: "Journal.reference",
      },
      {
        Header: "Description",
        accessor: "description", 
      },
      {
        Header: "Debit",
        accessor: "debit",
        Cell: ({ cell: { value } }) =>
          value && "$" + value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
      },
      {
        Header: "Credit",
        accessor: "credit",
        Cell: ({ cell: { value } }) =>
          value && "$" + value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"),
      },
      {
        Header: "Action",
        accessor: (item) => (
          <>
          {
            !item.reconcileId&&
            (
          <ReconcileModal
            ledger={item}
            location={props.propertyId}
            update={ReconcileAccount}
          />
            )
          }
          </>
        ),
      },
    ],
    []
  );
  /* eslint-enable */

  const { transCache, totalItems, ReconcileAccount } = props;

  /* eslint-disable */
  useEffect(() => {
    const result = totalItems / 30;
    setLastPage(Math.ceil(result));
    transCache && setData(transCache);
  }, [transCache && transCache]);
  /* eslint-enable */

  return (
    <>
      
      <main className="main-content" role="main">
        <div className="">
          <div className="">
            <ReconcileTable
              columns={columns}
              data={data}
              defaultColumn={defaultColumn}
              lastPage={lastPage}
              onPaginate={props.handlePaginate}
              paginate={props.paginate}
              sortDesc={false}
              sortId={"postedDate"}
              totalItems={totalItems}
              update={ReconcileAccount}
            />
          </div>
        </div>
      </main>
    </>
  );
}