/* eslint-disable */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import Moment from "react-moment";
import { Checkbox, Button } from "semantic-ui-react";
import ApplicationTable from "./ApplicationTable";
import { LEAD_CREATED_FORMAT, NUMBER_ZERO } from "../../utils/constants";
import { dataFilterByColumn } from "../../utils/common";
import "../Leads/LeadsExtra.scss";

const SetDateFormat = ({ date }) => (
  <Moment format={LEAD_CREATED_FORMAT}>{date}</Moment>
);

export default function Table(props) {
  const [filterInput, setFilterInput] = useState("");
  const [multiFilterData, setMultiFilterData] = useState([]);
  const [data, setData] = useState([]);
  const [statusFilter, setStatusFilter] = React.useState([]);
  const [unitTypeFilter, setUnitTypeFilter] = React.useState([]);
  const [unitNumberFilter, setUnitNumberFilter] = React.useState([]);
  const [currentPageIndex, setPageIndex] = useState(NUMBER_ZERO);
  const { applications, multipleHandling, view, selectedAppsForBulkEdit } =
    props;

  const multipleColumnsSearch = (inputValue, data) => {
    const filteredData = data.filter(
      (value) =>
        value.node.primaryApplicant.firstName
          .toLowerCase()
          .includes(inputValue.toLowerCase()) ||
        value.node.primaryApplicant.lastName
          .toLowerCase()
          .includes(inputValue.toLowerCase())
    );
    return filteredData;
  };

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    const filteredData = multipleColumnsSearch(value, applications);
    setData(filteredData);
    setFilterInput(value);
    setPageCount(filteredData.length);
    if (value === "") {
      const startRow = 30 * currentPageIndex;
      const endRow = startRow + 30;
      setData(applications.slice(startRow, endRow));
    }
    setFilterApplied(true);
    setMultiFilterData(filteredData);
  };

  const filterByColumn = (
    filteredData,
    filter,
    column,
    arrayToFilter,
    filteredArray,
    key
  ) => {
    filter[0].value.forEach((ele) => {
      filteredData = dataFilterByColumn(ele || "", arrayToFilter, column, key);
      filteredData.forEach((ele) => {
        filteredArray.push(ele);
        return null;
      });
    });
    setData(filteredArray);
    setMultiFilterData(filteredArray);
  };

  /* function to check the filtered data on the basis of the column on which filter is applied */
  const updateFilterByPreviousValue = () => {
    const filteredData = [];
    const unitTypeFilterData = [];
    const statusFilterData = [];
    const unitNumberFilterData = [];
    let isStatusFilter = false;
    let isUnitTypeFilter = false;
    if (statusFilter.length) {
      isStatusFilter = true;
      filterByColumn(
        filteredData,
        statusFilter,
        "status",
        applications,
        statusFilterData
      );
    }
    if (unitTypeFilter.length) {
      isUnitTypeFilter = true;
      filterByColumn(
        filteredData,
        unitTypeFilter,
        "unitType",
        isStatusFilter ? statusFilterData : applications,
        unitTypeFilterData,
        "type"
      );
    }
    if (unitNumberFilter.length) {
      let dataToSend = [];
      if (isUnitTypeFilter) {
        dataToSend = unitTypeFilterData;
      } else if (isStatusFilter) {
        dataToSend = statusFilterData;
      } else {
        dataToSend = applications;
      }
      filterByColumn(
        filteredData,
        unitNumberFilter,
        "unitNumber",
        dataToSend,
        unitNumberFilterData,
        "number"
      );
    }
  };

  /* function to check if the filter is applied, then calls updateFilterByPreviousValue
    else sets the data to the original one */
  const handleMultipleFiltering = () => {
    if (
      statusFilter.length ||
      unitTypeFilter.length ||
      unitNumberFilter.length
    ) {
      setFilterApplied(true);
      updateFilterByPreviousValue();
    } else {
      setData(props.applications);
      setMultiFilterData([]);
    }
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return "";
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  // const SelectStatusFilter = (type) => (
  //   <Select
  //     classNamePrefix="react-select"
  //     className={statusFilter.length && 'applied-filter'}
  //     hideSelectedOptions={false}
  //     controlShouldRenderValue={false}
  //     onChange={(entry) => {
  //       if (entry === null) entry = [];
  //       setSelectStatus(entry);
  //       onFilteredChangeCustom(
  //         entry.map((o) => o.value),
  //         type,
  //       );
  //     }}
  //     value={selectStatus}
  //     isMulti
  //     formatOptionLabel={formatOptionLabel}
  //     isSearchable
  //     options={getUnique(applications, type).map((o, i) => ({
  //       id: i,
  //       value: o.node[type],
  //       label: o.node[type],
  //     }))}
  //   />
  // );

  // const SelectUnitType = (type, value) => (
  //   <Select
  //     classNamePrefix="react-select"
  //     className={unitTypeFilter.length && 'applied-filter'}
  //     hideSelectedOptions={false}
  //     controlShouldRenderValue={false}
  //     onChange={(entry) => {
  //       if (entry === null) entry = [];
  //       setSelectUnitType(entry);
  //       onFilteredChangeCustom(
  //         entry.map((o) => o.value),
  //         type,
  //         value,
  //       );
  //     }}
  //     value={selectUnitType}
  //     isMulti
  //     formatOptionLabel={formatOptionLabel}
  //     isSearchable
  //     options={getUnique(applications, type, value).map((o, i) => ({
  //       id: i,
  //       value: value ? o.node[type][value] : o.node[type],
  //       label: value ? o.node[type][value] : o.node[type],
  //     }))}
  //   />
  // );

  // const SelectUnitNumber = (type, value) => (
  //   <Select
  //     classNamePrefix="react-select"
  //     className={unitNumberFilter.length && 'applied-filter'}
  //     hideSelectedOptions={false}
  //     controlShouldRenderValue={false}
  //     onChange={(entry) => {
  //       if (entry === null) entry = [];
  //       setSelectUnitNumber(entry);
  //       onFilteredChangeCustom(
  //         entry.map((o) => o.value),
  //         type,
  //         value,
  //       );
  //     }}
  //     value={selectUnitNumber}
  //     isMulti
  //     formatOptionLabel={formatOptionLabel}
  //     isSearchable
  //     options={getUnique(applications, type, value).map((o, i) => ({
  //       id: i,
  //       value: value ? o.node[type][value] : o.node[type],
  //       label: value ? o.node[type][value] : o.node[type],
  //     }))}
  //   />
  // );

  const showData = (item) => {
    if (item.node.adults > 1) {
      return (
        <>
          <i aria-hidden="true" className="group icon mr-2" />
          {item.node.primaryApplicant
            ? item.node.primaryApplicant.lastName
            : ""}
        </>
      );
    }
    return item.node.primaryApplicant.lastName
      ? item.node.primaryApplicant.lastName
      : "";
  };

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        Header: "Select",
        accessor: (item) => (
          <Checkbox
            onChange={(e) => multipleHandling(item, e)}
            checked={selectedAppsForBulkEdit.includes(item.node.id)}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: "Order",
        accessor: "node.orderNumber",
      },
      {
        Header: "Status",
        accessor: "node.status",
        // Filter: SelectStatusFilter('status'),
        filter: "includes",
      },
      {
        Header: "First Name",
        accessor: "node.primaryApplicant.firstName",
      },
      {
        Header: "Last Name",
        accessor: (item) => showData(item),
      },
      {
        Header: "Applied On",
        accessor: "node.created",
        Cell: ({ cell: { value } }) => <SetDateFormat date={value} />,
      },
      {
        Header: "Unit Type",
        accessor: "node.unitType.type",
        // Filter: SelectUnitType('unitType', 'type'),
        filter: "includes",
      },
      {
        Header: "Unit Number",
        accessor: "node.unitNumber.number",
        // Filter: SelectUnitNumber('unitNumber', 'number'),
        filter: "includes",
      },
      {
        Header: "View",
        accessor: (item) => (
          <Button icon="eye" size="mini" onClick={() => view(item)} />
        ),
        disableSortBy: true,
      },
    ],
    [props, selectedAppsForBulkEdit]
  );
  /* eslint-enable */

  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [loading, setLoading] = React.useState(false);
  const [startRowIndex, setStartRowIndex] = useState(0);
  const [endRowIndex, setEndRowIndex] = useState(0);
  const [lastPage, setLastPage] = useState();
  const [isFilterApplied, setFilterApplied] = useState(false);

  const calculateLastPage = ({ pageSize, controlledPageCount }) => {
    const result = controlledPageCount / pageSize;
    setLastPage(Math.ceil(result));
  };

  /* eslint-disable */
  const fetchData = useCallback(
    ({ pageSize, pageIndex, filteredValue }) => {
      // setPageSize(pageSize);
      setPageIndex(pageIndex);
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      setFilterApplied(false);
      setTimeout(() => {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
          const startRow = pageSize * pageIndex;
          const endRow = applications.length;
          if (
            unitTypeFilter.length ||
            statusFilter.length ||
            unitNumberFilter.length ||
            filteredValue
          ) {
            setData(multiFilterData.slice(startRow, endRow));
            setPageCount(multiFilterData.length);
          } else {
            setData(applications.slice(startRow, endRow));
            setPageCount(applications.length);
          }
          setStartRowIndex(startRow);
          setEndRowIndex(endRow);
          setLoading(false);
        }
      }, 2000);
    },
    [multiFilterData, applications]
  );
  /* eslint-enable */

  useEffect(() => {
    setData(applications);
  }, [applications]);

  return (
    <div className="table-container">
      <ApplicationTable
        calculateLastPage={calculateLastPage}
        columns={columns}
        completeData={applications}
        data={data}
        defaultColumn={defaultColumn}
        endRow={endRowIndex}
        fetchData={fetchData}
        filteredValue={filterInput}
        handleMultipleFiltering={handleMultipleFiltering}
        isFilterApplied={isFilterApplied}
        lastPage={lastPage}
        loading={loading}
        onChange={handleFilterChange}
        pageCount={pageCount}
        pageSize={lastPage}
        startRow={startRowIndex}
        sortId={"node.created"}
        sortDesc={false}
      />
    </div>
  );
}
