import React from "react";
import { Button, Grid, Input, Modal } from "semantic-ui-react";
import { RepayMutation } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import { PAYMENTS_URL } from "../../utils/constants";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import mixpanel from "mixpanel-browser";

const PayClient = getClient(PAYMENTS_URL);

function RepayModal(props) {
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState("");
  const [theRefund, setToRefund] = React.useState(false);
  const [theVoid, setToVoid] = React.useState(false);
  const [theMain, setToMain] = React.useState(false);
  const [loading, setLoader] = React.useState(false);
  const VoidFunction = () => {
    if (props.payment.notes.split(".").pop() === "Credit") {
      setLoader(true);
      try {
        mixpanel.track("Manager Accounting Action", {
          sub: "Void from Credit",
        });
        PayClient.mutate({
          mutation: RepayMutation,
          variables: {
            transaction: {
              id: props.payment.number,
              type: "void",
              currency: "USD",
              amount: props.payment.amount,
              invoiceId: props.payment.InvoiceId,
              paymentMethodType: props.payment.notes.split(".").pop(),
              ledgerAccountId: props.payment.LedgerAccountId,
              locationId: props.payment.LocationId,
              paymentId: props.payment._id,
            },
          },
        }).then((response) => {
          setLoader(false);
          setOpen(false);
          toast.success("Success");
        });
      } catch (e) {
        setLoader(false);
        toast.fail("Error");
      }
    } else {
      try {
        mixpanel.track("Manager Accounting Action", {
          sub: "Void from Other ",
        });
        PayClient.mutate({
          mutation: RepayMutation,
          variables: {
            transaction: {
              id: props.payment.number,
              type: "void",
              currency: "USD",
              amount: props.payment.amount,
              invoiceId: props.payment.InvoiceId,
              paymentMethodType: props.payment.notes.split(".").pop(),
              ledgerAccountId: props.payment.LedgerAccountId,
              locationId: props.payment.LocationId,
              paymentId: props.payment._id,
            },
          },
        }).then((response) => {
          setLoader(false);
          setOpen(false);
          toast.success("Success");
        });
      } catch (e) {
        setLoader(false);
        toast.fail("Error");
      }
    }
  };
  const RepayFunction = (prop) => {
    setLoader(true);
    if (prop === "Reverse") {
      try {
        mixpanel.track("Manager Accounting Action", {
          sub: "Reverse Invoice",
        });
        PayClient.mutate({
          mutation: RepayMutation,
          variables: {
            transaction: {
              id: props.payment.number,
              type: "reverse",
              currency: "USD",
              amount: amount,
              invoiceId: props.payment.InvoiceId,
              paymentMethodType: props.payment.notes.split(".").pop(),
              ledgerAccountId: props.payment.LedgerAccountId,
              locationId: props.payment.LocationId,
              paymentId: props.payment._id,
            },
          },
        }).then((response) => {
          setLoader(false);
          toast.success("Success");
          setOpen(false);
        });
      } catch (e) {
        setLoader(false);
        toast.fail("Error");
      }
    }
    if (prop === "Refund") {
      try {
        mixpanel.track("Manager Accounting Action", {
          sub: "Refund Invoice",
        });
        PayClient.mutate({
          mutation: RepayMutation,
          variables: {
            transaction: {
              id: props.payment.number,
              type: "refund",
              currency: "USD",
              amount: amount,
              invoiceId: props.payment.InvoiceId,
              paymentMethodType: props.payment.notes.split(".").pop(),
              ledgerAccountId: props.payment.LedgerAccountId,
              locationId: props.payment.LocationId,
              paymentId: props.payment._id,
            },
          },
        }).then((response) => {
          setLoader(false);
          toast.success("Success");
          setOpen(false);
        });
      } catch (e) {
        setLoader(false);
        toast.fail("Error");
      }
    }

    setOpen(false);
  };
  let minutes = moment().diff(moment(props.payment.paymentDate), "minutes");
  const CheckMain = () => {
    if (
      props.payment.notes &&
      props.payment.notes.split(".").pop() === "Credit" &&
      minutes > 24
    ) {
      setToMain(true);
    } else {
      return;
    }
  };
  const toVoid = () => {
    setToMain(false);
    setToVoid(true);
  };
  const toRefund = () => {
    setToMain(false);
    setToRefund(true);
  };

  return (
    <Modal
      className="semanticModal"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
      size="mini"
      trigger={
        <Button compact fluid onClick={CheckMain}>
          {/* Open/CC */}
          {props.payment.notes &&
            moment().isBefore(moment(props.closeTime)) &&
            props.payment.notes.split(".").pop() === "Credit" &&
            "Void"}
          {/* Closed/ACH */}
          {props.payment.notes &&
            props.payment.notes.split(".").pop() === "ACH" &&
            "Voids"}
          {/* Closed/CC */}
          {props.payment.notes &&
            moment().isAfter(moment(props.closeTime)) &&
            props.payment.notes.split(".").pop() === "Credit" &&
            "Refund"}
          {props.payment.notes &&
            moment().isAfter(moment(props.closeTime)) &&
            props.payment.notes.split(".").pop() === "Recurring" &&
            "Refunds"}
        </Button>
      }
    >
      <Modal.Content>
        <Modal.Description>
          {/* Only Allow Refund (Open/CC)*/}
          {props.payment.notes &&
            props.payment.notes.split(".").pop() === "Credit" &&
            moment().isAfter(moment(props.closeTime)) && (
              <>
                <Grid columns="equal">
                  <Grid.Column>
                    Input amount you would like to Refund
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      focus
                      style={{ paddingRight: "10px" }}
                      placeholder={`$${props.payment.amount}`}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </Grid.Column>
                </Grid>
                <br />
                <Button
                  disabled={!amount}
                  compact
                  onClick={() => RepayFunction("Refund")}
                  fluid
                >
                  Refund
                </Button>
              </>
            )}
          {/* Only Allow Void (Closed/ACH)*/}
          {props.payment.notes &&
            props.payment.notes.split(".").pop() === "ACH" && (
              <>
                <Grid columns="equal">
                  <Grid.Column>
                    Are you sure you want to Void this transaction?
                  </Grid.Column>
                  <Grid.Column>
                    <Button fluid onClick={VoidFunction}>
                      Void
                    </Button>
                  </Grid.Column>
                </Grid>
              </>
            )}
          {props.payment.notes &&
            props.payment.notes.split(".").pop() === "Credit" &&
            moment().isBefore(moment(props.closeTime)) && (
              <>
                <Grid columns="equal">
                  <Grid.Column>
                    Are you sure you want to Void this transaction?
                  </Grid.Column>
                  <Grid.Column>
                    <Button fluid onClick={VoidFunction}>
                      Void
                    </Button>
                  </Grid.Column>
                </Grid>
              </>
            )}
          {/* Only Allow Refund/Void (Closer/CC)*/}
          {props.payment.notes &&
            props.payment.notes.split(".").pop() === "Credit" &&
            minutes < 24 &&
            theMain && (
              <Grid columns="equal">
                <Grid.Column>
                  <Button fluid onClick={toVoid}>
                    Void
                  </Button>
                </Grid.Column>
                <Grid.Column>
                  <Button fluid onClick={toRefund}>
                    Reverse
                  </Button>
                </Grid.Column>
              </Grid>
            )}
          {theVoid && (
            <Grid columns="equal">
              <Grid.Column>
                Are you sure you want to Void this transaction?
              </Grid.Column>
              <Grid.Column>
                <Button fluid onClick={VoidFunction}>
                  Void
                </Button>
              </Grid.Column>
            </Grid>
          )}
          {theRefund && (
            <>
              <Grid columns="equal">
                <Grid.Column>Input amount you would like to Refund</Grid.Column>
                <Grid.Column>
                  <Input
                    focus
                    style={{ paddingRight: "10px" }}
                    placeholder={`$${props.payment.amount}`}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Grid.Column>
              </Grid>
              <br />
              <Button
                disabled={!amount}
                compact
                onClick={() => RepayFunction("Reverse")}
                fluid
              >
                Reverse
              </Button>
            </>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {loading ? (
          <Button
            disabled
            loading
            labelPosition="right"
            onClick={() => setOpen(false)}
          />
        ) : (
          <Button
            content="Close"
            labelPosition="right"
            onClick={() => setOpen(false)}
          />
        )}
        <Button
          content="Close"
          labelPosition="right"
          onClick={() => setOpen(false)}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default RepayModal;
