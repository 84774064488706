import React from "react";

function CommentsHistory() {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="theme-card card">
            <div className="card-header">
              <div className="card-header-title">Comments/History</div>
            </div>
            <div className="card-body">
              <div className="card-row-notice">
                <div>
                  <div className="notice-title">
                    Email Sent by Manager REM
                  </div>
                  <div className="notice-subtitle">
                    Email has been sent for application status update.
                  </div>
                </div>
                <div className="notice-time">Mar 9, 2021 1:29 PM</div>
              </div>
              <div className="card-row-notice">
                <div>
                  <div className="notice-title">
                    Status Changed by Manager REM
                  </div>
                  <div className="notice-subtitle">
                    Under Review --> Approved
                  </div>
                </div>
                <div className="notice-time">Mar 9, 2021 1:29 PM</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CommentsHistory;
