import React, { Component } from "react";
import { withRouter } from "react-router";
import { PageLoader } from "../../components/Loader/PageLoader";
import {
  singleMaintenanceGql,
  updateMaintenance,
  maintenanceActivity,
  serviceProfessionalsGql,
} from "../../store/person/maintenance";
import { NavLink } from "react-router-dom";
import { parseGraphQLErrors } from "../../utils/common";
import { MAINTENANCE_URL_PHOTO } from "../../utils/constants";
import getAuthToken from "../../store/auth/authUtility";
import get from "lodash/get";
import moment from "moment";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Datetime from "react-datetime";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import "react-datetime/css/react-datetime.css";
import {
  Grid,
  Feed,
  Segment,
  List,
  Image,
  Form,
  Card,
  Modal,
  Dropdown,
  TextArea,
  Header,
  Button,
} from "semantic-ui-react";
import { getCookie } from "../../store/auth/authUtility";
class MaintenanceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maintenanceRequest: {},
      viewopen: false,
      message: "",
      fetched: false,
      dateTime: "",
      isError: "",
      color: "teal",
      maintImage: null,
      description: "",
      staffNotes: "",
      errors: {},
      status: "",
      personId: "",
      maintId: "Maintenance",
      maintName: "Select New Tech",
      primary: "",
      scheduledStartDate: "",
      scheduledEndDate: "",
      enteredDate: "",
      completedDate: "",

      serviceProfessionals: [],
      timeFromState: "",
      stage: "Overview",
      inputs: ["input-0"],
      statusUpdates: [],
      workOrder: "",
      value: "",
      roleFetched: false,
      isServiceProfessional: false,
      loading: false,
    };
    this.handleNoteChange = this.handleNoteChange.bind(this);
  }

  componentDidMount() {
    this.fetchMaintenanceDetails();
    this.maintenanceActivity();
    this.getManagers();
    mixpanel.track("Manager Page Load", {
      sub: "Maintenance Detail",
    });
  }

  fetchMaintenanceDetails = () => {
    const roleType =
      this.props.role.serviceProfessional === "true"
        ? "serviceProfessional"
        : "manager";
    this.setState({
      loading: true,
      sendChange: true,
      primary: this.props.match.params.location,
    });
    try {
      this.props.maintenanceClient
        .query({
          query: singleMaintenanceGql,
          variables: {
            id: this.props.match.params.maintenanceId,
            location: this.props.match.params.location,
            role: roleType,
          },
        })
        .then((res) => {
          const maintenanceRequest = get(
            res.data,
            "maintenanceRequest.edges",
            []
          )[0].node;
          this.getPhotos(maintenanceRequest.fileUrls);
          this.setState({ maintenanceRequest: maintenanceRequest });
          this.setState({ staffNotes: maintenanceRequest.staffNotes });
          this.setState({ status: maintenanceRequest.status });
          this.setState({
            scheduledStartDate: maintenanceRequest.scheduledStart,
          });
          this.setState({ scheduledEndDate: maintenanceRequest.scheduledEnd });
          this.setState({
            enteredDate: moment(maintenanceRequest.enteredDate).toISOString(
              true
            ),
          });
          this.setState({ completedDate: maintenanceRequest.completedDate });
          this.setState({
            maintName:
              maintenanceRequest.assigned.firstName +
              " " +
              maintenanceRequest.assigned.lastName,
            maintId: maintenanceRequest.assigned.id,
            fetched: true,
            loading: false,
          });
          this.getManagers();
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.setState({ message: error.message, isError: true });
        });
    } catch (e) {
      this.setState({ message: e });
    }
    // }
  };

  getPhotos = (photos) => {
    let url = new URL(MAINTENANCE_URL_PHOTO);
    url.searchParams.append(["path"], photos);
    url.searchParams.append("location", this.props.match.params.location);
    axios({
      method: "get",
      url: url.href,
      headers: {
        authorization: getAuthToken(),
      },
      responseType: "JSON", // important
    }).then(async (response) => {
      const maintImages = response.data.result;
      this.setState({ maintImages });
    });
  };

  maintenanceActivity = () => {
    this.setState({ sendChange: true, fetched: true });
    try {
      this.props.maintenanceClient
        .query({
          query: maintenanceActivity,
          variables: {
            maintenanceRequestId: this.props.match.params.maintenanceId,
          },
        })
        .then((res) => {
          const maintenanceActive = res.data.maintenanceActivity.edges;
          this.setState({ statusUpdates: maintenanceActive, fetched: false });
        })
        .catch((error) => {
          this.setState({ loading: false, fetched: false });
          this.setState({ message: error.message, isError: true });
        });
    } catch (e) {
      this.setState({ message: e });
    }
  };

  setOpen = () => {
    this.setState({ viewopen: true });
  };
  setClose = () => {
    this.setState({ viewopen: false });
  };

  getManagers = () => {
    try {
      this.props.maintenanceClient
        .query({
          query: serviceProfessionalsGql,
          variables: {
            location: this.props.match.params.location,
          },
        })
        .then((res) => {
          const professionals = res.data.serviceProfessionals.edges;
          this.setState({
            serviceProfessionals: professionals,
            fetched: false,
          });
        })
        .catch((error) => {
          this.setState({ loading: false, fetched: false });
          this.setState({ message: error.message, isError: true });
        });
    } catch (e) {
      this.setState({ message: e });
    }
  };

  updateLogs() {
    const { scheduledEndDate, scheduledStartDate, enteredDate, completedDate } =
      this.state;
    const objArr = [];
    objArr.push(
      scheduledStartDate,
      scheduledEndDate,
      enteredDate,
      completedDate
    );
    const newArr = objArr.filter(function (el) {
      const check = el.datetime;
      return check !== "";
    });
    this.setState({ updateLogs: newArr });
  }

  clearState() {
    this.setState({
      maintenanceRequest: {},
      message: "",
      selectedDate: [],
      fetched: false,
      dateTime: "",
      isError: "",
      start: [],
      end: [],
      description: "",
      finaldescription: "",
      errors: {},
      step: 1,
      maintId: "",
      scheduledStart: "",
      scheduledEnd: "",
      enterDate: "",
      completeDate: "",
      percent: 20,
      stage: "Overview",
      inputs: ["input-0"],
      materials: [],
      workOrder: "",
      value: "",
    });
  }
  saved = () =>
    toast.success("Saved", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  error = () =>
    toast.error("Error", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  setMaintenance = (e) => {
    const {
      maintenanceRequest,
      scheduledEndDate,
      scheduledStartDate,
      enteredDate,
      completedDate,
    } = this.state;
    const objArr = [];
    objArr.push(
      typeof scheduledEndDate == "object"
        ? scheduledEndDate
        : { status: "End", datetime: moment(scheduledEndDate).toISOString() },
      typeof scheduledStartDate == "object"
        ? scheduledStartDate
        : {
            status: "Start",
            datetime: moment(scheduledStartDate).toISOString(),
          },
      typeof enteredDate == "object"
        ? enteredDate
        : { status: "Entered", datetime: moment(enteredDate).toISOString() },
      typeof completedDate == "object"
        ? completedDate
        : { status: "Completed", datetime: moment(completedDate).toISOString() }
    );

    const newArr = objArr.filter(function (el) {
      /* eslint-disable */
      if (el !== null) {
        return el.datetime !== "";
      } else return;
      /* eslint-enable */
    });
    mixpanel.track("Manager Maintenance Action", {
      sub: "Set New Status",
    });
    this.props.maintenanceClient
      .mutate({
        mutation: updateMaintenance,
        variables: {
          input: {
            maintenance: {
              role: "manager",
              location: maintenanceRequest.location,
              id: maintenanceRequest.id,
              statusDate: newArr,
            },
          },
        },
      })
      .then((res) => {
        objArr.forEach((obj) => {
          if (obj && obj.status === "Start") {
            this.setState({ status: "Scheduled" });
          }
          if (obj && obj.status === "Entered") {
            this.setState({ status: "Entered" });
          }
          if (obj && obj.status === "Completed") {
            this.setState({ status: "Completed" });
          } else return;
        });

        this.maintenanceActivity();
        this.saved();
      })
      .catch((error) => {
        console.log(error);
        // alert(error);
      });
  };

  // Save New Maintenance Tech
  setMaintenanceTech = () => {
    const { maintenanceRequest, maintId } = this.state;
    if (maintId) {
      mixpanel.track("Manager Maintenance Action", {
        sub: "Assign Maintenance Tech",
      });
      this.props.maintenanceClient
        .mutate({
          mutation: updateMaintenance,
          variables: {
            input: {
              maintenance: {
                id: maintenanceRequest.id,
                assigned: maintId.toString(),
                role: "manager",
                location: maintenanceRequest.location,
              },
            },
          },
        })
        .then((res) => {
          if (res) {
            this.setState({ status: "Assigned" });
          }
          this.maintenanceActivity();
          this.saved();
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        });
    }
  };
  // Save Staff Notes
  setMaintenanceNotes = (e) => {
    const { maintenanceRequest, staffNotes } = this.state;
    const value = document.getElementById("staffNotes").value;
    e.preventDefault();
    mixpanel.track("Manager Maintenance Action", {
      sub: "Set Internal Maintenance Note",
    });
    this.props.maintenanceClient
      .mutate({
        mutation: updateMaintenance,
        variables: {
          input: {
            maintenance: {
              id: maintenanceRequest.id,
              staffNotes: staffNotes,
              role: "manager",
              location: maintenanceRequest.location,
            },
          },
        },
      })
      .then((res) => {
        if (res) {
          this.saved();
          this.setState({
            statusUpdates: [
              {
                node: {
                  created: moment().format("MM-DD-YYYY hh:mm A"),
                  newValue: value,
                  subject: "Staff Note",
                },
              },
              ...this.state.statusUpdates,
            ],
          });
        }
      })
      .catch((error) => {
        console.log(e);
        alert(error);
      });
  };

  contactRenter = (e) => {
    e.preventDefault();
    const { maintenanceRequest } = this.state;
    mixpanel.track("Manager Maintenance Action", {
      sub: "Contact Renter",
    });
    this.props.maintenanceClient
      .mutate({
        mutation: updateMaintenance,
        variables: {
          input: {
            id: maintenanceRequest.ndbId,
            accessNotes: e.target.message.value,
          },
        },
      })
      .then((res) => {
        this.saved();
      })
      .catch((error) => {
        console.log(e);
        alert(e);
      });
  };

  onMaintenanceSelect = (e) => {
    this.setState(
      { status: "Assigned", maintId: e.target.value },
      function () {}
    );
  };

  handleStartClick = (date) => {
    this.setState({
      scheduledStartDate: {
        status: "Start",
        datetime: moment(date._d).toISOString(),
      },
    });
  };
  handleEndClick = (date) => {
    this.setState({
      scheduledEndDate: {
        status: "End",
        datetime: moment(date._d).toISOString(),
      },
    });
  };
  handleEntryClick = (date) => {
    this.setState({
      enteredDate: {
        status: "Entered",
        datetime: moment(date._d).toISOString(),
      },
    });
  };
  handleCompleteClick = (date) => {
    this.setState({
      completedDate: {
        status: "Completed",
        datetime: moment(date._d).toISOString(),
      },
    });
  };

  onFormFieldChange = (event) => {
    const target = event.target;
    const name = target.id;
    const value = target.value;

    this.setState((state) => ({
      [name]: value,
      errors: { ...state.errors, [name]: this.validateField(name, value) },
    }));
  };

  validateField = (value) => {
    if (value.length === 0) {
      return "is missing";
    } else if (value === null || value === undefined) {
      return "is invalid";
    }
  };

  closeMessage = () => {
    this.setState({ message: "" });
  };

  setWorkOrderId = (workOrder) => {
    this.setState({ workOrder: workOrder });
  };

  setErrorState = (message) => {
    this.setState({ message: parseGraphQLErrors(message), isError: true });
  };

  setSuccessState = (message) => {
    this.setState({ message, isError: false });
  };

  handleChange = (e, { name, value, text }) => {
    this.setState({ [name]: value, maintName: e.target.textContent }, () => {});
  };
  handleNoteChange(event) {
    this.setState({ staffNotes: event.target.value });
  }

  render() {
    // const ViewMaintenanceDetails = () => (

    // )
    const { loading, maintenanceRequest, status, statusUpdates } = this.state;

    const cookie = getCookie();

    const {
      requestedByDesc,
      requestedBy,
      accessNotes,
      category,
      created,
      permissionToEnter,
      priority,
      nte,
      unit,
      petType,
    } = maintenanceRequest;

    return (
      <>
        {loading ? (
          <PageLoader text />
        ) : (
          <>
            <div className="maintenance-top-header">
              <div className="left-wrap">
                <NavLink exact to="/maintenance">
                  <span className="back-btn">
                    <img src="assets/img/back-arrow.svg" alt="back-arrow"></img>
                  </span>
                </NavLink>
                <h2>Maintenance Detail</h2>
              </div>
              {/* <div className="right-wrap">
              <Button>
                Maintenance Invite
              </Button>
            </div> */}
            </div>
            <div className="maintenance-details">
              <Card className="customer-info-wrap">
                <Card.Header className="sub-header">
                  <Header as="h2" textAlign="center">
                    Customer Information
                  </Header>
                </Card.Header>
                <Card.Content>
                  <Grid columns="3">
                    <Grid.Column>
                      <List>
                        <List.Item>
                          <List.Content>
                            <List.Description>Requested By</List.Description>
                            <List.Header>
                              {requestedBy && requestedBy.firstName}{" "}
                              {requestedBy && requestedBy.lastName}
                            </List.Header>
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content>
                            <List.Description>Unit</List.Description>
                            <List.Header>{unit}</List.Header>
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content>
                            <List.Description>Phone</List.Description>
                            <List.Header>
                              {requestedBy && requestedBy.phoneMobile}
                            </List.Header>
                          </List.Content>
                        </List.Item>
                      </List>
                    </Grid.Column>
                    <Grid.Column>
                      <List>
                        <List.Item>
                          <List.Content>
                            <List.Description>Email</List.Description>
                            <List.Header>
                              {requestedBy && requestedBy.email}
                            </List.Header>
                          </List.Content>
                        </List.Item>
                        {/* <List.Item>
                        <List.Content>
                          <List.Description>Smoking</List.Description>
                          <List.Header>No</List.Header>
                        </List.Content>
                      </List.Item> */}
                        <List.Item>
                          <List.Content>
                            <List.Description>Pets</List.Description>
                            <List.Header>{petType}</List.Header>
                          </List.Content>
                        </List.Item>
                      </List>
                    </Grid.Column>
                    <Grid.Column>
                      <List>
                        {/* <List.Item>
                        <List.Content>
                          <List.Description>Notes on File</List.Description>
                          <List.Header>None</List.Header>
                        </List.Content>
                      </List.Item> */}

                        {/* <List.Item>
                        <List.Content>
                          <List.Description>Car</List.Description>
                          <List.Header>No</List.Header>
                        </List.Content>
                      </List.Item>
                      */}
                      </List>
                    </Grid.Column>
                  </Grid>
                  <Grid className="description-wrapper">
                    <Header as="h2" textAlign="left">
                      Detailed Description
                    </Header>

                    <Segment style={{ height: "100px", overflowY: "auto" }}>
                      {requestedByDesc}
                    </Segment>
                  </Grid>
                  <Grid className="photos-wrapper">
                    {this.state.maintImages &&
                      this.state.maintImages.map((image) => {
                        return (
                          <Grid.Column
                            style={{
                              width: "120px",
                              maxHeight: "auto",
                              minHeight: "120px",
                              overflow: "hidden",
                            }}
                          >
                            <Modal
                              className="semanticModal"
                              onClose={() => this.setOpen(false)}
                              onOpen={() => this.setOpen(true)}
                              size="small"
                              open={this.state.viewopen}
                              trigger={
                                <div>
                                  <Image
                                    src={`data:${image}`}
                                    style={{
                                      height: "90px",
                                      maxWidth: "100%",
                                      maxHeight: "90px",
                                    }}
                                    size="small"
                                    onClick={() =>
                                      this.setState({ maintImage: image })
                                    }
                                  />
                                </div>
                              }
                            >
                              <Modal.Content image>
                                <Image
                                  centered
                                  fluid
                                  src={`data:${this.state.maintImage}`}
                                  size="large"
                                />
                              </Modal.Content>
                              <Modal.Actions>
                                <Button
                                  className="ok-btn"
                                  onClick={() => this.setClose(true)}
                                  positive
                                >
                                  OK
                                </Button>
                              </Modal.Actions>
                            </Modal>
                          </Grid.Column>
                        );
                      })}
                  </Grid>
                </Card.Content>
              </Card>
              <Card className="maintenance-info-wrap">
                <Card.Header className="sub-header">
                  <Header as="h2" textAlign="center">
                    Maintenance Information
                  </Header>
                  <div className="right-col">
                    {status !== "canceled" && status !== "completed" && (
                      <span>
                        Active: {moment().diff(moment(created), "days")} Days
                      </span>
                    )}
                  </div>
                </Card.Header>
                <Card.Content>
                  <Grid columns="3">
                    <Grid.Column>
                      <List>
                        <List.Item>
                          <List.Content>
                            <List.Description>
                              Date Requested (
                              {Intl.DateTimeFormat().resolvedOptions().timeZone}
                              )
                            </List.Description>
                            <List.Header>
                              {moment(created).format("MM/DD/YYYY hh:mm A")}
                            </List.Header>
                          </List.Content>
                        </List.Item>

                        <List.Item>
                          <List.Content>
                            <List.Description>Category</List.Description>
                            <List.Header
                              style={{ textTransform: "capitalize" }}
                            >
                              {category}
                            </List.Header>
                          </List.Content>
                        </List.Item>
                      </List>
                    </Grid.Column>
                    <Grid.Column>
                      <List>
                        <List.Item>
                          <List.Content>
                            <List.Description>Entry</List.Description>
                            <List.Header
                              style={{ textTransform: "capitalize" }}
                            >
                              {permissionToEnter
                                ? permissionToEnter.toString()
                                : "None"}
                            </List.Header>
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Content>
                            <List.Description>Priority</List.Description>
                            <List.Header
                              style={{ textTransform: "capitalize" }}
                            >
                              {priority}
                            </List.Header>
                          </List.Content>
                        </List.Item>
                      </List>
                    </Grid.Column>
                    <Grid.Column>
                      <List>
                        <List.Item>
                          <List.Content>
                            <List.Description>Access Notes</List.Description>
                            <List.Header>
                              {accessNotes === "undefined"
                                ? "None"
                                : accessNotes}
                            </List.Header>
                          </List.Content>
                        </List.Item>

                        <List.Item>
                          <List.Content>
                            <List.Description>NTE</List.Description>
                            <List.Header>${nte}</List.Header>
                          </List.Content>
                        </List.Item>
                      </List>
                    </Grid.Column>
                  </Grid>
                  <Grid className="save-tech-wrap">
                    <h4>Assign Technician</h4>
                    <h6>Please assign your technician for this job order</h6>
                    <div className="save-tech-btn-wrap">
                      <Dropdown
                        compact
                        labeled
                        name="maintId"
                        floating
                        text={this.state.maintName}
                        search
                        selection
                        options={
                          this.state.serviceProfessionals &&
                          this.state.serviceProfessionals.map((val) => {
                            return {
                              key: val.id,
                              value: val.node.person
                                ? val.node.person.id
                                : val.node.id,
                              text: `${
                                val.node.person
                                  ? val.node.person.firstName
                                  : "No First"
                              } ${
                                val.node.person
                                  ? val.node.person.lastName
                                  : "No Last"
                              }`,
                            };
                          })
                        }
                        onChange={this.handleChange}
                      />
                      {cookie.manager === "true" && (
                        <Button
                          style={{ background: "#4C158A" }}
                          /* eslint-disable */
                          disabled={
                            this.state.maintenanceRequest.status ===
                              "Canceled" ||
                            this.state.serviceProfessionals.length === 0 ||
                            (maintenanceRequest.assigned &&
                              this.state.maintName ==
                                maintenanceRequest.assigned.firstName +
                                  " " +
                                  maintenanceRequest.assigned.lastName)
                          }
                          /* eslint-enable */
                          // icon="save"
                          content={
                            this.state.serviceProfessionals.length === 0
                              ? "No Techs Available"
                              : "Save Technician"
                          }
                          onClick={this.setMaintenanceTech}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid className="set-times-wrap">
                    <h4>Set Maintenance Time</h4>
                    <h6>Please set technician time for this job order</h6>
                    <br />
                    <Grid columns="3">
                      <Grid.Column>
                        <Header as="h3" textAlign="center">
                          Range
                        </Header>
                        <div className="datetime-col">
                          <Datetime
                            defaultValue={
                              maintenanceRequest &&
                              maintenanceRequest.scheduledStart !== null &&
                              moment(maintenanceRequest.scheduledStart)
                                .local()
                                .format("MM-DD-YYYY hh:mm A")
                            }
                            onChange={this.handleStartClick}
                          />
                        </div>
                        <div className="datetime-col">
                          <Datetime
                            defaultValue={
                              maintenanceRequest &&
                              maintenanceRequest.scheduledEnd !== null &&
                              moment(maintenanceRequest.scheduledEnd)
                                .local()
                                .format("MM-DD-YYYY hh:mm A")
                            }
                            onChange={this.handleEndClick}
                          />
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <Header as="h3" textAlign="center">
                          Entry
                        </Header>
                        <div className="datetime-col">
                          <Datetime
                            defaultValue={
                              maintenanceRequest &&
                              maintenanceRequest.enteredDate !== null &&
                              moment(maintenanceRequest.enteredDate)
                                .local()
                                .format("MM-DD-YYYY hh:mm A")
                            }
                            onChange={this.handleEntryClick}
                          />
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <Header as="h3" textAlign="center">
                          Complete
                        </Header>
                        <div className="datetime-col">
                          <Datetime
                            className="datetimeC"
                            defaultValue={
                              maintenanceRequest &&
                              maintenanceRequest.completedDate !== null &&
                              moment(maintenanceRequest.completedDate)
                                .local()
                                .format("MM-DD-YYYY hh:mm A")
                            }
                            onChange={this.handleCompleteClick}
                          />
                        </div>
                      </Grid.Column>
                    </Grid>
                    <Button
                      disabled={
                        this.state.maintenanceRequest.status === "Canceled" ||
                        !this.state.scheduledStartDate ||
                        !this.state.scheduledEndDate
                      }
                      success
                      floated="right"
                      onClick={this.setMaintenance}
                    >
                      Save Times
                    </Button>
                  </Grid>
                </Card.Content>
              </Card>
              <Card className="staff-notes-wrapper">
                <Card.Header>
                  <Header as="h2" textAlign="center">
                    Staff Notes
                  </Header>
                </Card.Header>

                {/* <Card.Meta className="maintenance-details-header">
                <Header
                  className="maintenance-details-header-inner"
                  as="h4"
                  textAlign="center"
                >
                  Tech:{" "}
                  {maintenanceRequest.assigned
                    ? maintenanceRequest.assigned.nameUpper
                    : "None"}
                </Header>
              </Card.Meta> */}

                <Card.Content>
                  <Form>
                    <TextArea
                      id="staffNotes"
                      name="staffNotes"
                      onChange={this.handleNoteChange}
                      value={this.state.staffNotes}
                    />
                  </Form>
                  <Button
                    disabled={
                      this.state.maintenanceRequest.status === "Canceled"
                    }
                    compact
                    attached="bottom"
                    onClick={this.setMaintenanceNotes}
                  >
                    {" "}
                    <img src="/assets/img/icons-plane.svg" alt="send-btn"></img>
                  </Button>
                </Card.Content>
              </Card>

              <Card className="log-wrap">
                <Card.Header className="sub-header">
                  <Header as="h2" textAlign="center">
                    Change Log
                  </Header>
                </Card.Header>
                <Card.Content>
                  <Feed>
                    {statusUpdates.map((logs) => {
                      const { prevValue, newValue, subject, created } =
                        logs.node;
                      var startD = newValue.split("__").shift();
                      var endD = newValue.split("__").pop();
                      var CombineDate =
                        moment(startD).local().format("MM-DD-YYYY hh:mm A") +
                        " - " +
                        moment(endD).local().format("MM-DD-YYYY hh:mm A");
                      var startDate = moment(startD)
                        .local()
                        .format("MM-DD-YYYY hh:mm A");
                      return (
                        <Feed.Event>
                          <Feed.Content>
                            <div className="left-col">
                              <Feed.Summary content={subject} />
                              {CombineDate !==
                                "Invalid date - Invalid date" && (
                                <Feed.Extra text>
                                  {!newValue.includes("__")
                                    ? startDate
                                    : CombineDate}
                                </Feed.Extra>
                              )}
                              {CombineDate ===
                                "Invalid date - Invalid date" && (
                                <Feed.Extra text>
                                  {moment(prevValue)
                                    .local()
                                    .format("MM-DD-YYYY hh:mm A") ===
                                  "Invalid date"
                                    ? prevValue
                                    : moment(prevValue)
                                        .local()
                                        .format("MM-DD-YYYY hh:mm A")}{" "}
                                  ->{" "}
                                  {moment(newValue)
                                    .local()
                                    .format("MM/DD/YYYY") === "Invalid date"
                                    ? newValue
                                    : moment(newValue)
                                        .local()
                                        .format("MM-DD-YYYY hh:mm A")}
                                </Feed.Extra>
                              )}
                            </div>
                            <Feed.Date
                              content={moment(created)
                                .local()
                                .format("MM-DD-YYYY hh:mm A")}
                            />
                          </Feed.Content>
                        </Feed.Event>
                      );
                    })}
                  </Feed>
                </Card.Content>
              </Card>
            </div>
          </>
        )}
      </>
    );
  }
}
export default withRouter(MaintenanceDetail);
