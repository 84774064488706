import gql from "graphql-tag";
import {
  propertyPlace,
  propertyLocation,
  appliancesKey,
  locationAddressKey,
} from "../utils/propertiesKeys";

// Multi Entity
export const primaryLocations = gql`
  query locations($status: String) {
    locations(status: $status) {
      edges {
        node {
          totalOccupied
          name
          id
          slPrefixNum
          slLocationId
          templateId
          organizationId
          pointsBalance
          customId
          photos
          totalUnits
          addresses {
            streetOne
            streetTwo
            city
            state
            country
            zip
          }
          status
          depositRules
          esginType
        }
      }
    }
  }
`;

export const primaryUnits = gql`
  query units($locationId: String!) {
    units(locationId: $locationId) {
      edges {
        node {
          id
          number
        }
      }
    }
  }
`;
// Single Entity
export const primaryLocation = gql`
query primaryLocation($customId: String!){
  primaryLocation(customId: $customId){
    edges {
      node {
        ndbId
        photos
        name
        fees
        totalUnits
        type
        generalAmenities
        ${locationAddressKey}
        walkScore {
          walk
          transit
          bike
        }
        propertyManagers {
          firstName
          lastName
          phoneMobile
          email
          ndbId
        }
        properties {
          ndbId
          ${propertyPlace}
          ${propertyLocation}
          points
          dateListed
          propertyType
          status
          ${appliancesKey}
        }
      }
    }
  }
}`;

export const CreateLocation = gql`
  mutation CreateLocation($input: LocationInput!) {
    createLocation(location: $input) {
      response
      locations {
        id
        name
      }
    }
  }
`;
export const AlterLocation = gql`
  mutation updateLocation($input: UpdateLocationInput!) {
    updateLocation(location: $input) {
      response
    }
  }
`;

export const unitType = gql`
  query unitType($locationId: String!) {
    unitType(locationId: $locationId) {
      edges {
        node {
          id
          type
          bathrooms
          bedrooms
          locationId
          sqft
          photos
        }
      }
    }
  }
`;

export const units = gql`
  query units($locationId: String!, $unitTypesId: String!) {
    units(locationId: $locationId, unitTypesId: $unitTypesId) {
      edges {
        node {
          id
          level
          unitTypesId
          number
          deposit
          nonRefundable
          price
        }
      }
    }
  }
`;

export const fetchEmailTemplates = gql`
  query locationUtils($locationId: String!, $templateType: String!) {
    locationUtils(locationId: $locationId, templateType: $templateType) {
      edges {
        node {
          emailTemplate
        }
      }
    }
  }
`;

export const fetchBackgroundInfo = gql`
  query locationUtils($locationId: String!, $templateType: String!) {
    locationUtils(locationId: $locationId, templateType: $templateType) {
      edges {
        node {
          applicationInformation
        }
      }
    }
  }
`;

export const getLocationType = gql`
  query {
    locationType(sort: [TYPE_ASC]) {
      edges {
        node {
          id
          type
        }
      }
    }
  }
`;

export const createLocationType = gql`
  mutation create {
    createLocationType(locationType: LocationTypeInput) {
      response
    }
  }
`;

export const deleteLocationType = gql`
  mutation update {
    deleteLocationType(locationType: DeleteLocationTypeInput) {
      response
    }
  }
`;

export const updateLocationType = gql`
  mutation updateLocationType($input: UpdateLocationTypeInput!) {
    updateLocationType(location: $input) {
      response
    }
  }
`;

export const bulkCreateLocationType = gql`
  mutation create {
    bulkCreateLocationType(locationType: [LocationTypeInput]) {
      response
    }
  }
`;

export const createAmenities = gql`
  mutation createAmenities($input: AmenitiesInput) {
    createAmenities(amenities: $input) {
      response
      amenitiesObj {
        id
        name
        locationId
        isDefault
        createdBy
      }
    }
  }
`;

export const getAmenities = gql`
  query {
    amenities {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const getPetsList = gql`
  query pets($petType: String, $distinct: Boolean) {
    pets(petType: $petType, distinct: $distinct) {
      edges {
        node {
          id
          type
          name
        }
      }
    }
  }
`;

export const getPropertyInfoAndPolicies = gql`
  query location($locationId: String!) {
    location(id: $locationId) {
      edges {
        node {
          id
          organizationId
          name
          customId
          addresses {
            streetOne
            streetTwo
            city
            state
            country
            zip
          }
          lat
          lng
          amenities
          yearBuilt
          description
          locationTypeId
          services
          photos
          petInformation
          petPolicyDocPath
          paymentPolicyDocPath
          applicationDocPath
          leaseDocPath
          depositRules
          contact
          cancellationPolicyDocPath
          hoaPolicyDocPath
          distributionNoticeDocPath
          esignDocPath
          petsPermitted
          status
          googlePlaceId
          totalUnits
          totalPrices
          totalBeds
          totalBaths
          totalSizes
          onBoardingStep
          disabled
          createdAt
          updatedAt
          bmLocationId
          esginType
        }
      }
    }
  }
`;
export const getPointsBalance = gql`
  query location($locationId: String!) {
    location(id: $locationId) {
      edges {
        node {
          pointsBalance
        }
      }
    }
  }
`;

export const getEvocalize = gql`
  query authorization($userId: String!) {
    authorization(userId: $userId) {
      token
    }
  }
`;

export const getUnitsInfo = gql`
  query units($locationId: String!) {
    units(locationId: $locationId) {
      edges {
        node {
          id
          locationId
          unitTypesId
          building
          number
          description
          bathrooms
          bedrooms
          sqft
          price
          photos
          level
          deposit
          nonRefundable
          photos
          amenities
          status
          unitType {
            id
            type
          }
        }
      }
    }
  }
`;

export const getProperties = gql`
  query locations($status: String) {
    locations(status: $status) {
      edges {
        node {
          id
          organizationId
          locationTypeId
          slLocationId
          name
          description
          yearBuilt
          services
          petInformation
          status
          petPolicyDocPath
          paymentPolicyDocPath
          cancellationPolicyDocPath
          amenities
          addresses {
            streetOne
            streetTwo
            city
            state
            country
            zip
          }
          microsite
          photos
          googlePlaceId
          petsPermitted
          totalUnits
          totalPrices
          totalBeds
          totalBaths
          totalSizes
          onBoardingStep
          disabled
          createdAt
        }
      }
    }
  }
`;

export const setupMicrosite = gql`
  mutation SetupMicrosite($microsite: SetupMicrositeInput!) {
    setupMicrosite(microsite: $microsite) {
      response
    }
  }
`;

export const statusFilteredUnits = gql`
  query units($locationId: String!, $status: String!) {
    units(locationId: $locationId, status: $status) {
      edges {
        node {
          id
          level
          unitTypesId
          number
          price
          bathrooms
          bedrooms
          sqft
          photos
          status
        }
      }
    }
  }
`;
