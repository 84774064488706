import React, { Component } from "react";
import moment from "moment";
import {
  pmSingleApplicationGql,
  applicationHistory,
  createComment,
  updateComment,
  deleteComment,
  updateBulkEdit,
  allApplicantDecisionSummary,
} from "../../store/person/applications";
import {
  createBackgroundCheckGql
} from "../../store/person/integrations";
import { fetchBackgroundInfo } from "../../store/person/properties";
import { createLease } from "../../store/person/leases";
import EditComment from "../../components/Leads/EditComment";
import EmailLead from "../../components/Modals/EmailLead";
import GiftPointsModal from "../../components/Modals/GiftPointsModal";
import TAModal from "../../components/Modals/TAModal";
import { PageLoader } from "../../components/Loader/PageLoader";
import { Loader } from "../../components/Loader/Loader";
import CreateLease from "../Lease/CreateLeasePage";
import ConditionsStatusChangeModal from "../../components/Modals/ApproveWithConditionsStatusModal";
import { getClient } from "../../init-apollo-googleFn";
// import { MAINTENANCE_URL_PHOTO } from "../../utils/constants";
import {
  APPLICATION_MANAGER,
  APPLICATION_CREATION_EMAIL_IMAGE,
  APPLICATION_COMMENT_URL,
  LEAD_CREATED_FORMAT,
  LEASE_MANAGER,
  APPLICATION_CREATION_EMAIL,
  LOCATION_UTILS,
} from "../../utils/constants";
import axios from "axios";
import getAuthToken from "../../store/auth/authUtility";
import "../Leads/ViewLead.scss";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  Divider,
  Grid,
  Feed,
  TextArea,
  Segment,
  Modal, Image,
  Header,
  Accordion,
  Button,
  Breadcrumb,
  Icon,
} from "semantic-ui-react";
import { toastFailMsg } from "../../utils/common";
import mixpanel from "mixpanel-browser";

const applicationQuery = getClient(APPLICATION_MANAGER);
const applicationComment = getClient(APPLICATION_COMMENT_URL);
const leaseManager = getClient(LEASE_MANAGER);
const BACKGROUND_INFO = getClient(LOCATION_UTILS);
const BACKGROUND_RUN = getClient(APPLICATION_CREATION_EMAIL)

class ViewApplication extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      commentLoader: false,
      sending: false,
      modalOpen: false,
      appImages: null,
      fetchComment: false,
      commentField: "",
      commentCache: [],
      date: "",
      viewopen: false,
      application: {},
      activeIndex: 0,
      decisionData: {},
      statusToPass: "",
      decisionSummary: [],
      lease: {},
      primaryActiveIndex: 0,
      coAppActiveIndex: 0,
      occupantActiveIndex: 0,
      vehicleActiveIndex: 0,
      petsActiveIndex: 0,
      backgroundCheckInfo: [],
      openLeaseModal: false,
      isShowing: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.fetchApplication();
    mixpanel.track("Manager Page Load", {
      sub: "View Application",
    });
  }

  fetchApplication = async () => {
    this.setState({ loading: true });
    const params = this.props.match.params;
    try {
      await applicationQuery
        .query({
          query: pmSingleApplicationGql,
          variables: {
            applicationId: params.applicationId,
          },
        })
        .then((res) => {
          const data = res.data.application && res.data.application.edges[0];
          data && this.setState({ application: data.node, loading: false });
          this.fetchDecisionSummary();
          this.fetchBackgroundInfo();
          this.fetchComments();
          console.log(data)
          this.getPhotos(data.node.fileUrls)
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  fetchComments = async () => {
    this.setState({ fetchComment: true, commentLoader: true });
    const { application } = this.state;
    try {
      await applicationComment
        .query({
          query: applicationHistory,
          variables: {
            applicationId: application.id,
          },
        })
        .then((res) => {
          this.setState({
            commentCache: res.data.comments && res.data.comments.edges,
            fetchComment: false,
            openLeaseModal: true,
            commentLoader: false,
          });
        });
    } catch (e) {
      this.setState({
        openLeaseModal: true,
        commentLoader: false,
        loading: false,
      });
    }
  };

  fetchDecisionSummary = async () => {
    this.setState({ loading: true });
    const { application } = this.state;
    try {
      await applicationQuery
        .query({
          query: allApplicantDecisionSummary,
          variables: {
            applicationId: application.id,
          },
        })
        .then((res) => {
          this.setState({
            decisionSummary:
              res.data.allApplicantDecisionSummary &&
              res.data.allApplicantDecisionSummary.edges,
            loading: false,
          });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };
  setOpen = () => {
    this.setState({ viewopen: true });
  };
  setClose = () => {
    this.setState({ viewopen: false });
  };

  getPhotos = (photos) => {
    let url = new URL(APPLICATION_CREATION_EMAIL_IMAGE);
    url.searchParams.append(["path"], photos);
    url.searchParams.append("location", this.props.selectedPrimary.node.id);
    axios({
      method: "get",
      url: url.href,
      headers: {
        authorization: getAuthToken(),
      },
      responseType: "JSON", // important
    }).then(async (response) => {
      const appImages = response.data.result;
      this.setState({ appImages });
    });
  };

  fetchBackgroundInfo = async () => {
    const { selectedPrimary } = this.props;
    try {
      await BACKGROUND_INFO.query({
        query: fetchBackgroundInfo,
        variables: {
          locationId: selectedPrimary && selectedPrimary.node.id,
          templateType: "application",
        },
      }).then((res) => {
        const appInfo =
          res.data.locationUtils &&
          res.data.locationUtils.edges &&
          res.data.locationUtils.edges.length &&
          res.data.locationUtils.edges[0].node.applicationInformation;
        this.setState({ backgroundCheckInfo: appInfo });
      });
    } catch (e) {
      console.log(e);
    }
  };

  createLease = (lease) => {
    this.setState({ sending: true });
    const {
      unit,
      unitChargeCode,
      petChargeCode,
      vehicleChargeCode,
      depositChargeCode,
      fixedDepositAmount,
      refundableAmount,
      startDate,
      endDate,
      frequency,
      leaseGuarantee,
      firstLastInvoice,
      unitChargeAmount,
      petChargeAmount,
      vehicleChargeAmount,
      petQuantity,
      vehicleQuantity,
      extraCharges,
      isProRata,
      unitProRata,
      petProRata,
      vehicleProRata,
      billOnDate,
      bmCustomForms,
      bmStandardForms,
    } = lease;
    const { application } = this.state;
    let dataToSend = {
      unit,
      unitChargeCode,
      petChargeCode,
      vehicleChargeCode,
      depositChargeCode,
      petQuantity,
      vehicleQuantity,
      unitAmount: Number(unitChargeAmount),
      petAmount: Number(petChargeAmount),
      vehicleAmount: Number(vehicleChargeAmount),
      deposit: Number(fixedDepositAmount),
      nonRefundable: Number(refundableAmount),
      startDate: moment(startDate).startOf("day").format().slice(0, -6),
      endDate: moment(endDate).startOf("day").format().slice(0, -6),
      leaseFrequency: frequency,
      guarantee: leaseGuarantee,
      firstLastInvoice,
      applicationId: application.id,
      extraCharges: extraCharges
        ? extraCharges.map((charge) => ({
          chargeCodeId: charge.id,
          amount: charge.amountDefault,
          quantity: charge.quantity,
          proRata: charge.proRata,
        }))
        : [],
    };
    if (
      isProRata &&
      unitProRata &&
      petProRata &&
      vehicleProRata &&
      billOnDate
    ) {
      dataToSend = {
        ...dataToSend,
        isProRata,
        unitProRata,
        vehicleProRata,
        petProRata,
        billOnDate,
      };
    }
    if (bmCustomForms && bmCustomForms.length && bmStandardForms.length) {
      dataToSend = { ...dataToSend, bmCustomForms, bmStandardForms };
    }
    try {
      mixpanel.track("Manager Application Action", {
        sub: "Create Lease from Application",
      });
      leaseManager
        .mutate({
          mutation: createLease,
          variables: {
            input: {
              lease: dataToSend,
            },
          },
        })
        .then((res) => {
          this.setState({ sending: false, loading: false });
          this.success("Lease created successfully");
          this.props.history.push({
            pathname: "/lease",
          });
        })
        .catch((e) => {
          toastFailMsg(e);
          this.setState({ sending: false });
        });
    } catch (e) {
      toastFailMsg(e);
      this.setState({ sending: false });
    }
  };

  submitComment = () => {
    const { commentField, application } = this.state;
    try {
      mixpanel.track("Manager Application Action", {
        sub: "Create Comment",
      });
      applicationComment
        .mutate({
          mutation: createComment,
          variables: {
            input: {
              comment: {
                message: commentField,
                applicationId: application.id,
              },
            },
          },
        })
        .then((res) => {
          const { user } = this.props;
          this.setState({
            commentField: "",
            commentCache: [
              {
                node: {
                  created: moment().format(),
                  subject: `Comment Added by ${user.firstName} ${user.lastName}`,
                  message: commentField,
                  id: res.data.createComment.comment.id,
                  commentType: "comment",
                },
              },
              ...this.state.commentCache,
            ],
          });
          this.success("Comment added successfully");
          setTimeout(() => {
            this.fetchComments();
          }, 200);
        });
    } catch (e) {
      alert(e);
      console.log(e);
    }
  };

  handleChange(event) {
    this.setState({ commentField: event.target.value });
  }

  handleClick = (e, titleProps) => {
    const { index, type } = titleProps;
    this.setState({
      primaryActiveIndex: -1,
      coAppActiveIndex: -1,
      occupantActiveIndex: -1,
      vehicleActiveIndex: -1,
      petsActiveIndex: -1,
    });
    const newIndex = this.state[type] === index ? -1 : index;
    this.setState({ [type]: newIndex });
  };

  deleteComment = (event) => {
    const { commentCache } = this.state;
    try {
      mixpanel.track("Manager Application Action", {
        sub: "Delete Comment",
      });
      applicationComment
        .mutate({
          mutation: deleteComment,
          variables: {
            input: {
              comment: {
                commentId: event.node.id,
              },
            },
          },
        })
        .then((res) => {
          this.state.commentCache.forEach((comment) => {
            if (event.node.id === comment.node.id) {
              const arr = commentCache.filter(
                (comment) => comment.node.id !== event.node.id
              );
              this.setState({ commentCache: arr });
            }
          });
          this.success("Comment deleted successfully");
        });
    } catch (e) {
      alert(e);
    }
  };

  updateComment = (event) => {
    try {
      mixpanel.track("Manager Application Action", {
        sub: "Update Comment",
      });
      applicationComment
        .mutate({
          mutation: updateComment,
          variables: {
            input: {
              comment: {
                commentId: event.existingId,
                message: event.newComment,
              },
            },
          },
        })
        .then((res) => {
          this.success("Comment updated successfully");
          this.fetchComments();
        })
        .catch((error) => {
          alert(error);
        });
    } catch (e) {
      alert(e);
    }
  };
  // Run background check
  runBackground = (id) => {
    try {
      mixpanel.track("Manager Application Action", {
        sub: "Background Check",
      });
      BACKGROUND_RUN
        .mutate({
          mutation: createBackgroundCheckGql,
          variables: {
            input: {
              applicationId:id
            },
          },
        })
        .then((res) => {
          this.success("Successfully run background check");
          // this.fetchComments();
        })
        .catch((error) => {
          toastFailMsg("Application Info is incorrect, Please update or have the Renter update");
        });
    } catch (e) {
      alert(e);
    }
  };


  /* function to move to application form on edit application click */
  redirectToForm = () => {
    if (this.state.application !== undefined) {
      this.props.history.push({
        pathname: "/applicationForm",
        data: this.state.application,
        backgroundData: this.state.backgroundCheckInfo,
      });
    }
  };

  /* eslint-disable */
  showBackgroundInfo = () => {
    const { application, backgroundCheckInfo } = this.state;
    let arr = [];
    backgroundCheckInfo.filter((ele) => {
      application &&
        application.primaryApplicant.backgroundChecks.checks.forEach((item) => {
          if (ele.id === item) return arr.push(ele);
        });
    });

    return arr.map((ele) => <p>{ele.background_question}</p>);
  };
  /* eslint-enable */

  /* function called on the selection of the changed status from the deny, cancel button
    calls updateBulkEdit mutation */
  changeAppStatus = async (value, status) => {
    const { application } = this.state;
    let input = {};
    status
      ? (input = {
        application: {
          ids: application.id,
          status: status,
          conditions: value,
          locationId:
            this.props.selectedPrimary && this.props.selectedPrimary.node.id,
        },
      })
      : (input = {
        application: {
          ids: application.id,
          status: value,
          locationId:
            this.props.selectedPrimary && this.props.selectedPrimary.node.id,
        },
      });
    this.setState({ loading: true });
    try {
      mixpanel.track("Manager Application Action", {
        sub: "Bulk Edit",
      });
      await applicationQuery
        .mutate({
          mutation: updateBulkEdit,
          variables: {
            input,
          },
        })
        .then((res) => {
          this.setState({ loading: false });
          this.fetchApplication();
        });
    } catch (e) {
      alert(e);
      this.setState({ loading: false });
      // this.fail();
    }
  };

  toggle = () => {
    mixpanel.track("Manager Application Action", {
      sub: "Create Lease Button Clicked",
    });
    this.props.history.push({
      pathname:
        "/createLease/location=" +
        this.props.selectedPrimary.node.id +
        "/id=" +
        this.state.application.id,
      locationId:
        this.props.selectedPrimary.node && this.props.selectedPrimary.node.id,
      isBluemoon:
        this.props.selectedPrimary.node &&
        this.props.selectedPrimary.node.esginType === "bluemoon",
      isDocuSign:
        this.props.selectedPrimary.node &&
        this.props.selectedPrimary.node.esginType === "docusign",
      application: this.state.application,
      createLease: this.createLease,
      location: this.props.selectedPrimary && this.props.selectedPrimary.node,
    });
  };

  fail = () =>
    toast.error("Error", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  success = (msg) =>
    toast.success(msg, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  successSent = () =>
    toast.success("Application Emailed!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  handleClose = () => this.setState({ modalOpen: false });

  requirements = () => {
    const {
      application,
      primaryActiveIndex,
      coAppActiveIndex,
      occupantActiveIndex,
      vehicleActiveIndex,
      petsActiveIndex,
    } = this.state;
    const PrimaryApplicant = application.primaryApplicant;

    return (
      <>
        <div className="form-data-wrapper">
          <div className="form-data-col">
            <p className="form-data-label">Floor Plan</p>
            <p className="form-data-text">
              {application.unitType ? application.unitType.type : "NA"}
            </p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Unit Number</p>
            <p className="form-data-text">
              {application.unitNumber ? application.unitNumber.number : "NA"}
            </p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Bedrooms</p>
            <p className="form-data-text">
              {application.unitType ? application.unitType.bedrooms : "NA"}
            </p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Bathrooms</p>
            <p className="form-data-text">
              {application.unitType ? application.unitType.bathrooms : "NA"}
            </p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Floor</p>
            <p className="form-data-text">
              {application.unitNumber ? application.unitNumber.level : "NA"}
            </p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Affordable Housing Program</p>
            <p className="form-data-text">
              {application.affordableHousingProgram
                ? application.affordableHousingProgram.code
                : "NA"}
            </p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Leasing Agent</p>
            <p className="form-data-text">
              {application.leasingAgent
                ? application.leasingAgent.firstName +
                " " +
                application.leasingAgent.lastName
                : "NA"}
            </p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Move In date</p>
            <p className="form-data-text">
              {application.targetMoveInDate
                ? application.targetMoveInDate
                : "NA"}
            </p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Liquid Filled Furniture?</p>
            <p className="form-data-text">
              {application.liquidFilledFurniture ? "Yes" : "No"}
            </p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Adults</p>
            <p className="form-data-text">{application.adults}</p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Children</p>
            <p className="form-data-text">{application.children}</p>
          </div>
          <div className="form-data-col">
            <p className="form-data-label">Pets</p>
            <p className="form-data-text">{application.petsNumber}</p>
          </div>
          <div className="form-data-col border-0">
            <p className="form-data-label">Does any occupant smoke?</p>
            <p className="form-data-text">{application.smoke ? "Yes" : "No"}</p>
          </div>
        </div>
        <Accordion fluid styled className="custom-accordian mt-25">
          <Accordion.Title
            active={primaryActiveIndex === 0}
            index={0}
            type="primaryActiveIndex"
            onClick={this.handleClick}
          >
            <span className="first-lastname-text">
              {PrimaryApplicant && PrimaryApplicant.firstName}{" "}
              {PrimaryApplicant && PrimaryApplicant.lastName}
            </span>
            <div>
              <span className="co-applicant-text">Primary Applicant</span>
              <Icon name="chevron right" className="mr-0" />
            </div>
          </Accordion.Title>
          <Accordion.Content active={primaryActiveIndex === 0} className="p-0">
            <div className="applicant-info-box">
              <div className="applicant-info-col">
                <p className="applicant-info-label">Mobile Phone</p>
                <p className="applicant-info-text">
                  {PrimaryApplicant && PrimaryApplicant.phoneMobile}
                </p>
              </div>
              <div className="applicant-info-col">
                <p className="applicant-info-label">Email</p>
                <p className="applicant-info-text">
                  {PrimaryApplicant && PrimaryApplicant.email}
                </p>
              </div>
              <div className="applicant-info-col">
                <p className="applicant-info-label">Government ID Type</p>
                <p className="applicant-info-text">
                  {PrimaryApplicant &&
                    PrimaryApplicant.drivingLicence &&
                    PrimaryApplicant.drivingLicence.idType}
                </p>
              </div>
              <div className="applicant-info-col">
                <p className="applicant-info-label">Drivers License/ID #</p>
                <p className="applicant-info-text">
                  {PrimaryApplicant &&
                    PrimaryApplicant.drivingLicence &&
                    PrimaryApplicant.drivingLicence.number}
                </p>
              </div>
              <div className="applicant-info-col">
                <p className="applicant-info-label">Issuing State</p>
                <p className="applicant-info-text">
                  {PrimaryApplicant &&
                    PrimaryApplicant.drivingLicence &&
                    PrimaryApplicant.drivingLicence.issuingState}
                </p>
              </div>
              <div className="applicant-info-col">
                <p className="applicant-info-label">Date of Birth</p>
                <p className="applicant-info-text">
                  {PrimaryApplicant && PrimaryApplicant.dateOfBirth
                    ? PrimaryApplicant.dateOfBirth
                    : "NA"}
                </p>
              </div>
              <div className="applicant-info-col">
                <p className="applicant-info-label">Marital Status</p>
                <p className="applicant-info-text">
                  {PrimaryApplicant && PrimaryApplicant.maritalStatus
                    ? PrimaryApplicant.maritalStatus
                    : "NA"}
                </p>
              </div>
            </div>
            <div className="review-address-heading">Address Details</div>
            {PrimaryApplicant &&
              PrimaryApplicant.rentalHistory && PrimaryApplicant.rentalHistory.map((ele, index) => (
                <>
                  <p className="review-address-paragraph">
                    Address {index + 1}
                  </p>
                  <div className="applicant-info-box">
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Address</p>
                      <p className="applicant-info-text">
                        {`${ele.address !== null ? ele.address.streetLine1 : ""}
                        ${ele.address !== null ? ele.address.city : ""}
                        ${ele.address !== null ? ele.address.state : ""}
                        ${ele.address !== null ? ele.address.postcode : ""}`}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Monthly Rent</p>
                      <p className="applicant-info-text">
                        {ele.monthlyRent ? ele.monthlyRent : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">
                        Duration of Residence
                      </p>
                      <p className="applicant-info-text">{`${ele.startDate} - ${ele.isCurrentlyResiding
                        ? "Currently Residing"
                        : ele.endDate
                        }`}</p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Landlord Name</p>
                      <p className="applicant-info-text">{ele.landlordName}</p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Landlord Email</p>
                      <p className="applicant-info-text">
                        {ele.landlordEmail ? ele.landlordEmail : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col border-0">
                      <p className="applicant-info-label">Landlord Phone</p>
                      <p className="applicant-info-text">
                        {ele.landlordPhone ? ele.landlordPhone : "NA"}
                      </p>
                    </div>
                  </div>
                </>
              ))}
            <div className="review-address-heading">Employment Details</div>
            {PrimaryApplicant &&
              PrimaryApplicant.employmentHistory && PrimaryApplicant.employmentHistory.map((ele, index) => (
                <>
                  <p className="review-address-paragraph">
                    Employment {index + 1}
                  </p>
                  <div className="applicant-info-box">
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Address</p>
                      <p className="applicant-info-text">
                        {`${ele.address !== null ? ele.address.streetLine1 : ""
                          }, ${ele.address !== null ? ele.address.city : ""}, ${ele.address !== null ? ele.address.state : ""
                          }, ${ele.address !== null ? ele.address.postcode : ""}`}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Occupation</p>
                      <p className="applicant-info-text">{ele.title}</p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Employer/Company</p>
                      <p className="applicant-info-text">{ele.employerName}</p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Monthly Income</p>
                      <p className="applicant-info-text">{ele.monthlyIncome}</p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">
                        Duration of Employment
                      </p>
                      <p className="applicant-info-text">{`${ele.startDate} - ${ele.isCurrentlyWorking
                        ? "Currently Working"
                        : ele.endDate
                        }`}</p>
                    </div>
                    <div className="applicant-info-col border-0">
                      <p className="applicant-info-label">Supervisor Name</p>
                      <p className="applicant-info-text">
                        {ele.supervisorName}
                      </p>
                    </div>
                    <div className="applicant-info-col border-0">
                      <p className="applicant-info-label">Supervisor Email</p>
                      <p className="applicant-info-text">
                        {ele.supervisorEmail ? ele.supervisorEmail : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col border-0">
                      <p className="applicant-info-label">Supervisor Phone</p>
                      <p className="applicant-info-text">
                        {ele.supervisorPhone ? ele.supervisorPhone : "NA"}
                      </p>
                    </div>
                  </div>
                </>
              ))}
            <div className="review-address-heading">Other Incomes</div>
            {PrimaryApplicant &&
              PrimaryApplicant.otherIncomes &&
              PrimaryApplicant.otherIncomes.map((ele, index) => (
                <div className="applicant-info-box">
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Source</p>
                    <p className="applicant-info-text">
                      {ele.source ? ele.source : "NA"}
                    </p>
                  </div>
                  <div className="applicant-info-col border-0">
                    <p className="applicant-info-label">Monthly Income</p>
                    <p className="applicant-info-text">
                      {ele.monthlyIncome ? ele.monthlyIncome : "NA"}
                    </p>
                  </div>
                </div>
              ))}
            {PrimaryApplicant &&
              PrimaryApplicant.backgroundChecks &&
              PrimaryApplicant.backgroundChecks.checks &&
              PrimaryApplicant.backgroundChecks.checks.length > 0 && (
                <>
                  <div className="review-address-heading">
                    Background Information
                  </div>
                  {this.showBackgroundInfo()}
                </>
              )}
            <div className="review-address-heading">Emergency Contact</div>
            <div className="applicant-info-box">
              <div className="applicant-info-col">
                <p className="applicant-info-label">Name</p>
                <p className="applicant-info-text">
                  {PrimaryApplicant &&
                    PrimaryApplicant.emergencyContact &&
                    PrimaryApplicant.emergencyContact.name}
                </p>
              </div>
              <div className="applicant-info-col">
                <p className="applicant-info-label">Mobile Phone</p>
                <p className="applicant-info-text">
                  {PrimaryApplicant &&
                    PrimaryApplicant.emergencyContact &&
                    PrimaryApplicant.emergencyContact.mobilePhone}
                </p>
              </div>
              <div className="applicant-info-col">
                <p className="applicant-info-label">Email</p>
                <p className="applicant-info-text">
                  {PrimaryApplicant &&
                    PrimaryApplicant.emergencyContact &&
                    PrimaryApplicant.emergencyContact.email}
                </p>
              </div>
              <div className="applicant-info-col">
                <p className="applicant-info-label">Relationship</p>
                <p className="applicant-info-text">
                  {PrimaryApplicant &&
                    PrimaryApplicant.emergencyContact &&
                    PrimaryApplicant.emergencyContact.relationship}
                </p>
              </div>
              <div className="applicant-info-col border-0">
                <p className="applicant-info-label">Address</p>
                <p className="applicant-info-text">
                  {PrimaryApplicant &&
                    PrimaryApplicant.emergencyContact &&
                    PrimaryApplicant.emergencyContact.address
                    ? `${PrimaryApplicant.emergencyContact.address.streetLine1},
                        ${PrimaryApplicant.emergencyContact.address.city},
                        ${PrimaryApplicant.emergencyContact.address.state},
                        ${PrimaryApplicant.emergencyContact.address.postcode}`
                    : ""}
                </p>
              </div>
            </div>
          </Accordion.Content>
          {application.coApplicants &&
            application.coApplicants.length > 0 &&
            application.coApplicants.map((ele, index) => (
              <>
                <Accordion.Title
                  active={coAppActiveIndex === index + 1}
                  index={index + 1}
                  type="coAppActiveIndex"
                  onClick={this.handleClick}
                >
                  <span className="first-lastname-text">
                    {ele.firstName} {ele.lastName}
                  </span>
                  <div>
                    <span className="co-applicant-text">{`Co-Applicant ${index + 1
                      }`}</span>
                    <Icon name="chevron right" className="mr-0" />
                  </div>
                </Accordion.Title>
                <Accordion.Content
                  active={coAppActiveIndex === index + 1}
                  className="p-0"
                >
                  <div className="applicant-info-box">
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Mobile Phone</p>
                      <p className="applicant-info-text">
                        {ele.phoneMobile ? ele.phoneMobile : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Email</p>
                      <p className="applicant-info-text">
                        {ele.email ? ele.email : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Government ID Type</p>
                      <p className="applicant-info-text">
                        {ele.drivingLicence && ele.drivingLicence.idType
                          ? ele.drivingLicence.idType
                          : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">
                        Drivers License/ID #
                      </p>
                      <p className="applicant-info-text">
                        {ele.drivingLicence && ele.drivingLicence.number
                          ? ele.drivingLicence.number
                          : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Issuing State</p>
                      <p className="applicant-info-text">
                        {ele.drivingLicence && ele.drivingLicence.issuingState
                          ? ele.drivingLicence.issuingState
                          : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Date of Birth</p>
                      <p className="applicant-info-text">
                        {ele.dateOfBirth ? ele.dateOfBirth : "NA"}
                      </p>
                    </div>
                    <div className="applicant-info-col">
                      <p className="applicant-info-label">Marital Status</p>
                      <p className="applicant-info-text">
                        {ele.maritalStatus ? ele.maritalStatus : "NA"}
                      </p>
                    </div>
                  </div>
                  <div className="review-address-heading">Address Details</div>
                  {ele.rentalHistory && ele.rentalHistory.map((ele, index) => (
                    <>
                      <p className="review-address-paragraph">
                        Address {index + 1}
                      </p>
                      <div className="applicant-info-box">
                        <div className="applicant-info-col">
                          <p className="applicant-info-label">Address</p>
                          <p className="applicant-info-text">
                            {`${ele.address && ele.address.streetLine1},
                        ${ele.address && ele.address.city},
                        ${ele.address && ele.address.state},
                        ${ele.address && ele.address.postcode}`}
                          </p>
                        </div>
                        <div className="applicant-info-col">
                          <p className="applicant-info-label">Monthly Rent</p>
                          <p className="applicant-info-text">
                            {ele.monthlyRent ? ele.monthlyRent : "NA"}
                          </p>
                        </div>
                        <div className="applicant-info-col">
                          <p className="applicant-info-label">
                            Duration of Residence
                          </p>
                          <p className="applicant-info-text">{`${ele.startDate
                            } - ${ele.isCurrentlyResiding
                              ? "Currently Residing"
                              : ele.endDate
                            }`}</p>
                        </div>
                        <div className="applicant-info-col">
                          <p className="applicant-info-label">Landlord Name</p>
                          <p className="applicant-info-text">
                            {ele.landlordName}
                          </p>
                        </div>
                        <div className="applicant-info-col">
                          <p className="applicant-info-label">Landlord Email</p>
                          <p className="applicant-info-text">
                            {ele.landlordEmail ? ele.landlordEmail : "NA"}
                          </p>
                        </div>
                        <div className="applicant-info-col border-0">
                          <p className="applicant-info-label">Landlord Phone</p>
                          <p className="applicant-info-text">
                            {ele.landlordPhone ? ele.landlordPhone : "NA"}
                          </p>
                        </div>
                      </div>
                    </>
                  ))}
                  {ele.isFinanciallyResponsible && (
                    <div className="review-address-heading">
                      Employment Details
                    </div>
                  )}
                  {ele.isFinanciallyResponsible &&
                    ele.employmentHistory.map((ele, index) => (
                      <>
                        <p className="review-address-paragraph">
                          Employment {index + 1}
                        </p>
                        <div className="applicant-info-box">
                          <div className="applicant-info-col">
                            <p className="applicant-info-label">Address</p>
                            <p className="applicant-info-text">
                              {`${ele.address && ele.address.streetLine1}, ${ele.address && ele.address.city
                                }, ${ele.address && ele.address.state}, ${ele.address && ele.address.postcode
                                }`}
                            </p>
                          </div>
                          <div className="applicant-info-col">
                            <p className="applicant-info-label">Occupation</p>
                            <p className="applicant-info-text">{ele.title}</p>
                          </div>
                          <div className="applicant-info-col">
                            <p className="applicant-info-label">
                              Employer/Company
                            </p>
                            <p className="applicant-info-text">
                              {ele.employerName}
                            </p>
                          </div>
                          <div className="applicant-info-col">
                            <p className="applicant-info-label">
                              Monthly Income
                            </p>
                            <p className="applicant-info-text">
                              {ele.monthlyIncome}
                            </p>
                          </div>
                          <div className="applicant-info-col">
                            <p className="applicant-info-label">
                              Duration of Employment
                            </p>
                            <p className="applicant-info-text">{`${ele.startDate
                              } - ${ele.isCurrentlyWorking
                                ? "Currently Working"
                                : ele.endDate
                              }`}</p>
                          </div>
                          <div className="applicant-info-col border-0">
                            <p className="applicant-info-label">
                              Supervisor Name
                            </p>
                            <p className="applicant-info-text">
                              {ele.supervisorName}
                            </p>
                          </div>
                          <div className="applicant-info-col border-0">
                            <p className="applicant-info-label">
                              Supervisor Email
                            </p>
                            <p className="applicant-info-text">
                              {ele.supervisorEmail ? ele.supervisorEmail : "NA"}
                            </p>
                          </div>
                          <div className="applicant-info-col border-0">
                            <p className="applicant-info-label">
                              Supervisor Phone
                            </p>
                            <p className="applicant-info-text">
                              {ele.supervisorPhone ? ele.supervisorPhone : "NA"}
                            </p>
                          </div>
                        </div>
                      </>
                    ))}
                  {application.isFinanciallyResponsible && (
                    <div className="review-address-heading">Other Incomes</div>
                  )}
                  {application.isFinanciallyResponsible &&
                    ele.otherIncomes.map((ele, index) => (
                      <div className="applicant-info-box">
                        <div className="applicant-info-col">
                          <p className="applicant-info-label">Source</p>
                          <p className="applicant-info-text">
                            {ele.source ? ele.source : "NA"}
                          </p>
                        </div>
                        <div className="applicant-info-col border-0">
                          <p className="applicant-info-label">Monthly Income</p>
                          <p className="applicant-info-text">
                            {ele.monthlyIncome ? ele.monthlyIncome : "NA"}
                          </p>
                        </div>
                      </div>
                    ))}
                </Accordion.Content>
              </>
            ))}
          {application.occupants && application.occupants.map((ele, index) => (
            <>
              <Accordion.Title
                active={occupantActiveIndex === index + 2}
                index={index + 2}
                type="occupantActiveIndex"
                onClick={this.handleClick}
              >
                <div className="ml-auto">
                  <span className="co-applicant-text">{`Occupant ${index + 1
                    }`}</span>
                  <Icon name="chevron right" className="mr-0" />
                </div>
              </Accordion.Title>
              <Accordion.Content active={occupantActiveIndex === index + 2}>
                <div className="applicant-info-box">
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">First Name</p>
                    <p className="applicant-info-text">{ele.firstName}</p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Last Name</p>
                    <p className="applicant-info-text">{ele.lastName}</p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Date of Birth</p>
                    <p className="applicant-info-text">{ele.dateOfBirth}</p>
                  </div>
                </div>
              </Accordion.Content>
            </>
          ))}
          {application.pets && application.pets.map((ele, index) => (
            <>
              <Accordion.Title
                active={petsActiveIndex === index + 3}
                index={index + 3}
                type="petsActiveIndex"
                onClick={this.handleClick}
              >
                <div className="ml-auto">
                  <span className="co-applicant-text">{`Pet ${index + 1
                    }`}</span>
                  <Icon name="chevron right" className="mr-0" />
                </div>
              </Accordion.Title>
              <Accordion.Content active={petsActiveIndex === index + 3}>
                <div className="applicant-info-box">
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Name</p>
                    <p className="applicant-info-text">{ele.name}</p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Pet Type</p>
                    <p className="applicant-info-text">{ele.petType}</p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Weight</p>
                    <p className="applicant-info-text">{ele.weight}</p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Color</p>
                    <p className="applicant-info-text">
                      {ele.color ? ele.color : "NA"}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Breed</p>
                    <p className="applicant-info-text">{ele.breed}</p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Gender</p>
                    <p className="applicant-info-text">
                      {ele.gender ? ele.gender : "NA"}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Age</p>
                    <p className="applicant-info-text">{ele.age}</p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Service?</p>
                    <p className="applicant-info-text">
                      {ele.service ? "True" : "False"}
                    </p>
                  </div>
                </div>
              </Accordion.Content>
            </>
          ))}
          {application.vehicles && application.vehicles.map((ele, index) => (
            <>
              <Accordion.Title
                active={vehicleActiveIndex === index + 4}
                index={index + 4}
                type="vehicleActiveIndex"
                onClick={this.handleClick}
              >
                <div className="ml-auto">
                  <span className="co-applicant-text">{`Vehicle ${index + 1
                    }`}</span>
                  <Icon name="chevron right" className="mr-0" />
                </div>
              </Accordion.Title>
              <Accordion.Content active={vehicleActiveIndex === index + 4}>
                <div className="applicant-info-box">
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Make</p>
                    <p className="applicant-info-text">
                      {ele.make ? ele.make : "NA"}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Model</p>
                    <p className="applicant-info-text">
                      {ele.model ? ele.model : "NA"}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Year</p>
                    <p className="applicant-info-text">
                      {ele.year ? ele.year : "NA"}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Color</p>
                    <p className="applicant-info-text">
                      {ele.color ? ele.color : "NA"}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">Licence Plate</p>
                    <p className="applicant-info-text">
                      {ele.licensePlate ? ele.licensePlate : "NA"}
                    </p>
                  </div>
                  <div className="applicant-info-col">
                    <p className="applicant-info-label">State</p>
                    <p className="applicant-info-text">
                      {ele.state ? ele.state : "NA"}
                    </p>
                  </div>
                </div>
              </Accordion.Content>
            </>
          ))}
        </Accordion>
      </>
    );
  };

  decisionSummaryRender = (ele) => (
    <>
      <Header className="comments-head">Decision Summary</Header>
      <span>{ele && ele.node.firstName + " " + ele && ele.node.lastName}</span>
      <div className="decision-summary-box">
        <div className="decision-summary-col">
          <p className="decision-summary-label">Screening Decision</p>
          <p className="decision-summary-text">
            {ele && ele.node.taApplicationStatus}
          </p>
        </div>
        <div className="decision-summary-col">
          <p className="decision-summary-label">Decision Date/Time</p>
          <p className="decision-summary-text">
            {ele &&
              moment(ele.node.taApplicationTimestamp).format(
                LEAD_CREATED_FORMAT
              )}
          </p>
        </div>
        <div className="decision-summary-col">
          <p className="decision-summary-label">Application Score</p>
          <p className="decision-summary-text">
            {ele && ele.node.taApplicationScore}/100
          </p>
        </div>
        <div className="decision-summary-col w-100">
          <p className="decision-summary-label">Decision Notes</p>
          <p className="decision-summary-text">
            {ele && ele.node.taApplicationNote}
          </p>
        </div>
      </div>
    </>
  );

  render() {
    const { application, fetchComment, isShowing, decisionSummary, loading } =
      this.state;
    const { user, selectedPrimary } = this.props;
    const Bread = () => (
      <Breadcrumb className="custom-breadcrumb">
        <Breadcrumb.Section link>
          <NavLink exact to="/applications">
            Applications
          </NavLink>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>
          {" "}
          {application.primaryApplicant && application.primaryApplicant.lastName
            ? application.primaryApplicant.lastName
            : "Application"}
        </Breadcrumb.Section>
      </Breadcrumb>
    );

    const TopGrid = () => (
      <>
        <Bread />
        <div className="lead-profile-head">
          <Header as="h1">
            {`${application.primaryApplicant &&
              application.primaryApplicant.lastName
              }, ${application.primaryApplicant &&
              application.primaryApplicant.firstName
              }`}
          </Header>
          <p className="application-status">{application.status}</p>
        </div>

        <div className="lead-profile-body">
          <div className="lead-profile-body-img">
            <i aria-hidden="true" class="user icon"></i>
            {/* <Image src='/assets/img/avatar.jpg' fluid /> */}
          </div>
          <div className="lead-profile-info">
            <Header as="h3">
              <a
                href={"tel:" + application.phoneMobile}
                title={application.phoneMobile}
              >
                <i aria-hidden="true" class="phone icon"></i>
                {application &&
                  application.primaryApplicant &&
                  application.primaryApplicant.phoneMobile}
              </a>
            </Header>
            <Header as="h3">
              <a href={"mailto:" + application.email} title={application.email}>
                <i aria-hidden="true" class="mail icon"></i>
                {application &&
                  application.primaryApplicant &&
                  application.primaryApplicant.email}
              </a>
            </Header>
            {application.primaryApplicant &&
              application.primaryApplicant.rentalHistory &&
              application.primaryApplicant.rentalHistory[0] && (
                <Header
                  as="h3"
                  icon="location arrow"
                  content={`${application.primaryApplicant.rentalHistory[0].address
                    ? application.primaryApplicant.rentalHistory[0].address
                      .streetLine1
                    : ""
                    }
                  ${application.primaryApplicant.rentalHistory[0].address &&
                      application.primaryApplicant.rentalHistory[0].address
                        .streetLine2 !== null
                      ? application.primaryApplicant.rentalHistory[0].address
                        .streetLine2
                      : ""
                    }
                  ${application.primaryApplicant.rentalHistory[0].address
                      ? application.primaryApplicant.rentalHistory[0].address
                        .city
                      : ""
                    }
                ${application.primaryApplicant.rentalHistory[0].address
                      ? application.primaryApplicant.rentalHistory[0].address
                        .state
                      : ""
                    }
                  ${application.primaryApplicant.rentalHistory[0].address
                      ? application.primaryApplicant.rentalHistory[0].address
                        .postcode
                      : ""
                    }`}
                />
              )}
            <Header as="h4">
              Application Created: {moment(application.created).format("lll")}
            </Header>
          </div>
        </div>
        <Divider className="lead-details-divider" />
      </>
    );

    const ButtonGroup = () => (
      <Grid stackable width={2}>
        <Grid.Row stackable className="mx-0">
          <Grid.Column stackable className="full-button-wrapper">
            <GiftPointsModal
              location={
                selectedPrimary &&
                selectedPrimary.node &&
                selectedPrimary.node.id
              }
              user={user.ndbId}
              recipient={
                application.primaryApplicant &&
                application.primaryApplicant.email
              }
            />
            {application.status !== "Denied" &&
              application.status !== "Cancelled" &&
              application.status !== "Current Resident" && (
                <Button
                  size="large"
                  floated="right"
                  className="dark-button"
                  onClick={this.redirectToForm}
                >
                  Edit Application
                </Button>
              )}
            <EmailLead
              successSent={this.successSent}
              disabled={fetchComment}
              fetchComment={fetchComment}
              appId={application.id}
              location={
                selectedPrimary &&
                selectedPrimary.node &&
                selectedPrimary.node.id
              }
              pmContact={user}
              appEmail={
                application.primaryApplicant &&
                application.primaryApplicant.email
              }
            />
            {application.status !== "Denied" &&
              application.status !== "Cancelled" && (
                <Button
                  size="large"
                  floated="right"
                  className="dark-button"
                  onClick={() => this.changeAppStatus("Denied")}
                >
                  Deny Application
                </Button>
              )}
            {application.status !== "Denied" &&
              application.status !== "Cancelled" && (
                <Button
                  size="large"
                  floated="right"
                  className="dark-button"
                  onClick={() => this.changeAppStatus("Cancelled")}
                >
                  Cancel Application
                </Button>
              )}
            {/* {application.primaryApplicant &&(
              <Button
                size="large"
                floated="right"
                className="dark-button"
                onClick={() => this.runBackground(application.id)}
              >
                Run Background
              </Button>
            )} */}
            {application.status === "New" && (
              <Button
                size="large"
                floated="right"
                className="dark-button"
                onClick={() => this.changeAppStatus("Under Review")}
              >
                Under Review
              </Button>
            )}
            {(application.status === "Under Review" ||
              application.status === "Approve w/Conditions") && (
                <Button
                  size="large"
                  floated="right"
                  className="dark-button"
                  onClick={() => this.changeAppStatus("Approved")}
                >
                  Approved
                </Button>
              )}
            {application.status === "Under Review" && (
              <ConditionsStatusChangeModal
                approvalConditions={this.changeAppStatus}
              />
            )}
            {(application.status === "Approve w/Conditions" ||
              application.status === "Approved") && (
                <Button
                  size="large"
                  floated="right"
                  className="dark-button"
                  onClick={() => this.changeAppStatus("New")}
                >
                  Unapprove
                </Button>
              )}
            {(application.status === "Approve w/Conditions" ||
              application.status === "Approved" ||
              application.status === "Current Resident") &&
              this.state.openLeaseModal &&
              !application.isLeaseCreated && (
                <Button
                  size="large"
                  floated="right"
                  className="dark-button"
                  onClick={this.toggle}
                >
                  Create Lease
                </Button>
              )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
    return (
      <>
        {loading ? (
          <PageLoader text />
        ) : (
          <>
            {/* {commentLoader && <PageLoader >Gathering Data...</PageLoader>} */}
            <TopGrid />

            <ButtonGroup />

            {isShowing &&
              (application.status === "Approve w/Conditions" ||
                application.status === "Approved" ||
                application.status === "Current Resident") &&
              this.state.openLeaseModal &&
              !application.isLeaseCreated && (
                <CreateLease sending={this.state.sending} />
              )}
            {this.state.application && this.requirements()}
            <Header>Documents</Header>
            <Segment>
              <Grid  textAlign="center" columns="3">

              
            {this.state.appImages &&
              this.state.appImages.map((image) => {
                return (
                  <Grid.Column
                    // style={{
                    //   width: "120px",
                    //   maxHeight: "auto",
                    //   minHeight: "120px",
                    //   overflow: "hidden",
                    // }}
                    textAlign="center"
                  >
                    <Modal
                      className="semanticModal"
                      onClose={() => this.setOpen(false)}
                      onOpen={() => this.setOpen(true)}
                      size="small"
                      open={this.state.viewopen}
                      trigger={
                        <div>
                          <Image
                            src={`data:${image}`}
                            style={{
                              height: "90px",
                              maxWidth: "100%",
                              maxHeight: "90px",
                            }}
                            size="small"
                            onClick={() =>
                              this.setState({ maintImage: image })
                            }
                          />
                        </div>
                      }
                    >
                      <Modal.Content image>
                        <Image
                          centered
                          fluid
                          src={`data:${this.state.maintImage}`}
                          size="large"
                        />
                      </Modal.Content>
                      <Modal.Actions>
                        <Button
                          className="ok-btn"
                          onClick={() => this.setClose(true)}
                          positive
                        >
                          OK
                        </Button>
                      </Modal.Actions>
                    </Modal>
                  </Grid.Column>
                );
              })}
              </Grid>
</Segment>
            {decisionSummary && decisionSummary.length > 0 && (
              <>
                <Header className="comments-head">Decision Summary</Header>
                {decisionSummary.map((ele, index) => (
                  <>
                    <span className="decision-summary-sub-head d-flex align-item-center justify-content-between">
                      {ele && ele.node.firstName}{" "}
                      {ele && ele.node.lastName + " -"}{" "}
                      {ele.node.isPrimaryApplicant
                        ? "Primary Applicant"
                        : `Co-Applicant ${index}`}
                      <TAModal ele={ele.node.email} />
                    </span>
                    <div className="decision-summary-box">
                      <div className="decision-summary-col">
                        <p className="decision-summary-label">
                          Screening Decision
                        </p>
                        <p className="decision-summary-text">
                          {ele && ele.node.taApplicationStatus}
                        </p>
                      </div>
                      <div className="decision-summary-col">
                        <p className="decision-summary-label">
                          Decision Date/Time
                        </p>
                        <p className="decision-summary-text">
                          {ele &&
                            moment(ele.node.taApplicationTimestamp).format(
                              LEAD_CREATED_FORMAT
                            )}
                        </p>
                      </div>
                      <div className="decision-summary-col">
                        <p className="decision-summary-label">
                          Application Score
                        </p>
                        <p className="decision-summary-text">
                          {ele && ele.node.taApplicationScore}/100
                        </p>
                      </div>
                      <div className="decision-summary-col w-100">
                        <p className="decision-summary-label">Decision Notes</p>
                        <p className="decision-summary-text">
                          {ele && ele.node.taApplicationNote}
                        </p>
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}

            <Header className="comments-head">Comments/History</Header>

            <div className="comments-textarea-wrapper">
              <div>Remaining Characters: {this.state.commentField.length}/1500</div>
              <TextArea
                className="comments-textarea"
                value={this.state.commentField}
                name="commentField"
                id="commentField"
                maxLength="1500"
                onChange={this.handleChange}
              />

              <Button
                onClick={this.submitComment}
                disabled={
                  this.state.fetchComment ||
                  !this.state.commentField.trim().length
                }
                positive
                className="update-button"
              >
                <span class="send-icon"></span>
              </Button>
            </div>


            {this.state.commentCache && this.state.commentCache.length > 0 && (
              <Feed className="history-feed">
                {this.state.fetchComment ? (
                  <Loader text size="small" active inline="centered" />
                ) : (
                  this.state.commentCache &&
                  this.state.commentCache.map((logs) => {
                    const { node } = logs;
                    return (
                      <Feed.Event>
                        {/* {
                          logs.node.commentType !== "comment" &&
                          <Feed.Label icon='wrench' />
                        } */}

                        <Feed.Content>
                          <Feed.Date
                            content={moment(node.created).local().format("lll")}
                          />
                          {this.props.user.ndbId ===
                            (node && node.person && node.person.id) &&
                            node.commentType === "comment" ? (
                            <span
                              title="Delete"
                              className="delete-icon"
                              onClick={() => this.deleteComment(logs)}
                            ></span>
                          ) : (
                            ""
                          )}
                          {node.person && (
                            <Feed.Summary>
                              {node.subject} by {node.person.firstName}{" "}
                              {node.person.lastName}
                            </Feed.Summary>
                          )}
                          {!node.person && (
                            <Feed.Summary>{node.subject}</Feed.Summary>
                          )}
                          <Feed.Extra text content={node.message} />
                        </Feed.Content>
                        {logs.node.commentType === "comment" &&
                          this.props.user.ndbId ===
                          (node && node.person && node.person.id) && (
                            <Feed.Label>
                              <EditComment
                                comment={logs}
                                updateComment={this.updateComment}
                              />
                            </Feed.Label>
                          )}
                      </Feed.Event>
                    );
                  })
                )}
              </Feed>
            )}
          </>
        )}
      </>
    );
  }
}
export default ViewApplication;
