import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { BillsAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import NewBillsModal from "../../components/Modals/NewBillsModal";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { Table } from "../../components/Tables/InvoiceData";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  Button,
} from "semantic-ui-react";
import "./Accounting.scss";
import moment from "moment";
import BillDetailsModal from "../../components/Modals/BillDetailsModal";
import PaymentModal from "../../components/Modals/PaymentModal";

const invoiceClient = getClient(ACCOUNTING);

class PayBills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      invoiceCache: [],
      tableData: [],
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Pay Bills",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  StatusFormat = (status) => {
    if (status === "approved") return "Approved";
    if (status === "created") return "Created";
    if (status === "issued") return "Issued";
    if (status === "paid") return "Paid";
    if (status === "partiallyPaid") return "Partially Paid";
    if (status === "voided") return "Voided";
    else return "";
  };

  getLedger = (data) => {
    if (!data) {
      this.setState({ loading: true });
    }
    invoiceClient
      .query({
        query: BillsAccounting,
        variables: {
          location: this.props.selectedPrimary.node.id,
          status: ["approved", "partiallyPaid"],
        },
      })
      .then((res) => {
        if (res.data) {
          const dataToPass = get(res, "data.slLocationBills.data", []).map((obj, i) => {
            const apId = obj.APAccount._id;
            const { name } = obj.Vendor;
            const { amount, dueAmount, dueDate, invoiceDate, postingDate, status} = obj;
            const uiAmount = "$ " + amount.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            const uiDueAmount = "$ " + dueAmount.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            const uiDueDate = moment(dueDate).format("l");
            const uiInvoiceDate = moment(invoiceDate).format("l");
            const uiPostingDate = moment(postingDate).format("l");
            const uiStatus = this.StatusFormat(status);
            const billDetailsModal = <BillDetailsModal bill={obj} user={this.props.user} location={this.props} bills />;
            const actions = (status !== "paid" && status !== "voided") ? <PaymentModal bill={obj} user={this.props.user} location={this.props} bills={true} /> : "";
            const edit = status === "created" ? <NewBillsModal bill={obj} user={this.props.user} location={this.props} /> : "";
            return (({ invoiceNumber }) => ({ "modal": billDetailsModal, apId, uiDueDate, uiInvoiceDate, uiPostingDate, invoiceNumber, uiAmount, uiDueAmount, uiStatus, name, actions, edit }))(obj)
          });
          this.setState({
            invoiceCache: get(res, "data.slLocationBills.data", []),
            tableData: dataToPass,
            totalItems: get(res, "data.slLocationBills.totalItems"),
          });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  };

  dataPush = (response) => {
    this.setState((prevState) => ({
      invoiceCache: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.invoiceCache,
      ],
    }));
  };
  updateInvoice = (status, invoiceId) => {
    const { invoiceCache } = this.state;
    const array = [];
    if (status === "Delete") {
      this.setState({
        invoiceCache: invoiceCache.filter(
          (invoice) => invoice._id !== invoiceId
        ),
      });
    } else {
      invoiceCache.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      this.setState({ invoiceCache: array });
    }
  };

  render() {
    const { loading } = this.state;
    const mainHeader = ["ID", "AP ID", "Due", "Invoice", "Posting", "Number", "Amount", "Amount Due", "Status", "Vendor", "Action", "Edit"];
    const fail = () =>
    toast.error("No agent with this ID exists in our system", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const success = () =>
    toast.success("Success!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    // !: Error handling.
    // TODO: Style

    return (
      <>
      <main className="main-content balance-sheet-layout" role="main">
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="page-header flex-row">
              <div className="d-flex align-items-center">
                {/* <span className="page-back"> */}
                  {/* <img src={backIcon} alt="back" /> */}
                  
                {/* </span> */}
                <div>
                  <div className="page-header-title pl-0">Pay Bills</div>
                </div>
              </div>
              <div className="d-flex align-items-end">
                {/* <div className="ml-3">
                  <Header textAlign="left">Start Date</Header>
                  <Datetime inputProps={{ placeholder: "Select Start Date" }} />
                </div>
                <div className="ml-3">
                  <Header textAlign="left">End Date</Header>
                  <Datetime inputProps={{ placeholder: "Select End Date" }} />
                </div> */}
                <NewBillsModal
                user={this.props.user}
                getLedger={this.getLedger}
                dataPush={this.dataPush}
                fail={fail}
                success={success}
                propertyId={
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.id
            }
                id={this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.slLocationId}
              />
                {/* <Button
                  className="ml-3"
                  id="getLedger"
                  style={{ background: "#5e6267", color: "white" }}
                >
                  Get Report
                </Button> */}
                <Button
                onClick={() => window.print()}
                compact
                  className="ml-4 noPrint noPrint-button"
                  style={{
                    backgroundImage:
                      "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                  }}
                >
                  Print
                </Button>
                {/* <Button className="ml-2" style={{ backgroundImage: "linear-gradient(110deg, #3b1c5a, #374db1 162%)", color: "white" }}>Share</Button> */}
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader text inTable />
        ) : (
          <div className="balance-sheet-table">
            {/* <Table
              updateInvoice={this.updateInvoice}
              getNewData={this.getNewData}
              property={
                this.props.selectedPrimary &&
                this.props.selectedPrimary.node &&
                this.props.selectedPrimary.node.customId
              }
              dataPush={this.dataPush}
              getLedger={this.getLedger}
              bills
              user={this.props.user}
              invoiceCache={this.state.invoiceCache && this.state.invoiceCache}
              totalItems={this.state.totalItems && this.state.totalItems}
              propertyId={
                this.props.selectedPrimary.node &&
                this.props.selectedPrimary.node.id
              }
              staticId={
                this.props.selectedPrimary.node &&
                this.props.selectedPrimary.node.slLocationId
              }
            /> */}
            <GeneralAccountingTable
              mainHeader={mainHeader}
              mainCellData={this.state.tableData}
              updateInvoice={this.updateInvoice}
              getNewData={this.getNewData}
              property={
                this.props.selectedPrimary &&
                this.props.selectedPrimary.node &&
                this.props.selectedPrimary.node.customId
              }
              dataPush={this.dataPush}
              getLedger={this.getLedger}
              bills
              user={this.props.user}
              totalItems={this.state.totalItems && this.state.totalItems}
              propertyId={
                this.props.selectedPrimary.node &&
                this.props.selectedPrimary.node.id
              }
              staticId={
                this.props.selectedPrimary.node &&
                this.props.selectedPrimary.node.slLocationId
              }
            />
          </div>
        )}
        </main>
      </>
    );
  }
}

export default withApollo(PayBills);
