import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { PaymentAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss";
import { Button } from "semantic-ui-react";
import moment from "moment";
const invoiceClient = getClient(ACCOUNTING);

class ARCash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      offset: 0,
      tableData: [],
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "AR Cash",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  getLedger = () => {
    try {
      this.setState({ loading: true });
      invoiceClient
        .query({
          query: PaymentAccounting,
          variables: {
            location: this.props.selectedPrimary.node.id,
            offset: this.state.offset,
            limit: 9999999,
          },
        })
        .then((res) => {
          if (res.data) {
            console.log(res)
            const dataToPass = get(res, "data.slLocationPayments.data", []).map((k, i) => {
              const { name } = k.Location;
              const { number } = k.Invoice;
              const uiType = `${k.type[0].toUpperCase()}${k.type.slice(1, k.type.length)}`
              const person = k.Invoice.Agent&&k.Invoice.Agent.name;
              const unit = k.Unit&&k.Unit.number;
              const uiStatus = `${k.status[0].toUpperCase()}${k.status.slice(1, k.status.length)}`
              const uiPaymentDate = moment(k.paymentDate).format("l");
              const uiPostedDate = moment(k.postedDate).format("l");
              const uiAmount = `$ ${Number.parseFloat(k.amount).toLocaleString('en')}`
              return (({ _id, memo }) => ({ _id, uiType, uiStatus, name, person, unit,  number, uiPaymentDate, uiPostedDate, memo, uiAmount }))(k)
            });
            this.setState({
              tableData: dataToPass,
              totalItems: get(res, "data.slLocationPayments.totalItems"),
            });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  dataPush = (response) => {
    this.setState((prevState) => ({
      cashCache: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.cashCache,
      ],
    }));
  };
  updateInvoice = (status, invoiceId) => {
    const { cashCache } = this.state;
    const array = [];
    if (status === "Delete") {
      this.setState({
        cashCache: cashCache.filter((invoice) => invoice._id !== invoiceId),
      });
    } else {
      cashCache.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      this.setState({ cashCache: array });
    }
  };

  handleOffset = (x) => {
    this.setState({offset: x})
  }

  render() {
    const { loading } = this.state;
    const mainHeader = ["Id", "Type", "Status", "Location", "User", "Unit", "Invoice", "Payment Date", "Posted Date", "Memo", "Amount"];
    // !: Error handling.
    // TODO: Format data for user.
    return (
      <>
        <main className="main-content balance-sheet-layout" role="main">
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="page-header flex-row mb-3">
              <div className="d-flex align-items-center">
                {/* <span className="page-back"> */}
                  {/* <img src={backIcon} alt="back" /> */}
                  
                {/* </span> */}
                <div>
                  <div className="page-header-title pl-0">Receive Cash</div>
                </div>
              </div>
              <div className="d-flex align-items-end">
                <Button
                onClick={() => window.print()}
                compact
                  className="ml-4 noPrint noPrint-button"
                  style={{
                    backgroundImage:
                      "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                  }}
                >
                  Print
                </Button>
                {/* <Button className="ml-2" style={{ backgroundImage: "linear-gradient(110deg, #3b1c5a, #374db1 162%)", color: "white" }}>Share</Button> */}
              </div>
            </div>
          </div>
        </div>
            {loading ? (
              <Loader text inTable />
            ) : (
              <div className="balance-sheet-table">
                <GeneralAccountingTable
                  mainCellData={this.state.tableData}
                  mainHeader={mainHeader}
                />
              </div>
            )}
        </main>
      </>
    );
  }
}

export default withApollo(ARCash);
