import React , {useEffect}from 'react'
import { Button, Header, Image, Modal, Table, Segment, Dimmer } from 'semantic-ui-react'
import { Loader } from "../../components/Loader/Loader";
// import { Table } from "../../components/Tables/ReconcileData";
import { computeAmounts } from "../../utils/common";
import moment from "moment";

function ReconcileModalContainer(props) {
  const [open, setOpen] = React.useState(false)
  return (
    <Modal
     className="semanticModal custom-modal-large"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="large"
      trigger={<a className="number-linkoff" onClick={() =>props.getSingleLedger(props.number)}>{computeAmounts(props.balance)}</a>}
    >
      <Modal.Header>{props.name}</Modal.Header>
      <Modal.Content >
        <Modal.Description style={{minHeight:"200px",maxHeight:'500px', overflowY:'auto'}}>
        {
          props.singleLedger&& props.singleLedger.length==0 ?
          <Loader />
          :

              <Table striped>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Number</Table.HeaderCell>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Natural Balance</Table.HeaderCell>
        <Table.HeaderCell>Sub Type</Table.HeaderCell>
        <Table.HeaderCell>Type</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
        {
          props.singleLedger && props.singleLedger.map(ledger => {
            return (
            <Table.Row>
              <Table.Cell>{ledger.number}</Table.Cell>
              <Table.Cell>{ledger.name}</Table.Cell>
              <Table.Cell>{ledger.naturalBalance}</Table.Cell>
              <Table.Cell>{ledger.subtype}</Table.Cell>
              <Table.Cell>{ledger.type}</Table.Cell>
            </Table.Row>
            )
          })
        }
        
    </Table.Body>
  </Table>
        }
          {/* <Table transCache={props.singleLedger}/> */}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ReconcileModalContainer