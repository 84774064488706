import React from "react";
import { Dropdown } from "semantic-ui-react";

const UnitFloorPlan = ({
  floorPlanOptions,
  namePrefix,
  setValue,
  watch,
  setIsNewPlanModal,
}) => {
  const [floor, setFloor] = React.useState({});
  const dropdownRef = React.useRef();

  const floorPlanId = watch(`${namePrefix}.unitTypesId`);

  React.useEffect(() => {
    if (floorPlanId)
      setFloor(floorPlanOptions.find((f) => f.value === floorPlanId) || {});
  }, [floorPlanId, floorPlanOptions]);

  const setFloorPlanDetails = (field, value) =>
    setValue(field, value, { shouldValidate: true, shouldDirty: true });

  const handleChange = (data) => {
    setFloorPlanDetails(`${namePrefix}.unitTypesId`, data.value);
    if (data.bedrooms)
      setFloorPlanDetails(`${namePrefix}.bedrooms`, data.bedrooms);
    if (data.bathrooms)
      setFloorPlanDetails(
        `${namePrefix}.bathrooms`,
        parseInt(data.bathrooms, 10)
      );
    if (data.sqft) setFloorPlanDetails(`${namePrefix}.sqft`, data.sqft);
  };

  const handleNewPlan = () => setIsNewPlanModal(true);

  return (
    <Dropdown
      fluid
      className="selection"
      ref={dropdownRef}
      value={floor.value}
      text={floor.text}
    >
      <Dropdown.Menu scrolling>
        <div className="btn-box">
          <button type="button" className="add-btn" onClick={handleNewPlan}>
            Add new floor plan
          </button>
        </div>
        {floorPlanOptions.map((option) => (
          <Dropdown.Item
            active={option.value === floor.value}
            key={option.key}
            value={option.value}
            text={option.text}
            onClick={() => handleChange(option)}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UnitFloorPlan;
