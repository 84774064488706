import React, { useState, useEffect, useMemo } from "react";
import { Dropdown, Grid, Icon, Popup, Select, Header, Label} from "semantic-ui-react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { get, omit } from "lodash";
import Amenities from "./Amenities";
import PropertyImages from "./PropertyImages";
import PropertyAddress from "./PropertyAddress";
import { client } from "../../../init-apollo";
import {
  getYearOptions,
  parseAddresses,
  parseGraphQLErrors,
  toastFailMsg,
} from "../../../utils/common";
import { getAuthToken,getCookie } from "../../../store/auth/authUtility";
import { LOCATIONAPI, LOCATION_UTILS, WEPAY_URL } from "../../../utils";
import { getClient } from "../../../init-apollo-googleFn";
import { getPetsList } from "../../../store/person/properties";
import { ConvenienceFeesWepay } from "../../../store/person/accounting";
import { onRequestSuccess } from "../../../store/auth";
import * as WepayUtils from "../../../utils/wepay";

const locationUtilsClient = getClient(LOCATION_UTILS);
const wePayClient = getClient(WEPAY_URL);

const generatePetDescriptionStr = (petInformation = {}) => {
  let str = "";
  if (
    Object.keys(petInformation).length &&
    Array.isArray(petInformation.description)
  ) {
    let description = "";
    petInformation.description.forEach((item) => {
      description += `
        {
          type: "${item.type}",
          name: "${item.name}",
        },
      `;
    });
    str = `
      petInformation: {
        weight: "${petInformation.weight}",
        number: ${petInformation.totalNumber ? petInformation.totalNumber : 0},
        description: [${description}]
      },`;
    return str;
  }
  return str;
};

const Pets = ({
  control,
  options,
  errors,
  fields,
  append,
  remove,
  watch,
  register,
}) => {
  const [breedOptions, setBreedOptions] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchPetTypes = (petType) => {
    if (petType && !breedOptions[petType]) {
      setLoading(true);
      locationUtilsClient
        .query({ query: getPetsList, variables: { petType } })
        .then((response) => {
          if (response.data.pets) {
            const data = response.data.pets.edges || [];
            const breedTypes = data.map(({ node }) => ({
              ...node,
              key: node.id,
              text: node.name,
              value: node.id,
            }));
            setBreedOptions((prev) => ({ ...prev, [petType]: breedTypes }));
          }
        })
        .catch((error) => toastFailMsg(parseGraphQLErrors(error)))
        .finally(() => setLoading(false));
    }
    return [];
  };



  return (
    <div className="grey-block">
      <div className="head">Pet Restrictions</div>
      <p />
      <div className="form-row">
        <div className="input-section">
          <div className="select-box">
            <div className="input-box">
              <input
                className="form-control"
                type="number"
                name="petInformation.weight"
                placeholder="Weight"
                min="0"
                autoComplete="off"
                ref={register()}
              />
              <span className="hint">lbs</span>
            </div>
            <ErrorMessage
              errors={errors}
              name="petInformation.weight"
              as="span"
              className="error-msg"
            />
          </div>
          <div className="select-box">
            <div className="input-box">
              <input
                className="form-control"
                type="number"
                name="petInformation.totalNumber"
                placeholder="Number of Pets"
                min="0"
                autoComplete="off"
                ref={register({ valueAsNumber: true })}
              />
            </div>
            <ErrorMessage
              errors={errors}
              name="petInformation.totalNumber"
              as="span"
              className="error-msg"
            />
          </div>
        </div>
      </div>
      {fields.map((item, index) => {
        const petType = watch(`petInformation.description[${index}]`);
        const selectedPetName = (
          options.find((p) => p.value === petType.type) || {}
        ).text;
        return (
          <div key={item.id} className="form-row">
            <div className="input-section">
              <div className="select-box">
                <Controller
                  name={`petInformation.description[${index}].type`}
                  control={control}
                  defaultValue={item.type || ""}
                  // rules={{ required: 'Please select type of pet' }}
                  render={({ value, onChange }) => (
                    <Dropdown
                      fluid
                      search
                      selection
                      options={options}
                      selectOnBlur={false}
                      value={value}
                      onChange={(e, data) => {
                        onChange(data.value);
                        const selectedType = options.find(
                          (p) => p.value === data.value
                        );
                        if (selectedType) fetchPetTypes(selectedType.text);
                      }}
                      placeholder="Select Pet Type"
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name={`petInformation.description[${index}].type`}
                  as="span"
                  className="error-msg"
                />
              </div>
              <div className="select-box">
                <Controller
                  name={`petInformation.description[${index}].name`}
                  control={control}
                  defaultValue={item.name || ""}
                  // rules={{ required: 'Please select breed of pet' }}
                  render={({ value, onChange }) => (
                    <Dropdown
                      fluid
                      search
                      selection
                      loading={loading && !breedOptions[selectedPetName]}
                      options={
                        breedOptions[selectedPetName] ||
                        fetchPetTypes(selectedPetName)
                      }
                      selectOnBlur={false}
                      value={value}
                      onChange={(e, data) => onChange(data.value)}
                      placeholder="Select Breed"
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name={`petInformation.description[${index}].name`}
                  as="span"
                  className="error-msg"
                />
              </div>
            </div>
            {/* eslint-disable */}
            {index === fields.length - 1 ? (
              <a
                className="delete-btn"
                onClick={() => append({ type: "", name: "" })}
              >
                <img src="assets/img/icons-plus.svg" alt="icons-plus" />
              </a>
            ) : (
              <a className="delete-btn" onClick={() => remove(index)}>
                <img
                  src="assets/img/black-icons-delete.svg"
                  alt="black-icons-delete"
                />
              </a>
            )}
            {/* eslint-enable */}
          </div>
        );
      })}
    </div>
  );
};

const PropertyInfoForm = ({
  details,
  selectedPropertyType,
  propertyTypes,
  petTypes,
  amenityTypes,
  handleSubmitting,
  onLocationCreate,
  onAddNewAmenities,
  onNextStep,
  locationId,
  onCancelClick,
  wepay1stStep,
}) => {
  const formMethods = useForm({ mode: "onChange" });
  const { register, handleSubmit, setValue, control, errors, watch } =
    formMethods;

  const fieldArrayMethods = useFieldArray({
    control,
    name: "petInformation.description",
  });
  const [amenities, setAmenities] = useState([]);
  const [images, setImages] = useState([]);
  const [addressDetails, setAddressDetails] = useState();
  const [checkTermsOfService, setCheckTermsOfService] = useState(false);
  const [wePayFees, setWePayFees] = useState([]);
  const [companyType, setCompanyType] = useState(false);
  const [checkPrivacyPolicy, setPrivacyPolicy] = useState(false);
  const [merchant, setMerchant] = useState(null);
  const [SSN, setSSN] = useState("");


  const petsPermitted = watch("petsPermitted");

  const isUpdatingLocation = useMemo(() => !!details.id, [details]);

  const propertyTypesOptions = useMemo(
    () =>
      propertyTypes.map((p) => ({
        key: p.node.id,
        text: p.node.type,
        value: p.node.id,
      })),
    [propertyTypes]
  );

  const petTypesOptions = useMemo(
    () =>
      petTypes.map(({ node }) => ({
        ...node,
        key: node.id,
        text: node.type,
        value: node.id,
      })),
    [petTypes]
  );

  const initializeEditForm = (data) => {
    Object.entries(data).forEach(([key, value]) => {
      switch (key) {
        case "addresses": {
          setAddressDetails({ ...value[0], googlePlaceId: data.googlePlaceId });
          setValue("address", parseAddresses(JSON.stringify(value)));
          break;
        }
        case "petsPermitted":
          setValue("petsPermitted", value ? "Yes" : "No");
          break;
        case "petInformation": {
          const petDescription = get(value, "description", [])
            .map((item) => {
              const pet = petTypesOptions.find((p) => p.id === item.type);
              if (pet !== undefined) return { type: pet.id, name: item.name };
              return " ";
            })
            .filter((p) => p);
          setValue("petInformation.weight", get(value, "weight", ""));
          setValue("petInformation.totalNumber", Number(get(value, "number")));
          setValue("petInformation.description", petDescription);
          break;
        }
        case "photos": {
          if (Array.isArray(value)) {
            setImages(value.map((img) => ({ previewUrl: img })));
          }
          break;
        }
        default:
          setValue(key, value);
          break;
      }
    });
  };

  /* eslint-disable */
  useEffect(() => {
    if (details) initializeEditForm(details);
  }, [details, petTypesOptions]);
  useEffect(() => {
    fetchWePayPrice()
  }, []);
  /* eslint-enable */

  useEffect(() => {
    setAmenities((prevAmenities) => {
      let selected = prevAmenities.filter((a) => a.selected).map((a) => a.id);
      if (Array.isArray(details.amenities))
        selected = [...selected, ...details.amenities];
      return amenityTypes.map((a) => ({
        id: a.node.id,
        name: a.node.name,
        selected: selected.includes(a.node.id),
      }));
    });
  }, [amenityTypes, details.amenities]);

  /* eslint-disable */
  useEffect(() => {
    if (petsPermitted === "Yes")
      fieldArrayMethods.append({ type: "", name: "" });
    else fieldArrayMethods.remove();
  }, [petsPermitted, isUpdatingLocation]);
  /* eslint-enable */

  useEffect(() => {
    WepayUtils.getMerchant(locationId)
      .then((merchant) => {
        console.log("merchant", merchant);
        setMerchant(merchant);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const onChangeAmenities = (_, index) => {
    const updatedAmenities = [...amenities];
    updatedAmenities[index].selected = !updatedAmenities[index].selected;
    setAmenities(updatedAmenities);
  };

  const handleNewAmenities = async (value) => {
    const response = await onAddNewAmenities(value);
    return response.id;
  };

  const refreshTokenWithNewLocation = () => {
    onRequestSuccess(client, "reload");
  };

  const savePropertyInfo = (data) => {
    const {
      name,
      locationTypeId,
      yearBuilt,
      description,
      customId,
      googlePlaceId,
      address,
      petInformation,
      amenities: selectedAmenities,
    } = data;

    if (merchant === null) {
      if (!checkPrivacyPolicy || !checkTermsOfService) {
        toastFailMsg(
          "You cannot go to next step before accepting terms of service and privacy policy for the Wepay."
        );
        return;
      }
    }

    const editingLocationId = isUpdatingLocation ? `id: "${locationId}"` : "";
    const existingImgUrl = images.reduce((result, img) => {
      if (!(img instanceof File) && img.previewUrl) result.push(img.previewUrl);
      return result;
    }, []);
    const existingPhotos = isUpdatingLocation
      ? `existingPhotos: ${JSON.stringify(existingImgUrl)},`
      : "";
    const formData = new FormData();
    if (isUpdatingLocation) {
      formData.append(
        "query",
        `mutation ${isUpdatingLocation ? "UpdateLocation" : "CreateLocation"}{
        ${isUpdatingLocation ? "updateLocation" : "createLocation"}(location: {
            ${editingLocationId}
            name: "${name}",
            locationTypeId : "${selectedPropertyType || locationTypeId}",
            yearBuilt: ${yearBuilt},
            description:"${description
          .replace(/(?:\r\n|\r|\n)/g, "\\n")
          .replaceAll('"', "")}",
            customId:"${customId}",
            googlePlaceId: "${googlePlaceId}",
            ${details.addresses &&
        details.addresses[0] !== address &&
        `
              addresses: [
                {
                  streetOne: "${address.streetOne}",
                  city: "${address.city}",
                  country: "${address.country}",
                  state: "${address.state}",
                  zip: "${address.zip}"
                }
              ]
              `
        }
          ,
            petsPermitted: ${data.petsPermitted === "Yes"}
            ${existingPhotos}
            ${generatePetDescriptionStr(petInformation)}
            amenities: ${JSON.stringify(selectedAmenities)},
            status: ${details.status || "draft"}
          }){
            response
            location{
              id
              organizationId
            }
          }
        }`
      );
    }
    if (!isUpdatingLocation) {
      formData.append(
        "query",
        `mutation ${isUpdatingLocation ? "UpdateLocation" : "CreateLocation"}{
        ${isUpdatingLocation ? "updateLocation" : "createLocation"}(location: {
            ${editingLocationId}
            name: "${name}",
            locationTypeId : "${selectedPropertyType || locationTypeId}",
            yearBuilt: ${yearBuilt},
organizationId:${getCookie().claims}
            description:"${description
          .replace(/(?:\r\n|\r|\n)/g, "\\n")
          .replaceAll('"', "")}",
            customId:"${customId}",
            googlePlaceId: "${googlePlaceId}",
              addresses: [
                {
                  streetOne: "${address.streetOne}",
                  city: "${address.city}",
                  country: "${address.country}",
                  state: "${address.state}",
                  zip: "${address.zip}"
                }
              ]

          ,
            petsPermitted: ${data.petsPermitted === "Yes"}
            ${existingPhotos}
            ${generatePetDescriptionStr(petInformation)}
            amenities: ${JSON.stringify(selectedAmenities)},
            status: ${details.status || "draft"}
          }){
            response
            location{
              id
              organizationId
            }
          }
        }`
      );
    }

    if (images.length) {
      images.forEach((item) => {
        if (item instanceof File) formData.append("", item);
      });
    }

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: getAuthToken(),
      },
      body: formData,
    };

    handleSubmitting(true);
    fetch(LOCATIONAPI, requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.data.createLocation) {
          refreshTokenWithNewLocation();
          await wepay1stStep(
            result.data.createLocation.location.organizationId,
            result.data.createLocation.location.id,
            checkTermsOfService,
            checkPrivacyPolicy,
            SSN,
            companyType
          );
          setTimeout(() => {
            onLocationCreate(result.data.createLocation.location.id);
            onNextStep();
          }, 3000);
        } else if (result.data.updateLocation) {
          await wepay1stStep(
            result.data.createLocation.location.organizationId,
            locationId,
            checkTermsOfService,
            checkPrivacyPolicy,
            SSN,
            companyType
          );
          onNextStep();
        } else {
          return toastFailMsg(result.errors[0].message);
        }
      })
      .catch((error) => toastFailMsg(parseGraphQLErrors(error)))
      .finally(() => handleSubmitting(false));
  };

  const onSubmit = (data) => {
    const selectedAmenities = amenities.reduce((result, item) => {
      if (item.selected) result.push(item.id);
      return result;
    }, []);
    const address = addressDetails
      ? omit(addressDetails, "googlePlaceId")
      : { streetOne: data.address };
    const googlePlaceId = addressDetails ? addressDetails.googlePlaceId : "";
    if (googlePlaceId === "") {
      toastFailMsg(
        "Please select the address from the google place suggestion dropdown"
      );
    } else {
      const requestData = {
        ...omit(data, "dogWalking", "groceryDelivery"),
        images,
        address,
        googlePlaceId,
        customId: data.name,
        amenities: selectedAmenities,
      };
      savePropertyInfo(requestData);
    }
  };

  const gridDimension = useMemo(
    () => (selectedPropertyType ? 16 : 8),
    [selectedPropertyType]
  );

  const fetchWePayPrice = () => {
    wePayClient
      .query({
        query: ConvenienceFeesWepay,
      })
      .then((response) => {
        const convFees = response.data.convenienceFeesWepay && response.data.convenienceFeesWepay
        setWePayFees(convFees)
        console.log(response)
      })
      .catch((error) => toastFailMsg(parseGraphQLErrors(error)))
      .finally();
  };

  const companyTypes = [
    {
      key: 'sole_proprietor',
      text: 'Sole Proprietor',
      value: 'sole_proprietor',
    },
    {
      key: 'limited_liability_company',
      text: 'Limited Liability Company',
      value: 'limited_liability_company',
    },
    {
      key: 'corporation',
      text: 'Corporation',
      value: 'corporation',
      disabled: true
    },
  ]


  return (
    <>
      <PropertyImages
        title="Upload photos of your property"
        images={images}
        setImages={setImages}
      />
      <div className="form-section">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Column
              mobile={16}
              tablet={gridDimension}
              computer={gridDimension}
            >
              <div className="form-group">
                <div className="has-float-label">
                  <input
                    className="form-control"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Property Name"
                    autoComplete="off"
                    ref={register({ required: "Property Name is required." })}
                  />
                  <label htmlFor="name">Property Name</label>
                </div>
                <ErrorMessage
                  errors={errors}
                  name="name"
                  as="span"
                  className="error-msg"
                />
              </div>
            </Grid.Column>
            {!selectedPropertyType && (
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <div className="has-float-label select-float-label">
                  <Controller
                    name="locationTypeId"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Property Type is required." }}
                    render={(props) => (
                      <Dropdown
                        fluid
                        selection
                        options={propertyTypesOptions}
                        selectOnBlur={false}
                        value={props.value}
                        className={props.value ? "" : "blank-select"}
                        onChange={(e, data) => props.onChange(data.value)}
                      />
                    )}
                  />
                  <label htmlFor="locationTypeId">Property Type</label>
                </div>
                <ErrorMessage
                  errors={errors}
                  name="locationTypeId"
                  as="span"
                  className="error-msg"
                />
              </Grid.Column>
            )}
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="form-group">
                <div className="has-float-label">
                  <PropertyAddress
                    control={control}
                    setValue={setValue}
                    setAddressDetails={setAddressDetails}
                    value={parseAddresses(JSON.stringify(details.addresses))}
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name="address"
                  as="span"
                  className="error-msg"
                />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>
              <div className="has-float-label select-float-label">
                <Controller
                  name="yearBuilt"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Year built is required." }}
                  render={(props) => (
                    <Dropdown
                      fluid
                      search
                      selection
                      className={props.value ? "" : "blank-select"}
                      options={getYearOptions()}
                      selectOnBlur={false}
                      value={props.value}
                      onChange={(e, data) => props.onChange(data.value)}
                    />
                  )}
                />
                <label htmlFor="yearBuilt">Year Built</label>
              </div>
              <ErrorMessage
                errors={errors}
                name="yearBuilt"
                as="span"
                className="error-msg"
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="form-group">
                <div className="has-float-label textarea-label">
                  <textarea
                    className="form-control"
                    type="text"
                    name="description"
                    id="description"
                    placeholder="Description"
                    autoComplete="off"
                    ref={register({
                      required: "Description is required.",
                    })}
                  />
                  <label htmlFor="description">Description</label>
                </div>
                <ErrorMessage
                  errors={errors}
                  name="description"
                  as="span"
                  className="error-msg"
                />
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="white-block">
                <div className="head">Pets Permitted</div>
                <div className="radio-container">
                  <label className="custom-radio">
                    Yes
                    <input
                      type="radio"
                      name="petsPermitted"
                      value="Yes"
                      ref={register({ required: "Please select one option" })}
                    />
                    <span className="checkmark" />
                  </label>
                  <label className="custom-radio">
                    No
                    <input
                      type="radio"
                      name="petsPermitted"
                      value="No"
                      ref={register({ required: "Please select one option" })}
                    />
                    <span className="checkmark" />
                  </label>
                </div>
                <ErrorMessage
                  errors={errors}
                  name="petsPermitted"
                  as="span"
                  className="error-msg"
                />
              </div>
            </Grid.Column>
            {petsPermitted === "Yes" && (
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Pets
                  {...formMethods}
                  {...fieldArrayMethods}
                  options={petTypesOptions}
                />
              </Grid.Column>
            )}
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Amenities
                options={amenities}
                onChange={onChangeAmenities}
                onAddNew={handleNewAmenities}
              />
            </Grid.Column>
            {merchant === null && (
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <div className="white-block">
                  {/* <Grid.Row columns="3" stretched> */}
                  <Grid>
                    <Grid.Column mobile={5} tablet={5} computer={5}>
                      {/* terms of service */}
                      <div className="radio-container">
                        <label className="custom-checkbox">
                          <a
                            href="https://go.wepay.com/terms-of-service-us/"
                            target="_blank"
                            style={{ color: 'blue' }}
                          >
                            Terms of Service
                          </a>{" "}
                          for Wepay
                          <input
                            type="checkbox"
                            value={checkTermsOfService}
                            checked={checkTermsOfService}
                            onChange={() =>
                              setCheckTermsOfService(!checkTermsOfService)
                            }
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={5} tablet={5} computer={5}>
                      {/* privacy policy */}
                      <div className="radio-container">
                        <label className="custom-checkbox">
                          <a
                            href="https://go.wepay.com/privacy-policy/"
                            target="_blank"
                            style={{ color: 'blue' }}
                          >
                            Privacy Policy
                          </a>{" "}
                          for Wepay
                          <input
                            type="checkbox"
                            value={checkPrivacyPolicy}
                            checked={checkPrivacyPolicy}
                            onChange={() =>
                              setPrivacyPolicy(!checkPrivacyPolicy)
                            }
                          />
                          <span className="checkmark" />
                        </label>
                      </div>
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={6} computer={6}>
                      {/* SSN */}

                      <div className="form-group">
                        <div className="has-float-label">
                          <input
                            className="form-control"
                            id="orgRegistrationId"
                            name="orgRegistrationId"
                            type="number"
                            placeholder="SSN"
                            autoComplete="off"
                            value={SSN}
                            onChange={(e) => setSSN(e.target.value)}
                            required
                          />
                          <label htmlFor="orgRegistrationId">SSN{" "}<Popup content='Wepay requires users social security number in order to transmit rent payments.' trigger={<Icon name='question circle' />} /></label>
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid>
                  <div>
                    <Dropdown
                      placeholder='Company Type'
                      onChange={(e, { value }) => setCompanyType({ value })}
                      // onChange={({ target: { value } }) => {
                      //   setCompanyType(value);
                      // }}
                      selection
                      options={companyTypes}
                    />
                  </div>
                  {/* </Grid.Row> */}
                  <div>
                    <br></br>
                    <Header as="h4">WePay Fees</Header>
                    {wePayFees.map(item => {
                      return (
                        <>
                        <Label as='a' color='blue' >
                          Type
                          <Label.Detail> {item.type}</Label.Detail>
                        </Label>
                        <Label as='a' color='blue' >
                          Charge Type
                          <Label.Detail> {item.chargeType}</Label.Detail>
                        </Label>
                        <Label as='a' color='blue' >
                          Amount
                          <Label.Detail> %{item.convenienceAmount}</Label.Detail>
                        </Label>
                        <br></br>
                        </>
                      )
                    })}
                  </div>
                </div>
              </Grid.Column>
            )}
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className="btn-block">
                <div>
                  <button
                    type="button"
                    className="btn cancel-btn"
                    onClick={onCancelClick}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button type="submit" className="btn next-btn">
                    {merchant !== null
                      ? "Proceed to step 2"
                      : "Create Property"}
                  </button>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default PropertyInfoForm;
