import mixpanel from "mixpanel-browser";
import moment, { now } from "moment";
import React, { Component } from "react";
import { toast } from "react-toastify";
import {
  Card,
  Confirm,
  Container,
  Grid,
  Icon,
  Menu,
  Segment,
} from "semantic-ui-react";
import { PageLoader } from "../../components/Loader/PageLoader";
import { getClient } from "../../init-apollo-googleFn";
import { getCookie } from "../../store/auth/authUtility";
import { googleEventsGql } from "../../store/calendar/calendar";
import { pmApplicationsGql } from "../../store/person/applications";
import { TourLocation, updateTour } from "../../store/person/leads";
import { maintenanceGql } from "../../store/person/maintenance";
import { eventsGql } from "../../store/person/person";
import {
  APPLICATION_MANAGER,
  CALENDAR_SERVICE_URL,
  LEADS_TOUR,
  PERSON_URL,
} from "../../utils/constants";
import "./Schedule.scss";
import CreateEventModal from "./CreateEventModal";
import Tour from 'reactour'
import { scheduleSteps } from "../../utils/tourSteps";

const toursClient = getClient(LEADS_TOUR);
const appClient = getClient(APPLICATION_MANAGER);
const personClient = getClient(PERSON_URL);
const calendarClient = getClient(CALENDAR_SERVICE_URL);
class Schedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chosen: moment().startOf("day"),
      selectedMonth: moment(),
      selectedDay: moment().startOf("day"),
      selectedMonthEvents: [],
      selectedDayEvents: [],
      showEvents: false,
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      tourData: "",
      modalOpen: false,
      events: "",
      isTourOpen:false,
      loading: false,
      googleEvents: [],
      hideEventsExistsOnGoogleCalendar: [],
      showCreateEventModal: false,
      showDashboardCreateEventModal: false,
    };

    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.addEvent = this.addEvent.bind(this);
    this.showCalendar = this.showCalendar.bind(this);
    this.goToCurrentMonthView = this.goToCurrentMonthView.bind(this);

    this.initialiseEvents();
  }

  componentDidMount() {
    const cookie = getCookie();
    if (cookie.manager === "true") {
      // this.getAgenda()
      this.getCal();
      this.getGoogleCalendarEvents();
    }

    mixpanel.track("Manager Page Load", {
      sub: "Schedule",
    });
  }

  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    const cookie = getCookie();
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        if (cookie.manager === "true") {
          // this.getAgenda()
          this.getCal();
          this.getApplicationAgenda();
          this.getGoogleCalendarEvents();
        }
      });
    }

    if (prevProps.toggleCreateEvent) {
      this.setState({
        showDashboardCreateEventModal: prevProps.toggleCreateEvent,
      });
    }
  }

  getCal = () => {
    try {
      personClient
        .query({
          query: eventsGql,
          variables: {
            locationId: this.props.dashboard
              ? this.props.primaryLocation
              : this.props.selectedPrimary.node.id,
          },
        })
        .then((res) => {
          const setAgenda = [];
          const events =
            res.data.personCalendar && res.data.personCalendar.edges;
          events &&
            events.forEach((event) => {
              const split =
                event.node.startDate && event.node.startDate.split("T");
              const newT =
                split &&
                moment(split[0] + " " + split[1], "DD/MM/YYYY HH:mm A");
              const secT = moment.utc(newT._i);
              const newEvent = {
                title: event.node.eventName,
                time: secT.local().format("DD/MM/YYYY hh:mm A"),
                lodat: moment(secT),
                date: moment(secT),
                description: event.node.description,
                id: event.node.eventId,
                dynamic: true,
              };
              setAgenda.push(newEvent);
            });

          this.setState({
            selectedMonthEvents: setAgenda,
            loading: false,
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  getAgenda = () => {
    try {
      toursClient
        .query({
          query: TourLocation,
          variables: {
            location: this.props.dashboard
              ? this.props.primaryLocation
              : this.props.selectedPrimary.node.id,
          },
        })
        .then((res) => {
          const eventFilter = res.data.tours.edges;
          const setAgenda = [];
          const filterArr = eventFilter.filter(
            (task) => task.node.status !== "cancel"
          );
          filterArr.forEach((event) => {
            const address =
              event.node.primaryLocation.addresses[0].streetOne +
              " " +
              event.node.primaryLocation.addresses[0].city +
              " " +
              event.node.primaryLocation.addresses[0].state +
              " " +
              event.node.primaryLocation.addresses[0].zip;
            const localTime = moment(
              event.node.date + " " + event.node.time,
              "DD/MM/YYYY HH:mm A"
            );
            const local = moment.utc(localTime._i);
            const DDate = local;
            const adjustedTime = DDate.local().format("hh:mm A");
            const adjustedDate = DDate.local().format("MM/DD/YYYY");
            const newEvent = {
              title: "Tour -",
              time: adjustedTime + " - " + address,
              lodat: moment(adjustedTime, "hh:mm A"),
              date: moment(adjustedDate),
              description:
                "Tour with " +
                event.node.name +
                " at " +
                event.node.primaryLocation.name,
              id: event.node.id,
              dynamic: true,
            };
            setAgenda.push(newEvent);
          });
          this.setState({
            selectedMonthEvents: setAgenda,
            loading: false,
          });
          this.getMaintenanceRequests();
          this.clickToday();
        })
        .catch((error) => {
          console.log(error);
          this.clickToday();
          this.setState({ loading: false });
        });
    } catch (e) {
      console.log(e);
      this.clickToday();
      this.setState({ loading: false });
    }
  };

  getApplicationAgenda = () => {
    this.setState({ loading: true });
    try {
      appClient
        .query({
          query: pmApplicationsGql,
          variables: {
            locationId: this.props.selectedPrimary.node.id,
          },
        })
        .then((res) => {
          const eventFilter = res.data.applications.edges;
          const setAgenda = [...this.state.selectedMonthEvents];
          const filterArr = eventFilter.filter(
            (task) =>
              task.node.status !== "Cancelled" || task.node.status !== "Denied"
          );
          filterArr.forEach((event) => {
            const newEvent = {
              title: "Application",
              date: moment(event.node.targetMoveInDate),
              description:
                "Application of " + event.node.primaryApplicant.firstName,
              id: event.node.id,
              dynamic: true,
            };
            setAgenda.push(newEvent);
          });
          this.setState({
            selectedMonthEvents: setAgenda,
            loading: false,
          });
          this.clickToday();
        })
        .catch((error) => {
          this.clickToday();
          this.setState({ loading: false });
        });
    } catch (e) {
      this.clickToday();
      this.setState({ loading: false });
    }
  };

  getMaintenanceRequests() {
    const monthEvents = this.state.selectedMonthEvents;
    const roleType =
      this.props.role.serviceProfessional === "true"
        ? "serviceProfessional"
        : "manager";
    const statusType = [
      "Submitted",
      "Processing",
      "Scheduled",
      "Completed",
      "Canceled",
      "Approval Needed",
      "Approved",
    ];
    this.setState({ loading: true });
    try {
      this.props.maintenanceClient
        .query({
          query: maintenanceGql,
          variables: {
            role: roleType,
            status: statusType,
            location: this.props.selectedPrimary.node.id,
          },
        })
        .then((res) => {
          const array = res.data.maintenanceRequest.edges;

          let newEvents = [];
          array.forEach((event) => {
            const newEvent = {
              title: event.node.unit,
              date: moment(event.node.scheduledStart),
              dynamic: true,
              item: event.node.category,
              id: event.node.id,
              location: event.node.location,
              maintenance: true,
            };
            newEvents.push(newEvent);
          });

          for (var i = 0; i < newEvents.length; i++) {
            monthEvents.push(newEvents[i]);
          }
          this.setState({ loading: false });
          this.setState({
            selectedMonthEvents: monthEvents,
          });
          this.clickToday();
        })
        .catch((error) => {
          this.clickToday();
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.clickToday();
      this.setState({ loading: false });
      console.log(e);
    }
  }

  getGoogleCalendarEvents = async () => {
    // first day of 3 months before and last day of 3 months after from today.
    let hiddenEvents = this.state.hideEventsExistsOnGoogleCalendar;
    const variables = {
      startDate: moment()
        .subtract(3, "months")
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDate: moment().add(4, "months").startOf("month").format("YYYY-MM-DD"),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      location: this.props.dashboard
        ? this.props.primaryLocation
        : this.props.selectedPrimary.node.id,
    };
    try {
      const data = await calendarClient.query({
        query: googleEventsGql,
        variables,
      });

      const agenda = [...this.state.selectedMonthEvents];
      const currentMonth = this.state.selectedMonth.format("YYYY-MM-DD");
      const edges = data?.data?.googleEvents?.edges ?? [];
      const googleEvents = edges.reduce((prev, acc) => {
        const { start, summary, attendees, id, end, status, description } =
          acc.node;
        const startDate = moment(start.dateTime).format("YYYY-MM-DD");

        if (!prev[startDate]) prev[startDate] = [];

        const newEvent = {
          title: summary,
          date: moment(start.dateTime),
          time: moment(start.dateTime).format("DD/MM/YYYY hh:mm A"),
          endDate: moment(end.dateTime),
          description: summary,
          id,
          dynamic: true,
          attendees,
          status,
          isGoogleCalendarEvent: true,
        };

        if (parseInt(description) > 0) {
          hiddenEvents.push(description); //description includes leasera event id to hide on calendar if is created also on google events
        }

        // TODO!: calculate the google events and leasera events count by not duplicating events.
        if (currentMonth === startDate) {
          agenda.push(newEvent);
        }

        prev[startDate].push(newEvent);

        return prev;
      }, {});

      this.setState({
        googleEvents,
        selectedMonthEvents: agenda,
        hideEventsExistsOnGoogleCalendar: hiddenEvents,
      });
    } catch (error) {
      //
      console.log(error);
    } finally {
      //
    }
  };

  previous() {
    const currentMonthView = this.state.selectedMonth;
    this.setState({
      selectedMonth: currentMonthView.subtract(1, "month"),
    });
  }

  next() {
    const currentMonthView = this.state.selectedMonth;
    this.setState({
      selectedMonth: currentMonthView.add(1, "month"),
    });
  }
  clickToday = () => {
    if (this.props.dashboard) {
      const data = { date: "" };
      data.date = moment().format("MM/DD/YYYY");
      this.select(data);
    }
  };

  select(day) {
    const dayEventsRendered = [];

    for (var i = 0; i < this.state.selectedMonthEvents.length; i++) {
      if (this.state.selectedMonthEvents[i].date.isSame(day.date, "day")) {
        dayEventsRendered.push(this.state.selectedMonthEvents[i]);
      }
    }

    const currentDate = day.date.format("YYYY-MM-DD");
    // select events from selected day.
    if (Object.keys(this.state.googleEvents).includes(currentDate)) {
      this.state.googleEvents[currentDate].map((event) => {
        dayEventsRendered.push({
          ...event,
          eventType: "google-event",
        });
      });
    }

    // set the selected day
    this.setState({
      selectedDayEvents: dayEventsRendered,
      showEvents: true,
      selectedDay: day.date,
      chosen: day.date,
    });

    // this.props.dashboardList(dayEventsRendered, day.date);
    // }
  }

  goToCurrentMonthView() {
    this.setState({
      selectedMonth: moment(),
    });
  }

  showCalendar() {
    this.setState({
      selectedMonth: this.state.selectedMonth,
      selectedDay: this.state.selectedDay,
      showEvents: false,
    });
  }

  renderMonthLabel() {
    const currentMonthView = this.state.selectedMonth;
    return (
      <Menu.Header className="month-label">
        {currentMonthView.format("MMMM YYYY")}
      </Menu.Header>
    );
  }

  renderDayLabel() {
    const currentSelectedDay = this.state.selectedDay;
    return (
      <Menu.Header className="month-label">
        {currentSelectedDay.format("DD MMMM YYYY")}
      </Menu.Header>
    );
  }

  renderWeeks() {
    const currentMonthView = this.state.selectedMonth;
    const currentSelectedDay = this.state.selectedDay;
    const monthEvents = this.state.selectedMonthEvents;
    const chosen = this.state.chosen;
    let weeks = [];
    let done = false;
    let previousCurrentNextView = currentMonthView
      .clone()
      .startOf("month")
      .subtract(1, "d")
      .day("Sunday");
    let count = 0;
    let monthIndex = previousCurrentNextView.month();
    let googleEventsMonthCount = 0;
    let monthGoogleEvents = [];

    Object.keys(this.state.googleEvents).map((date) => {
      if (this.state.selectedMonth.format("YYYY-MM") === date.slice(0, 7)) {
        // Same Month
        googleEventsMonthCount += this.state.googleEvents[date].length;
        monthGoogleEvents = [
          ...monthGoogleEvents,
          ...this.state.googleEvents[date],
        ];
      }
    });

    while (!done) {
      weeks.push(
        <Week
          previousCurrentNextView={previousCurrentNextView.clone()}
          currentMonthView={currentMonthView}
          monthEvents={[...monthEvents, ...monthGoogleEvents]}
          selected={currentSelectedDay}
          select={(day) => this.select(day)}
          chosen={chosen}
        />
      );
      previousCurrentNextView.add(1, "w");
      done = count++ > 2 && monthIndex !== previousCurrentNextView.month();
      monthIndex = previousCurrentNextView.month();
    }
    return weeks;
  }

  handleAdd() {
    const monthEvents = this.state.selectedMonthEvents;
    const currentSelectedDate = this.state.selectedDay;

    let newEvents = [];
    var eventTitle = prompt("Please enter a name for your event: ");

    switch (eventTitle) {
      case "":
        alert("Event name cannot be empty.");
        break;
      case null:
        alert("Changed your mind? You can add one later!");
        break;
      default:
        var newEvent = {
          title: eventTitle,
          date: currentSelectedDate,
          dynamic: true,
        };

        newEvents.push(newEvent);

        for (var i = 0; i < newEvents.length; i++) {
          monthEvents.push(newEvents[i]);
        }

        this.setState({
          selectedMonthEvents: monthEvents,
        });
        break;
    }
  }

  addEvent() {
    const currentSelectedDate = this.state.selectedDay;
    let isAfterDay = moment().startOf("day").subtract(1, "d");

    if (currentSelectedDate.isAfter(isAfterDay)) {
      this.handleAdd();
    }
  }

  success = () =>
    toast.success("Event Canceled!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  removeEvent(i) {
    const monthEvents = this.state.selectedMonthEvents.slice();
    const index = i;
    mixpanel.track("Manager Lead Action", {
      sub: "Remove Tour",
    });
    try {
      toursClient
        .mutate({
          mutation: updateTour,
          variables: {
            input: {
              tour: {
                id: index.id,
                status: "cancel",
                comment: "Cancel",
              },
            },
          },
        })
        .then((response) => {
          /* eslint-disable */
          const newEvent = monthEvents.filter((event) => event !== index);
          /* eslint-enable */
          this.success();
          this.setState({
            selectedMonthEvents: newEvent,
          });
        });
    } catch (e) {
      console.log(e);
      alert(e);
    }
  }

  initialiseEvents() {
    const monthEvents = this.state.selectedMonthEvents;
    let allEvents = [];

    for (var i = 0; i < allEvents.length; i++) {
      monthEvents.push(allEvents[i]);
    }

    this.setState({
      selectedMonthEvents: monthEvents,
    });
  }

  render() {
    const { loading } = this.state;
    const showEvents = this.state.showEvents;

    if (this.state.showDashboardCreateEventModal)
      return (
        <CreateEventModal
          isOpen={this.state.showDashboardCreateEventModal}
          toggleModal={() =>
            this.setState({
              showDashboardCreateEventModal:
                !this.state.showDashboardCreateEventModal,
            })
          }
          personId={this.props.user?.ndbId}
          activeDate={this.state.selectedDay}
          locationId={
            this.props.dashboard
              ? this.props.primaryLocation
              : this.props.selectedPrimary.node.id
          }
        />
      );

    if (showEvents) {
      return (
        <>
          {loading ? (
            <PageLoader text />
          ) : (
            <div className="wrapper">
              <div className="colors-description">
                <div className="color-item">
                  <div className="color-google"></div>
                  <div className="title">Google Events</div>
                </div>

                <div className="color-item">
                  <div className="color-leasera"></div>
                  <div className="title">REM Events</div>
                </div>
              </div>
              <CreateEventModal
                isOpen={this.state.showCreateEventModal}
                toggleModal={() =>
                  this.setState({
                    showCreateEventModal: !this.state.showCreateEventModal,
                  })
                }
                personId={this.props.user?.ndbId}
                activeDate={this.state.selectedDay}
                locationId={
                  this.props.dashboard
                    ? this.props.primaryLocation
                    : this.props.selectedPrimary.node.id
                }
              />
              <Container fluid textAlign="center" className="main-calendar">
                <Menu attached="top" fluid className="calendar-header">
                  <Menu.Item
                    onClick={this.showCalendar}
                    icon="calendar alternate outline"
                    position="left"
                    className="calendar-action"
                  />
                  {this.renderDayLabel()}
                  <Menu.Item
                    onClick={() =>
                      this.setState({ showCreateEventModal: true })
                    }
                    icon="add square"
                    position="right"
                    className="calendar-action"
                  />
                </Menu>
                <Segment
                  attached="bottom"
                  textAlign="center"
                  className="calendar-body"
                >
                  <Events
                    selectedMonth={this.state.selectedMonth}
                    selectedDay={this.state.selectedDay}
                    hideEventsExistsOnGoogleCalendar={
                      this.state.hideEventsExistsOnGoogleCalendar
                    }
                    history={this.props}
                    selectedDayEvents={this.state.selectedDayEvents}
                    selectedMonthEvents={this.state.selectedMonthEvents}
                    removeEvent={(i) => this.removeEvent(i)}
                  />
                </Segment>
              </Container>
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          <div className="page-header mb-4">
            <div className="d-flex align-items-center">
              <div>
                <div className="page-header-title schedule-first-step ">Schedule 
                {/* <Icon size='small' circular name='lightbulb' onClick={() => this.setState({ isTourOpen: true })} /> */}
                </div>
                
              </div>
            </div>
          </div>
          <Tour
            steps={scheduleSteps}
            isOpen={this.state.isTourOpen}
            onRequestClose={() => this.setState({ isTourOpen: false })}
            // maskClassName="mask"
            className="helper"
            rounded={5}
            startAt={0}
          />
          {loading ? (
            <PageLoader />
          ) : (
            <Container textAlign="center" className="main-calendar schedule-second-step">
              <Menu attached="top" fluid className="calendar-header">
                <Menu.Item
                  onClick={this.previous}
                  icon="arrow left"
                  position="left"
                  className="calendar-action"
                />
                {this.renderMonthLabel()}
                {/* <Menu.Item
                  onClick={this.next}
                  icon="arrow right"
                  position="right"
                  className="calendar-action"
                /> */}
              </Menu>
              <Segment
                attached="bottom"
                textAlign="center"
                className="calendar-body"
              >
                <Grid columns={7}>
                  <DayNames />
                  {this.renderWeeks()}
                </Grid>
              </Segment>
            </Container>
          )}
        </>
      );
    }
  }
}

class Events extends React.Component {
  state = {
    open: false,
    result: "show the modal to capture a result",
    tourHours: {},
  };
  componentDidMount() {
    this.createHours();
  }
  show = () => this.setState({ open: true });
  handleCancel = () => this.setState({ result: "cancelled", open: false });
  handleConfirm = (event) => {
    this.props.removeEvent(event);
    this.setState({ result: "confirmed", open: false });
  };

  createHours() {
    // create hours between 9 till 17 each 30 mins

    const calculateArrayLength = 24 * 2;
    let currentHour = 0;
    let tourHours = {};
    let isHalf = false; // timeline starting at time.

    for (let i = 0; i <= calculateArrayLength; i++) {
      let currentMinute;
      if (isHalf) {
        currentMinute = "30";
      } else {
        currentMinute = "00";
      }

      const meridien = currentHour > 11 ? "PM" : "AM";
      const make12Hour = currentHour > 12 ? currentHour - 12 : currentHour;
      const shownHour = make12Hour < 10 ? `0${make12Hour}` : make12Hour;

      if (isHalf) {
        currentHour += 1;
        isHalf = false; // for the next loop
      } else {
        isHalf = true; // for the next loop
      }

      tourHours[`${shownHour}:${currentMinute}${meridien}`] = [];
    }

    this.setState({ tourHours }, () => {
      this.bindEventsToHours();
    });
  }

  tours = { ...this.state.tourHours };
  bindEventsToHours() {
    let tours = { ...this.state.tourHours };
    this.props.selectedDayEvents.map((event) => {
      const eventStartHour = event.date.format("h");
      const eventStartMinute = event.date.minutes();
      const eventA = event.date.format("A");
      let startTimeIndex = `${eventStartHour < 10 ? `0${eventStartHour}` : eventStartHour
        }:30${eventA}`;
      if (eventStartMinute > 30) {
        startTimeIndex = `${eventStartHour + 1 < 10
            ? `0${eventStartHour + 1}`
            : eventStartHour + 1
          }:00${eventA}`;
      } else if (eventStartMinute < 30) {
        startTimeIndex = `${eventStartHour < 10 ? `0${eventStartHour}` : eventStartHour
          }:00${eventA}`;
      }

      if (Object.keys(tours).includes(startTimeIndex)) {
        tours[startTimeIndex].push(event);
      }
    });

    this.setState({ tourHours: tours });
  }

  reRoute = (item) => {
    if (item.maintenance === true) {
      const { id, location } = item;
      this.props.history.history.push({
        pathname: "/maintenanceDetail/location=" + location + "/id=" + id,
      });
    } else return;
  };

  renderEvents() {
    const { open, tourHours } = this.state;
    const {
      // selectedMonthEvents: monthEvents,
      selectedDay: currentSelectedDay,
    } = this.props;

    return Object.keys(tourHours).map((time, i) => {
      return (
        <div className="tour-hour-border">
          <div className="tourHourOnCalendar">{time}</div>

          {tourHours[time].map((event) => {
            if (!event.date.isSame(currentSelectedDay, "day")) return null;
            if (this.props.hideEventsExistsOnGoogleCalendar.includes(event.id))
              return null;

            const descriptionArr =
              event.description && event.description.split("::");
            const formattedTime = event.time && event.time.split("/");
            return (
              <>
                <Card
                  className={`event-card ${event?.isGoogleCalendarEvent
                      ? "google-calendar-event"
                      : "leasera-event"
                    }`}
                  onClick={() => this.reRoute(event)}
                >
                  <Card.Content>
                    {event.title !== "Application" && (
                      <Icon
                        floated="right"
                        name="trash alternate"
                        onClick={this.show}
                        className="event-delete"
                      />
                    )}
                    {event.maintenance ? (
                      <Card.Header>
                        Maintenance Request in Unit: #{descriptionArr[0]}
                      </Card.Header>
                    ) : (
                      <Card.Header>{descriptionArr[0]}</Card.Header>
                    )}
                    {formattedTime && (
                      <Card.Meta>
                        {formattedTime[1]}/{formattedTime[0]}/{formattedTime[2]}
                      </Card.Meta>
                    )}
                  </Card.Content>
                </Card>
                <Confirm
                  className="semanticModal"
                  open={open}
                  onCancel={() => this.handleCancel()}
                  onConfirm={() => this.handleConfirm(event)}
                />
              </>
            );
          })}
        </div>
      );
    });
  }

  render() {
    return (
      <Card.Group className="events-daily" style={{ flexDirection: "column" }}>
        {this.renderEvents()}
      </Card.Group>
    );
  }
}

class DayNames extends React.Component {
  render() {
    return (
      <>
        <Grid.Column className="calendar-dow">Sun</Grid.Column>
        <Grid.Column className="calendar-dow">Mon</Grid.Column>
        <Grid.Column className="calendar-dow">Tue</Grid.Column>
        <Grid.Column className="calendar-dow">Wed</Grid.Column>
        <Grid.Column className="calendar-dow">Thu</Grid.Column>
        <Grid.Column className="calendar-dow">Fri</Grid.Column>
        <Grid.Column className="calendar-dow">Sat</Grid.Column>
      </>
    );
  }
}

class Week extends React.Component {
  render() {
    let days = [];
    let date = this.props.previousCurrentNextView;
    let currentMonthView = this.props.currentMonthView;
    let selected = this.props.selected;
    let select = this.props.select;
    let monthEvents = this.props.monthEvents;
    let chosen = this.props.chosen;
    for (var i = 0; i < 7; i++) {
      var dayHasEvents = false;
      let dayEventCount = 0;

      for (var j = 0; j < monthEvents.length; j++) {
        if (monthEvents[j].date.isSame(date, "day")) {
          dayHasEvents = true;
          dayEventCount++;
        }
      }

      let day = {
        name: date.format("dd").substring(0, 1),
        number: date.date(),
        isCurrentMonth: date.month() === currentMonthView.month(),
        isBefore: date.isBefore(new Date(), "day"),
        isToday: date.isSame(new Date(), "day"),
        date: date,
        hasEvents: dayHasEvents,
        eventCount: dayEventCount,
      };

      days.push(
        <Day day={day} chosen={chosen} selected={selected} select={select} />
      );
      date = date.clone();
      date.add(1, "d");
    }
    return <>{days}</>;
  }
}

class Day extends React.Component {
  render() {
    let day = this.props.day;
    let selected = this.props.selected;
    let select = this.props.select;
    let chosen = this.props.chosen;
    const choose = (date) => {
      chosen = date;
    };
    return (
      <Grid.Column
        centered
        textAlign="center"
        className={
          "day" +
          (day.isToday ? " today" : "") +
          (day.date.isSame(chosen) ? " chosen" : "") +
          (day.isBefore ? " before" : "") +
          (day.isCurrentMonth ? "" : " different-month") +
          (day.date.isSame(selected) ? " selected" : "")
        }
        onClick={() => {
          select(day);
          choose(day.date);
        }}
      >
        <div className="day-number">{day.number}</div>
        {day.eventCount >= 1 ? (
          <div className="event-label">{day.eventCount} Event</div>
        ) : (
          <div>&nbsp;</div>
        )}
      </Grid.Column>
    );
  }
}

export default Schedule;
