import React from "react";
import { Grid, Table } from "semantic-ui-react";
import AddCreditCardMethod from "./AddCreditCardMethod";
import AddBankAccountMethod from "./AddBankAccountMethod";
import * as WepayUtils from "../../../utils/wepay";

const modalTypes = {
  bank: "bank",
  credit_card: "credit_card",
};

export default function AddPayoutMethods({ organizationId, locationId }) {
  const [showModal, setShowModal] = React.useState(false);
  const [modalType, setModalType] = React.useState(modalTypes.bank);
  const [paymentMethods, setPaymentMethods] = React.useState(null);

  const fetchPaymentMethods = async () => {
    try {
      const res = await WepayUtils.getPaymentMethods({ locationId });
      setPaymentMethods(res);
    } catch (error) {}
  };

  const deletePaymentMethod = (id, payoutMethod) => async () => {
    console.log({ id, payoutMethod });
    try {
      const res = await WepayUtils.deletePaymentMethod({
        id,
        payoutMethod,
      });

      if (res === "200") {
        fetchPaymentMethods();
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (paymentMethods === null) fetchPaymentMethods();
  }, [paymentMethods]);

  return (
    <Grid.Column mobile={16} tablet={16} computer={16}>
      <div className="white-block">
        <div className="head">Add Payout Methods</div>

        {paymentMethods !== null && (
          <Table>
            <thead>
              <tr>
                <td>Name</td>
                <td>Last Four</td>
                <td>Account Type</td>
                <td cellSpacing={4}>Address</td>
                <td>Actions</td>
              </tr>
            </thead>
            {paymentMethods?.map((method, i) => {
              return (
                <tr>
                  <td key={`payoutMethod-${i}`}>{method.name}</td>
                  <td>***{method.lastFour}</td>
                  <td>{method.achType}</td>
                  <td>{method?.metaData?.billing_address.street_address}</td>
                  <td>{method?.metaData?.billing_address.city}</td>
                  <td>{method?.metaData?.billing_address.state}</td>
                  <td>{method?.metaData?.billing_address.zip}</td>
                  {/* <td>
                    <span
                      onClick={deletePaymentMethod(
                        method.id,
                        method.payoutMethod
                      )}
                    >
                      DELETE
                    </span>
                  </td> */}
                </tr>
              );
            })}
          </Table>
        )}
        <div>
          {/* <button
            type="button"
            onClick={() => {
              setShowModal(true);
              setModalType(modalTypes.credit_card);
            }}
            className="btn next-btn"
          >
            Add Credit Card
          </button> */}

          <button
            type="button"
            onClick={() => {
              setShowModal(true);
              setModalType(modalTypes.bank);
            }}
            className="btn next-btn"
          >
            Add Bank Account
          </button>

          <AddBankAccountMethod
            showModal={showModal && modalType === modalTypes.bank}
            toggleIsShowing={() => setShowModal(false)}
            organizationId={organizationId}
            locationId={locationId}
            fetchPaymentMethods={fetchPaymentMethods}
          />

          {/* <AddCreditCardMethod
            showModal={showModal && modalType === modalTypes.credit_card}
            toggleIsShowing={() => setShowModal(false)}
          /> */}
        </div>
      </div>
    </Grid.Column>
  );
}
