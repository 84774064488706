import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { BillsAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { Table } from "../../components/Tables/InvoiceData";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss";
import {
  Button,
} from "semantic-ui-react";
import BillDetailsModal from "../../components/Modals/BillDetailsModal";
import PaymentModal from "../../components/Modals/PaymentModal";
import NewBillsModal from "../../components/Modals/NewBillsModal";
import moment from "moment";

const invoiceClient = getClient(ACCOUNTING);

class Bills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      tableData: [],
      old: [],
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Bills",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  StatusFormat = ( values ) => {
    if (values === "partiallyPaid") return "Partially Paid";
    if (values === "paid") return "Paid";
    if (values === "created") return "Created";
    if (values === "approved") return "Approved";
    if (values === "voided") return "Voided";
    else return "";
  };

  getLedger = (data) => {
    if (!data) {
      this.setState({ loading: true });
    }
    invoiceClient
      .query({
        query: BillsAccounting,
        variables: {
          location: this.props.selectedPrimary.node.id,
        },
      })
      .then((res) => {
        if (res.data) {
          const dataToPass = get(res, "data.slLocationBills.data", []).map((obj) => {
            const apId = obj.APAccount._id;
            const { name } = obj.Vendor;
            const { amount, dueAmount, dueDate, invoiceDate, postingDate, invoiceNumber, status } = obj;
            const uiAmount = "$" + amount.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
            const uiDueAmount = "$" + dueAmount.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
            const uiDueDate = moment(dueDate).format("l");
            const uiInvoiceDate = moment(invoiceDate).format("l");
            const uiPostingDate = moment(postingDate).format("l");
            const billDetailsModal = <BillDetailsModal bill={obj} user={this.props.user} location={this.props} bills getLedger={this.getLedger}/>
            const action = (status !== "paid" && status !== "voided") ? <PaymentModal bill={obj} user={this.props.user} location={this.props} bills={true} getLedger={this.getLedger} />:"";
            const edit = status === "created" ? <NewBillsModal bill={obj} user={this.props.user} location={this.props} />:""
            const uiStatus = this.StatusFormat(status);
            return { billDetailsModal, apId, uiDueDate, uiInvoiceDate, uiPostingDate, invoiceNumber, uiAmount, uiDueAmount, uiStatus, name, action, edit }
          });
          this.setState({
            tableData: dataToPass,
            old: get(res, "data.slLocationBills.data", []),
            totalItems: get(res, "data.slLocationBills.totalItems"),
          });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  };

  dataPush = (response) => {
    this.setState((prevState) => ({
      tableData: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.tableData,
      ],
    }));
  };
  updateInvoice = (status, invoiceId) => {
    const { tableData } = this.state;
    const array = [];
    if (status === "Delete") {
      this.setState({
        tableData: tableData.filter(
          (invoice) => invoice._id !== invoiceId
        ),
      });
    } else {
      tableData.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      this.setState({ tableData: array });
    }
  };

  render() {
    const { loading } = this.state;
    const mainHeader = ["ID", "AP ID", "Due", "Invoice", "Posting", "Number", "Amount", "Amount Due", "Status", "Vendor", "Action", "Edit"];

    // !: Links/Modals/Actions. Error handling.
    // TODO: Format data for user. Style.

    return (
      <>
      <div className="row mb-4">
          <div className="col-md-12">
            <div className="page-header">
              <div className="d-flex align-items-center">
                {/* <span className="page-back"> */}
                  {/* <img src={backIcon} alt="back" /> */}
                  
                {/* </span> */}
                <div>
                  <div className="page-header-title">Bills</div>
                </div>
              </div>
              <div className="d-flex align-items-end">
                <Button
                onClick={() => window.print()}
                  className="ml-4 noPrint noPrint-button"
                  compact
                  style={{
                    backgroundImage:
                      "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                  }}
                >
                  Print
                </Button>
                {/* <Button className="ml-2" style={{ backgroundImage: "linear-gradient(110deg, #3b1c5a, #374db1 162%)", color: "white" }}>Share</Button> */}
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader text inTable />
        ) : (
          <>
          {/* <Table
            updateInvoice={this.updateInvoice}
            getNewData={this.getNewData}
            user={this.props.user}
            property={
              this.props.selectedPrimary &&
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.customId
            }
            dataPush={this.dataPush}
            getLedger={this.getLedger}
            bills
            invoiceCache={this.state.old && this.state.old}
            totalItems={this.state.totalItems && this.state.totalItems}
            propertyId={
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.id
            }
            staticId={
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.slLocationId
            }
          /> */}
            <div className="table-container">
              <GeneralAccountingTable
                mainCellData={this.state.tableData}
                mainHeader={mainHeader}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

export default withApollo(Bills);
