import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { VendorsAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { Table } from "../../components/Tables/InvoiceData";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss";
import mixpanel from "mixpanel-browser";
import VendorsModal from "../../components/Modals/VendorsModal";
import {  toast } from "react-toastify";
import { Button } from "semantic-ui-react";

const invoiceClient = getClient(ACCOUNTING);

class Vendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      tableData: [],
      old: [],
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Vendors",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  getLedger = (data) => {
    this.setState({ loading: true });
    invoiceClient
      .query({
        query: VendorsAccounting,
        variables: {
          location: this.props.selectedPrimary.node.id,
        },
      })
      .then((res) => {
        if (res.data) {
          const dataToPass = get(res, "data.slLocationVendors.data", []).map((obj,i) => {
            const { id, name, accNumber, email, terms, attachments } = obj;
            const uiId = id ? id : "";
            const uiName = name ? name : "";
            const uiAccNumber = accNumber ? accNumber : "";
            const uiEmail = email ? email : "";
            const uiTerms = terms ? terms : "";
            const uiAttachments = attachments ? attachments : "";
            // const vendorsActions = { "vendorsObj": obj, "vendorsActions": true}
            return { uiId, uiName, uiAccNumber, uiEmail, uiTerms, uiAttachments }
          });
          this.setState({
            tableData: dataToPass,
            old: get(res, "data.slLocationVendors.data", []),
            totalItems: get(res, "data.slLocationVendors.totalItems"),
          });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  };

  dataPush = (response) => {
    this.setState((prevState) => ({
      tableData: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.tableData,
      ],
    }));
  };
  updateInvoice = (status, invoiceId) => {
    const { tableData } = this.state;
    const array = [];
    if (status === "Delete") {
      this.setState({
        tableData: tableData.filter(
          (invoice) => invoice._id !== invoiceId
        ),
      });
    } else {
      tableData.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      this.setState({ tableData: array });
    }
  };

  render() {
    const { loading } = this.state;

    const mainHeader = ["Id", "Name", "Account Number", "Email", "Terms", "Attachment"];
    const fail = () =>
    toast.error("No agent with this ID exists in our system", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const success = () =>
    toast.success("Success!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    // !: Actions. Error handling.
    // TODO: Make data presentable to user.
    return (
      <>
      <div className="row mb-4">
          <div className="col-md-12">
            <div className="page-header">
              <div className="d-flex align-items-center">
                <div>
                  <div className="page-header-title">Vendors</div>
                </div>
              </div>
              <div className="d-flex align-items-end">
              <VendorsModal
                dataPush={this.dataPush}
                fail={fail}
                user={this.props.user}
                success={success}
                getLedger={this.getLedger}
                propertyId={
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.id
            }
              />
                <Button
                onClick={() => window.print()}
                compact
                  className="ml-4 noPrint noPrint-button"
                  style={{
                    backgroundImage:
                      "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                 
                  }}
                >
                  Print
                </Button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader text inTable />
        ) : (
          <>
          {/* <Table
            updateInvoice={this.updateInvoice}
            user={this.props.user}
            getNewData={this.getNewData}
            property={
              this.props.selectedPrimary &&
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.customId
            }
            dataPush={this.dataPush}
            vendors
            getLedger={this.getLedger}
            invoiceCache={this.state.old && this.state.old}
            totalItems={this.state.totalItems && this.state.totalItems}
            propertyId={
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.id
            }
          /> */}
          <GeneralAccountingTable
            mainCellData={this.state.tableData}
            mainHeader={mainHeader}
          />
          </>
        )}
      </>
    );
  }
}

export default withApollo(Vendors);
