import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { InvoiceAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { Table } from "../../components/Tables/AgingData";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss";

const invoiceClient = getClient(ACCOUNTING);

class Aging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      invoiceCache: [],
      offset: 0,
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Aging",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  getLedger = (data) => {
    try {
      this.setState({ loading: true });
      invoiceClient
        .query({
          query: InvoiceAccounting,
          variables: {
            location: this.props.selectedPrimary.node.id,
            credits: false,
            offset: this.state.offset,
            limit: 30,
          },
        })
        .then((res) => {
          if (res.data) {
            this.setState({
              invoiceCache: get(res, "data.slLocationInvoices.data", []),
              totalItems: get(res, "data.slLocationInvoices.totalItems"),
            });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  dataPush = (response) => {
    this.setState((prevState) => ({
      invoiceCache: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.invoiceCache,
      ],
    }));
  };
  updateInvoice = (status, invoiceId) => {
    const { invoiceCache } = this.state;
    const array = [];
    if (status === "Delete") {
      this.setState({
        invoiceCache: invoiceCache.filter(
          (invoice) => invoice._id !== invoiceId
        ),
      });
    } else {
      invoiceCache.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      this.setState({ invoiceCache: array });
    }
  };

  handleOffset = (x) => {
    this.setState({offset: x});
  }

  render() {
    const { loading } = this.state;
    return (
      <>
        {loading ? (
          <Loader text inTable />
        ) : (
          <Table
            updateInvoice={this.updateInvoice}
            getNewData={this.getNewData}
            property={
              this.props.selectedPrimary &&
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.customId
            }
            offset={this.state.offset}
            handleOffset={this.handleOffset}
            dataPush={this.dataPush}
            getLedger={this.getLedger}
            invoiceCache={this.state.invoiceCache && this.state.invoiceCache}
            totalItems={this.state.totalItems && this.state.totalItems}
            propertyId={
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.id
            }
          />
        )}
      </>
    );
  }
}

export default withApollo(Aging);
