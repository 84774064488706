import React, { useState, useEffect } from "react";
import { Button, Modal, Dropdown, Header } from "semantic-ui-react";
import "./moveLeadModal.scss";

export function MoveLeadModal(props) {
  const [open, setOpen] = useState(false);
  const [primaryList, setPrimaryList] = useState([]);
  const [changedLocation, setUpdateLocation] = useState("");
  const [locationToSend, setLocationToSend] = useState("");

  useEffect(() => {
    const data = props.locations.map((location, index) => ({
      key: `location.node ${index} ${1}`,
      text: location.node.customId,
      value: location.node.id,
    }));
    setPrimaryList(data);
  }, [props.locations]);

  const selectProperty = (e) => {
    setUpdateLocation(e.target.textContent);
    props.locations.forEach((locationMatch) => {
      if (e.target.textContent === locationMatch.node.customId) {
        setLocationToSend(locationMatch.node.id);
      }
    });
  };

  return (
    <Modal
      className="semanticModal modal-ui modal-radius overflow-y-visible"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        props.from !== "ViewLead" ? (
          <Button
            disabled={props.disabled}
            size="large"
            floated="right"
            className="dark-button"
          >
            Move Lead
          </Button>
        ) : (
          <div>Move Lead</div>
        )
      }
    >
      <Modal.Header textAlign="left" className="modal-header-bg">
        <Header textAlign="left" className="modal-heading-custom">
          Move Lead
        </Header>
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <Dropdown
            // className="location-dropdown"

            fluid
            selection
            options={primaryList}
            text={changedLocation ? changedLocation : props.propertyCustomId}
            selectOnBlur={false}
            onChange={(e) => selectProperty(e)}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="modal-close-button"
          content="Close"
          onClick={() => setOpen(false)}
          positive
        />
        {props.sending ? (
          <Button loading> Loading</Button>
        ) : (
          <Button
            className="modal-save-button"
            content="Move"
            disabled={!locationToSend}
            onClick={() =>
              props.onMoveLead({
                move: true,
                location: locationToSend,
                id: props.leadId,
              })
            }
            positive
          />
        )}
      </Modal.Actions>
    </Modal>
  );
}
