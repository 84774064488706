import React from "react";
import {
  Button,
  Header,
  Image,
  Modal,
  Table,
  Grid,
  Tab,
  Dropdown,
  Progress,
  Segment,
  Input,
  Select,
} from "semantic-ui-react";
import get from "lodash/get";
import {
  PurchaseOrderLineItems,
} from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import { INVOICE_ACCOUNTING_PAYMENT } from "../../utils/constants";
import moment from "moment";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
import mixpanel from "mixpanel-browser";
import ViewInvoiceDetails from "../Accounting/ViewInvoiceDetails";
const paymentClient = getClient(INVOICE_ACCOUNTING_PAYMENT);

function WorkOrderDetailModal(props) {
  const [open, setOpen] = React.useState(false);
  const [POLineItems, setPOLItemArray] = React.useState([]);
  const order = props.order

  const getLineItems = () => {
    try {
      paymentClient
        .query({
          query: PurchaseOrderLineItems,
          variables: {
            PurchaseOrderId: props.order._id,
          },
        })
        .then((res) => {
          const purchaseData = get(res, "data.purchaseOrderLineItems.data", null);
          const purchaseArray = purchaseData.map(item => (
              {
                "description":item.description,
                "ItemId": item._id,
                "quantity": parseInt(item.quantity),
                "amount": parseFloat(item.total).toFixed(2),
                "LedgerAccountId":0
              }
          )
          )
          if (res.data) {
            setPOLItemArray(purchaseArray);
          }
        })
        .catch((error) => {});
    } catch (e) {}
  }


  /* eslint-disable */
  return (
    <Modal
      className="semanticModal paybillsmodaloverview overflow-y-visible"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      // size='large'
      trigger={
        <a onClick={() => getLineItems()} className="paybillsmodal">
          {props.order._id}
        </a>
      }
    >
      <Modal.Header>
      </Modal.Header>
        <Modal.Content className="billscontent">
        <Grid columns={2}>
          <Grid.Column>
            <Table compact>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Vendor</Table.Cell>
                  <Table.Cell>
                    #{order.VendorId} -{" "}
                    {order.Vendor&&order.Vendor.name}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Status</Table.Cell>
                  <Table.Cell style={{ textTransform: "Capitalize" }}>
                    {order.status}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Approved</Table.Cell>
                  <Table.Cell >
                    {order.approved_on}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Approved By</Table.Cell>
                  <Table.Cell>{order.approved_name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Approved Email</Table.Cell>
                  <Table.Cell>{order.approved_email}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column>
            <Table compact>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Amount</Table.Cell>
                  <Table.Cell>$ {order.amount}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Delivery Date</Table.Cell>
                  <Table.Cell>{moment(order.deliveryDate).format("MM/DD/YYYY")}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Issue Date</Table.Cell>
                  <Table.Cell>{moment(order.issueDate).format("MM/DD/YYYY")}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Warehouse</Table.Cell>
                  <Table.Cell>{order.Warehouse&&order.Warehouse.name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Location</Table.Cell>
                  <Table.Cell>{order.Location&&order.Location.name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Receiving Account</Table.Cell>
                  <Table.Cell>
                  #{order.InventoryReceivingAccount&&order.InventoryReceivingAccount.number} -{" "}
                    {order.InventoryReceivingAccount&&order.InventoryReceivingAccount.name}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>External Id</Table.Cell>
                  <Table.Cell>{order.externalId}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Currency</Table.Cell>
                  <Table.Cell>
                    {order.currency}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid>

        <Table padded="very" compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {POLineItems && POLineItems.map(item => {
                return (
                <Table.Row>
                  <Table.Cell>{item.ItemId}</Table.Cell>
                  <Table.Cell>
                    {item.description}
                  </Table.Cell>
                  <Table.Cell>{item.quantity}</Table.Cell>
                  <Table.Cell>
                    $ {item.amount}
                  </Table.Cell>
                </Table.Row>
                )
              })
             
              }
            </Table.Body>
          </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)} content="Close"  />
      </Modal.Actions>
    </Modal>
  );
}

export default WorkOrderDetailModal;
