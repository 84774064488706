import React from "react";
import { Container, Dropdown, Modal, Button , Icon} from "semantic-ui-react";
import { PageLoader } from "../../Loader/PageLoader";
import get from "lodash/get";
import PropertyCard from "./PropertyCard";
import PropertyListItem from "./PropertyListItem";
import { getClient } from "../../../init-apollo-googleFn";
import Tour from 'reactour'
import { propertiesSteps } from "../../../utils/tourSteps";
import {
  LOCATIONAPI,
  ROUTES,
  BLUEMOON_API,
  EVOCALIZE,
} from "../../../utils/constants";
import {
  toastFailMsg,
  parseGraphQLErrors,
  parseAddresses,
  parseDepositRules,
  parsePhotosUrl,
  toastSuccessMsg,
} from "../../../utils/common";
import {
  AlterLocation,
  getProperties,
  getEvocalize,
} from "../../../store/person/properties";
import PropertyTypeSelection from "./PropertyTypeSelection";
import CancelDeleteModal from "../AddProperty/CancelDeleteModal";
import { bluemoonInfo } from "../../../store/bluemoon/bluemoon";
import mixpanel from "mixpanel-browser";
const EvocalizeClient = getClient(EVOCALIZE);
const locationClient = getClient(LOCATIONAPI);
const bluemoonApi = getClient(BLUEMOON_API);

const options = [
  {
    key: "publish",
    text: "Publish",
    value: "publish",
  },
  {
    key: "draft",
    text: "Draft",
    value: "draft",
  },
];

const PropertyListing = ({
  history,
  selectedPrimary,
  primaryLocations,
  user,
  organizationId,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [getProperty, setGetProperty] = React.useState(false);
  const [tourOpen, setTourOpen] = React.useState(false);
  const [isListView, setIsListView] = React.useState(false);
  const [isPropertySelection, setIsPropertySelection] = React.useState(false);
  const [isDisabling, setIsDisabling] = React.useState(null);
  const [propertyList, setPropertyList] = React.useState([]);
  const [statusFilter, setStatusFilter] = React.useState(options[0].value);
  const [modalEvoc, setEvocModal] = React.useState(false);
  const [evocData, setEvocData] = React.useState(false);
  const [filteredPropertyList, setFilteredPropertyList] = React.useState([]);

  const filterPropertiesByStatus = (list, status) => {
    setFilteredPropertyList(
      list.filter((item) =>
        status === "draft"
          ? item.status === status
          : item.status === null || item.status === status
      )
    );
  };

  const getPropertiesList = () => {
    setIsLoading(true);

    locationClient
      .query({
        query: getProperties,
        variables: {
          status: statusFilter,
        },
      })
      .then((response) => {
        // console.log("Get Properties List");
        const locations = get(response, "data.locations.edges", []);
        if (locations) {
          const locationNodes = locations.map((l) => l.node);
          setPropertyList(locationNodes);
          // filterPropertiesByStatus(locationNodes, statusFilter);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toastFailMsg(
          parseGraphQLErrors(error).toString() ||
          "Unable to get property lists."
        );
      });
    // .finally(() => setIsLoading(false));
  };

  const fetchCredentialDetail = async () => {
    await bluemoonApi
      .query({
        query: bluemoonInfo,
      })
      .then((res) => {
        const data = res.data.bluemoonInfo.edges[0].node;
        localStorage.setItem("bluemoonData", JSON.stringify(data));
      })
      .catch((error) => {
        toastFailMsg(parseGraphQLErrors(error));
      });
  };

  /* eslint-disable */
  React.useEffect(() => {
    getPropertiesList();
    fetchCredentialDetail();
    mixpanel.track("Manager Page Load", {
      sub: "Property Listing",
    });
  }, []);
  /* eslint-enable */

  React.useEffect(() => {
    getPropertiesList();
  }, [statusFilter]);

  const handlePropertyFilter = (e, { value }) => {
    setStatusFilter(value);
    // filterPropertiesByStatus(propertyList, value);
  };

  const navigateToDetails = (locationId) => {
    history.push(ROUTES.propertyDetails.replace(":locationId", locationId));
  };

  const onSelectPropertyType = (propertyType) => {
    setIsPropertySelection(false);
    history.push({
      pathname: ROUTES.addProperty,
      search: `propertyType=${propertyType}`,
    });
  };

  const onCardClickEvocalize = (key) => {
    try {
      mixpanel.track("Manager Property Action", {
        sub: "Route to Evocalize",
      });
      EvocalizeClient.query({
        query: getEvocalize,
        variables: {
          userId: "3",
        },
      })
        .then((res) => {
          const token = res.data.authorization.token;
          setEvocModal(true);
          setEvocData(
            `https://leasera.integration.evocalize.com/#/sso?token=${token}`
          );
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const disableProperty = ({ id: locationId, disabled, photos }) => {
    if (locationId) {
      mixpanel.track("Manager Property Action", {
        sub: "Disable Property",
      });
      locationClient
        .mutate({
          mutation: AlterLocation,
          variables: {
            input: {
              id: locationId,
              existingPhotos: photos,
              disabled: !disabled,
            },
          },
        })
        .then((response) => {
          if (response.data) {
            toastSuccessMsg(
              `Property ${disabled ? "activated" : "deactivated"} successfully.`
            );
            getPropertiesList();
          }
        })
        .catch((error) => {
          toastFailMsg(
            parseGraphQLErrors(error).toString() ||
            "Property can't be disabled."
          );
        });
    }
  };

  const onDisableResponse = (response) => {
    if (response) disableProperty(isDisabling);
    setIsDisabling(null);
  };

  if (isLoading) return <PageLoader text />;
  return (
    <>
      <Container className="property-container">
        <div className="header-section">
          <div className="left-container">
            <div className="head properties-first-step">Properties <Icon size='small' circular name='lightbulb' onClick={() => setTourOpen(true)} /></div>
            <p>Here&apos;s the list of your added properties</p>
          </div>
          <div className="right-container">
            <ul>
              <li>
                {/* eslint-disable */}
                <a
                  className={isListView ? "" : "active"}
                  onClick={() => setIsListView(false)}
                >
                  <img src="assets/img/card-menu-icon.svg" alt="" />
                </a>
                {/* eslint-enable */}
              </li>
              <li>
                {/* eslint-disable */}
                <a
                  className={isListView ? "active" : ""}
                  onClick={() => setIsListView(true)}
                >
                  <img src="assets/img/list-menu-icon.svg" alt="" />
                </a>
                {/* eslint-enable */}
              </li>
            </ul>
            <div className="properties-second-step">

              <Dropdown
                fluid
                selection
                options={options}
                value={statusFilter}
                onChange={handlePropertyFilter}
              />
            </div>
            <button
              type="button"
              className="btn next-btn properties-third-step"
              onClick={() => setIsPropertySelection(true)}
            >
              Add Property
            </button>
          </div>
        </div>
        <PropertyTypeSelection
          isOpen={isPropertySelection}
          onClose={() => setIsPropertySelection(false)}
          onSelect={onSelectPropertyType}
        />
        <div
          className={`card-inner-container ${isListView ? "list-view" : ""}`}
        >
          {propertyList &&
            propertyList.map((item, i) => {
              const addresses = parseAddresses(item.addresses);
              const depositRules = parseDepositRules(item.depositRules);
              const photos = parsePhotosUrl(item.photos);
              const PropertyItem = isListView ? PropertyListItem : PropertyCard;
              const { templateId } =
                primaryLocations &&
                primaryLocations[i] !== undefined &&
                primaryLocations[i].node;
              // console.log(templateId)
              return (
                <PropertyItem
                  key={item.id}
                  {...{
                    ...item,
                    addresses,
                    depositRules,
                    photos,
                    primaryLocations,
                    organizationId,
                  }}
                  user={user}
                  templateId={templateId}
                  onCardClickEvocalize={onCardClickEvocalize}
                  navigateToDetails={navigateToDetails}
                  onDeactivate={setIsDisabling}
                />
              );
            })}
          {!!isDisabling && (
            <CancelDeleteModal
              isOpen={!!isDisabling}
              onClose={() => setIsDisabling(null)}
              onResponse={(response) => onDisableResponse(response)}
              title={
                isDisabling.disabled
                  ? "Activate Property"
                  : "Deactivate Property"
              }
              yesBtnText={`Yes, ${isDisabling.disabled ? "Activate" : "Deactivate"
                } Property`}
              isDeleting
            >
              <p>
                {`Are you sure you want to ${isDisabling.disabled ? "activate " : "deactivate "
                  }`}
                <strong>{isDisabling.name}</strong>
                {`${isDisabling.disabled ? " property?" : " from your listing?"
                  }`}
              </p>
            </CancelDeleteModal>
          )}
        </div>
      </Container>
      <Modal
        className=" evocalizeModal"
        onClose={() => setEvocModal(false)}
        onOpen={() => setEvocModal(true)}
        open={modalEvoc}
        size="large"
      >
        <Modal.Content>
          <Modal.Description>
            <iframe src={evocData}></iframe>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Close"
            labelPosition="right"
            icon="checkmark"
            onClick={() => setEvocModal(false)}
            positive
          />
        </Modal.Actions>
      </Modal>
      <Tour
        steps={propertiesSteps}
        isOpen={tourOpen}
        startAt={0}
        onRequestClose={() => setTourOpen(false)}
        // maskClassName="mask"
        className="helper"
        rounded={5}
      />
    </>
  );
};

export default PropertyListing;
