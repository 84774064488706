import React, { useState } from "react";
import { Button, Image, Modal, Form } from "semantic-ui-react";
import { LOYALTY } from "../../utils/constants";
import { sendLoyaltyPointGql } from "../../store/person/loyalty";
import { getClient } from "../../init-apollo-googleFn";
import mixpanel from "mixpanel-browser";
import { parseGraphQLErrors, toastFailMsg, toastSuccessMsg } from "../../utils/common";
import { getEmitter } from "../../utils";
import pointsIcon from "../../assets/images/points-icon.svg";
import giftIcon from "../../assets/images/gift-points-icon.svg";
import LeaLoader from '../../components/PageLoader/LeaLoader';
const eventEmit = getEmitter();

const loyaltyClient = getClient(LOYALTY);
function GiftPointsModal(prop) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendGift = () => {
    setIsSubmitting(true);
    mixpanel.track("Manager Loyalty Action", {
      sub: "Gift Points to User",
    });
    loyaltyClient
      .mutate({
        mutation: sendLoyaltyPointGql,
        variables: {
          package: {
            renterEmail: prop.recipient,
            locationId: prop.location,
            points: amount,
            message: message,
          },
        },
      })
      .then((res) => {
        toastSuccessMsg("Successfully gifted points to user");
        setIsSubmitting(false);
        onClose();
        eventEmit.emit("PurchasePoints");
      })
      .catch((e) => {
        toastFailMsg("Trouble processing request, please refer to system admin");
        setIsSubmitting(false);
        onClose();
      });
  };

  const onClose = () => {
    setOpen(false);
    setAmount(false);
    setMessage("");
  }

  return (
    <Modal
      className="semanticModal loyalty-points-modal"
      closeIcon
      onClose={() => onClose()}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={
        prop.from !== "ViewLead" ? (
          <Button className="btn-gradient">
            {" "}
            <Image src={pointsIcon} alt="points" /> Gift Loyalty Points
          </Button>
        ) : (
          <div>Gift Loyalty Points</div>
        )
      }
    >
      {/* <Modal.Header>Gift Loyalty Points</Modal.Header> */}
      <Modal.Content>
        <Modal.Description>
          <div className="points-icon">
            <Image src={giftIcon} alt="points" />
          </div>
          <div className="points-modal-title">Gift Loyalty Points</div>
          <div className="points-modal-description">
            Say thank you by gifting loyalty points.
          </div>
          <Form>
            <Form.Input
              label="Points"
              placeholder="Points"
              type="number"
              name="name"
              onChange={({ target: { value } }) => {
                setAmount(value);
              }}
            />
            {/* <div className='points-balance'>Points Balance: 500</div> */}
            <Form.TextArea
              label="Personalized Message"
              placeholder="Input Personalize Message Here"
              value={message}
              maxLength={155}
              onChange={({ target: { value } }) => {
                setMessage(value);
              }}
            />
            <p>{message.length}/155 characters</p>
            <Button
              fluid
              style={{backgroundColor: !amount && '#4c158a'}}
              className="btn-gradient"
              disabled={!amount || isSubmitting}
              onClick={() => sendGift()}
            >
              {isSubmitting ?
                <LeaLoader wrapperStyle={{position: 'static', height: '40px', width: '40px', margin: '16px auto 0 auto'}} logoStyle={{height: '60px'}}/>
              :
                "Gift Now"
              }
            </Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default GiftPointsModal;
