import React from 'react'
import { Table } from 'semantic-ui-react'
import _ from "lodash";
import { computeAmounts } from "../../utils/common";
import ReconcileModalContainer from "../Modals/ReconcileModalContainer";

    const StripedBalanceTable = ({ ledgerCache, getSingleLedger, singleLedger }) => {
        const grouped = _.groupBy(ledgerCache, car => car.node ?car.node.type: car.type);
        const balanceArr= []
        const newa = Object.keys(grouped).forEach(function(key) {
            const grouped2 = _.groupBy(grouped[key], sub => sub.node?sub.node.subType:sub.subType);
            const balanceArr2= []
            Object.keys(grouped2).forEach(function(key) { 
                const tempObj = {
                    name:key,
                    items:grouped2[key]
                }
                balanceArr2.push(tempObj)
            });
            const object = {
                primary:key,
                sub:balanceArr2
            }
            balanceArr.push(object)
          });
        const addFunctionQuant = (item) => {
            console.log(item)
            const sum = item.reduce(add, 0);
            function add(accumulator, a) {
                console.log(a)
                if(typeof a=="object"){
                    return (accumulator += parseFloat(a.balance));
                }
                else return (accumulator += parseFloat(a));
            }
            if(Math.sign(sum) !== 1){
                return <span>$ ({Math.abs(Number.parseFloat(sum)).toLocaleString('en')})</span>
            }
            if(Math.sign(sum) == 1){
                return <span>$ {Math.abs(Number.parseFloat(sum)).toLocaleString('en')}</span>
            }
          };
        return (
            <>
            <Table compact={'very'} striped>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Account Name</Table.HeaderCell>
                    <Table.HeaderCell>Balance</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>
                {
                   balanceArr.length!==0? balanceArr.map(primary => {
                        const primaryTotal = []
                        primary.sub.map(arry => {
                            const sum = arry.items.reduce((partialSum, a) => partialSum + parseFloat(a.node?a.node.balance:a.balance), 0);
                            primaryTotal.push(sum)
                        })
                        return (
                            <>
                            <Table.Row>
                                <Table.Cell style={{fontWeight:'900'}}>{primary.primary}</Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>
                            {
                               primary.sub.map(sub => {
                                    return(
                                        <>
                                    <Table.Row>
                                        <Table.Cell style={{fontWeight:'600'}}>&nbsp; {sub.name}</Table.Cell>
                                        <Table.Cell></Table.Cell>
                                    </Table.Row>
                                    {
                                        sub.items.map(line => {
                                            return(
                                                <>
                                                <Table.Row>
                                                    <Table.Cell>&nbsp; &nbsp;- {line.node?line.node.name:line.name}</Table.Cell>
                                                    {/* <Table.Cell  textAlign="right"><ReconcileModalContainer name={line.node.name} number={line.node.number} balance={line.node.balance} singleLedger={singleLedger} getSingleLedger={getSingleLedger} /></Table.Cell> */}
                                                    <Table.Cell  textAlign="right">{computeAmounts(line.node?line.node.balance:line.balance)}</Table.Cell>
                                                </Table.Row>
                                                </>
                                            )
                                        })
                                        
                                    }
                                    <Table.Row>
                                        <Table.Cell style={{fontWeight:'600'}}>&nbsp; Total {sub.name}</Table.Cell>
                                        <Table.Cell style={{borderBottom:"2px solid black"}} textAlign="right">{addFunctionQuant(sub.items)}</Table.Cell>
                                    </Table.Row>
                                    </>
                                    )
                               })
                               
                            }
                            <Table.Row>
                                <Table.Cell style={{fontWeight:'900'}}>Total {primary.primary}</Table.Cell>
                                <Table.Cell style={{borderBottom:"4px solid black"}} textAlign="right">{primaryTotal.length !== 0 &&addFunctionQuant(primaryTotal)}</Table.Cell>
                            </Table.Row>
                            </>
                        )

                    }):
                    <div className="no-results-text-application text-center py-5">
                        <span className="not-found-icon"></span>
                        <p className="no-results-text">
                            Please select the dates you wish to generate a report for. Select both a start and end date then click the 'Generate' button in the upper right.
                        </p>
                        </div>
                }
                </Table.Body>
            </Table>
  </>
  )
}

export default StripedBalanceTable