import React, { Component } from "react";
import moment from "moment";
import { disablePastDt, formatErrorMessage } from "../../utils/common";
import { UNIT_TYPE_API, UNITAPI } from "../../utils/constants";
import housingProgramByPropertyGql from "../../store/person/housingProgramByPropertyGql";
import { unitType, units } from "../../store/person/properties";
import ReeValidate from "ree-validate";
import { toast } from "react-toastify";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "react-toastify/dist/ReactToastify.css";
import { Button, Form, Message } from "semantic-ui-react";
import "./Applications.scss";
import toaster from "../../utils/toaster";
import { getClient } from "../../init-apollo-googleFn";

const unitTypeManager = getClient(UNIT_TYPE_API);
const unitManager = getClient(UNITAPI);
class ApplicationUnitInfo extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate.Validator({
      adults: "required|numeric|min_value:1",
      children: "required|numeric",
      petsNumber: "required|numeric",
    });

    this.state = {
      errors: formatErrorMessage(this.validator.errors.items),
      affordableHousingData: [],
      units: [],
      unitTypeData: [],
      unitsData: [],
      bedroom: null,
      bathroom: null,
      level: "",
    };
  }

  componentDidMount() {
    const { application } = this.props;
    this.fetchAffordableHousing();
    this.fetchUnitsData(this.props.selectedPrimary);
    this.fetchUnitTypeData();
    if (application.unitType) {
      this.fetchUnitNumber("event", "value", application.unitType);
    }
  }

  fetchAffordableHousing = async () => {
    const { affordableHousing, customId } = this.props;
    customId &&
      (await affordableHousing
        .query({
          query: housingProgramByPropertyGql,
          variables: { location: customId },
        })
        .then((res) => {
          this.setState({
            affordableHousingData: res.data.housingPrograms.edges,
          });
        })
        .catch((error) => {
          toast.error(error);
        }));
  };

  fetchUnitTypeData = async () => {
    const { customId } = this.props;
    customId &&
      (await unitTypeManager
        .query({
          query: unitType,
          variables: { locationId: customId },
        })
        .then((res) => {
          this.setState({ unitTypeData: res.data.unitType.edges });
        })
        .catch((error) => {
          toast.error(error);
        }));
  };

  /* function to remove the error and revalidate the data */
  removeErrorAndReValidate = (name, type) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator
      .validate(name, this.state.application[type][name])
      .then(() => {
        this.setState({ errors });
      });
  };

  /* function that validates the data captured, if data is valid,
  it calls the handlesubmit function to integrate the mutation */
  validateAndSubmit = async (e) => {
    e.preventDefault();
    const { application, updateFormSteps, leasingAgentStateValue } = this.props;
    let updatedErrors = [];
    const { errors } = this.validator;
    const valid = await this.validator.validateAll(application);
    if (
      (valid || !this.props.showComment) &&
      leasingAgentStateValue &&
      application.liquidFilledFurniture !== undefined &&
      application.liquidFilledFurniture !== null &&
      application.smoke !== undefined &&
      application.smoke !== null
    ) {
      updateFormSteps(1);
    } else if (
      application.liquidFilledFurniture === undefined ||
      application.liquidFilledFurniture === null
    ) {
      updatedErrors.push({
        field: "Liquid Filled Furniture",
        message: "Liquid filled furniture is a mandatory field",
      });
    }
    if (application.smoke === undefined || application.smoke === null) {
      updatedErrors.push({
        field: "Smoke",
        message: "Occupant smoke is a mandatory field",
      });
    }
    if (!!errors.items.length) {
      errors.items.forEach((item, index) => {
        updatedErrors.push({ field: item.field, message: item.msg });
      });
    }
    this.setState({ errors: updatedErrors });
  };

  /* function to delete the empty field from the state */
  removeEmptyData = (field) => {
    const applicationData = this.state.application;
    delete applicationData[field];
  };

  fetchUnitsData = (selectedPrimary) => {
    const units =
      selectedPrimary &&
      selectedPrimary.node &&
      selectedPrimary.node.properties;
    const unitsArray =
      units &&
      units.map((node) => {
        return {
          key: node.ndbId,
          value: node.location.unitNum,
          text: node.location.unitNum,
        };
      });
    this.setState({ units: unitsArray });
  };

  fetchUnitNumber = async (e, { value }, id) => {
    const { customId, updateDropdownValues, bedrooms, bathrooms, level } =
      this.props;
    customId &&
      (await unitManager
        .query({
          query: units,
          variables: { locationId: customId, unitTypesId: !id ? value.id : id },
        })
        .then((res) => {
          this.setState({
            unitsData: [],
            bedroom: !id ? value.bedroom : bedrooms,
            bathroom: !id ? value.bathroom : bathrooms,
            level: !id ? "" : level,
          });
          this.setState({ unitsData: res.data.units.edges }, () => {
            !id
              ? updateDropdownValues(e, { value })
              : updateDropdownValues(e, "unitType", id);
          });
        })
        .catch((error) => {
          console.log(error);
        }));
  };

  selectedUnitNumber = async (e, { value }) => {
    this.setState({ level: value.level });
    this.props.updateDropdownValues(e, { value });
  };

  dropdownGroup = (
    application,
    updateApplication,
    handleDateChange,
    affordableHousingData,
    updateApplicantName,
    pm,
    leasingAgentStateValue,
    affordableData,
    affordableText,
    unitType,
    unitsData,
    unitTypeText,
    unitNumberText,
    bedrooms
  ) => (
    <>
      <Form className="unit-info-form">
        <Form.Group widths="equal" className="unit-info-form-first-row">
          <Form.Input
            className="height-38"
            label="First Name"
            placeholder="First Name"
            autoFocus
            value={application.primaryApplicant.firstName}
            onChange={(e) => updateApplicantName(e.target.value, "firstName")}
          />
          <Form.Input
            className="height-38"
            label="Last name"
            placeholder="Last name"
            value={application.primaryApplicant.lastName}
            onChange={(e) => updateApplicantName(e.target.value, "lastName")}
          />
          <div className="move-date">
            <p className="move-date-label">Move In Date</p>
            <Datetime
              timeFormat={false}
              isValidDate={disablePastDt}
              minDate={moment().toDate()}
              closeOnSelect={true}
              inputProps={{ readOnly: true, placeholder: "MM/DD/YYYY" }}
              defaultValue={moment(application.targetMoveInDate)}
              onChange={(e) => handleDateChange(e, "targetMoveInDate")}
            />
          </div>
        </Form.Group>

        <Form.Group widths="equal" className="mt-4">
          <Form.Select
            label="Leasing Agent"
            placeholder="Leasing Agent"
            selection
            required
            selectOnBlur={false}
            search
            options={pm.filter((x) => x)}
            text={leasingAgentStateValue}
            value={leasingAgentStateValue}
            onChange={this.props.updateDropdownValues}
          />
          <Form.Select
            label="Floor Plan"
            placeholder="Floor Plan"
            selectOnBlur={false}
            search
            options={unitType}
            selection
            text={unitTypeText}
            value={unitTypeText}
            onChange={this.fetchUnitNumber}
          />
          <Form.Input
            className="height-38 mxw-150"
            label="Bedrooms"
            placeholder="Bedrooms"
            readOnly
            value={this.state.bedroom}
          />
          <Form.Input
            className="height-38 mxw-150"
            label="Bathrooms"
            placeholder="Bathrooms"
            readOnly
            value={this.state.bathroom}
          />
          <Form.Select
            label="Unit Number"
            placeholder="Unit Number"
            selection
            selectOnBlur={false}
            search
            options={unitsData}
            text={unitNumberText}
            value={unitNumberText}
            onChange={this.selectedUnitNumber}
          />
          <Form.Input
            className="height-38 mxw-150"
            label="Floor Level"
            placeholder="Floor Level"
            value={this.state.level}
            readOnly
          />
        </Form.Group>

        <Form.Group widths="equal" className="mt-4">
          <Form.Select
            fluid
            label="Affordable Housing Program"
            placeholder="Affordable Housing Program"
            text={affordableText}
            value={affordableText}
            options={affordableData}
            onChange={this.props.updateDropdownValues}
            className="select-dropdown"
          />
          <Form.Input
            fluid
            className="height-38"
            required
            label="Adult(s)"
            placeholder="Adult(s)"
            value={application.adults}
            onChange={(e) => updateApplication(e, "adults")}
          />
          <Form.Input
            fluid
            className="height-38"
            required
            label="Children"
            placeholder="Children"
            value={application.children}
            onChange={(e) => updateApplication(e, "children")}
          />
        </Form.Group>

        <Form.Group className="mt-4">
          <Form.Input
            fluid
            className="pets-col height-38"
            required
            label="Pet(s)"
            placeholder="Pet(s)"
            value={application.petsNumber}
            onChange={(e) => updateApplication(e, "petsNumber")}
          />

          <div className="radio-col required field">
            <label className="radio-col-label">Liquid Filled Furniture?</label>
            <div className="radio-wrapper">
              <Form.Radio
                label="Yes"
                value="true"
                checked={application.liquidFilledFurniture === true}
                onChange={(e) => updateApplication(e, "liquidFilledFurniture")}
              />
              <Form.Radio
                className="ml-5"
                label="No"
                value="false"
                checked={application.liquidFilledFurniture === false}
                onChange={(e) => updateApplication(e, "liquidFilledFurniture")}
              />
            </div>
          </div>

          <div className="radio-col required field">
            <label className="radio-col-label">
              Do you or any occupant smoke?
            </label>
            <div className="radio-wrapper">
              <Form.Radio
                label="Yes"
                value="true"
                checked={application.smoke === true}
                onChange={(e) => updateApplication(e, "smoke")}
              />
              <Form.Radio
                className="ml-5"
                label="No"
                value="false"
                checked={application.smoke === false}
                onChange={(e) => updateApplication(e, "smoke")}
              />
            </div>
          </div>
        </Form.Group>
        {this.props.showComment && (
          <Form.TextArea
            className="text-area mt-3"
            label="Comments"
            placeholder="Write a comment"
            value={application.comment}
            onChange={(e) => updateApplication(e, "comment")}
          />
        )}
        {!!this.state.errors.length && (
          <Message visible error>
            <Message.List>
              {this.state.errors.map((item, index) => (
                <Message.Item
                  key={index}
                >{`${item.field} - ${item.message}`}</Message.Item>
              ))}
            </Message.List>
          </Message>
        )}
      </Form>
    </>
  );

  render() {
    const {
      application,
      updateApplication,
      handleDateChange,
      updateApplicantName,
      leasingAgentStateValue,
      affordableText,
      user,
      unitTypeText,
      unitNumberText,
      bedrooms,
    } = this.props;
    const { affordableHousingData, unitTypeData, unitsData } = this.state;
    let pm = [],
      affordableData = [],
      unitType = [],
      units = [];
    // propertyManagers && propertyManagers.map((ele, index) => {                  //TODO: To be added later after getting PM info
    //   pm.push(ele && { key: ele.firstName, text: ele.firstName + " " +
    //   ele.lastName, value: {firstName:ele.firstName, lastName: ele.lastName,
    //   id:ele.ndbId, field: 'leasingAgent'}})
    // })
    pm.push(
      user && {
        key: user.firstName,
        text: user.firstName + " " + user.lastName,
        value: {
          firstName: user.firstName,
          lastName: user.lastName,
          id: user.ndbId,
          field: "leasingAgent",
        },
      }
    );
    affordableHousingData &&
      affordableHousingData.forEach((ele, index) => {
        affordableData.push(
          ele && {
            key: ele.node.name,
            text: ele.node.name,
            value: {
              name: ele.node.name,
              id: ele.node.code,
              field: "affordableHousingProgram",
            },
          }
        );
      });
    unitTypeData &&
      unitTypeData.forEach((ele, index) => {
        unitType.push(
          ele && {
            key: ele.node.type,
            text: ele.node.type,
            value: {
              name: ele.node.type,
              id: ele.node.id,
              field: "unitType",
              bedroom: ele.node.bedrooms,
              bathroom: ele.node.bathrooms,
            },
          }
        );
      });
    unitsData &&
      unitsData.forEach((ele, index) => {
        units.push(
          ele && {
            key: ele.node.number,
            text: ele.node.number,
            value: {
              name: ele.node.number,
              id: ele.node.id,
              field: "unitNumber",
              level: ele.node.level,
            },
          }
        );
      });
    return (
      <>
        {this.dropdownGroup(
          application,
          updateApplication,
          handleDateChange,
          affordableHousingData,
          updateApplicantName,
          pm,
          leasingAgentStateValue,
          affordableData,
          affordableText,
          unitType,
          units,
          unitTypeText,
          unitNumberText,
          bedrooms
        )}
        <div className="text-right mt-4">
          {/* <Button positive className="modal-save-button wid-110" onClick={() => this.props.updateFormSteps(0)}>
                Previous
              </Button> */}
          <Button
            positive
            className="modal-save-button mr-0 ml-2 wid-110"
            content="Next"
            onClick={(e) => this.validateAndSubmit(e)}
          />
          {toaster()}
        </div>
      </>
    );
  }
}
export default ApplicationUnitInfo;
