import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { PurchaseOrder } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { TableBalance } from "../../components/Tables/WorkOrderData";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss";
import mixpanel from "mixpanel-browser";
import WorkOrderModal from "../../components/Modals/WorkOrderModal";
import {  toast } from "react-toastify";
import { Button } from "semantic-ui-react";
import moment from "moment";
import WorkOrderDetailModal from "../../components/Modals/WorkOrderDetailModal";

const invoiceClient = getClient(ACCOUNTING);

class WorkOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      tableData: [],
      workOrderCache: [],
      offset: 0,
      gen: [],
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Work Order",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  getLedger = () => {
      this.setState({ loading: true });
    invoiceClient
      .query({
        query: PurchaseOrder,
        variables: {
          location: this.props.selectedPrimary.node.id,
          limit:30,
          offset: this.state.offset,
          // VendorId:14619,
          // id:327
        },
      })
      .then((res) => {
        const dataToPass = res.data.purchaseOrder.data.map((obj,i) => {
          const { amount, deliveryDate, issueDate, status } = obj;
          const uiAmount = `$ ${Number.parseFloat(amount).toLocaleString('en')}`
          const uiDeliveryDate = moment(deliveryDate).format('MM/DD/YYYY');
          const uiIssueDate = moment(issueDate).format('MM/DD/YYYY');
          const uiStatus = status === "partiallyFulfilled" ? "Partially Fulfilled" : `${status[0].toUpperCase()}${status.slice(1, status.length)}`;
          const workOrdersModal = <WorkOrderDetailModal order={obj}/>;
          return (({ WarehouseId }) => ({ workOrdersModal , WarehouseId, uiStatus, uiIssueDate, uiDeliveryDate, uiAmount }))(obj)
        });
        this.setState({ loading: false, tableData: dataToPass, workOrderCache: res.data.purchaseOrder.data });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  };

  dataPush = (response) => {
    this.setState((prevState) => ({
      tableData: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.tableData,
      ],
    }));
  };
  updateInvoice = (status, usage) => {
    const { tableData } = this.state;
    this.setState({ loading: true });
    if(usage !== "delete"){
      const newArr = tableData.map(item => {
        if(item._id == status._id && usage == "update"){
          item.amount = status.amount
          item.status = status.status
          item.issueDate = status.issueDate
          item.deliveryDate = status.deliveryDate
          item.externalId = status.externalId
          return item
        }
        if(item._id == status._id && usage == "issue"){
          item.status = status.status
          return item
        }
        else return item
      })
      this.setState({loading:false,tableData:newArr})
    }
    if(usage == 'delete'){
      const filteredArray = tableData.filter(item => item._id !== status)
      this.setState({ loading:false,tableData:filteredArray})
    }
  };

  render() {
    const { loading } = this.state;

    const mainHeader = ["Id", "Warehouse", "Status", "Issued", "Delivery", "Amount", "Actions"];
    const fail = () =>
    toast.error("No agent with this ID exists in our system", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const success = () =>
    toast.success("Success!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    // !: Actions.
    // TODO: Format data for user.

    return (
      <>
      <div className="row mb-4">
          <div className="col-md-12">
            <div className="page-header">
              <div className="d-flex align-items-center">
                <div>
                  <div className="page-header-title">Work Order</div>
                </div>
              </div>
              <div className="d-flex align-items-end">
              <WorkOrderModal
                dataPush={this.dataPush}
                fail={fail}
                user={this.props.user}
                updateInvoice={this.updateInvoice}
                success={success}
                getLedger={this.getLedger}
                propertyId={
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.id
              }
              />
                <Button
                onClick={() => window.print()}
                compact
                  className="ml-4 noPrint noPrint-button"
                  style={{
                    backgroundImage:
                      "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                 
                  }}
                >
                  Print
                </Button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader text inTable />
        ) : (
          <>
          <TableBalance
          updateInvoice={this.updateInvoice}
          propertyId={
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.id
            }
            workOrderCache={this.state.workOrderCache && this.state.workOrderCache}
          />
          {/* <GeneralAccountingTable
            mainCellData={this.state.tableData}
            mainHeader={mainHeader}
          /> */}
          </>
        )}
      </>
    );
  }
}

export default withApollo(WorkOrder);
