import { gql } from "apollo-boost";
import get from "lodash/get";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Checkbox,
  Container,
  Dropdown,
  Grid,
  Loader,
  Form,
} from "semantic-ui-react";
import { getClient } from "../../../init-apollo-googleFn";
import { bluemoonProperties } from "../../../store/bluemoon/bluemoon";
import { AlterLocation } from "../../../store/person/properties";
import {
  BLUEMOON_API,
  decodeBase64ID,
  LOCATIONAPI,
  ROUTES,
  THIRD_PARTY_API_URL,
  UNIT_AVAILABLITY_STATUS,
} from "../../../utils";
import {
  parseAddresses,
  parseGraphQLErrors,
  parsePhotosUrl,
  pluralize,
  toastFailMsg,
  toastSuccessMsg,
} from "../../../utils/common";
import { PageLoader } from "../../Loader/PageLoader";
import UnitCard from "../PropertyListing/UnitCard";
import UnitInfoModal from "../PropertyListing/UnitInfoModal";
import UnitListItem from "../PropertyListing/UnitListItem";
import withPropertyDetails from "./withPropertyDetails";
import * as WepayUtils from "../../../utils/wepay";

const locationClient = getClient(LOCATIONAPI);
const bluemoonApi = getClient(BLUEMOON_API);
const thirdPartyClient = getClient(THIRD_PARTY_API_URL);

const recordsGql = gql`
  {
    records {
      id
      record_id
      record_type
      status
    }
  }
`;

const createRecordsGql = gql`
  mutation createRecord($input: RecordInput!) {
    createRecord(input: $input) {
      id
      record_id
      record_type
      status
    }
  }
`;
const setRecordsGql = gql`
  mutation setRecord($status: Boolean!, $id: Int!) {
    setRecord(status: $status, id: $id) {
      id
      record_id
      record_type
      status
    }
  }
`;

const ReviewPropertyDetails = ({
  details,
  units,
  locationId,
  match,
  history,
  orgDetails,
  petTypes,
  amenityTypes,
  onCancelClick,
  propertyTypes = [],
  isPropertyDetails = false,
  user,
  onPublish,
  ...props
}) => {
  const [filteredUnits, setFilteredUnits] = React.useState([]);
  const [unitInfo, setUnitInfo] = React.useState(null);
  const [unitStatusFilter, setUnitStatusFilter] = React.useState("all");
  const [isListView, setIsListView] = React.useState(false);
  const [bluemoonName, setBluemoonName] = React.useState([]);
  const [thirdPartyRecords, setThirdPartyRecords] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [locationStatusLoader, setLocationStatusLoader] = React.useState(false);
  const [paymentMethods, setPaymentMethods] = React.useState(null);
  const [accountPayoutMethodId, setAccountPayoutMethodId] = React.useState("");
  const [accountPayoutFrequency, setAccountPayoutFrequency] =
    React.useState("daily");
  const [toggleUnitsForSpace, setToggleUnitsForSpace] = React.useState(false);
  const [merchant, setMerchant] = React.useState(null);

  const { params } = match;
  const contact = details && details.contact && JSON.parse(details.contact);

  const socials =
    contact &&
    contact.socials &&
    Object.keys(contact.socials)
      .filter((key) => !key.includes("email"))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: contact.socials[key] });
      }, {});

  const socialsArray = socials && Object.entries(socials);
  const socialArr1 = socialsArray && socialsArray.slice(0, 3);
  const socialArr2 = socialsArray && socialsArray.slice(3, socialsArray.length);

  const currentLocationId = locationId || params.locationId;

  React.useEffect(() => {
    setFilteredUnits(units);
  }, [units]);

  /* eslint-disable */
  React.useEffect(() => {
    mixpanel.track("Manager Page Load", {
      ndbId: user && user.ndbId,
      sub: "Review Property Detail",
    });
  }, []);
  /* eslint-enable */

  const fetchPaymentMethods = async () => {
    try {
      const res = await WepayUtils.getPaymentMethods({
        locationId: props.locationId,
      });
      console.log(res);
      setPaymentMethods(res);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (paymentMethods === null) fetchPaymentMethods();
  }, [paymentMethods]);

  React.useEffect(() => {
    WepayUtils.getMerchant(locationId)
      .then((merchant) => {
        console.log("merchant", merchant);
        setMerchant(merchant);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const createAccountForWepay = async () => {
    //TODO: check legal entity exist and create if not
    try {
      if (merchant !== null && merchant.accountId === null) {
        //TODO: createWepayAccount
        const res = await WepayUtils.createWepayAccount({
          locationId: currentLocationId,
          payoutMethodId: accountPayoutMethodId,
          payoutFrequency: accountPayoutFrequency,
        });
        console.log(res);
      } else if (merchant === null) {
        toastFailMsg(
          "You have to go to step1, accept terms of service and privacy policy for wepay to create payout account!"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const savePropertyInfo = async (status = "publish") => {
    try {
      await createAccountForWepay();
      const response = await locationClient.mutate({
        mutation: AlterLocation,
        variables: {
          input: {
            id: currentLocationId,
            name: details.name,
            status,
          },
        },
      });
      if (response.data) {
        toastSuccessMsg(
          `Property ${status === "publish" ? "added" : "updated"} successfully.`
        );
        onPublish();
        history.push(ROUTES.propertiesListing);
      }
    } catch (error) {
      console.log(error);
      toastFailMsg(
        parseGraphQLErrors(error).toString() || "Unable to get unit details."
      );
    }
  };

  const handleStatusFilter = (e, { value }) => {
    setUnitStatusFilter(value);
    setFilteredUnits(
      units.filter((u) =>
        value !== "all" ? u.status.toLowerCase() === value.toLowerCase() : true
      )
    );
  };

  const getAmenities = React.useCallback(
    (amenities = []) =>
      Array.isArray(amenities)
        ? amenities
            .map((item) => {
              const amenity = amenityTypes.find((x) => x.node.id === item);
              return get(amenity, "node.name", "");
            })
            .filter((x) => x)
            .join(", ")
        : "",
    [amenityTypes]
  );

  /* eslint-disable */
  const photos = React.useMemo(() => {
    if (details.photos) return parsePhotosUrl(details.photos);
    return [];
  }, [details.photos]);

  const { depositRules = {} } = details;
  const propertyType = React.useMemo(
    () => propertyTypes.find((p) => p.node.id === details.locationTypeId) || {},
    [propertyTypes, details.locationTypeId]
  );

  React.useEffect(() => {
    if (details.esginType === "bluemoon") {
      fetchBluemoonProperties();
    }
  }, [details]);
  /* eslint-enable */

  const fetchBluemoonProperties = async () => {
    await bluemoonApi
      .query({
        query: bluemoonProperties,
      })
      .then((res) => {
        const data = res.data.bluemoonProperties.edges;
        setBluemoonName(data[0].node.name);
        data.map((ele) => {
          if (ele.node.id === details.bmLocationId) {
            setBluemoonName(ele.node.name);
          }
          return null;
        });
      })
      .catch((error) => {
        toastFailMsg(parseGraphQLErrors(error));
      });
  };

  const handleSendNotification = React.useCallback(() => {
    history.push({
      pathname: ROUTES.pushNotification + "/location/" + details.id,
      state: details,
    });
  }, [history, details]);

  const getRecords = async () => {
    setLoading(true);
    try {
      const records = await thirdPartyClient.query({
        query: recordsGql,
        fetchPolicy: "no-cache",
      });

      const recordData = records.data.records.reduce(
        (a, b) => {
          if (a[b.record_type]) {
            a[b.record_type][b.record_id] = b;
          }

          return a;
        },
        { location_id: {}, unit_id: {} }
      );

      setThirdPartyRecords(recordData);
      console.log("recordData", recordData);
    } catch (error) {
      toastFailMsg(
        parseGraphQLErrors(error).toString() || "Unable to get records."
      );
    } finally {
      setLoading(false);
    }
  };

  const createRecords = async (record_id, record_type, status, callback) => {
    try {
      const records = await thirdPartyClient.mutate({
        mutation: createRecordsGql,
        variables: {
          input: {
            record_id,
            record_type,
            status,
            provider: "zillow",
            custom_data: JSON.stringify({}),
          },
        },
        fetchPolicy: "no-cache",
      });
      callback(records.data.createRecord);
    } catch (error) {
      toastFailMsg(
        parseGraphQLErrors(error).toString() || "Unable to get property lists."
      );
    }
  };

  const setRecord = async (id, status, callback) => {
    try {
      const records = await thirdPartyClient.mutate({
        mutation: setRecordsGql,
        variables: {
          id,
          status,
        },
        fetchPolicy: "no-cache",
      });
      callback(records.data.setRecord);
    } catch (error) {
      toastFailMsg(
        parseGraphQLErrors(error).toString() || "Unable to get property lists."
      );
    }
  };

  const getThirdPartyApiStatus = (unit_id) => {
    if (thirdPartyRecords.unit_id[unit_id] !== undefined) {
      return thirdPartyRecords.unit_id[unit_id].status;
    }
    return false;
  };

  const getThirdPartyLocationStatus = (location_id) => {
    const location_id_decode = decodeBase64ID(location_id);

    if (Object.keys(thirdPartyRecords).length === 0) return false;

    if (thirdPartyRecords.location_id[location_id_decode] !== undefined) {
      return thirdPartyRecords.location_id[location_id_decode].status;
    }
    return false;
  };

  React.useEffect(() => {
    getRecords();
  }, []);

  const setThirdPartUnitStatus = async (unit_id, statusBool, callback) => {
    const newRecords = { ...thirdPartyRecords };

    if (newRecords.unit_id[unit_id] !== undefined) {
      const record = newRecords.unit_id[unit_id];
      await setRecord(record.id, statusBool, (data) => {
        newRecords.unit_id[data.record_id] = data;
      });
    } else {
      await createRecords(unit_id, "unit_id", statusBool, (data) => {
        newRecords.unit_id[data.record_id] = data;
      });
    }

    if (callback) callback();

    setThirdPartyRecords(newRecords);
  };

  const setThirdPartLocationStatus = async (
    locationId,
    statusBool,
    callback
  ) => {
    setLocationStatusLoader(true);
    const location_id = decodeBase64ID(locationId);
    const newRecords = { ...thirdPartyRecords };

    if (newRecords.location_id[location_id] !== undefined) {
      const record = newRecords.location_id[location_id];
      await setRecord(record.id, statusBool, (data) => {
        newRecords.location_id[data.record_id] = data;
      });
    } else {
      await createRecords(location_id, "location_id", statusBool, (data) => {
        newRecords.location_id[data.record_id] = data;
      });
    }

    if (callback) callback();

    setThirdPartyRecords(newRecords);
    setLocationStatusLoader(false);
  };

  if (loading) return <PageLoader text />;

  return (
    <Container className="add-property-container">
      {isPropertyDetails && (
        <div className="header">
          <div>
            {/* eslint-disable */}
            <a className="back-btn" onClick={() => history.goBack()}>
              <img
                src="assets/img/left-arrow-small.svg"
                alt="arrow-down-small"
              />
            </a>
            {/* eslint-enable */}
            Property Detail
          </div>
          <div className="btn-section">
            <button
              type="button"
              onClick={handleSendNotification}
              className="btn next-btn"
            >
              Send Notification
            </button>
            <Link
              to={ROUTES.editPropertyDetails.replace(
                ":locationId",
                currentLocationId
              )}
            >
              <button type="button" className="btn next-btn">
                Edit Property
              </button>
            </Link>
            <div className="custom-dropdown">
              {/* eslint-disable */}
              <a className="more-btn">
                <img src="assets/img/icons-more.svg" alt="icons-more" />
              </a>
              <div className="dropdown-list">
                <div className="list-item">
                  <a>
                    <img src="assets/img/icons-edit.svg" alt="icons-edit" />
                    View Public URL
                  </a>
                </div>
                <div className="list-item red-text">
                  <a>
                    <img
                      src="assets/img/icons-red-delete.svg"
                      alt="icons-red-delete"
                    />
                    Delete Property
                  </a>
                  {/* eslint-enable */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="review-container">
        <Carousel fade>
          {photos.map((img, index) => (
            <Carousel.Item key={img}>
              <div className="img-box" key={index.toString()}>
                <img src={img} alt="renter-bg" className="d-block w-80" />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="propery-head">
          <div className="text-left">
            <div className="propery-name">{details.name}</div>
            <div className="address">{parseAddresses(details.addresses)}</div>
            <div className="property-info">
              {`${get(details, "totalBeds[0]", 0)}+ ${pluralize(
                get(details, "totalBeds[0]", 0),
                "Bed"
              )}`}{" "}
              <span className="dot" />{" "}
              <span>{`${get(details, "totalBaths[0]", 0)}+ ${pluralize(
                get(details, "totalBaths[0]", 0),
                "Bath"
              )}`}</span>{" "}
              <span className="dot" />{" "}
              <span>{`${get(details, "totalSizes[0]", 0)} sq.ft - ${get(
                details,
                "totalSizes[1]",
                0
              )} sq.ft`}</span>
            </div>
            <br />
            <br />
            <div className="zillow-switch-container">
              <div className="zillow-switch-label">Zillow Listing Status: </div>
              <div className="zillow-switch-content">
                {locationStatusLoader ? (
                  <Loader active inline />
                ) : (
                  <Checkbox
                    toggle
                    checked={getThirdPartyLocationStatus(currentLocationId)}
                    onChange={() =>
                      setThirdPartLocationStatus(
                        currentLocationId,
                        !getThirdPartyLocationStatus(currentLocationId)
                      )
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <div className="big-text">
            <span>{`$${get(
              details,
              "totalPrices[0]",
              0
            ).toLocaleString()}-$${get(
              details,
              "totalPrices[1]",
              0
            ).toLocaleString()}`}</span>
            <span className="bar">|</span>
            <span>{`${units.length} units`}</span>
          </div>
        </div>
        <div className="grey-row">
          <div className="block">
            <span className="head">Property type</span>
            <div className="text">{get(propertyType, "node.type", "")}</div>
          </div>
          <div className="block">
            <span className="head">Year built</span>
            <div className="text">{details.yearBuilt}</div>
          </div>
          <div className="block">
            <span className="head">Date listed</span>
            <div className="text">
              {moment(details.createdAt).format("MMMM DD, YYYY")}
            </div>
          </div>
          <div className="block">
            <span className="head">Property Phone</span>
            <div className="text">{contact && contact.phone}</div>
          </div>
          <div className="block">
            <span className="head">Property Email</span>
            <div className="text">{contact && contact.email}</div>
          </div>
        </div>
        <h3>Description</h3>
        <pre
          style={{
            textAlign: "left",
            fontFamily: "Be Vietnam",
            whiteSpace: "pre-wrap",
          }}
        >
          {details.description}
        </pre>
        <h3>Amenities</h3>
        <p>{getAmenities(details.amenities)}</p>
        <div className="d-flex mb-5">
          <div className="w-100">
            <h3>Pets Permitted</h3>
            <p>{details.petsPermitted ? "Yes" : "No"}</p>
          </div>
        </div>
        {Array.isArray(details.petInformation) &&
          details.petInformation.length > 0 && (
            <div className="pets-section">
              <div className="head">Pet Restrictions</div>
              <div className="head-row">
                <div className="w-25">Weight</div>
                <div className="w-25">Total Number</div>
              </div>
              <div className="data-row">
                <div className="w-25">{`${get(
                  details,
                  "petInformation[0].weight",
                  ""
                )} lbs`}</div>
                <div className="w-25">
                  {get(details, "petInformation[0].number", "NA")}
                </div>
              </div>
              <div className="head-row">
                <div className="w-25">S. No.</div>
                <div className="w-25">Pet type</div>
                <div className="w-50">Breed</div>
              </div>
              {get(details, "petInformation.description", []).map(
                (item, index) => {
                  const pet = petTypes.find((p) => p.node.name === item.name);
                  if (!pet) return null;
                  return (
                    <div className="data-row" key={index.toString()}>
                      <div className="w-25">{index + 1}</div>
                      <div className="w-25">{pet.node.type}</div>
                      <div className="w-50">{pet.node.name}</div>
                    </div>
                  );
                }
              )}
            </div>
          )}
        <div className="d-flex mb-5">
          <div className="w-30">
            <h3>Deposit Required</h3>
            <p>
              {depositRules.deposit_amount
                ? `$${depositRules.deposit_amount.toLocaleString()}`
                : "NA"}
            </p>
          </div>
          <div className="w-30">
            <h3>Deposit refundable</h3>
            <p>
              {depositRules.deposit_nonrefundable_amount
                ? `$${depositRules.deposit_nonrefundable_amount.toLocaleString()}`
                : "NA"}
            </p>
          </div>
          <div className="w-30">
            <h3>Lease Guarantee</h3>
            <p>{depositRules.lease_guarantee ? "Yes" : "No"}</p>
          </div>
        </div>
        <div className="d-flex mb-5">
          <div className="w-30">
            <h3>Application Credit Score</h3>
            <p>{depositRules.application_credit_score || "NA"}</p>
          </div>
          <div className="w-30">
            <h3>Income x times of rent</h3>
            <p>{depositRules.income_x_time_rent || "NA"}</p>
          </div>
          <div className="w-30">
            <h3>First and Last Month Rent Required</h3>
            <p>{depositRules.fl_month_required ? "Yes" : "No"}</p>
          </div>
        </div>
        {details.distributionNoticeDocPath ? (
          <>
            <div className="d-flex mb-5">
              <div className="w-30">
                <h3 className="m-t-4">Distribution Notice</h3>
                <p>
                  <a
                    href={details.distributionNoticeDocPath}
                    className="preview-link"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    View Distribution Notice
                  </a>
                </p>
              </div>
              {details.hoaPolicyDocPath && (
                <div className="w-30">
                  <h3 className="m-t-4">HOA Policy</h3>
                  <p>
                    <a
                      href={details.hoaPolicyDocPath}
                      className="preview-link"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      View HOA Policy
                    </a>
                  </p>
                </div>
              )}
              {details.esignDocPath &&
                details.esignDocPath.application_doc_path && (
                  <div className="w-30">
                    <h3 className="m-t-4">Application Policy</h3>
                    <p>
                      <a
                        href={details.esignDocPath.application_doc_path}
                        className="preview-link"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        View Application Policy
                      </a>
                    </p>
                  </div>
                )}
            </div>
            <div className="d-flex mb-5">
              {details.esignDocPath && details.esignDocPath.lease_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Lease Policy</h3>
                  <p>
                    <a
                      href={details.esignDocPath.lease_doc_path}
                      className="preview-link"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      View Lease Policy
                    </a>
                  </p>
                </div>
              )}
              {details.esignDocPath && details.esignDocPath.lease_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Pet Policy</h3>
                  <p>
                    {details.petPolicyDocPath && (
                      <a
                        href={details.petPolicyDocPath}
                        className="preview-link"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        View Pet Policy
                      </a>
                    )}
                  </p>
                </div>
              )}
              {details.esignDocPath && details.esignDocPath.lease_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Payment Policy</h3>
                  <p>
                    {details.paymentPolicyDocPath && (
                      <a
                        href={details.paymentPolicyDocPath}
                        className="preview-link"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        View Payment Policy
                      </a>
                    )}
                  </p>
                </div>
              )}
            </div>
            <div className="d-flex mb-5">
              {details.esignDocPath && details.esignDocPath.lease_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Cancellation Policy</h3>
                  <p>
                    {details.cancellationPolicyDocPath && (
                      <a
                        href={details.cancellationPolicyDocPath}
                        className="preview-link"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        View Cancellation Policy
                      </a>
                    )}
                  </p>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="d-flex mb-5">
              {details.applicationDocPath && (
                <div className="w-30">
                  <h3 className="m-t-4">Application Policy</h3>
                  <p>
                    <a
                      href={details.applicationDocPath}
                      className="preview-link"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      View Application Policy
                    </a>
                  </p>
                </div>
              )}
              {details.leaseDocPath && (
                <div className="w-30">
                  <h3 className="m-t-4">Lease Policy</h3>
                  <p>
                    <a
                      href={details.leaseDocPath}
                      className="preview-link"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      View Lease Policy
                    </a>
                  </p>
                </div>
              )}
              {details.esignDocPath && details.esignDocPath.lease_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Pet Policy</h3>
                  <p>
                    {details.petPolicyDocPath && (
                      <a
                        href={details.petPolicyDocPath}
                        className="preview-link"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        View Pet Policy
                      </a>
                    )}
                  </p>
                </div>
              )}
            </div>
            <div className="d-flex mb-5">
              {details.esignDocPath && details.esignDocPath.lease_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Payment Policy</h3>
                  <p>
                    {details.paymentPolicyDocPath && (
                      <a
                        href={details.paymentPolicyDocPath}
                        className="preview-link"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        View Payment Policy
                      </a>
                    )}
                  </p>
                </div>
              )}
              {details.esignDocPath && details.esignDocPath.lease_doc_path && (
                <div className="w-30">
                  <h3 className="m-t-4">Cancellation Policy</h3>
                  <p>
                    {details.cancellationPolicyDocPath && (
                      <a
                        href={details.cancellationPolicyDocPath}
                        className="preview-link"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        View Cancellation Policy
                      </a>
                    )}
                  </p>
                </div>
              )}
            </div>
          </>
        )}
        {details.esginType === "bluemoon" && (
          <div className="enable-bluemoon">
            <div className="top-header">
              <div className="left-data">
                <h6>Bluemoon Enabled</h6>
              </div>
            </div>
            <div className="has-float-label select-float-label">
              <label htmlFor="locationTypeId">Mapped Bluemoon Properties</label>
              <p>{bluemoonName}</p>
            </div>
          </div>
        )}
        {socialsArray && (
          <>
            <div style={{ margin: "60px 0" }}>
              <div className="d-flex mb-5">
                {socialArr1.map((k, i) => {
                  return (
                    <div className="w-30">
                      <h3>{k[0]}</h3>
                      <p>{k[1]}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            {socialArr2.length > 0 && (
              <div style={{ margin: "60px 0" }}>
                <div className="d-flex mb-5">
                  {socialArr2.map((k, i) => {
                    return (
                      <div className="w-30">
                        <h3>{k[0]}</h3>
                        <p>{k[1]}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </>
        )}
        {contact && contact.schedule && (
          <>
            <h3 className="review-mb10">Business Hours</h3>
            <div className="review-hours">
              <div className="d-flex review-mb10">
                <div className="w-30">
                  <h3>Monday</h3>
                  <p>
                    {contact.schedule.mondayFrom
                      ? `${contact.schedule.mondayFrom} - ${contact.schedule.mondayTo}`
                      : "Closed"}
                  </p>
                </div>
                <div className="w-30">
                  <h3>Tuesday</h3>
                  <p>
                    {contact.schedule.tuesdayFrom
                      ? `${contact.schedule.tuesdayFrom} - ${contact.schedule.tuesdayTo}`
                      : "Closed"}
                  </p>
                </div>
                <div className="w-30">
                  <h3>Wednesday</h3>
                  <p>
                    {contact.schedule.wednesdayFrom
                      ? `${contact.schedule.wednesdayFrom} - ${contact.schedule.wednesdayTo}`
                      : "Closed"}
                  </p>
                </div>
                <div className="w-30">
                  <h3>Thursday</h3>
                  <p>
                    {contact.schedule.thursdayFrom
                      ? `${contact.schedule.thursdayFrom} - ${contact.schedule.thursdayTo}`
                      : "Closed"}
                  </p>
                </div>
              </div>
              <div className="d-flex review-mb10">
                <div style={{ width: "25%" }}>
                  <h3>Friday</h3>
                  <p>
                    {contact.schedule.fridayFrom
                      ? `${contact.schedule.fridayFrom} - ${contact.schedule.fridayTo}`
                      : "Closed"}
                  </p>
                </div>
                <div style={{ width: "25%" }}>
                  <h3>Saturday</h3>
                  <p>
                    {contact.schedule.saturdayFrom
                      ? `${contact.schedule.saturdayFrom} - ${contact.schedule.saturdayTo}`
                      : "Closed"}
                  </p>
                </div>
                <div style={{ width: "25%" }}>
                  <h3>Sunday</h3>
                  <p>
                    {contact.schedule.sundayFrom
                      ? `${contact.schedule.sundayFrom} - ${contact.schedule.sundayTo}`
                      : "Closed"}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        {Array.isArray(orgDetails.contacts) && orgDetails.contacts.length > 0 && (
          <div className="pets-section">
            <div className="head">Vendors</div>
            <div className="head-row">
              <div className="w-25">Name</div>
              <div className="w-25">Email</div>
              <div className="w-25">Phone</div>
              <div className="w-25">Services</div>
            </div>
            {orgDetails.contacts.map((item, index) => (
              <div className="data-row" key={index.toString()}>
                <div className="w-25">{item.name}</div>
                <div className="w-25">{item.email || "NA"}</div>
                <div className="w-25">{item.phone || "NA"}</div>
                <div className="w-25">{item.services.join(", ")}</div>
              </div>
            ))}
          </div>
        )}
        {!!unitInfo && (
          <UnitInfoModal
            currentLocationId={currentLocationId}
            isOpen={!!unitInfo}
            onClose={() => setUnitInfo(null)}
            unit={unitInfo}
            propertyImages={photos}
            getAmenities={getAmenities}
          />
        )}
        <div className="m-t-4 unit-dropdown-container">
          <h3
            className="cursor-pointer"
            onClick={() => {
              setToggleUnitsForSpace(!toggleUnitsForSpace);
            }}
          >
            {`${filteredUnits.length} Units`}{" "}
            <i
              className={`fa fa-chevron-${toggleUnitsForSpace ? "down" : "up"}`}
            />
          </h3>

          {isPropertyDetails && (
            <div className="filter-section">
              <div className="right-container">
                <ul>
                  {/* eslint-disable */}
                  <li>
                    <a
                      className={isListView ? "" : "active"}
                      onClick={() => setIsListView(false)}
                    >
                      <img src="assets/img/card-menu-icon.svg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      className={isListView ? "active" : ""}
                      onClick={() => setIsListView(true)}
                    >
                      <img src="assets/img/list-menu-icon.svg" alt="" />
                    </a>
                  </li>
                  {/* eslint-enable */}
                </ul>
              </div>
              <span className="normal-text">Filter</span>
              <Dropdown
                fluid
                selection
                options={[
                  { key: "all", value: "all", text: "All" },
                  ...UNIT_AVAILABLITY_STATUS,
                ]}
                selectOnBlur={false}
                value={unitStatusFilter}
                onChange={handleStatusFilter}
              />
            </div>
          )}
        </div>
        {toggleUnitsForSpace && (
          <>
            {filteredUnits.length > 0 ? (
              <div className="small-card-container mt-3">
                {filteredUnits.map((unit) => {
                  const unitImg =
                    unit.photos && unit.photos.length
                      ? unit.photos[0]
                      : photos[0];
                  const UnitView = isListView ? UnitListItem : UnitCard;
                  return (
                    <UnitView
                      key={unit.id}
                      unit={unit}
                      unitImg={unitImg}
                      setUnitInfo={setUnitInfo}
                      onClick={() => setUnitInfo({ ...unit, unitImg })}
                      getStatus={getThirdPartyApiStatus(
                        decodeBase64ID(unit.id)
                      )}
                      setStatus={setThirdPartUnitStatus}
                    />
                  );
                })}
              </div>
            ) : (
              <p className="text-center pb-4">No Units available.</p>
            )}
          </>
        )}

        {merchant != null && merchant.accountId === null && (
          <div className="create-account-form">
            <div className="white-block">
              <div className="header">Create Payout Account</div>
              <div style={{ height: 50 }}></div>
              <Form>
                <Form.Group widths="equal">
                  <Form.Select
                    label="Payout Method"
                    value={accountPayoutMethodId}
                    onChange={(event, { value }) => {
                      setAccountPayoutMethodId(value);
                    }}
                    options={paymentMethods
                      ?.filter((m) => m.payoutMethod)
                      ?.map((method, i) => {
                        return {
                          key: `payout-method-${i}`,
                          text: `${method.name} - ******${method.lastFour}`,
                          value: method.heartlandToken,
                        };
                      })}
                    placeholder="Payout Method"
                  />

                  <Form.Select
                    label="Payout Frequency"
                    value={accountPayoutFrequency}
                    onChange={(event, { value }) => {
                      setAccountPayoutFrequency(value);
                    }}
                    options={[
                      { key: "d", text: "Daily", value: "daily" },
                      { key: "w", text: "Weekly", value: "weekly" },
                    ]}
                    placeholder="Payout Frequency"
                  />
                </Form.Group>
              </Form>
            </div>
          </div>
        )}

        {!isPropertyDetails && (
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <div className="btn-block">
              <div>
                <button
                  type="button"
                  className="btn cancel-btn"
                  onClick={onCancelClick}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="btn save-btn"
                  onClick={() => savePropertyInfo("draft")}
                >
                  Save and Finish
                </button>
                <button
                  type="button"
                  className="btn next-btn"
                  onClick={() => savePropertyInfo("publish")}
                >
                  Finish, Publish Listing
                </button>
              </div>
            </div>
          </Grid.Column>
        )}
      </div>
    </Container>
  );
};

export default withPropertyDetails(
  withRouter(ReviewPropertyDetails),
  "PropertyDetails"
);
