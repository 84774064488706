const prodConfig = {
  apiKey: "AIzaSyDA1wcz3xYK8r-wUWmUj_HGmqlrzIMjgus",
  authDomain: "leasera-production.firebaseapp.com",
  databaseURL: "https://leasera-production.firebaseio.com",
  projectId: "leasera-production",
  storageBucket: "leasera-production.appspot.com",
  messagingSenderId: "913859279590",
  appId: "1:913859279590:web:11b02c03a5b7f109ecd927",
};

const stagingConfig = {
  apiKey: "AIzaSyDgdX5L8P2elnasYa04zFW6MEftdSRLrSc",
  authDomain: "leasera-staging.firebaseapp.com",
  databaseURL: "https://leasera-staging.firebaseio.com",
  projectId: "leasera-staging",
  storageBucket: "leasera-staging.appspot.com",
  messagingSenderId: "690486137275",
  appId: "1:690486137275:web:2ab48341245d54dc0af49e",
};
 
const devConfig = {
  apiKey: "AIzaSyAnOMBzboGt-zL69J7ae_aC_yjWchdAn8M",
  authDomain: "leasera-200719.firebaseapp.com",
  databaseURL: "https://leasera-200719.firebaseio.com",
  projectId: "leasera-200719",
  storageBucket: "leasera-200719.appspot.com",
  messagingSenderId: "662469587140",
  appId: "1:662469587140:web:63efd01be202870886d991",
  measurementId: "G-FMJH20D4FV",
};

const WEB_PUSH_NOTIFICATION_KEY_PAIR = {
  dev: "BHzxl-8e3Rd73K8PQYR6LbXZTUoJVaSrMjUPjKXknqBBxTT3cBJx5kZ5VTj9rwL3hWgMzrharUHBRQbMzs-sz6E",
  staging:
    "BPr8TogJICOmLIFVvIpW4J0PMLGhYnIU7_TZWWe7sOoDvEdVm_Cbqeslgl9Ls1aDDyFCjUCqRQ_4KYlM0Hd6Vyo",
  production:
    "BKbeCHHtcDfbqgWEDr4It0B3hKifibvWQAuGhiRJKrZUV1BtWKP6wfWjnfHBqRzWpZY062_qRb3uev5XWZb6IgU",
};

let config = stagingConfig;
let vapidKey = WEB_PUSH_NOTIFICATION_KEY_PAIR.staging;

// if (process.env.REACT_APP_DEVELOP === "200719") {
//   config = devConfig;
//   vapidKey = WEB_PUSH_NOTIFICATION_KEY_PAIR.dev;
// }

// if (process.env.REACT_APP_STAGING === "staging") {
//   config = stagingConfig;
//   vapidKey = WEB_PUSH_NOTIFICATION_KEY_PAIR.staging;
// }

// if (process.env.REACT_APP_PRODUCTION === "production") {
//   config = prodConfig;
//   vapidKey = WEB_PUSH_NOTIFICATION_KEY_PAIR.production;
// }

export { config, vapidKey };