export const TOKEN = 'token';
export const EXPIRE_TIME = 'expireTime';
export const RELOAD = 'reload';
export const LOGIN = 'login';
export const SESSION_TIMEOUT_THRESHOLD = 0;
export const USER_EVENT = 'userLoggedStatus';
export const USER_LOC = 'locationData';
export const RENTER = 'renter';
export const NOTIFICATION = 'notification';
export const MANAGER = 'manager';
export const SERVICEPROFESSIONAL = 'serviceProfessional';
export const USER_ROLE_EVENT = 'userRoleStatus';
export const ROUTES = {
  signup: '/signup',
  leads: '/leads',
  fileManager: '/fileManager',
  balancesheet: '/accounting/balancesheet',
  properties: '/properties',
  applications: '/applications',
  existingapplications: '/applications/existingapplications',
  tenants: '/residents',
  schedule: '/schedule',
  loyalty: '/loyalty',
  home: '/',
  maintenance: '/maintenance',
  reports: '/reports',
  waitlist: '/leads/waitlist',
  ledger: '/accounting/ledger',
  boxscore: '/accounting/boxscore',
  transactions: '/accounting/transactions',
  reconcile: '/accounting/reconcile',
  aging: '/accounting/aging',
  journal: '/accounting/journal',
  journalreport: '/accounting/journalreport',
  trialreport: '/accounting/trialreport',
  charge: '/accounting/charge',
  invoices: '/accounting/invoices',
  incomestatement: '/accounting/incomestatement',
  rentroll: '/accounting/rentroll',
  delinquency: '/accounting/delinquency',
  paybills: '/accounting/paybills',
  workorder: '/accounting/workorder',
  bills: '/accounting/bills',
  vendors: '/accounting/vendors',
  financials: '/accounting/financials',
  credits: '/accounting/credits',
  arcash: '/accounting/arcash',
  tax: '/accounting/tax',
  dashboard: '/dashboard',
  lease: '/lease',
  settings: '/settings',
  leaseDetails: '/lease/details/:leaseId',
  addProperties: '/addProperties',
  addProperty: '/addProperty',
  bluemoonSettings: '/bluemoonSettings',
  propertiesListing: '/properties/list',
  propertyDetails: '/properties/details/:locationId',
  editPropertyDetails: '/properties/edit/:locationId',
  editLease: '/editLease/:leaseId',
  reportsData: '/reportsData',
  balanceSheet: '/balanceSheet',
  leaseRenewal: '/leaseRenewal',
  messages: '/communication/messages',
  thirdparty: '/third-party-api',
  pushNotification: '/communication/push-notification',
  communication: '/communication',
};

export const PORTALS = {
  landing: 'https://www.staging.leasera.com',
  search: 'https://www.staging.leasera.com/properties',
};

export const DOC_EXT = {
  pdf: 'Form',
  ppt: 'Template',
  xls: 'Sheet',
};
export const EXTENSIONS = {
  FORM: 'pdf',
  TEMPLATE: 'ppt',
  SHEET: 'xls',
  IMAGE: 'png',
};
export const CATEGORIES = [
  'Exterior',
  'Electrical',
  'Doors',
  'Flooring',
  'Heating/Cooling',
  'Lighting',
  'Locks',
  'Parking Lot',
  'Plumbing',
  'Walls',
  'General Misc',
];

export const LEAD_STATUS = [
  'Prospect',
  'Applied',
  'Tour',
  'Resident',
  'Lost',
  'Waitlist',
];

export const CATEGORIES_VALUES = [
  'exterior',
  'electrical',
  'doors',
  'flooring',
  'heating_cooling',
  'lighting',
  'locks',
  'parking_lot',
  'plumbing',
  'walls',
  'general_misc',
];

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const MONTH_NUMS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
export const YEAR_NUMS = [
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
  "2031",
  "2032",
  "2033",
  "2034",
  "2035",
  "2036",
  "2037",
  "2038",
  "2039",
  "2040",
];

export const URGENCIES = ['critical', 'urgent', 'important'];
export const TYPES = ['repair', 'replace'];

export const VALID_FORM_INPUT = 'form-control is-valid';
export const INVALID_FORM_INPUT = 'form-control is-invalid';

export const APP_STATUS = {
  action: 'Requires Action',
  accept: 'Accepted',
  declined: 'Declined',
  canceled: 'Canceled',
};

export const APPLICATION_ACTIONS = {
  accept: 'accept',
  decline: 'decline',
  action: 'requires_action',
};

export const UNIT_TYPE = [
  {
    key: '1BR/1BA',
    text: '1BR/1BA',
    value: '1BR/1BA',
  },
  {
    key: '1BR/2BA',
    text: '1BR/2BA',
    value: '1BR/2BA',
  },
  {
    key: '1BR/3BA',
    text: '1BR/3BA',
    value: '1BR/3BA',
  },
  {
    key: '2BR/1BA',
    text: '2BR/1BA',
    value: '2BR/1BA',
  },
  {
    key: '2BR/2BA',
    text: '2BR/2BA',
    value: '2BR/2BA',
  },
  {
    key: '2BR/3BA',
    text: '2BR/3BA',
    value: '2BR/3BA',
  },
  {
    key: '3BR/1BA',
    text: '3BR/1BA',
    value: '3BR/1BA',
  },
  {
    key: '3BR/2BA',
    text: '3BR/2BA',
    value: '3BR/2BA',
  },
  {
    key: '3BR/3BA',
    text: '3BR/3BA',
    value: '3BR/3BA',
  },
];

export const LEASE_EXPIRE_OPTIONS = [
  {
    key: '30',
    text: 'NEXT 30 DAYS',
    value: 'ONE_MONTH',
  },
  {
    key: '60',
    text: 'NEXT 60 DAYS',
    value: 'TWO_MONTH',
  },
  {
    key: '90',
    text: 'NEXT 90 DAYS',
    value: 'THREE_MONTH',
  },
  {
    key: '180',
    text: 'NEXT 180 DAYS',
    value: 'SIX_MONTH',
  },
  {
    key: 'all',
    text: 'ALL',
    value: 'ALL',
  },
];

export const DATE_FORMAT = 'MM/DD/YYYY';

export const DATE_ALPHA = 'DD MMM YYYY';
// export const DATE_ALPHA_US = "MMM DD, YYYY"

export const DATE_ALPHA_US = 'hh:mm A MMM DD, YYYY';
export const DATE_ALPHA_US_NO_TIME = 'MMM DD, YYYY';
export const SET_UPDATE = 'YYYY-MM-DD hh:mm A';
export const LEAD_CREATED_FORMAT = 'MMM DD, YYYY hh:mm A';
export const TIME_FORMAT = 'hh:mm';
export const LEASE_TIME_FORMAT = 'YYYY-MM-DDThh:mm:ss';

export const APPLIANCES = [
  {
    washerMachine: 'Washing Machine',
  },
  {
    dailyReception: 'Daily Reception',
  },
  {
    restaurant: 'Restaurant',
  },
  {
    freeWifi: 'Free Wifi',
  },
  {
    parking: 'Parking',
  },
  {
    nonSmokingRooms: 'Non Smoking Rooms',
  },
  {
    airportShuttle: 'Airport Shuttle',
  },
  {
    ac: 'AC',
  },
  {
    balcony: 'Balcony',
  },
  {
    heating: 'Heating',
  },
  {
    pool: 'Pool',
  },
  {
    hotTub: 'Hot Tub',
  },
  {
    gym: 'Gym',
  },
  {
    petsAllowed: 'Pets Allowed',
  },
  {
    dishWasher: 'Dish Washer',
  },
  {
    microwave: 'Microwave',
  },
  {
    terrace: 'Terrace',
  },
];

export const propertyTypeOptions = [
  {
    title: 'House',
    value: 'house',
  },
  {
    title: 'Apartment',
    value: 'apartment',
  },
  {
    title: 'Hotel',
    value: 'hotel',
  },
  {
    title: 'Hostel',
    value: 'hostel',
  },
  {
    title: 'Motel',
    value: 'motel',
  },
];

export const US_STATES = {
  Alabama: 'AL',
  Alaska: 'AK',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const NUMBERS = /^[0-9]*$/;

export const YEAR = 1900;

export const NUM_ARR = ['1', '2', '3', '4', '5', '5+'];

export const UNIT_STATUS = {
  lease: 'leased',
  ready: 'Ready',
  notReady: 'Not Ready',
};

export const CHARGE_TYPES = {
  Revenue: 'Revenue',
  Credit: 'Credit',
  Payment: 'Payment',
  Refund: 'Refund',
};

export const LEDGER_TYPES = {
  Asset: 'Asset',
  Liability: 'Liability',
  Equity: 'Equity',
  Revenue: 'Revenue',
  Expense: 'Expense',
};
export const DEFAULT_LEDGER = {
  Receivables: 'rent_receivables',
  Income: 'rent_income',
  Other: 'other_income',
  Undeposited: 'undeposited_funds',
  Deposit: 'deposit',
  Bank: 'bank_account',
  Payable: 'accounts_payable',
  Expenses: 'expenses',
  Tax: 'tax',
  Concessions: 'concessions'
};
export const BALANCE_TYPES = {
  Debit: 'Debit',
  Credit: 'Credit',
};

export const MAP_DEFAULT_CENTER = {
  lat: 47.6062,
  lng: 122.3321,
}; // Seattle's lat/lng

export const URGENCY = [
  {
    browsing: 'Browsing',
  },
  {
    nearFuture: 'Near future',
  },
  {
    immediate: 'Immediate',
  },
  {
    inAMonth: 'In a month',
  },
];

export const MESSAGE = 'message';
export const CALL = 'call';
export const DEACTIVATE = 'deactivate';
export const ACTIVATE = 'activate';

export const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
export const PRICE_REGEX = /^\d{1,}(\.\d{1,2})?\s*$/;
export const NUMERIC_REGEX = /^[1-9][0-9]*$/;
export const NUMERIC_REGEX_DECIMAL = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
export const WEBSITE_REGEX = /(\w|\d)(\w|\d)*\.\w+/i;
export const PHONE_REGEX = /^\d{10}$/;
// export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_LEAD = /^(?=.*\d)[\d ]+$/;

export const SQ_FEETS = [
  '500 - 1000',
  '1000 - 1500',
  '1500 - 2000',
  '2000 - 2500',
  '2500 - 3000',
  '3000 - 3500',
  '3500 - 4000',
  '4000 - 4500',
  '4500 - 5000',
  '5000+',
];

export const FAKE_LEASE = {
  startDate: null,
  endDate: null,
  primaryLessee: {
    firstName: null,
    lastName: null,
    ndbId: null,
    email: null,
    phoneHome: null,
    phoneMobile: null,
    bio: null,
    dateOfBirth: null,
    references: [
      {
        name: null,
        phone: null,
        email: null,
        __typename: null,
      },
    ],
    __typename: 'PersonType',
  },
  tenants: [
    {
      firstName: null,
      lastName: null,
      ndbId: null,
      __typename: null,
    },
  ],
  paid: null,
  __typename: 'PropertyLeaseType',
};

export const FAKE_APPLICANT = {
  firstName: null,
  lastName: null,
  email: null,
  phoneHome: null,
  phoneMobile: null,
  dateOfBirth: null,
  references: [
    {
      phone: null,
      title: null,
      name: null,
      email: null,
    },
  ],
};

export const ALL = 'all';
export const CREATED_AT_DESC = 'CREATED_AT_DESC';

export const TASKS = [
  'Clean garbage disposal.',
  'Clean the range hood and filter.',
  'Wash outside windows and siding.',
  'Unclog drains',
  'Test smoke detectors.',
];

export const MATERIALS = [
  'Hammer',
  'Measuring tape',
  'Screwdriver',
  'Power drill',
  'Hand saw',
  'Utility knife',
  'Five gallon bucket',
  'Step ladder',
  'Safety glasses',
];

export const ADD_LEAD_STATUS = [
  {
    key: 'prospect',
    text: 'Prospect',
    value: 'Prospect',
  },
  {
    key: 'tour',
    text: 'Tour',
    value: 'Tour',
  },
  {
    key: 'applied',
    text: 'Applied',
    value: 'Applied',
  },
  {
    key: 'resident',
    text: 'Resident',
    value: 'Resident',
  },
  {
    key: 'lost',
    text: 'Lost',
    value: 'Lost',
  },
  {
    key: 'waitlist',
    text: 'Waitlist',
    value: 'Waitlist',
  },
];

export const SERVICE_REQUIRED = [
  {
    key: 'true',
    text: 'Yes',
    value: 'Yes',
  },
  {
    key: 'false',
    text: 'No',
    value: 'No',
  },
];

export const ADD_APPLICATION_STATUS = [
  {
    key: 'new',
    text: 'New',
    value: 'new',
  },
  {
    key: 'approved',
    text: 'Approved',
    value: 'approved',
  },
  {
    key: 'under_review',
    text: 'Under Review',
    value: 'under_review',
  },
  {
    key: 'denied',
    text: 'Denied',
    value: 'denied',
  },
  {
    key: 'approved_with_conditions',
    text: 'Approve w/Conditions',
    value: 'approved_with_conditions',
  },
  {
    key: 'cancelled',
    text: 'Cancelled',
    value: 'cancelled',
  },
];

export const ADD_LEASE_STATUS = [
  {
    key: 'created',
    text: 'Created',
    value: 'created',
  },
  {
    key: 'signed',
    text: 'Signed',
    value: 'signed',
  },
  {
    key: 'move_in',
    text: 'Move In',
    value: 'move_in',
  },
  {
    key: 'move_out',
    text: 'Move Out',
    value: 'move_out',
  },
];

export const LEAD_SOURCE = [
  {
    key: 'leas',
    text: 'Leasera.com',
    value: 'Leasera.com',
  },
  {
    key: 'apart',
    text: 'Apartments.com',
    value: 'Apartments.com',
  },
  {
    key: 'walk',
    text: 'Walk-In',
    value: 'Walk-In',
  },
];

export const MARITAL_STATUS = [
  {
    key: 'single',
    text: 'Single',
    value: 'Single',
  },
  {
    key: 'married',
    text: 'Married',
    value: 'Married',
  },
  {
    key: 'widowed',
    text: 'Widowed',
    value: 'Widowed',
  },
  {
    key: 'seperated',
    text: 'Seperated',
    value: 'Seperated',
  },
];

export const LEASE_FREQUENCY = [
  {
    key: 'Biweekly',
    text: 'Biweekly',
    value: 'Biweekly',
  },
  {
    key: 'Monthly',
    text: 'Monthly',
    value: 'Monthly',
  },
];

export const UNIT_AVAILABLITY_STATUS = [
  {
    key: 'Vacant',
    text: 'Vacant',
    value: 'Vacant',
  },
  {
    key: 'Pending',
    text: 'Pending',
    value: 'Pending',
  },
  {
    key: 'Signed',
    text: 'Signed',
    value: 'Signed',
  },
  {
    key: 'Unavailable',
    text: 'Unavailable',
    value: 'Unavailable',
  },
  {
    key: 'Unlisted',
    text: 'Unlisted',
    value: 'Unlisted',
  },
  {
    key: 'Off the Market',
    text: 'Off the Market',
    value: 'OffTheMarket',
  },
];

export const payVerificationDoc = [
  'Select Type',
'affiliation',
'alberta_province',
'any_other_government_issued_photo_id',
'articles_amendment',
'advise_and_consult_responses',
'articles_of_association',
'banking_license',
'bankruptcy_trustee',
'benefits_card',
'birth_certificate',
'building_society_statement',
'business_license_certificate',
'business_report',
'canadian_citizenship',
'canadian_health_card',
'certificate_of_association',
'certificate_of_authority',
'certificate_of_domestication',
'certificate_of_existence',
'certificate_of_formation',
'certificate_of_good_standing',
'certificate_of_incorporation',
'certificate_of_liability_insurance',
'certificate_of_limited_partnership',
'certificate_of_naturalization',
'certificate_of_organization',
'certificate_of_status',
'certificate_of_trust',
'certification_of_compliance',
'certified_copy_of_court_order',
'certificate_of_citizenship',
'certificate_of_naturalization',
'change_of_address',
'charity_license',
'charity_registration_number',
'companies_house',
'corporate_amalgamation_agreement',
'corporate_annual_gov_filing',
'corporate_profile_report',
'council_tax_statement',
'credit_card_statement',
'current_bank_statement',
'current_local_tax_bill',
'current_lease_contract',
'current_utility_bill',
'current_business_lease_contract',
'current_residence_lease_contract',
'dba_registration',
'divorce_decree',
'drivers_license',
'electoral_register_entry',
'employment_authorization_card',
'evidence_of_authority',
'evidence_of_corporate_registration',
'evidence_of_exchange',
'evidence_of_name_change',
'evidence_of_nonprofit_registration',
'evidence_of_registration_of_regulated_funds',
'executor_of_estate',
'filed_audited_accounts',
'firearms_certificate',
'foreign_passport',
'form_adv',
'hoa_statement',
'hud_statement',
'identity_card_northern_ireland',
'identity_card_by_eoni',
'inland_revenue_tax_assessment',
'insurance_bill',
'insurance_card',
'insurance_declaration_page',
'irs_501c3_determination',
'irs_confirmation_of_tin',
'letter_from_social_security',
'limited_partnership_certificate',
'limited_partnership_charter_letter',
'llc_agreement',
'llc_filing_receipt',
'marriage_certificate',
'matricula_consular_card',
 'matricula_consular_card',
 'military_id',
 'military_orders',
 'miscellaneous_charge_network_compliance_doc',
 'miscellaneous_compliance_doc',
 'mortgage_closing_document',
 'mortgage_statement',
 'other_evidence_of_filings',
 'other_government_issued_photo_id',
 'operating_agreement',
 'order_of_creation',
 'passport',
 'partnership_agreement',
 'proof_of_supervision_by_gov',
 'social_security_card',
 'permanent_resident_card',
 'secretary_of_state',
 'schedule_q',
 'social_insurance_number_card',
 'social_security_benefit_notice',
 'social_security_card',
 'state_registrar_of_legal_entities',
 'tax_exemption_letter',
 'tax_statement',
 'tribal_or_bureau_of_indian_affairs_id',
 'trust_agreement',
 'vehicle_registration',
 'web_referral',
 'quebec_company_registration'
]

export const AMENITIES = [
  'WiFi',
  'Heater',
  'Free parking',
  'Kitchen',
  'Cable TV',
  'Air conditioning',
  'Hangers',
  'Iron',
  'Dryer',
  'Washing machine',
  'Games console',
  'High chair',
  'Smoke alarm',
  'Fire extinguisher',
  'Kettle',
];

export const VENDOR_SERVICES = [
  'Plumbing',
  'Electrician',
  'Fire',
  'Water damage',
  'Mold',
  'House cleaning',
  'Landscaping',
];

export const ON_BOARDING_STEPS = [
  'prop_info',
  'units',
  'payment_policies',
  'org_info',
];

export const MAX_PIN_LENGTH = 6;
export const MIN_PIN_LENGTH = 4;
export const NUMBER_ONE = 1;
export const NUMBER_TWO = 2;
export const NUMBER_THREE = 3;
export const NUMBER_ZERO = 0;
export const NUMBER_TEN = 10;
export const BACKGROUND_CHECK_TEXT = `Please indicate the year, location and type of each felony, misdemeanor
involving a controlled substance, violence to another person or destruction of property, or a sex crime other than those
resolved by dismissal or acquittal. We may need to discuss more facts before making a decision. You represent the answer is
"no" to any item not checked above.`;
export const PRIORITY = ['Urgent', 'Medium', 'Low'];
export const COMING_SOON = 'COMING SOON';
export const GOOGLE_API_KEY = 'AIzaSyAnOMBzboGt-zL69J7ae_aC_yjWchdAn8M';
export const LEASE_PATH = '/lease';
export const BLUEMOON_PATH = 'https://www.bluemoonforms.com/?p=home';
export const CLEAR_NOTIFICATIONS = 'Mark As Read';
export const CLEAR_NOTIFICATIONS_TEXT = 'Are you sure you want to mark all the notifications as read?';

export const DOCUMENTS_URL = 'https://service-organization-cg2k2cekgq-uc.a.run.app/organization/graphql';
export const DOCUMENTS_GET_URL = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/org_documents`;
export const APPLICATIONS_URL = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/person_application`;
export const HELLOSIGN_URL = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/person_hellosign`;
export const PERSON_URL = 'https://service-person-cg2k2cekgq-uc.a.run.app/person/graphql';
export const PERSON_URL_IMG = 'https://service-person-cg2k2cekgq-uc.a.run.app/person';
export const PRIMARY_LOCATION_URL = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/primary_location`;
export const LEASES_URL = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/person_lease`;
export const INVOICES_URL = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/invoice`;
export const PERSON_PAYMENT = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/person_payment`;
export const PERSON_WEPAY = `https://dev-dot-leasera-${process.env.REACT_APP_STAGING}.uc.r.appspot.com/graphql`;
export const MAINTENANCE_URL = 'https://service-mr-manager-cg2k2cekgq-uc.a.run.app/mr_manager/graphql';
export const MAINTENANCE_URL_PHOTO = 'https://service-mr-manager-cg2k2cekgq-uc.a.run.app/mr_manager';
export const MANAGEMENT_URL = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/management`;
export const LEADS_URL = 'https://service-lead-manager-cg2k2cekgq-uc.a.run.app/lead_manager/graphql';
export const LEADS_TOUR = `https://service-lead-tour-mr-cg2k2cekgq-uc.a.run.app/lead_tour_mr/graphql`;
export const LEADS_TOUR_TYPE = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/lead_tour_type`;
export const LEADS_COMMENT_URL = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/lead_comments`;
export const APPLICATION_COMMENT_URL = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/application_comment`;
export const LEADS_COMMUNICATION_URL = `https://service-lead-communication-cg2k2cekgq-uc.a.run.app/lead_communication/graphql`;
export const AFFORDABLE_HOUSING = 'https://service-affordable-housing-cg2k2cekgq-uc.a.run.app/affordable_housing/graphql';
export const FUSEBIT = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/fusebit_configuration`;
export const APPLICATION_MANAGER = 'https://service-application-manager-cg2k2cekgq-uc.a.run.app/application_manager/graphql';
export const CHARGE_CODE = 'https://service-charge-code-cg2k2cekgq-uc.a.run.app/charge_code/graphql';
export const ACCOUNTING = 'https://service-accounting-cg2k2cekgq-uc.a.run.app/accounting/graphql';
export const ACCOUNTING_REPORTING = 'https://service-reporting-cg2k2cekgq-uc.a.run.app/reporting/graphql';
export const INVOICE_ACCOUNTING = 'https://service-accounting-cg2k2cekgq-uc.a.run.app/accounting';
export const INVOICE_ACCOUNTING_PAYMENT = 'https://service-accounting-cg2k2cekgq-uc.a.run.app/accounting/graphql';
export const LOCATIONAPI = 'https://service-location-manager-cg2k2cekgq-uc.a.run.app/location/graphql';
export const LOCATIONDOC = 'https://service-location-manager-cg2k2cekgq-uc.a.run.app/location';
export const LOCATION_TYPE_API = 'https://service-location-type-cg2k2cekgq-uc.a.run.app/location_type/graphql';
export const UNITAPI = 'https://service-unit-cg2k2cekgq-uc.a.run.app/unit/graphql';
export const UNIT_TYPE_API = 'https://service-unit-type-cg2k2cekgq-uc.a.run.app/unit_type/graphql';
export const LOCATION_UTILS = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/location_utils`;
export const LEASE_MANAGER = 'https://service-lease-manager-cg2k2cekgq-uc.a.run.app/lease_manager/graphql';
export const PAYMENTS_URL = `https://payment-dev-dot-leasera-${process.env.REACT_APP_STAGING}.uc.r.appspot.com/graphql`;
export const BGC = 'https://service-background-manager-cg2k2cekgq-uc.a.run.app/background_manager/graphql';
export const ACCMETRICS = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/accounting_metrics_v2`;
export const LEASE_COMMENT = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/lease_comment`;
export const ORGANIZATION_API = 'https://service-organization-cg2k2cekgq-uc.a.run.app/organization/graphql';
export const TASK_URL = 'https://service-task-manager-cg2k2cekgq-uc.a.run.app/task_manager/graphql';
export const DOMAIN_MANAGER = 'https://service-domain-manager-cg2k2cekgq-uc.a.run.app/domain_manager/graphql';
export const MICROSITE = 'https://service-domain-manager-cg2k2cekgq-uc.a.run.app/domain_manager/graphql';
export const BLUEMOON_API = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/bluemoon`;
export const DOCUSIGN = `https://us-central1-leasera-${process.env.REACT_APP_STAGING}.cloudfunctions.net/docu_sign`
export const LOYALTY = `https://service-package-loyalty-cg2k2cekgq-uc.a.run.app/package_loyalty/graphql`;
export const EVOCALIZE = `https://service-evocalize-cg2k2cekgq-uc.a.run.app/evocalize/graphql`;
export const SCHEDULEINTEGRATE = `https://service-calendar-cg2k2cekgq-uc.a.run.app/calendar/graphql`;
export const CHAT_URL = `https://service-chat-cg2k2cekgq-uc.a.run.app/chat/graphql`;
export const CHAT_URL_IMAGE_PATH = "https://service-chat-cg2k2cekgq-uc.a.run.app/chat?path=";

export const DOCUSIGN_VIEW = `https://service-docu-sign-cg2k2cekgq-uc.a.run.app/docu_sign/graphql`;

export const APPLICATION_CREATION_EMAIL = 'https://service-application-manager-cg2k2cekgq-uc.a.run.app/application_manager/graphql';
export const APPLICATION_CREATION_EMAIL_IMAGE = 'https://service-application-manager-cg2k2cekgq-uc.a.run.app/application_manager';
export const CALENDAR_SERVICE_URL = 'https://service-calendar-cg2k2cekgq-uc.a.run.app/calendar/graphql';
export const THIRD_PARTY_API_URL = 'https://service-zillow-api-cg2k2cekgq-uc.a.run.app/graphql';
export const PUSH_NOTIFICATION_URL = "https://service-push-notification-cg2k2cekgq-uc.a.run.app/push_notification/graphql";
export const WEPAY_URL = `https://dev-dot-leasera-${process.env.REACT_APP_STAGING}.uc.r.appspot.com/graphql`;



