import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Icon , Table, Checkbox} from "semantic-ui-react";
import { toast } from "react-toastify";
import ChargeCodeModal from "../Modals/ChargeCodeModal";
import JournalModal from "../Modals/JournalModal";
import { bool } from "yup";


export function GeneralAccountingTable(props) {
  const [headerArray, setHeaderArray] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [toggled, viewToggle] = useState(false);
  const [viewLedgerData, viewData] = useState("");
  const [loader, setLoading] = useState(false);


  function TableRender(props) {
    const { mainCellData, mainHeader, selectChange, changeState} = props.props;
    

    const AppSelectBox = (obj) => {
      return (
        <Checkbox
          onChange={() => selectChange(obj)}
          checked={changeState.includes(obj.node.id)}
        />
      )
    }

    const sortFunctionality= (header) =>{
      
    }
    useEffect(() => {
      setHeaderArray(mainHeader);
      setMainData(mainCellData);
    },[props.props]);

    const success = () =>
    toast.success("Success!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    const failure = () =>
      toast.error("Something went wrong!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });


    const switchCase = (cell) =>{
        if (React.isValidElement(cell)) {
          return (
            <Table.Cell width='1' textAlign="center">
              {cell}
            </Table.Cell>
          )
        }
        if (cell&&cell.appSelect) {
          return (
            <Table.Cell width='1' textAlign="center">
              {AppSelectBox(cell.obj)}
            </Table.Cell>
          )
        }
        else return "Blank"
    }

    // !: Check original tables for clickable column data.

    return (
      <>
        <Table columns={mainData.length} >
          <Table.Header >
            <Table.Row  textAlign="center">
              {
                headerArray.map((item, index) => {
                  return(
                  <>
                    <Table.HeaderCell width={1} onClick={() => sortFunctionality(index)} style={{background:'#555555', color:'white'}}>{item}</Table.HeaderCell>
                  </>
                  )
                })
              }
            </Table.Row>
          </Table.Header>
          </Table>
          <div style={{maxHeight:'750px', overflowY:'overlay'}}>
          <Table >
          {mainCellData.length > 0 ?
            <Table.Body >
              {typeof mainData === "object" ?
                mainData && mainData.map((item, index) => {
                  return (
                    <Table.Row columns={mainHeader.length}>
                      {Object.values(item).map((cell, index) => {
                          if(typeof cell === "object" || typeof cell === "boolean"){
                            return(
                              switchCase(cell)
                              )
                            }
                            else{
                                return (
                                <Table.Cell style={{overflowWrap:"break-word", maxWidth:'112.705px'}} width={1} textAlign="center">{cell}</Table.Cell>
                            )
                        }
                      })}
                    </Table.Row>
                  )
                })
              :
                mainData&& mainData.map((item, index)=> {
                  return (
                    <Table.Row columns={item.length}> 
                      {
                        item.map((cell, index) => {
                          if(typeof cell == "object"){
                            return(
                              switchCase(cell)
                              )
                            }
                            else{
                              return (
                              <Table.Cell style={{overflowWrap:"break-word"}} width={1} textAlign="center">{cell}</Table.Cell>
                            )
                          }
                        })
                      }
                    </Table.Row>
                  )
                })
              }
            </Table.Body>
          :
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="center">
                  No Results Found
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          }
        </Table>
        </div>
      </>
    )
  }
  

  return (
    <>
        <TableRender
        props={props}
        />
    </>
  );
}
