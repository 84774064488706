import React, { Component } from "react";
import {
  Form,
  Button,
  Modal,
  Header,
  Dropdown,
  Input,
} from "semantic-ui-react";
import { withApollo } from "react-apollo";
import _ from "lodash";
import { getClient } from "../../init-apollo-googleFn";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LEADS_TOUR, LEADS_TOUR_TYPE } from "../../utils/constants";
import { createTour, TourTypes, TourLocation } from "../../store/person/leads";

const tourAPI = getClient(LEADS_TOUR);
const tourTypeAPI = getClient(LEADS_TOUR_TYPE);

class ToursModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      name: "",
      date: "",
      duration: "30",
      type: "",
      link: "",
      agent: "",
      tourArr: [],
      notAvailable: "",
      notAvailableDate: "",
      selectedTime: "",
      modalOpen: false,
      timeLoad: false,
    };
  }

  componentDidMount() {
    this.nameSetter();

    if (this.state.modalOpen === true) {
      this.currentTours();
      this.fetchTypes();
    }
  }

  currentTours = () => {
    try {
      tourAPI
        .query({
          query: TourLocation,
          variables: {
            location: this.props.lead.location,
          },
        })
        .then((res) => {
          const values = res.data.tours.edges;
          const filteredValues = values.filter((item) =>
            moment(item.node.date).isSameOrAfter(moment())
          );
          const cancelFilter = filteredValues.filter(
            (item) => item.node.status !== "cancel"
          );
          this.setState({ notAvailable: cancelFilter });
        });
    } catch (e) {
      console.log(e);
    }
  };
  fetchTypes = () => {
    try {
      tourTypeAPI
        .query({
          query: TourTypes,
          variables: {
            location: this.props.lead && this.props.lead.location,
          },
        })
        .then((res) => {
          const array = res.data.tourTypes.edges;
          this.setState({ tourArr: array });
          this.currentTours();
        });
    } catch (e) {
      console.log(e);
    }
  };
  nameSetter = () => {
    if (this.props.lead) {
      const { lead } = this.props;
      this.setState({
        name: lead.firstName + " " + lead.lastName,
        errors: { ...this.state.errors, name: true },
      });
    } else {
      console.log("Function");
    }
  };
  handleClose = () => {
    this.setState({
      modalOpen: false,
      date: "",
      duration: 15,
      type: "",
      link: "",
      agent: "",
      selectedTime: "",
    });
  };

  success = () =>
    toast.success("Tour Type Saved!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  // Handle the time selected
  handleChange = (name, e) => {
    this.setState({
      selectedTime: e.children,
    });
  };
  handleLinkChange = (e, { value }) => {
    this.setState({ link: value });
  };
  // Handle Dropdowns value change
  handleDropdownChange = (field, value) => {
    this.setState({ [field]: value });
  };
  handleTypeChange = (event, { value }) => {
    this.setState({ type: value, duration: value.duration }, () => {});
    if (value.connectionInfo) {
      this.setState(
        { link: value.connectionInfo, duration: value.duration },
        () => {}
      );
    }
  };
  //   Handle DatePicker date change
  handleDateChange = (event) => {
    this.setState({ date: event._d });
    this.tourCheck(event._d);
  };

  tourCheck = (date) => {
    this.setState({ timeLoad: true });
    try {
      tourAPI
        .query({
          query: TourLocation,
          variables: {
            location: this.props.lead.location,
          },
        })
        .then((res) => {
          if (res.data.tours) {
            const eventFilter = res.data.tours.edges;
            const filterArr = eventFilter.filter(
              (task) => task.node.status !== "cancel"
            );
            const dateFilter = filterArr.filter(
              (task) =>
                moment(task.node.date).format("MM/DD/YYYY") ===
                moment(date).format("MM/DD/YYYY")
            );
            const endarray = [];
            dateFilter.forEach((item) => {
              const value = moment(item.node.date).format("MM/DD/YYYY");
              const localTime = moment(
                value + " " + item.node.time,
                "DD/MM/YYYY HH:mm A"
              );
              const local = moment.utc(localTime._i);
              const DDate = local;
              const date = DDate.local().format("DD/MM/YYYY");
              const time = DDate.local().format("hh:mm A");
              const newObj = { date: date, time: time };
              endarray.push(newObj);
            });
            this.setState({ notAvailableDate: endarray });
            this.setState({ timeLoad: false });
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
  validateAndSubmit = (e) => {
    e.preventDefault();
    this.handleInviteSubmit();
  };

  handleInviteSubmit = (localDateTime) => {
    this.setState({ sending: true });
    const { name, type, date, selectedTime, duration, link } = this.state;
    var moment = require("moment-timezone");
    const timeZone = moment.tz.guess();
    const value = moment(date).format("DD/MM/YYYY");
    const localTime = moment(
      value + " " + selectedTime,
      "DD/MM/YYYY HH:mm A",
      timeZone
    );
    const newdate = moment.utc(localTime).format("MM/DD/YYYY");
    const newtime = moment.utc(localTime).format("hh:mm A");

    tourAPI
      .mutate({
        mutation: createTour,
        variables: {
          input: {
            tour: {
              leadId: this.props.lead.id,
              name: name,
              tourType: type.code,
              date: newdate,
              time: newtime,
              duration: parseInt(duration),
              agent: this.props.pm.ndbId,
              location: this.props.lead.location,
              connectionInfo: type.connectionInfo ? link : "",
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          },
        },
      })
      .then((response) => {
        this.props.fetchComments();
        this.props.tourSet();
        this.props.fetchTour();
        this.setState({ sending: false });
        this.handleClose();
        this.props.successTour();
      })
      .catch((error) => {
        alert(error);
        console.log(error);
        this.setState({ sending: false });
        this.handleClose();
      });
  };

  mainForm = (selectedTime, date, notAvailableDate) => {
    const getDuration = (number) =>
      _.times(number, (index) => ({
        key: index,
        text: `${(index + 1) * 10}`,
        value: index,
      }));
    const getAgent = (number, prefix = "Property Manager") =>
      _.times(number, (index) => ({
        key: index,
        text: `${prefix}`,
        value: index,
      }));
    var today = Datetime.moment().subtract(1, "day");
    var valid = function (current) {
      return current.isAfter(today);
    };
    const m = moment.utc();
    const localDateTime = m.local().format("YYYY-MMM-DD h:mm A");
    const localTime = moment(localDateTime).format("HH:mm A");
    return (
      <>
        <Form width="equal" className="schedule-tour-form">
          <Form.Group width="equal">
            <Form.Field required width={8}>
              <label>Name</label>
              {this.props.lead && (
                <Form.Input
                  className="lead-set height-40"
                  value={
                    this.props.lead.firstName + " " + this.props.lead.lastName
                  }
                  name="name"
                  onChange={(event) => {
                    this.handleChange("name", event);
                  }}
                />
              )}
              {!this.props.lead && (
                <Form.Input
                  className="height-40"
                  placeholder="Name"
                  name="name"
                  onChange={(event) => {
                    this.handleChange("name", event);
                  }}
                />
              )}
            </Form.Field>
            <Form.Field width={8}>
              <label>Tour Type</label>
              <Dropdown
                placeholder="Any Available"
                fluid
                name="type"
                selection
                scrolling
                options={this.state.tourArr.map((tour) => ({
                  key: tour.node.id,
                  text: tour.node.name,
                  value: tour.node,
                }))}
                onChange={this.handleTypeChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group width="equal" className="margin-top-20">
            <Form.Field width={8}>
              <label>Tour Agent</label>
              <Dropdown
                placeholder="Any Available"
                fluid
                selection
                name="agent"
                scrolling
                options={getAgent(1)}
                onChange={(e) =>
                  this.handleDropdownChange("agent", e.target.textContent)
                }
              />
            </Form.Field>
            {/* <Form.Field width={8}>
              <label>Duration(Minutes)</label>
              <Dropdown
                placeholder="Select Time"
                fluid
                selection
                text={this.state.duration}
                name="duration"
                scrolling
                options={getDuration(6)}
                onChange={(e) =>
                  this.handleDropdownChange("duration", e.target.textContent)
                }
              />
            </Form.Field> */}
          </Form.Group>

          <Form.Group className="margin-top-20">
            <Form.Field>
              <label>Date</label>
              <Input fluid className="schedule-date">
                <Datetime
                  timeFormat={false}
                  viewMode="days"
                  isValidDate={valid}
                  input={false}
                  minDate={moment().toDate()}
                  defaultValue={moment(this.state.date)}
                  onChange={this.handleDateChange}
                />
              </Input>
            </Form.Field>
            <Form.Field width={12} className="pr-15">
              <label>
                {date
                  ? moment(date).format("MM/DD/YYYY")
                  : "Please Select Date"}
              </label>
              {
                this.state.timeLoad ? (
                  "Gathering Times..."
                ) : (
                  /* eslint-disable */
                  <Button.Group fluid className="select-time-buttons">
                    <Button.Group widths="6" basic vertical>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "07:00") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "07:00 AM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "7:00 AM"}
                      >
                        7:00 AM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "07:30") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "07:30 AM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "7:30 AM"}
                      >
                        7:30 AM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "08:00") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "08:00 AM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "8:00 AM"}
                      >
                        8:00 AM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "08:30") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "08:30 AM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "8:30 AM"}
                      >
                        8:30 AM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "09:00") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "09:00 AM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "9:00 AM"}
                      >
                        9:00 AM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "09:30") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "09:30 AM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "9:30 AM"}
                      >
                        9:30 AM
                      </Button>
                    </Button.Group>
                    <Button.Group widths="6" basic vertical>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "10:00") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "10:00 AM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "10:00 AM"}
                      >
                        10:00 AM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "10:30") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "10:30 AM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "10:30 AM"}
                      >
                        10:30 AM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "11:00") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "11:00 AM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "11:00 AM"}
                      >
                        11:00 AM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "11:30") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "11:30 AM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "11:30 AM"}
                      >
                        11:30 AM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "12:00") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "12:00 PM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "12:00 PM"}
                      >
                        12:00 PM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "12:30") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "12:30 PM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "12:30 PM"}
                      >
                        12:30 PM
                      </Button>
                    </Button.Group>
                    <Button.Group widths="6" basic vertical>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "13:00") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "01:00 PM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "1:00 PM"}
                      >
                        1:00 PM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "13:30") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "01:30 PM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "1:30 PM"}
                      >
                        1:30 PM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "14:00") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "02:00 PM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "2:00 PM"}
                      >
                        2:00 PM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "14:30") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "02:30 PM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "2:30 PM"}
                      >
                        2:30 PM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "15:00") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "03:00 PM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "3:00 PM"}
                      >
                        3:00 PM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "15:30") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "03:30 PM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "3:30 PM"}
                      >
                        3:30 PM
                      </Button>
                    </Button.Group>
                    <Button.Group widths="6" basic vertical>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "16:00") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "04:00 PM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "4:00 PM"}
                      >
                        4:00 PM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "16:30") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "04:30 PM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "4:30 PM"}
                      >
                        4:30 PM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "17:00") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "05:00 PM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "5:00 PM"}
                      >
                        5:00 PM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "17:30") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "05:30 PM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "5:30 PM"}
                      >
                        5:30 PM
                      </Button>
                      <Button
                        disabled={
                          date === "" ||
                          (moment(date).isSame(localDateTime, "day") &&
                            localTime > "18:00") ||
                          (notAvailableDate &&
                            notAvailableDate.some(
                              (item) => item.time === "07:00 PM"
                            ))
                        }
                        onClick={this.handleChange}
                        toggle
                        active={selectedTime === "6:00 PM"}
                      >
                        6:00 PM
                      </Button>
                    </Button.Group>
                  </Button.Group>
                )
                /* eslint-enable */
              }
            </Form.Field>
          </Form.Group>
        </Form>
      </>
    );
  };

  render() {
    const { selectedTime, date, type, link, notAvailableDate } = this.state;
    return (
      <>
        <Modal
          className="semanticModal modal-radius"
          onClose={() => this.handleClose()}
          onOpen={() => {
            this.setState({ modalOpen: true });
            this.fetchTypes();
          }}
          open={this.state.modalOpen}
          size="small"
          trigger={
            <Button
              className="dark-button"
              disabled={this.props.disabled || this.props.tour}
              size="large"
              floated="right"
            >
              Schedule Tour
            </Button>
          }
        >
          <Modal.Header textAlign="left" className="modal-header-bg">
            <Header textAlign="left" className="modal-heading-custom">
              Schedule Tour
            </Header>
          </Modal.Header>
          <Modal.Content>
            {this.mainForm(selectedTime, date, notAvailableDate)}
            {type.connectionInfo && (
              <Input
                className="height-40 mt-4"
                fluid
                onChange={this.handleLinkChange}
                value={link}
              />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="modal-close-button"
              positive
              onClick={() => this.handleClose()}
            >
              Cancel
            </Button>
            {this.state.sending ? (
              <Button className="modal-save-button" positive loading>
                Loading
              </Button>
            ) : (
              <Button
                type="button"
                className="modal-save-button"
                positive
                disabled={
                  this.state.name === "" ||
                  this.state.selectedTime === "" ||
                  this.state.agent === "" ||
                  this.state.type === "" ||
                  this.state.date === "" ||
                  this.state.duration === ""
                }
                content="Submit"
                onClick={(e) => this.handleInviteSubmit(e)}
              >
                Submit
              </Button>
            )}
          </Modal.Actions>
        </Modal>

      </>
    );
  }
}
export default withApollo(ToursModal);
