import React, { Component } from "react";
import get from "lodash/get";
import { withApollo } from "react-apollo";
import Table from "../../components/Lease/LeaseTable";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { FormatLeases } from "../../components/Tables/TableHandlers";
import { toast } from "react-toastify";
import { leasesGql, updateBulkEdit } from "../../store/person/leases";
import { qpDatatables, qpTooltipPopover } from "../../utils/misc";
import { Header, Label, Button, Input, Dropdown, Step, Icon, Popup } from "semantic-ui-react";
import "./Lease.scss";
import {
  LEASE_MANAGER,
  ADD_LEASE_STATUS,
  CREATED_AT_DESC,
  ROUTES,
} from "../../utils/constants";
import "../Applications/Applications.scss";
import { getClient } from "../../init-apollo-googleFn";
import { PageLoader } from "../../components/Loader/PageLoader";
import { parseGraphQLErrors, toastFailMsg } from "../../utils/common";
import BulkEditModal from "../../components/Modals/BulkEditModal";
import moment from "moment";
import mixpanel from "mixpanel-browser";
import Tour from 'reactour'
import { leasesSteps } from "../../utils/tourSteps";
const newClient = getClient(LEASE_MANAGER);

class Lease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      message: "",
      isTourOpen:false,
      lease: [],
      leaseStable: [],
      value: '',
      loading: false,
      selectedLeaseForBulkEdit: [],
      filter: false,
      leaseOptions: [
        {
          key: 'All',
          text: 'All',
          value: 'All',
        },
        {
          key: 'Created',
          text: 'Created',
          value: 'created',
        },
        {
          key: 'In Progress',
          text: 'In Progress',
          value: 'in_progress',
        },
        {
          key: 'Initiated',
          text: 'Initiated',
          value: 'signed',
        },
        {
          key: 'Move Out',
          text: 'Move Out',
          value: 'move_out',
        },
        {
          key: 'Move In',
          text: 'Move In',
          value: 'move_in',
        },
      ],
      tableData: [],
      disableBulkEdit: true,
      sending: false,
      editLease: false,
      selectedPrimary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
    };
  }

  componentDidMount() {
    qpDatatables();
    qpTooltipPopover();
    this.props.selectedPrimary.node &&
      this.props.selectedPrimary.node.customId &&
      this.getLease();
    mixpanel.track("Manager Page Load", {
      sub: "Lease",
    });
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};
    if (props.primaryLocations) {
      const nodeData = props.selectedPrimary.node;
      if (nodeData !== state.selectedPrimary) {
        newState["loading"] = true;
        newState["selectedPrimary"] = nodeData;
      }
    }
    return newState;
  }

  componentDidUpdate(props) {
    if (props.selectedPrimary.node !== this.state.selectedPrimary) {
      this.setState({ selectedLeaseForBulkEdit: [] });
      this.getLease();
    }
  }

  success = (msg) =>
    toast.success(msg, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  setMessage = (message, isError = false) => {
    this.setState({ message, isError });
  };

  getLease = () => {
    this.setState({ loading: true });
    try {
      newClient
        .query({
          query: leasesGql,
          variables: {
            locationId:
              this.props.selectedPrimary && this.props.selectedPrimary.node.id,
            // sort: CREATED_AT_DESC,
          },
        })
        .then((response) => {

          this.setState({ loading: false, disableBulkEdit: true });
          const lease = get(response, "data.leases.edges", []);
          const filtered = lease.filter(item => item.node.status !== "move_in")
          const moveOut = filtered.filter(item => item.node.status !== "move_out")
          const dataToPass = FormatLeases(moveOut, this.onViewDetails, this.props)();
          this.setState({ lease: moveOut, tableData: dataToPass, leaseStable: moveOut });
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.setMessage(parseGraphQLErrors(error), true);
        });
    } catch (e) {
      console.log(e);
    }
  };

  /* function to delete the unchecked leads from the selectedLeaseForBulkEdit array */
  removeUncheckedLeads = (data, id) => {
    const tempArr = [...data];
    const index = tempArr.indexOf(id);
    if (index !== -1) {
      tempArr.splice(index, 1);
      this.setState({ selectedLeaseForBulkEdit: tempArr }, () => {
        if (this.state.selectedLeaseForBulkEdit.length === 0) {
          this.setState({ disableBulkEdit: true });
        }
      });
    }
  };

  /* function to add checked leads in the selectedLeaseForBulkEdit Array */
  addCheckedLeads = (data, id) => {
    const joined = data.concat(id);
    this.setState({
      selectedLeaseForBulkEdit: joined,
      disableBulkEdit: false,
    });
  };

  /* function called on the click of the select checkbox for leads
    checks if the lead is already checked, if yes, calls removeUncheckedLeads,
    if not, calls addCheckedLeads and add the lead in the array */
  handleMultipleCheckboxSelection = (item, e) => {
    console.log(e)
    const { selectedLeaseForBulkEdit } = this.state;
    const isPresent = selectedLeaseForBulkEdit.includes(item.node.id);
    if (isPresent) {
      this.removeUncheckedLeads(selectedLeaseForBulkEdit, item.node.id);
    } else {
      this.addCheckedLeads(selectedLeaseForBulkEdit, item.node.id);
    }
  };

  /* function called on the selection of the changed status from the bulk edit dropdown
   calls updateBulkEdit mutation */
  updateBulkEdit = async (value) => {
    this.setState({ sending: true, loading: true });
    try {
      mixpanel.track("Manager Lease Action", {
        sub: "Bulk Update",
      });
      await newClient
        .mutate({
          mutation: updateBulkEdit,
          variables: {
            input: {
              lease: {
                leaseIds: this.state.selectedLeaseForBulkEdit,
                status: value,
              },
            },
          },
        })
        .then((res) => {
          this.setState({ loading: true, selectedLeaseForBulkEdit: [] });
          this.setState({ sending: false, loading: false });
          setTimeout(() => {
            this.getLease();
          }, 200);
        });
    } catch (e) {
      this.setState({
        sending: false,
        loading: false,
        selectedLeaseForBulkEdit: [],
      });
      toastFailMsg(e.toString().replace("GraphQL error:", ""));
    }
  };

  onViewDetails = (item) => {
    this.props.history.push(
      ROUTES.leaseDetails.replace(":leaseId", item.node.id)
    );
  };

  setFilter = (value) => {
    const {leaseStable} = this.state
    const newValue = leaseStable.filter(item => (item.node.person.lastName).toLowerCase().includes(value.toLowerCase()))
    this.setState({ lease: newValue })
  }
  setFilterStatus = (event, { value, key }) => {
    const { leaseStable } = this.state
    if (value == "All") {
      this.setState({ lease: leaseStable })
    }
    else {
      const newValue = leaseStable.filter(item => item.node.status == value)
      this.setState({ lease: newValue })
    }
  }

  filterLeases = (val) => {
    if (val == "completed" && this.state.filter !== "completed") {
      const filtered = this.state.leaseStable.filter(item => item.node.status == "signed")
      this.setState({ filter: "completed" })
      this.setState({ lease: filtered })
    }
    if (val == "waiting" && this.state.filter !== "waiting") {
      this.setState({ filter: "waiting" })
      const filtered = this.state.leaseStable
      const newa = []
      filtered.map(item => {
        if (item.node.extLeaseInfo && item.node.extLeaseInfo.signers) {
          Object.keys(item.node.extLeaseInfo.signers).forEach(function (key) {
            if (item.node.extLeaseInfo.signers[key].isManager !== true) {
              if (item.node.extLeaseInfo.signers[key].status == 'sent' || item.node.extLeaseInfo.signers[key].status == 'delivered') {
                newa.push(item)
              }
            }
          });
        }
      })
      this.setState({ lease: newa })
    }
    if (val == "manager" && this.state.filter !== "manager") {
      this.setState({ filter: "manager" })
      const filtered = this.state.leaseStable
      const newa = []
      filtered.map(item => {
        if (item.node.extLeaseInfo && item.node.extLeaseInfo.signers) {
          Object.keys(item.node.extLeaseInfo.signers).forEach(function (key) {
            if (item.node.extLeaseInfo.signers[key].isManager == true) {
              if (item.node.extLeaseInfo.signers[key].status == 'delivered' || item.node.extLeaseInfo.signers[key].status == 'sent') {
                newa.push(item)
              }
            }
          });
        }
      })
      this.setState({ lease: newa })
    }
    if (val == "begin" && this.state.filter !== "begin") {
      this.setState({ filter: "begin" })
      const filtered = this.state.leaseStable.filter(item => item.node.status == "created")
      this.setState({ lease: filtered })
    }
    if (this.state.filter !== "none") {
      this.setState({ filter: "none" })
      this.setState({ lease: this.state.leaseStable })
    }
  }

  // Button Data -------------------------------------------------------
  ButtonGroup = (options) => (
    <div className="button-group-row">
      <Header className="leases-first-step" as="h2">Leases <Icon size='small' circular name='lightbulb' onClick={() => this.setState({ isTourOpen: true })} /></Header>
      <div className="leases-second-step">
        <Step.Group size="mini">
          <Popup content='User has been accepted and is awaiting documents sent to them.' trigger={
            <Step style={{
              padding: '15px',
              background: this.state.filter == "begin" ? "radial-gradient(circle, rgba(2,0,36,0) 47%, #4c158a 100%, #4c158a 100%)" : 'white'
            }} onClick={() => this.filterLeases("begin")} >
              <Icon name='paper plane outline' />
              <Step.Content>
                <Step.Title>Begin</Step.Title>
              </Step.Content>
            </Step>
          } />
          <Popup content='Renters have been sent documents and awaiting their signatures.' trigger={
            <Step onClick={() => this.filterLeases("waiting")} style={{ padding: '15px', background: this.state.filter == "waiting" ? "radial-gradient(circle, rgba(2,0,36,0) 47%, #4c158a 100%, #4c158a 100%)" : 'white' }}>
              <Icon name='hourglass half' />
              <Step.Content>
                <Step.Title>Waiting</Step.Title>
              </Step.Content>
            </Step>
          } />
          <Popup content='Renters have completd signing and are awating manager signatures.' trigger={
            <Step onClick={() => this.filterLeases("manager")} style={{ padding: '15px', background: this.state.filter == "manager" ? "radial-gradient(circle, rgba(2,0,36,0) 47%, #4c158a 100%, #4c158a 100%)" : 'white' }}>
              <Icon name='handshake outline' />
              <Step.Content>
                <Step.Title>Manager</Step.Title>
              </Step.Content>
            </Step>
          } />
          <Popup content='All parties have signed and Renters are awaiting Move In.' trigger={
            <Step onClick={() => this.filterLeases("completed")} style={{ padding: '15px', background: this.state.filter == "completed" ? "radial-gradient(circle, rgba(2,0,36,0) 47%, #4c158a 100%, #4c158a 100%)" : 'white' }}>
              <Icon name='truck' />
              <Step.Content>
                <Step.Title>Move In</Step.Title>
              </Step.Content>
            </Step>
          } />
        </Step.Group>
        {/* <Button compact attached='bottom'>Clear</Button> */}
      </div>
      <div className="lead-action-wrapper leases-third-step">


        {/* <span>
          Filter Status {' '}
          <Dropdown
            inline
            options={this.state.leaseOptions}
            defaultValue={this.state.leaseOptions[0].value}
            onChange={this.setFilterStatus}
          />
          {' '}
        </span> */}

        <BulkEditModal
          values={ADD_LEASE_STATUS}
          sending={this.state.sending}
          showButton={this.state.disableBulkEdit}
          updateBulkEdit={this.updateBulkEdit}
        />
      </div>
    </div>
  );
  handleChange = (e, { value }) => this.setState({ value })

  setFilter = (value) => {
    const { leaseStable } = this.state
    if (this.state.value == 1) {
      const newArr = []
      leaseStable.map(item => {
        if (item.node.status.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ lease: newArr })
    }
    if (this.state.value == 2) {
      const newArr = []
      leaseStable.map(item => {
        if (item.node.person && item.node.person.firstName.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ lease: newArr })
    }
    if (this.state.value == 3) {
      const newArr = []
      leaseStable.map(item => {
        if (item.node.person && item.node.person.lastName.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ lease: newArr })
    }
    if (this.state.value == 4) {
      const newArr = []
      leaseStable.map(item => {
        if (item.node.unitAmount.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ lease: newArr })
    }
    if (this.state.value == 5) {
      const newArr = []
      leaseStable.map(item => {
        if (item.node.leaseFrequency.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ lease: newArr })
    }
    if (this.state.value == 6) {
      const newArr = []
      leaseStable.map(item => {
        if (item.node.unit.number.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ lease: newArr })
    }
    if (this.state.value == 7) {
      const newArr = []
      leaseStable.map(item => {
        if (item.node.startDate.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ lease: newArr })
    }
    if (this.state.value == 8) {
      const newArr = []
      leaseStable.map(item => {
        if (item.node.endDate.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ lease: newArr })
    }
    if (this.state.value == 9) {
      const newArr = []
      leaseStable.map(item => {
        if (item.node.status.includes(value)) {
          newArr.push(item)
        }
      })
      this.setState({ lease: newArr })
    }
  }
  render() {
    const { lease, loading, leaseStable } = this.state;


    const mainHeader = ["SELECT", "STATUS", "FIRST NAME", "LAST NAME", "AMOUNT", "FREQUENCY", "UNIT", "START DATE", "END DATE", "SIGN STATUS", "TYPE", "VIEW", "EDIT", "SIGN"];
    const options = [
      { key: 1, text: 'Status', value: 1 },
      { key: 2, text: 'First', value: 2 },
      { key: 3, text: 'Last', value: 3 },
      { key: 4, text: 'Amount', value: 4 },
      { key: 5, text: 'Frequency', value: 5 },
      { key: 6, text: 'Unit', value: 6 },
      { key: 7, text: 'Start', value: 7 },
      { key: 8, text: 'End', value: 8 },
      { key: 9, text: 'Sign', value: 9 },
    ]
    return loading ? (
      <PageLoader text />
    ) : (
      <>
        <main className="main-content" role="main">
          <div className="roll-container ">
            {leaseStable && leaseStable.length > 0 ? (
              <>
                <this.ButtonGroup options={options} />
                <Table
                  lease={lease}
                  user={this.props.user}
                  multipleHandling={this.handleMultipleCheckboxSelection}
                  selectedAppsForBulkEdit={this.state.selectedLeaseForBulkEdit}
                  onViewDetails={this.onViewDetails}
                  history={this.props.history}
                  getLease={this.getLease}
                />
                {/* <GeneralAccountingTable
                  mainCellData={this.state.tableData}
                  mainHeader={mainHeader}
                /> */}
              </>
            ) : (
              <div className="no-results-text-application lease-no-result text-center py-5">
                <span className="not-found-icon"></span>
                <p className="no-results-text">
                  You don't have any lease for the selected property. Click
                  'Create Lease' from application detail page.
                </p>
              </div>
            )}
          </div>
        </main>
        <Tour
          steps={leasesSteps}
          isOpen={this.state.isTourOpen}
          onRequestClose={() => this.setState({ isTourOpen: false })}
          // maskClassName="mask"
          className="helper"
          startAt={0}
          rounded={5}
        />
      </>
    );
  }
}

export default withApollo(Lease);
