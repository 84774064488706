import { getClient } from "../init-apollo-googleFn";
import {
  accountCapabilitiesGql,
  createWepayAccountGql,
  createWepayLegalEntityGql,
  DeletePaymentMethodGql,
  GetPaymentMethodsGql,
  legalEntityVerificationGql,
  merchantGql,
} from "../store/wepay/wepay";
import { WEPAY_URL } from "./constants";

const wepayClient = getClient(WEPAY_URL);

// get payment methods
export const getPaymentMethods = async ({ organizationId }) => {
  return new Promise(async (res, rej) => {
    try {
      const result = await wepayClient.query({
        query: GetPaymentMethodsGql,
        fetchPolicy: "no-cache",
        variables: {
          organizationId,
        },
      });
      res(
        result?.data?.paymentMethods?.edges.reduce((methods, data) => {
          const {
            id,
            heartlandToken,
            metaData,
            name,
            type,
            status,
            zip,
            createdAt,
            achType,
            accountId,
            lastFour,
            isDelete,
            payoutMethod,
          } = data.node;
          methods.push({
            id,
            heartlandToken,
            name,
            type,
            status,
            zip,
            createdAt,
            achType,
            accountId,
            metaData: JSON.parse(metaData),
            lastFour,
            isDelete,
            payoutMethod,
          });

          return methods;
        }, [])
      );
    } catch (error) {
      rej(error);
    }
  });
};

// property edit page and add property page at the end of 1st step.
export const getMerchant = async (locationId: string) => {
  return new Promise(async (res, rej) => {
    try {
      const result = await wepayClient.query({
        query: merchantGql,
        variables: {
          locationId,
        },
        fetchPolicy: "no-cache",
      });
      res(result.data?.merchant);
    } catch (error) {
      rej(error);
    }
  });
};

// property edit page and add property page at the end of 1st step.
export const getLegalEntityVerification = async (locationId: string) => {
  return new Promise(async (res, rej) => {
    try {
      const result = await wepayClient.query({
        query: legalEntityVerificationGql,
        variables: {
          locationId,
        },
        // fetchPolicy: "no-cache",
      });
      console.log(result)
      res(result.data?.legalEntityVerification);
    } catch (error) {
      rej(error);
    }
  });
};

// property edit page and add property page at the end of 1st step.
export const getAccountCapibilities = async (locationId: string) => {
  return new Promise(async (res, rej) => {
    try {
      const result = await wepayClient.query({
        query: accountCapabilitiesGql,
        variables: {
          locationId,
        },
        fetchPolicy: "no-cache",
      });
      res(result.data?.accountCapabilities);
    } catch (error) {
      rej(error);
    }
  });
};

// property edit page and add property page at the end of 1st step.
/* companyType : individual */
export const createWepayLegalEntity = async ({
  organizationId,
  companyType,
  locationId,
  termsService,
  privacyPolicy,
  orgRegistrationId,
}) => {
  return new Promise(async (res, rej) => {
    try {
      const result = await wepayClient.mutate({
        mutation: createWepayLegalEntityGql,
        variables: {
          merchant: {
            organizationId,
            companyType,
            locationId,
            termsService,
            privacyPolicy,
            orgRegistrationId,
          },
        },
        fetchPolicy: "no-cache",
      });
      res(result);
    } catch (error) {
      rej(error);
    }
  });
};

export const createWepayAccount = async ({
  locationId,
  payoutMethodId,
  payoutFrequency,
}) => {
  return new Promise(async (res, rej) => {
    try {
      const result = await wepayClient.mutate({
        mutation: createWepayAccountGql,
        variables: {
          merchant: {
            locationId,
            payoutMethodId,
            payoutFrequency,
          },
        },
        fetchPolicy: "no-cache",
      });
      res(result?.data?.createWepayAccount?.response);
    } catch (error) {
      rej(error);
    }
  });
};

export const deletePaymentMethod = async ({ id, payoutMethod }) => {
  return new Promise(async (res, rej) => {
    try {
      const result = await wepayClient.mutate({
        mutation: DeletePaymentMethodGql,
        variables: {
          merchant: {
            id,
            payoutMethod,
          },
        },
        fetchPolicy: "no-cache",
      });
      res(result?.data?.deletePaymentMethod?.response);
    } catch (error) {
      rej(error);
    }
  });
};
