import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Datetime from "react-datetime";
import { Label, Icon, Form } from "semantic-ui-react";
import "react-datetime/css/react-datetime.css";

import { getClient } from "../../init-apollo-googleFn";
import { CALENDAR_SERVICE_URL } from "../../utils/constants";
import { createEventGql } from "../../store/calendar/calendar";
import "./Schedule.scss";
import {
  toastFailMsg,
  toastSuccessMsg,
  validateEmail,
} from "../../utils/common";
import "./CreateEventModal.scss";
import moment from "moment";

const calendarClient = getClient(CALENDAR_SERVICE_URL);

const validationSchema = Yup.object().shape({
  personId: Yup.string().required(),
  locationId: Yup.string().required(),
  eventName: Yup.string().required("Schedule event title is required"),
  description: Yup.string().required("Additional Note is required"),
  start: Yup.string().required(),
  end: Yup.string().required(),
  calendarTimeZone: Yup.string().required(),
  attendees: Yup.array().of(Yup.string()),
  addToGoogleCalendar: Yup.boolean().required(),
});

const TIMER_FIELDS = {
  START: "start",
  END: "end",
};

const defaultTimeSettings = {
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
};

const CreateEventModal = ({
  isOpen,
  toggleModal,
  locationId,
  personId,
  activeDate,
}) => {
  const [loader, setLoader] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [tempAttendee, setTempAttendee] = useState("");
  const [isOpenDate, setIsOpenDate] = useState(false);
  const [isOpenStartTime, setIsOpenStartTime] = useState(false);
  const [isOpenEndTime, setIsOpenEndTime] = useState(false);
  const [date, setDate] = useState(moment());
  const [start, setStart] = useState(moment().set(defaultTimeSettings));
  const [end, setEnd] = useState(
    moment().set({ ...defaultTimeSettings, minutes: 30 })
  );

  useEffect(() => {
    // close open pickers by outline click

    const listenerFunc = (event) => {
      if (!document.getElementById("pick-date").contains(event.target)) {
        if (isOpenDate) setIsOpenDate(false);
      }
      if (!document.getElementById("pick-start-time").contains(event.target)) {
        if (isOpenStartTime) setIsOpenStartTime(false);
      }
      if (!document.getElementById("pick-end-time").contains(event.target))
        if (isOpenEndTime) setIsOpenEndTime(false);
    };

    document.addEventListener("click", listenerFunc);

    return () => {
      document.removeEventListener("click", listenerFunc);
    };
  }, [isOpenDate, isOpenStartTime, isOpenEndTime]);

  useEffect(() => {
    if (date !== activeDate && moment(activeDate).isAfter(moment.now()))
      handleDateChange(activeDate);
  }, [activeDate]);

  const createMutation = async (values) => {
    setLoader(true);
    try {
      const res = await calendarClient.mutate({
        mutation: createEventGql,
        variables: { package: values },
      });
      if (
        res.data.addGeneralEvent &&
        res.data.addGeneralEvent.response === "200"
      ) {
        toastSuccessMsg("Event created!");
        resetForm();
        setAttendees([]);
      }
    } catch (error) {
      //
      console.log(error);
      if (error?.graphQLErrors.length > 0) {
        toastFailMsg(error.graphQLErrors[0].message);
      }
    } finally {
      setLoader(true);
    }
  };

  const handleDateChange = (d) => {
    setDate(d);
    const dateConfig = {
      year: d.get("year"),
      month: d.get("month"),
      date: d.get("date"),
    };
    setStart(start.set(dateConfig));
    setEnd(end.set(dateConfig));
  };

  const handleTimeChange = (field) => {
    return (timer) => {
      const optionalDate = timer.set({
        year: date.get("year"),
        month: date.get("month"),
        date: date.get("date"),
      });

      if (field === TIMER_FIELDS.START) {
        setStart(optionalDate);
      }

      if (field === TIMER_FIELDS.END) {
        setEnd(optionalDate);
      }
    };
  };

  const AddAttendees = (event) => {
    if (event.keyCode === 13) {
      if (attendees.includes(tempAttendee)) {
        toastFailMsg("This email address already exists!");
      } else if (!validateEmail(tempAttendee)) {
        toastFailMsg("This email address is not valid!");
      } else {
        setAttendees([...attendees, tempAttendee]);
        setTempAttendee("");
      }
    }
  };

  useEffect(() => {
    setFieldValue("attendees", attendees);
  }, [attendees]);

  useEffect(() => {
    const dateConfig = {
      year: date.get("year"),
      month: date.get("month"),
      date: date.get("date"),
    };

    setFieldValue(
      TIMER_FIELDS.START,
      start.set(dateConfig).format("MM/DD/YYYY HH:mm:ss")
    );

    if (moment(start.set(dateConfig)).isSameOrAfter(end)) {
      const msg = "Start time should be before end time!";
      setFieldError(TIMER_FIELDS.START, msg);
    }
  }, [start, date]);

  useEffect(() => {
    const dateConfig = {
      year: date.get("year"),
      month: date.get("month"),
      date: date.get("date"),
    };

    setFieldValue(
      TIMER_FIELDS.END,
      end.set(dateConfig).format("MM/DD/YYYY HH:mm:ss")
    );
    if (moment(end.set(dateConfig)).isSameOrBefore(start)) {
      const msg = "End time should be after start time!";
      setFieldError(TIMER_FIELDS.END, msg);
    }
  }, [end, date]);

  const deleteAttendee = (value) => {
    setAttendees(attendees.filter((v) => v !== value));
  };

  const {
    values,
    errors,
    setFieldValue,
    setFieldError,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      personId,
      locationId,
      eventName: "",
      description: "",
      addToGoogleCalendar: true,
      calendarTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      attendees: [],
    },
    validationSchema,
    onSubmit: (values) => {
      if (date.isBefore(moment.now())) {
        toastFailMsg("You have to set future date for event!");
      } else if (start.isAfter(end)) {
        toastFailMsg(
          "You have to set start time should be earlier then end time!"
        );
      } else {
        createMutation(values);
      }
    },
  });

  return (
    <div
      className={`modal fade create-event-modal ${isOpen && "show"}`}
      tabindex="-1"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-title">
            Add Schedule <Icon name="delete" onClick={() => toggleModal()} />
          </div>

          <div className="modal-body">
            <form className="event-create-form">
              <div className="form-row">
                <label htmlFor="eventName">Schedule Event *</label>
                <div className="input">
                  <input
                    type="text"
                    id="eventName"
                    value={values.eventName}
                    onChange={handleChange("eventName")}
                  />
                </div>
              </div>
              <div className="form-row">
                <label onClick={() => setIsOpenDate(true)}>Date *</label>
                <div>
                  <div id="pick-date">
                    <div className="input" onClick={() => setIsOpenDate(true)}>
                      {moment(date).format("MMM DD, YYYY")}
                    </div>
                    <Datetime
                      viewMode="days"
                      className="pick-date"
                      input={false}
                      value={date}
                      onChange={handleDateChange}
                      open={isOpenDate}
                      initialValue={activeDate}
                      initialViewDate={activeDate}
                      dateFormat={"MM/DD/YYYY"}
                      timeFormat={false}
                    />
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col xs-12 col-sm-6">
                  <label onClick={() => setIsOpenStartTime(true)}>
                    Start Time *
                  </label>
                  <div>
                    <div id="pick-start-time">
                      <div
                        className="input"
                        onClick={() => setIsOpenStartTime(true)}
                      >
                        {moment(start).format("HH:mm A")}
                      </div>
                      <Datetime
                        viewMode="time"
                        className="pick-start-time"
                        input={false}
                        value={start}
                        open={isOpenStartTime}
                        dateFormat={false}
                        onChange={handleTimeChange(TIMER_FIELDS.START)}
                        timeFormat={"HH:mm A"}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <label onClick={() => setIsOpenEndTime(true)}>
                    End Time *
                  </label>
                  <div>
                    <div id="pick-end-time">
                      <div
                        className="input"
                        onClick={() => setIsOpenEndTime(true)}
                      >
                        {moment(end).format("HH:mm A")}
                      </div>
                      <Datetime
                        viewMode="time"
                        className="pick-end-time"
                        input={false}
                        value={end}
                        open={isOpenEndTime}
                        onChange={handleTimeChange(TIMER_FIELDS.END)}
                        dateFormat={false}
                        timeFormat={"HH:mm A"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <label htmlFor="attendees">Attendees</label>
                <div className="tags">
                  {attendees.map((l, i) => (
                    <Label key={i}>
                      {l}{" "}
                      <Icon name="delete" onClick={() => deleteAttendee(l)} />
                    </Label>
                  ))}
                </div>
                <div className="input">
                  <input
                    type="text"
                    id="attendees"
                    value={tempAttendee}
                    onChange={(e) => setTempAttendee(e.target.value)}
                    onKeyDown={AddAttendees}
                  />
                </div>
              </div>

              <div className="form-row">
                <label htmlFor="description">Additional Notes *</label>
                <div className="input multiline">
                  <textarea
                    id="description"
                    type="text"
                    maxLength={255}
                    rows={5}
                    value={values.description}
                    onChange={handleChange("description")}
                  />
                </div>
              </div>

              <div className="form-row">
                <Form.Checkbox
                  checked={values.addToGoogleCalendar}
                  onClick={() =>
                    setFieldValue(
                      "addToGoogleCalendar",
                      !values.addToGoogleCalendar
                    )
                  }
                  label="Add to google calendar"
                />
              </div>

              <button type="button" onClick={handleSubmit}>
                ADD SCHEDULE
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEventModal;
