import React from "react";
import { amountFormatter } from "../../utils/common";

import "./ApplicationPropertyCard.scss";
const ApplicationPropertyCard = ({ property }) => {
  const { streetOne, streetTwo, city, state, postcode, unitNum } =
    property.location;
  const {
    bedrooms,
    bathrooms,
    size,
    photos,
    price,
    petsPermitted,
    maxOccupancy,
    securityDeposit,
    cleaningDeposit,
  } = property.place;

  return (
    <div className="card">
      <div className="card-body p-0">
        <div className="grid-container">
          <div>
            <img src={photos[0].url} alt="Property" className="property-img" />
          </div>
          <div>
            <div className="address">
              {streetOne} {streetTwo} <br />
              {city}, {state} {postcode} <br />
              {unitNum && <span>Unit: {unitNum}</span>}
            </div>
            <span className="property-size__item">
              <img src="assets/icons/bedrooms.svg" alt="bath icon" />
              <span> {bedrooms}</span>
            </span>
            <span className="property-size__item">
              <img src="assets/icons/bath.svg" alt="bedroom icon" />
              <span> {bathrooms}</span>
            </span>
            <span className="property-size__item">
              <img src="assets/icons/size.svg" alt="size icon" />
              <span> {size} sq.ft</span>
            </span>
          </div>
          <div className="responsive-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Rent</th>
                  <th>Security</th>
                  <th>Cleaning</th>
                  <th>Total</th>
                  <th>Empty Since</th>
                  <th>Max Occupancy</th>
                  <th>Pets</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-target="Rent">{amountFormatter(price)}</td>
                  <td data-target="Security">
                    {amountFormatter(securityDeposit)}
                  </td>
                  <td data-target="Cleaning">
                    {amountFormatter(cleaningDeposit)}
                  </td>
                  <td data-target="Total">
                    {amountFormatter(
                      price * 2 + securityDeposit + cleaningDeposit
                    )}
                  </td>
                  <td data-target="Empty Since">?</td>
                  <td data-target="Max Occupancy">{maxOccupancy || "?"}</td>
                  <td data-target="Pets">{petsPermitted || "?"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    // <div className="card ">
    //   <div className="card-body application-property-card">
    //     <div className="row">
    //       <div className="col">
    //         <div className="container p-0 m-0 d-block">
    //           <div className="application-property-card__img-box">
    //             <img src={photos[0].url} alt="Property" className="property-img" />
    //           </div>
    //           <div className="application-property-card__title-box">
    //             <h3 className="heading-md font-black application-property-card__title">
    //               <address>
    //                 {streetOne} {streetTwo} <br />
    //                 {city}, {state} {postcode} <br />
    //                 {unitNum && <span>Unit: {unitNum}</span>}

    //                 {/* TEMP */}
    //                 <span>Unit: 5</span>
    //               </address>
    //             </h3>
    //             <div className="property-size">
    //               <div className="property-size__item">
    //                 <img src='assets/icons/bedrooms.svg' alt="bath icon" />
    //                 <span> {bedrooms}</span>
    //               </div>
    //               <div className="property-size__item">
    //                 <img src='assets/icons/bath.svg' alt="bedroom icon" />
    //                 <span> {bathrooms}</span>
    //               </div>
    //               <div className="property-size__item">
    //                 <img src='assets/icons/size.svg' alt="size icon" />
    //                 <span> {size} sq.ft</span>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-md-auto">
    //         <div className="property-details">
    //           <div className="detail-container">
    //             <div className="responsive-table">
    //               <table className="table">
    //                 <thead>
    //                   <tr>
    //                     <th>Rent</th>
    //                     <th>Security</th>
    //                     <th>Cleaning</th>
    //                     <th>Total</th>
    //                     <th>Empty Since</th>
    //                     <th>Max Occupancy</th>
    //                     <th>Pets</th>
    //                   </tr>
    //                 </thead>
    //                 <tbody>
    //                   <tr>
    //                     <td data-target="Rent">{amountFormatter(price)}</td>
    //                     <td data-target="Security">{amountFormatter(securityDeposit)}</td>
    //                     <td data-target="Cleaning">{amountFormatter(cleaningDeposit)}</td>
    //                     <td data-target="Total">{amountFormatter((price * 2) + securityDeposit + cleaningDeposit)}</td>
    //                     <td data-target="Empty Since">?</td>
    //                     <td data-target="Max Occupancy">{maxOccupancy || "?"}</td>
    //                     <td data-target="Pets">{petsPermitted || "?"}</td>
    //                   </tr>
    //                 </tbody>
    //               </table>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="button-container">
    //       <div className="btn-group" role="group">
    //         <button
    //           id="btnGroupDrop1"
    //           type="button"
    //           className="btn btn-primary dropdown-toggle"
    //           data-toggle="dropdown"
    //           aria-haspopup="true"
    //           aria-expanded="false"
    //         >
    //           View
    //             </button>
    //         <div
    //           className="dropdown-menu dropdown-menu-right"
    //           aria-labelledby="btnGroupDrop1"
    //         >
    //           <a className="dropdown-item" to="#!"> {/*eslint-disable-line*/}
    //             Property
    //               </a>
    //           <a className="dropdown-item" to="#!"> {/*eslint-disable-line*/}
    //             Listings
    //               </a>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ApplicationPropertyCard;
