import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Delinquencysql } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { Table } from "../../components/Tables/RentRollData";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { ACCOUNTING_REPORTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss";
import { Button } from "semantic-ui-react";
import mixpanel from "mixpanel-browser";
import moment from "moment";

const invoiceClient = getClient(ACCOUNTING_REPORTING);

class Delinquency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      rollCache: [],
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Rent Roll",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  CapitalizeFirstLetter = ({ value }) => {
    return (
      <p style={{ textTransform: "capitalize", color: "black" }}>{value}</p>
    );
  };

  getLedger = (data) => {

    try {
      this.setState({ loading: true });
      invoiceClient
        .query({
          query: Delinquencysql,
          variables: {
            locationId: this.props.selectedPrimary.node.id,
            slLocationId:this.props.selectedPrimary.node.slLocationId
          },
        })
        .then((res) => {
          if (res.data && res.data.delinquency) {
            const dataToPass = get(res, "data.delinquency", []).map((k, i) => {
              const amount = `$${Number.parseFloat(k["Amount Receivable"]).toLocaleString('en')}`;
              const outMove = `${k["Move In"]?moment(k["Move In"]).format("MM/DD/YYYY"):''}`;
              const inMove = `${k["Move In"]?moment(k["Move Out"]).format("MM/DD/YYYY"):''}`;
              return (({Unit, Name}) => ({Unit, amount, Name, outMove, inMove }))(k)
            });
            this.setState({
              rollCache: dataToPass,
            });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  dataPush = (response) => {
    this.setState((prevState) => ({
      journalCache: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.journalCache,
      ],
    }));
  };
  updateInvoice = (status, invoiceId) => {
    const { journalCache } = this.state;
    const array = [];
    if (status === "Delete") {
      this.setState({
        journalCache: journalCache.filter(
          (invoice) => invoice._id !== invoiceId
        ),
      });
    } else {
      journalCache.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      this.setState({ journalCache: array });
    }
  };

  render() {
    const { loading } = this.state;
    const mainHeader = ["Unit", "Amount", "Tenant", "Move In", "Move Out"];
    
    return (
      <>
        <main className="main-content balance-sheet-layout" role="main">
          <div className="row mb-4">
            <div className="col-md-12">
              <div className="page-header flex-row">
                <div className="d-flex align-items-center">
                  <div>
                    <div className="page-header-title pl-0">Delinquency</div>
                  </div>
                </div>
                <div className="d-flex align-items-end">
                  <Button
                  onClick={() => window.print()}
                  compact
                    className="ml-4 noPrint noPrint-button"
                    style={{
                      backgroundImage:
                        "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                      
                    }}
                  >
                    Print
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <Loader text inTable />
          ) : (
            <>
              <div className="balance-sheet-table">
                {/* <Table rollCache={this.state.rollCache && this.state.rollCache} /> */}
                <GeneralAccountingTable
                  mainHeader={mainHeader}
                  mainCellData={this.state.rollCache}
                />
              </div>
            </>
          )}
          {/* </div> */}
        </main>
      </>
    );
  }
}

export default withApollo(Delinquency);
