import React from "react";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Dropdown, Grid } from "semantic-ui-react";
import { get, startCase } from "lodash";
import TotalUnitModal from "./TotalUnitModal";
import UnitImages from "./UnitImages";
import UnitAmenities from "./UnitAmenities";
import UnitFloorPlan from "./UnitFloorPlan";
import {
  NUMERIC_REGEX,
  PRICE_REGEX,
  UNITAPI,
  UNIT_AVAILABLITY_STATUS,
  UNIT_TYPE_API,
} from "../../../utils/constants";
import AddFloorPlan from "./AddFloorPlan";
import { getClient } from "../../../init-apollo-googleFn";
import { createBulkUnits, updateBulkUnits } from "../../../store/units/units";
import {
  parseGraphQLErrors,
  toastFailMsg,
  toastSuccessMsg,
} from "../../../utils/common";
import { unitType } from "../../../store/person/properties";

const unitClient = getClient(UNITAPI);
const unitTypeManager = getClient(UNIT_TYPE_API);

const UnitRowActions = ({ onCopy, onDelete }) => {
  const [openDropDown, setOpenDropDown] = React.useState(false);

  const onCopyAction = () => {
    onCopy();
    setOpenDropDown(false);
  };

  const onDeleteAction = () => {
    onDelete();
    setOpenDropDown(false);
  };

  /* eslint-disable */
  return (
    <div className="custom-dropdown">
      <a className="more-btn" onClick={() => setOpenDropDown(!openDropDown)}>
        <img src="assets/img/icons-more.svg" alt="icons-more" />
      </a>
      {openDropDown && (
        <div className="dropdown-list d-block">
          <div className="list-item" onClick={onCopyAction}>
            <a>
              <img src="assets/img/shared-icons.svg" alt="shared-icons" /> Copy
              data in selected units
            </a>
          </div>
          <div className="list-item red-text" onClick={onDeleteAction}>
            <a>
              <img
                src="assets/img/icons-red-delete.svg"
                alt="icons-red-delete"
              />{" "}
              Delete Unit
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

const UnitRow = ({
  index,
  amenityTypes,
  onCopy,
  onDelete,
  register,
  errors,
  control,
  onAddNewAmenities,
  ...formMethods
}) => {
  const [isOpenImageModal, setIsOpenImageModal] = React.useState(false);
  const [isOpenAmenitiesModal, setIsOpenAmenitiesModal] = React.useState(false);
  const [amenities, setAmenities] = React.useState([]);

  const currentUnitImages = formMethods.watch(`unitList[${index}].photos`);
  const unitAmenities = formMethods.watch(`unitList[${index}].amenities`) || [];
  React.useEffect(() => {
    setAmenities(
      amenityTypes.map((a) => ({
        id: a.node.id,
        name: a.node.name,
        selected: unitAmenities.includes(a.node.id),
      }))
    );
  }, [amenityTypes, unitAmenities]);

  const handleNewAmenities = async (value) => {
    const response = await onAddNewAmenities(value);
    return response.id;
  };

  const onChangeAmenities = (_, i) => {
    const updatedAmenities = [...amenities];
    updatedAmenities[i].selected = !updatedAmenities[i].selected;
    setAmenities(updatedAmenities);
    formMethods.setValue(
      `unitList[${index}].amenities`,
      updatedAmenities.filter((a) => a.selected).map((a) => a.id)
    );
  };

  const selectedAmenities = React.useMemo(
    () => amenities.filter((a) => a.selected),
    [amenities]
  );

  return (
    <div className="unit-row">
      <div className="unit-inner">
        <div className="block">
          <input
            type="checkbox"
            name={`unitList[${index}].selected`}
            style={{ width: "unset" }}
            ref={register()}
          />
        </div>
        <div className="block">
          <Controller
            name={`unitList[${index}].photos`}
            control={control}
            defaultValue={[]}
            render={() => (
              <UnitImages
                name={`unitList[${index}].photos`}
                isOpen={isOpenImageModal}
                onClose={() => setIsOpenImageModal(false)}
                existingImages={currentUnitImages}
                {...formMethods}
              />
            )}
          />

          {Array.isArray(currentUnitImages) && currentUnitImages.length > 0 ? (
            <div
              className="upload-btn after-upload"
              onClick={() => setIsOpenImageModal(true)}
            >
              {currentUnitImages.map((img, i) => (
                <div className="img-box" key={i.toString()}>
                  <div className="inner-box">
                    <img src={img} alt="renter-bg" />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <button
              type="button"
              className="upload-btn"
              onClick={() => setIsOpenImageModal(true)}
            >
              Upload
            </button>
          )}
        </div>
        <div className="block">
          <input
            type="number"
            name={`unitList[${index}].number`}
            ref={register({
              required: "Unit No. is required.",
              pattern: {
                value: NUMERIC_REGEX,
                message: "Please enter valid number.",
              },
              setValueAs: (value) => String(value),
            })}
          />
          <ErrorMessage
            errors={errors}
            name={`unitList[${index}].number`}
            as="span"
            className="error-msg"
          />
        </div>
        <div className="block">
          <input
            type="number"
            name={`unitList[${index}].level`}
            ref={register({
              required: "Floor No. is required.",
              pattern: {
                value: NUMERIC_REGEX,
                message: "Please enter valid number.",
              },
              setValueAs: (value) => String(value),
            })}
          />
          <ErrorMessage
            errors={errors}
            name={`unitList[${index}].level`}
            as="span"
            className="error-msg"
          />
        </div>
        <div className="block">
          <div className="has-float-label select-float-label floor-plan-container">
            <Controller
              name={`unitList[${index}].unitTypesId`}
              control={control}
              defaultValue=""
              rules={{ required: "Floor plan is required." }}
              render={() => (
                <UnitFloorPlan
                  namePrefix={`unitList[${index}]`}
                  {...formMethods}
                />
              )}
            />
          </div>
          <ErrorMessage
            errors={errors}
            name={`unitList[${index}].unitTypesId`}
            as="span"
            className="error-msg"
          />
        </div>
        <div className="block">
          <input
            type="number"
            name={`unitList[${index}].bedrooms`}
            disabled
            ref={register({
              required: "No. of beds is required.",
              valueAsNumber: true,
              pattern: {
                value: NUMERIC_REGEX,
                message: "Please enter valid number.",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name={`unitList[${index}].bedrooms`}
            as="span"
            className="error-msg"
          />
        </div>
        <div className="block">
          <input
            type="number"
            name={`unitList[${index}].bathrooms`}
            disabled
            ref={register({
              required: "No. of baths is required.",
              valueAsNumber: true,
              pattern: {
                value: NUMERIC_REGEX,
                message: "Please enter valid number.",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name={`unitList[${index}].bathrooms`}
            as="span"
            className="error-msg"
          />
        </div>
        <div className="block">
          <input
            type="number"
            name={`unitList[${index}].sqft`}
            ref={register({
              required: "Size of unit is required.",
              valueAsNumber: true,
              pattern: {
                value: PRICE_REGEX,
                message: "Please enter valid number.",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name={`unitList[${index}].sqft`}
            as="span"
            className="error-msg"
          />
        </div>
        <div className="block">
          <input
            type="number"
            name={`unitList[${index}].price`}
            ref={register({
              required: "Price of unit is required.",
              valueAsNumber: true,
              pattern: {
                value: PRICE_REGEX,
                message: "Please enter valid price.",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name={`unitList[${index}].price`}
            as="span"
            className="error-msg"
          />
        </div>
        <div className="block">
          <div className="has-float-label select-float-label">
            <Controller
              name={`unitList[${index}].status`}
              control={control}
              defaultValue=""
              rules={{ required: "Status is required." }}
              render={(props) => (
                <Dropdown
                  fluid
                  selection
                  options={UNIT_AVAILABLITY_STATUS}
                  selectOnBlur={false}
                  value={props.value}
                  text={props.text}
                  onChange={(e, data) => props.onChange(data.value)}
                />
              )}
            />
          </div>
          <ErrorMessage
            errors={errors}
            name={`unitList[${index}].status`}
            as="span"
            className="error-msg"
          />
        </div>
        <div className="block text">
          <Controller
            name={`unitList[${index}].amenities`}
            control={control}
            defaultValue={[]}
            render={() => (
              <UnitAmenities
                name={`unitList[${index}].amenities`}
                isOpen={isOpenAmenitiesModal}
                onClose={() => setIsOpenAmenitiesModal(false)}
                options={amenities}
                onChange={onChangeAmenities}
                onAddNew={handleNewAmenities}
              />
            )}
          />
          <button
            type="button"
            className="unit-select-btn"
            onClick={() => setIsOpenAmenitiesModal(true)}
          >
            {selectedAmenities.length || "Select"}
          </button>
        </div>
      </div>
      <UnitRowActions onCopy={onCopy} onDelete={onDelete} />
    </div>
  );
};

const UnitInfoForm = ({
  units,
  amenityTypes,
  onNextStep,
  isEditing,
  handleSubmitting,
  locationId,
  onCancelClick,
  onAddNewAmenities,
  onSave,
  unitData,
}) => {
  const [totalUnits, setTotalUnits] = React.useState(units.length || 1);
  const [addMultipleCount, setAddMultipleCount] = React.useState("");
  const [openUnitsModal, setOpenUnitsModal] = React.useState(!units.length);
  const [isNewPlanModal, setIsNewPlanModal] = React.useState(false);
  const [floorPlanOptions, setFloorPlanOptions] = React.useState([]);
  const formMethods = useForm({ mode: "onChange" });
  const { handleSubmit, setValue, getValues, register, unregister } =
    formMethods;

  const initializeEditForm = (data) => {
    data.map((unit, index) => {
      Object.entries(unit).map(([key, value]) => {
        // console.log(key, value)
        const fieldName = `unitList[${index}].${key}`;
        // console.log(fieldName)
        switch (key) {
          case "id":
            register(fieldName);
            setValue(fieldName, value);
            break;
          case "unitType":
            break;
          case "status": {
            let text = startCase(value).replace(" ", "");
            if (value === "off_the_market") text = "OffTheMarket";
            setValue(fieldName, text);
            break;
          }
          case "unitTypesId":
            setValue(fieldName, (unit.unitType && unit.unitType.id) || value);
            break;
          case "amenities":
            setValue(fieldName, value || []);
            break;
          default:
            setValue(fieldName, value);
            break;
        }
      });
    });
  };

  /* eslint-disable */
  React.useEffect(() => {
    if (
      unitData &&
      Object.keys(unitData).length !== 0 &&
      unitData.unitList.length
    ) {
      setTotalUnits(unitData.unitList.length || 1);
      initializeEditForm(unitData.unitList);
    } else if (units.length) {
      setTotalUnits(units.length || 1);
      initializeEditForm(units);
    }
  }, [
    units,
    unitData && Object.keys(unitData).length !== 0 && unitData.unitList.length,
  ]);

  React.useEffect(() => {
    unitTypeManager
      .query({ query: unitType, variables: { locationId } })
      .then((res) => {
        const plans = get(res, "data.unitType.edges");
        if (plans) {
          setFloorPlanOptions(
            plans.map(({ node }) => ({
              ...node,
              key: node.id,
              value: node.id,
              text: node.type,
            }))
          );
        }
      })
      .catch((error) => {
        toastFailMsg(parseGraphQLErrors(error));
      });
  }, []);
  /* eslint-enable */

  const onSubmit = () => {
    const data = getValues();
    handleSubmitting(true);
    let input = data.unitList.reduce((result, item) => {
      const updatedItem = { ...item, locationId };
      delete updatedItem.selected;
      result.push(updatedItem);
      return result;
    }, []);

    input = input.filter((data) => data.unitTypesId);
    unitClient
      .mutate({
        mutation: units.length ? updateBulkUnits : createBulkUnits,
        variables: { input },
      })
      .then((response) => {
        if (response.data) onNextStep();
      })
      .catch((e) => toastFailMsg(parseGraphQLErrors(e)))
      .finally(() => handleSubmitting(false));
  };

  const handleTotalUnitChange = (count) => {
    setTotalUnits(Number(count));
    setOpenUnitsModal(false);
  };

  const onAddNewUnit = (addNew = 1) => {
    setTotalUnits((prev) => prev + addNew);
  };

  const onAddMultipleUnit = (visible) => {
    const unitOverlay = document.querySelector(".unit-overlay");
    const unitBox = document.querySelector(".add-unit-box");
    if (unitOverlay) {
      if (visible) unitOverlay.classList.add("active");
      else unitOverlay.classList.remove("active");
    }
    if (unitBox) {
      if (visible) unitBox.classList.add("active");
      else unitBox.classList.remove("active");
    }
  };

  const onSaveMultipleUnit = () => {
    onAddNewUnit(addMultipleCount ? Number(addMultipleCount) : 0);
    onAddMultipleUnit(false);
    setAddMultipleCount("");
  };

  /* eslint-disable */
  const onCopy = (fromIndex) => {
    if (fromIndex > -1) {
      const { unitList } = getValues();
      const updatedUnitList = unitList.reduce((result, item, index) => {
        let updatedItem = item;
        // if (index > fromIndex) {
        if (item.selected) {
          updatedItem = {
            ...unitList[fromIndex],
            number:
              index === 0
                ? Number(item.number)
                : Number(result[index - 1].number) + 1,
          };
          delete updatedItem.id;
          setValue(`unitList[${index}]`, updatedItem, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
        result.push(updatedItem);
        return result;
      }, []);
      // setValue('unitList', updatedUnitList, { shouldDirty: true, shouldValidate: true });
    }
  };
  /* eslint-enable */

  const saveAsDraft = () => {
    const data = getValues();
    toastSuccessMsg("Saved");
    onSave(data);
  };

  const onDelete = (fromIndex) => {
    if (fromIndex > -1) {
      const { unitList } = getValues();
      unregister(`unitList[${fromIndex}]`);
      const updatedUnitList = [...unitList].filter(
        (_, index) => index !== fromIndex
      );
      setValue("unitList", updatedUnitList);
      setTotalUnits((prev) => prev - 1);
    }
  };

  const handleNewFloorPlan = (data) => {
    const newPlan = {
      ...data,
      key: data.id,
      text: data.type,
      value: data.id,
    };
    setFloorPlanOptions((prev) => [newPlan, ...prev]);
    setIsNewPlanModal(false);
  };

  return (
    <>
      {openUnitsModal && unitData && Object.keys(unitData).length === 0 && !isEditing &&(
        <TotalUnitModal
          isOpen={openUnitsModal}
          totalUnits={totalUnits}
          onClose={() => setOpenUnitsModal(false)}
          onSubmit={handleTotalUnitChange}
        />
      )}
      {isNewPlanModal && (
        <AddFloorPlan
          isOpen={isNewPlanModal}
          locationId={locationId}
          onClose={() => setIsNewPlanModal(false)}
          onCreateNew={handleNewFloorPlan}
        />
      )}
      <div className="white-block csv-block">
        <div className="head">Total number of units</div>
        <button type="button" className="btn next-btn">
          Upload CSV
        </button>
      </div>
      <div className="unit-row header-row">
        <div className="unit-inner">
          <div className="block text">Select</div>
          <div className="block text">Media</div>
          <div className="block text">Unit No.</div>
          <div className="block text">Floor</div>
          <div className="block text">Floor Plan</div>
          <div className="block text">Bedroom(s)</div>
          <div className="block text">Bathroom(s)</div>
          <div className="block text">Size (Sq. Ft.)</div>
          <div className="block text">Price/month</div>
          <div className="block text">Status</div>
          <div className="block text">Unit Amenities</div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {Array(totalUnits)
          .fill("")
          .map((unit, index) => (
            <UnitRow
              key={index.toString()}
              unit={unit}
              index={index}
              amenityTypes={amenityTypes}
              onCopy={() => onCopy(index)}
              onDelete={() => onDelete(index)}
              setIsNewPlanModal={setIsNewPlanModal}
              locationId={locationId}
              floorPlanOptions={floorPlanOptions}
              onAddNewAmenities={onAddNewAmenities}
              {...formMethods}
            />
          ))}
        <div className="unit-btn">
          <button
            type="button"
            id='purple'
            className="btn cancel-btn"
            onClick={() => onAddNewUnit()}
          >
            Add new unit
          </button>
          <button
            type="button"
            id='purple'
            className="btn cancel-btn"
            onClick={() => onAddMultipleUnit(true)}
          >
            Add multiple units
          </button>
          <div
            className="unit-overlay"
            onClick={() => onAddMultipleUnit(false)}
          />
          <div className="add-unit-box">
            <h2>Add multiple units</h2>
            <div className="form-group">
              <div className="has-float-label">
                <input
                  className="form-control"
                  type="number"
                  placeholder="Total number of units"
                  autoComplete="nope"
                  value={addMultipleCount}
                  onChange={(event) => setAddMultipleCount(event.target.value)}
                />
                <label htmlFor="totalUnits">Total number of units</label>
              </div>
            </div>
            <button
              type="button"
              className="btn modal-save-button"
              onClick={onSaveMultipleUnit}
            >
              Add Units
            </button>
          </div>
        </div>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <div className="btn-block">
            <div>
              <button
                type="button"
                className="btn cancel-btn"
                onClick={onCancelClick}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn next-btn"
                onClick={saveAsDraft}
              >
                Save
              </button>
            </div>
            <div>
              <button type="submit" className="btn next-btn">
                Proceed to step 3
              </button>
            </div>
          </div>
        </Grid.Column>
      </form>
    </>
  );
};

export default UnitInfoForm;
