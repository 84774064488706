import { gql } from 'apollo-boost';
import get from 'lodash/get';
import React from 'react';
import { Button, Container } from 'semantic-ui-react';
import { PageLoader } from '../../components/Loader/PageLoader';
import { getClient } from '../../init-apollo-googleFn';
import {
  parseAddresses,
  parseGraphQLErrors,
  parsePhotosUrl,
  toastFailMsg,
} from '../../utils/common';
import { UNITAPI, THIRD_PARTY_API_URL } from '../../utils/constants';
import ThirdPartyApiListItem from './ThirdPartyApiListItem';
import { decodeBase64ID } from '../../utils/base64';

const unitClient = getClient(UNITAPI);
const thirdPartyClient = getClient(THIRD_PARTY_API_URL);

const unitsGql = gql`
  query units($locationId: String!) {
    units(locationId: $locationId) {
      edges {
        node {
          id
          locationId
          unitTypesId
          level
          building
          number
          description
          bathrooms
          bedrooms
          sqft
          price
          maxOccupancy
          petsPermitted
          photos
          amenities
          deposit
          nonRefundable
          isDelete
          status
          unitType {
            id
          }
        }
      }
    }
  }
`;

const recordsGql = gql`
  {
    records {
      id
      record_id
      record_type
      status
    }
  }
`;

const createRecordsGql = gql`
  mutation createRecord($input: RecordInput!) {
    createRecord(input: $input) {
      id
      record_id
      record_type
      status
    }
  }
`;
const setRecordsGql = gql`
  mutation setRecord($status: Boolean!, $id: Int!) {
    setRecord(status: $status, id: $id) {
      id
      record_id
      record_type
      status
    }
  }
`;

const statusFilters = [
  {
    title: 'Active',
    value: 'active',
  },
  {
    title: 'Inactive',
    value: 'passive',
  },
];

const StatusGroup = React.memo(({ statusFilter, setStatusFilter }) => {
  return (
    <Button.Group compact size="mini">
      {statusFilters.map((status, i) => {
        return (
          <>
            <Button
              compact
              size="mini"
              positive={statusFilter == status.value}
              onClick={() => setStatusFilter(status.value)}
            >
              {status.title}
            </Button>
            {i !== statusFilters.length - 1 && <Button.Or text="or" />}
          </>
        );
      })}
    </Button.Group>
  );
});

const ThirdPartyApi = ({
  history,
  primaryLocations,
  selectedPrimary,
  user,
  organizationId,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const [propertyList, setPropertyList] = React.useState([]);
  const [thirdPartyRecords, setThirdPartyRecords] = React.useState({});
  const [statusFilter, setStatusFilter] = React.useState(
    statusFilters[0].value
  );

  const getUnits = async () => {
    setIsLoading(true);
    try {
      const response = await unitClient.query({
        query: unitsGql,
        variables: {
          locationId: selectedPrimary.node.id,
        },
        fetchPolicy: 'no-cache',
      });
      const locations = get(response, 'data.units.edges', []);
      if (locations) {
        const locationNodes = locations.map((l) => l.node);
        setPropertyList(locationNodes);
      }
    } catch (error) {
      toastFailMsg(
        parseGraphQLErrors(error).toString() || 'Unable to get property lists.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getRecords = async () => {
    setIsLoading(true);
    try {
      const records = await thirdPartyClient.query({
        query: recordsGql,
        fetchPolicy: 'no-cache',
      });

      const recordData = records.data.records.reduce(
        (a, b) => {
          if (a[b.record_type]) {
            a[b.record_type][b.record_id] = b;
          }

          return a;
        },
        { location_id: {}, unit_id: {} }
      );

      setThirdPartyRecords(recordData);
      console.log('recordData', recordData);
    } catch (error) {
      toastFailMsg(
        parseGraphQLErrors(error).toString() || 'Unable to get records.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const createRecords = async (record_id, record_type, status, callback) => {
    setIsLoading(true);

    try {
      const records = await thirdPartyClient.mutate({
        mutation: createRecordsGql,
        variables: {
          input: {
            record_id,
            record_type,
            status,
            provider: 'zillow',
            custom_data: JSON.stringify({}),
          },
        },
        fetchPolicy: 'no-cache',
      });
      callback(records.data.createRecord);
    } catch (error) {
      toastFailMsg(
        parseGraphQLErrors(error).toString() || 'Unable to get property lists.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const setRecord = async (id, status, callback) => {
    setIsLoading(true);

    try {
      const records = await thirdPartyClient.mutate({
        mutation: setRecordsGql,
        variables: {
          id,
          status,
        },
        fetchPolicy: 'no-cache',
      });
      callback(records.data.setRecord);
    } catch (error) {
      toastFailMsg(
        parseGraphQLErrors(error).toString() || 'Unable to get property lists.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getUnits();
    getRecords();
  }, [selectedPrimary.node.id]);

  const location_id = decodeBase64ID(selectedPrimary.node.id);
  const location_status =
    thirdPartyRecords.location_id?.[location_id]?.status;

  if (isLoading) return <PageLoader text />;
  return (
    <Container className="property-container">
      <div className="header-section">
        <div className="left-container">
          <div className="head">Properties</div>
          <p>
            Here's the list of your properties in the current location that you
            can activate on third-party api listings.
          </p>
        </div>
        <div className="right-container">
          <div
            style={{
              flex: 1,
              flexShrink: 1,
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 15,
            }}
          >
            <p>Toggle Status of location</p>

            <StatusGroup
              {...{
                statusFilter:
                  location_status === undefined
                    ? 'undefined'
                    : location_status
                    ? 'active'
                    : 'passive',
                setStatusFilter: async (status) => {
                  const newRecords = { ...thirdPartyRecords };
                  const statusBool = status === 'active';

                  if (newRecords.location_id[location_id] !== undefined) {
                    const record = newRecords.location_id[location_id];
                    await setRecord(record.id, statusBool, (data) => {
                      newRecords.location_id[data.record_id] = data;
                    });
                  } else {
                    await createRecords(
                      location_id,
                      'location_id',
                      statusBool,
                      (data) => {
                        newRecords.location_id[data.record_id] = data;
                      }
                    );
                  }

                  setThirdPartyRecords(newRecords);
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="card-inner-container list-view">
        {propertyList &&
          propertyList.map((item, i) => {
            console.log(item);
            const photos = parsePhotosUrl(item.photos);

            const unit_id = decodeBase64ID(item.id);
            const status = thirdPartyRecords.unit_id?.[unit_id]?.status;

            const { templateId } =
              primaryLocations &&
              primaryLocations[i] !== undefined &&
              primaryLocations[i].node;
            return (
              <ThirdPartyApiListItem
                key={item.id}
                {...{
                  ...item,
                  photos,
                  primaryLocations,
                  organizationId,
                }}
                user={user}
                templateId={templateId}
              >
                <StatusGroup
                  {...{
                    statusFilter:
                      status === undefined
                        ? 'undefined'
                        : status
                        ? 'active'
                        : 'passive',
                    setStatusFilter: async (status) => {
                      const newRecords = { ...thirdPartyRecords };
                      const statusBool = status === 'active';

                      if (newRecords.unit_id[unit_id] !== undefined) {
                        const record = newRecords.unit_id[unit_id];
                        await setRecord(record.id, statusBool, (data) => {
                          newRecords.unit_id[data.record_id] = data;
                        });
                      } else {
                        await createRecords(
                          unit_id,
                          'unit_id',
                          statusBool,
                          (data) => {
                            newRecords.unit_id[data.record_id] = data;
                          }
                        );
                      }

                      setThirdPartyRecords(newRecords);
                    },
                  }}
                />
              </ThirdPartyApiListItem>
            );
          })}
      </div>
    </Container>
  );
};

export default ThirdPartyApi;
