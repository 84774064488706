import React from "react";
import gql from "graphql-tag";
import { WEPAY_URL } from "../../utils";
import { getClient } from "../../init-apollo-googleFn";
import "./Wepay.scss";

const wepayClient = getClient(WEPAY_URL);

const createWepayLegalEntityGql = gql`
  mutation createWepayLegalEntity($merchant: CreateWePayLegalEntityInput) {
    createWepayLegalEntity(merchant: $merchant) {
      response
    }
  }
`;

const wepayMerchantGql = gql`
  query {
    merchant {
      id
      personId
      locationId
      leaseraAccountId
      legalEntityId
      legalEntityOwnerId
      merchantCategoryCode
      merchantCategoryDetail
      paymentFrequency
      statementDescription
      accountId
      role
      isActive
      createdAt
      updatedAt
    }
  }
`;

var myAppId = "472369";
var environment = ["stage", "production"];
var apiVersion = "3.0";
var error = window.WePay.configure(environment[0], myAppId, apiVersion);
if (error) {
  console.log(error);
}
var iframe_container_id = "kyc-iframe";
var options = "";
var country = "US";
var ssn4_enabled = true;

export default function WepayKYC({ locationId }) {
  const [showIframe, setShowIframe] = React.useState(false);
  const createLegalEntity = async (token) => {
    try {
      const response = await wepayClient.mutate({
        mutation: createWepayLegalEntityGql,
        variables: {
          merchant: {
            locationId,
            token,
          },
        },
      });
      if (response?.data?.createWepayLegalEntity?.response === "200") {
        fetchMerchants();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMerchants = async () => {
    try {
      const res = await wepayClient.query({
        query: wepayMerchantGql,
        fetchPolicy: "no-cache",
      });
      if (res.data.merchant === null) {
        setShowIframe(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initIframe = () => {
    var kycIframe = window.WePay.createKycIframe(iframe_container_id, {
      country_code: country.toUpperCase(),
      ssn4_enabled: ssn4_enabled,
    });
    if (kycIframe.error_code) {
      console.log(
        "The kyc iframe has not been created, due to container id or country_code error."
      );
      console.log("error", kycIframe);
    }

    document
      .getElementById("submit-kyc-button")
      .addEventListener("click", function (event) {
        var ein = document.getElementById("text-input-business_ein");
        console.log(ein);
        kycIframe
          .tokenize()
          .then(function (response) {
            createLegalEntity(response.id);
            console.log("response", JSON.stringify(response));
          })
          .catch(function (error) {
            console.log("error", JSON.stringify(error));
          });
      });
  };

  React.useEffect(() => {
    fetchMerchants();
  }, []);

  React.useEffect(() => {
    if (showIframe) initIframe();
  }, [showIframe]);

  if (!showIframe) return null;

  return (
    <>
      <div id="kyc-iframe"></div>
      <button id="submit-kyc-button">
        <span>Submit</span>
      </button>
    </>
  );
}
