import React, { useEffect, useState } from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import "../../containers/Accounting/Accounting.scss";

export default function ReconcileTable({
  columns,
  data,
  defaultColumn,
  filterTypes,
  lastPage,
  loading,
  ReconcileAccount,
  sortDesc,
  sortId,
  columnLimit,
  totalItems,
}) {
  const {
    canNextPage,
    canPreviousPage,
    endRow,
    
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    nextPage,
    pageCount: controlledPageCount,
    prepareRow,
    previousPage,
    page,
    state: { pageIndex },
  } = useTable(
    {
      autoResetPage: false,
      columns,
      filterTypes,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: columnLimit,
        pageSize: 9999999,
        sortBy: [
          {
            id: sortId,
            desc: sortDesc,
          },
        ],
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const [navNums, setNavNums] = useState([0, 1, 2, 3, 4]);
  const [ellipsisAnim, setEllipsisAnim] = useState(false);
  const [rightEllipsis, setRightEllipsis] = useState(0);

  const incrementNavNums = () => {
    const incremented = navNums.map((k) => {
      return k + 5;
    });
    setNavNums(incremented);
  };

  const decrementNavNums = () => {
    const decremented = navNums.map((k) => {
      return k - 5;
    });
    setNavNums(decremented);
  };

  // creates iterable array for mapping ui buttons.
  /* eslint-disable */
  const pageLength = Array.apply(
    null,
    Array(!isNaN(lastPage) ? lastPage : 0)
  ).map(() => {});
  /* eslint-enable */

  // Handles querying additional ledger via gotoPage if data > 1000
  const handleGoToPageIndex = (i) => {
    gotoPage(i);
    if (data.length < totalItems && pageLength.length === i + 1) {
      setEllipsisAnim(true);
      ReconcileAccount();
      // getLedger(data);
    }
  };

  const handleGoToPreviousPage = () => {
    previousPage();
    if (pageIndex % 5 === 0) decrementNavNums();
  };

  // Handles querying additional ledger data via next page arrow if data > 1000
  const handleGoToNextPage = () => {
    nextPage();
    if ((pageIndex + 1) % 5 === 0) incrementNavNums();
    if (data.length < totalItems && pageLength.length === pageIndex + 2) {
      ReconcileAccount();
      // getLedger(data);
    }
  };

  // Handles Ellipsis Animation, pagination UI, and calls getLedger
  const handleRightEllipsis = () => {
    if (data.length < totalItems) {
      setEllipsisAnim(true);
      // getLedger(data);
      ReconcileAccount();
    }
    incrementNavNums();
    setRightEllipsis(rightEllipsis + 1);
  };

  const handleLeftEllipsis = () => {
    decrementNavNums();
    gotoPage(navNums[4] - 5);
  };

  useEffect(() => {
    setEllipsisAnim(false);
  }, [data]);

  /* eslint-disable */
  useEffect(() => {
    gotoPage(navNums[0]);
  }, [rightEllipsis]);
  /* eslint-enable */
  //

  // Render the UI for your table\
  return (
    <>
      <table
        className="lead-table-list"
        style={{
          width: "100%",
          flex: "0 0 100%",
          position: "relative",
        }}
        {...getTableProps()}
      >
        <thead>
          <tr style={{ minHeight: "800px" }}>
            {loading ? (
              <td colSpan="10000">Loading...</td>
            ) : (
              <td colSpan="10000">
                {page.length !== 0 &&
                  `Showing ${
                    endRow <= controlledPageCount ? endRow : controlledPageCount
                  } of ${controlledPageCount}
                results`}
              </td>
            )}
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                  <br />
                  {column.canFilter ? column.render("Filter") : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length === 0 ? (
            <td colSpan={columns.length} style={{ textAlign: "center" }}>
              No Results Found
            </td>
          ) : (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {totalItems > 30 && (
        <div className="pagination">
          <span className="pagination-index">
            Page{" "}
            <strong>
              {pageIndex + 1} of {lastPage}
            </strong>{" "}
          </span>
          {pageIndex > 0 && (
            <button
              className="pagination-nav arrow-left"
              onClick={() => handleGoToPreviousPage()}
              disabled={!canPreviousPage}
            >
              {"<"}
            </button>
          )}
          {lastPage > 6 && navNums[0] > 4 && (
            <button
              className="pagination-nav"
              onClick={() => handleLeftEllipsis()}
            >
              <div className="ellipsis"></div>
            </button>
          )}
          {navNums[0] < lastPage && navNums[0] < Math.ceil(data.length / 30) && (
            <button
              className="pagination-nav"
              onClick={() => handleGoToPageIndex(navNums[0])}
            >
              {navNums[0] + 1}
            </button>
          )}
          {navNums[1] < lastPage && navNums[1] < Math.ceil(data.length / 30) && (
            <button
              className="pagination-nav"
              onClick={() => handleGoToPageIndex(navNums[1])}
            >
              {navNums[1] + 1}
            </button>
          )}
          {navNums[2] < lastPage && navNums[2] < Math.ceil(data.length / 30) && (
            <button
              className="pagination-nav"
              onClick={() => handleGoToPageIndex(navNums[2])}
            >
              {navNums[2] + 1}
            </button>
          )}
          {navNums[3] < lastPage && navNums[3] < Math.ceil(data.length / 30) && (
            <button
              className="pagination-nav"
              onClick={() => handleGoToPageIndex(navNums[3])}
            >
              {navNums[3] + 1}
            </button>
          )}
          {navNums[4] < lastPage && navNums[4] < Math.ceil(data.length / 30) && (
            <button
              className="pagination-nav"
              onClick={() => handleGoToPageIndex(navNums[4])}
            >
              {navNums[4] + 1}
            </button>
          )}
          {navNums[4] + 1 < lastPage && (
            <button
              className="pagination-nav"
              disable={ellipsisAnim}
              onClick={() => handleRightEllipsis()}
            >
              <div className={ellipsisAnim ? "loading" : "ellipsis"}></div>
            </button>
          )}
          {pageIndex + 1 < lastPage && (
            <button
              className="pagination-nav arrow-right"
              onClick={() => handleGoToNextPage()}
              disabled={!canNextPage}
            >
              {">"}
            </button>
          )}
        </div>
      )}
    </>
  );
}
