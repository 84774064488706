import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { AccountingMetrics } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import "./Accounting.scss";
import _ from "lodash";
import { Card, Header, Image, Grid, Icon } from "semantic-ui-react";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import { Line, Bar } from "react-chartjs-2";
import mixpanel from "mixpanel-browser";
import  AccountingGraphs  from "../../components/Graphs/AccountingGraphs";
import Tour from 'reactour'
import { accountingSteps } from "../../utils/tourSteps"; 
const ledgerClient = getClient(ACCOUNTING);

class Ledger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      isTourOpen:false
    };
  }

  componentDidMount() {
    // this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Accounting",
    });
  }
  componentWillReceiveProps(prevProps) {
   
  }



  render() {
    const { Metrics, Losses } = this.state;

    return (
      <div className="accounting-wrapper">
        <div className="top-head">
          <h4>Accounting <Icon size='small' circular name='lightbulb' onClick={() => this.setState({ isTourOpen: true })} /></h4>
          <h6>Here’s the overview of your accounts</h6>
        </div>
        <div className="table-rw">
          {/*At a Glance */}
          <span>
            <Card fluid className="banner-image-wrap accounting-first-step">
              <Image
                centered
                fluid
                size="big"
                src="/assets/img/renter-bg.jpg"
              />
              <Card.Content textAlign="center">
                <Card.Header>New COVID-19 tax credits</Card.Header>
                <Card.Description>
                  Keep more money in your pocket with new COVID-19 tax credits
                </Card.Description>
                <img src="assets/img/icons-close.svg" alt="cross-icon"></img>
              </Card.Content>
            </Card>
            <AccountingGraphs dashboard={false} selectedPrimary={this.props.selectedPrimary.node && this.props.selectedPrimary.node}/>
         
          </span>
        </div>
        <Tour
          steps={accountingSteps}
          isOpen={this.state.isTourOpen}
          onRequestClose={() => this.setState({ isTourOpen: false })}
          // maskClassName="mask"
          className="helper"
          startAt={0}
          rounded={5}
        />
      </div>
    );
  }
}

export default withApollo(Ledger);