import React, { useEffect, useState } from "react";
import {
  Button,
  Header,
  Image,
  Modal,
  Card,
  Grid,
  Feed,
} from "semantic-ui-react";
import { SCHEDULEINTEGRATE } from "../../utils/constants";
import {
  AddGoogleToken,
  GetIntegrations,
  DeleteGoogleToken,
} from "../../store/person/schedule";
import { getClient } from "../../init-apollo-googleFn";
import mixpanel from "mixpanel-browser";

export const GOOGLE_CA_ACCESS_TOKEN_KEY = 'GOOGLE_CA_ACCESS_TOKEN_KEY';
export const GOOGLE_CA_ACCESS_CODE_KEY = 'GOOGLE_CA_ACCESS_CODE';

const ScheduleIntegrate = getClient(SCHEDULEINTEGRATE);
function ScheduleIntegration(props) {
  const [open, setOpen] = React.useState(false);
  const [events, setEvents] = useState(null);
  const [integrations, setIntegrations] = useState([]);
  const SCOPES =
    "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar";

  const attachScript = () => {
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src = "https://apis.google.com/js/api.js";

    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.gapi) handleClientLoad();
    });
  };

  const handleClientLoad = () => {
    window.gapi.load("client:auth2", initClient);
  };

  const openSignInPopup = () => {
    window.gapi.auth2.authorize(
      {
        client_id: process.env.REACT_APP_GOOGLE,
        scope: SCOPES,
        access_type: "offline",
        response_type: "id_token_permission code",
        prompt: "consent",
      },
      (res) => {
        if (res) {
          responseGoogleSuccess(res);
          if (res.access_token)
            localStorage.setItem(GOOGLE_CA_ACCESS_TOKEN_KEY, res.access_token);
          if (res.code) 
            localStorage.setItem(GOOGLE_CA_ACCESS_CODE_KEY, res.code);
          // Load calendar events after authentication
        }
      }
    );
  };

  const initClient = () => {
    const API_KEY = "AIzaSyAnOMBzboGt-zL69J7ae_aC_yjWchdAn8M";
    openSignInPopup();
    if (!localStorage.getItem(GOOGLE_CA_ACCESS_TOKEN_KEY)) {
      // 
    } else {
      // Get events if access token is found without sign in popup
      fetch(
        `https://www.googleapis.com/calendar/v3/calendars/primary/events?key=${process.env.GMAP_API_KEY}&orderBy=startTime&singleEvents=true`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(GOOGLE_CA_ACCESS_TOKEN_KEY)}`,
          },
        }
      )
        .then((res) => {
          // Check if unauthorized status code is return open sign in popup
          if (res.status !== 401) {
            return res.json();
          } else {
            localStorage.removeItem(GOOGLE_CA_ACCESS_TOKEN_KEY);
            localStorage.removeItem(GOOGLE_CA_ACCESS_CODE_KEY);

            openSignInPopup();
          }
        })
        .then((data) => {});
    }
  };

  const responseGoogleSuccess = (response) => {
    try {
      mixpanel.track("Manager Lead Action", {
        sub: "Attach Google",
      });
      ScheduleIntegrate.mutate({
        mutation: AddGoogleToken,
        variables: {
          input: {
            personId: props.user.ndbId,
            locationId: props.primary.id,
            accessToken: response.access_token,
            refreshToken: response.code,
          },
        },
      }).then((res) => {
        fetchIntegrations();
      });
    } catch (e) {
      console.log(e);
      alert(e);
    }
  };

  const deleteIntegration = (response) => {
    try {
      mixpanel.track("Manager Lead Action", {
        sub: "Attach Google",
      });
      ScheduleIntegrate.mutate({
        mutation: DeleteGoogleToken,
        variables: {
          input: {
            personId: props.user.ndbId,
            locationId: props.primary.id,
            id: response.id,
          },
        },
      })
        .then((res) => {
          fetchIntegrations();
          window.gapi.auth2.revoke(response.accessToken, () => {
            console.log("access token revoked");
          });
        })
        .catch((error) => {
          fetchIntegrations();
          window.gapi.auth2.revoke(response.accessToken, () => {
            console.log("access token revoked");
          });
        });
    } catch (e) {
      console.log(e);

      alert(e);
    }
  };

  const fetchIntegrations = () => {
    try {
      ScheduleIntegrate.query({
        query: GetIntegrations,
        variables: {
          personId: props.user.ndbId,
        },
      })
        .then((res) => {
          setIntegrations(res.data.getIntegrations.edges);
        })
        .catch((error) => {
          setIntegrations([]);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      className="semanticModal semanticModal-attachModal "
      size="mini"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(false)}
      open={open}
      trigger={
        <Card
          onClick={() => fetchIntegrations()}
          style={{ border: "2px solid" }}
          className="settings-first-step"
        >
          <Card.Content>
            <Card.Header textAlign="center">Payout Integration</Card.Header>
            <Card.Meta textAlign="center">
              Integrate your other accounts
            </Card.Meta>
          </Card.Content>
        </Card>
      }
    >
      <Modal.Header>
        Attach Google Account
        <Button onClick={() => attachScript()} floated="right">
          New
        </Button>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {integrations &&
            integrations.map((integration) => {
              return (
                <Card fluid>
                  <Card.Content>
                    <Image
                      floated="right"
                      size="mini"
                      src="/assets/icons/googleLogo.png"
                    />
                    <Card.Header>
                      {props.user.firstName} {props.user.lastName}
                    </Card.Header>
                    <Card.Meta>{integration.node.createdAt}</Card.Meta>
                    <Card.Description>
                      Id: {integration.node.id}
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <Button
                      fluid
                      onClick={() => deleteIntegration(integration.node)}
                      basic
                      color="red"
                    >
                      Delete
                    </Button>
                  </Card.Content>
                </Card>
              );
            })}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ScheduleIntegration;
