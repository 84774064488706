import React, { useMemo, useState, useEffect } from "react";
import { ProfitLossDetails } from "../../store/person/accounting";
import { useTable, useFilters, useSortBy, usePagination, useExpanded } from "react-table";
import { Icon , Table, Button, Loader, Popup} from "semantic-ui-react";
import {toast } from "react-toastify";
import moment from "moment";
import { computeAmounts } from "../../utils/common";
import AccountingTable from "./BalanceTable";
import { getClient } from "../../init-apollo-googleFn";
import { multipleColumnsSearch } from "../../utils/common";
import { ACCOUNTING_REPORTING } from "../../utils/constants";
import ViewLedgerDetails from "../Accounting/ViewLedgerDetails";
import "react-toastify/dist/ReactToastify.css";
const ledgerClient = getClient(ACCOUNTING_REPORTING);
export function TableBalance(props) {
  const [filterInput, setFilterInput] = useState("");
  const [toggled, viewToggle] = useState(false);
  const [viewLedgerData, viewData] = useState("");
  const [loader, setLoading] = useState(false);


  function TableRender(props) {
    const [subTableData, setSubTableData] = useState(props.props.ledgerCache);
    const toggleSecondTable = (item, index) => {
      setLoading(true)
      if(!item.sub){
        ledgerClient
        .query({
          query: ProfitLossDetails,
          variables: {
            slLocationId: props.props.propertyData.node.slLocationId,
            slLedgerId: Number(item.node.slLedgerId),
          },
        })
        .then((res) => {
          const monthData = res.data.profitLossDetails
          const newTable = subTableData.map((data,ind) => {
            if(ind == index){
              data.sub = monthData
              data.open=true
              return data
            }
            else return data
          })
          setLoading(false)
          setSubTableData(newTable)
        })
        .catch((error) => {
          setLoading(false)
        });
      }
      if(item.sub && !item.open){
        const newTable = subTableData.map((data,ind) => {
          if(ind == index){
            data.open = true
            return data
          }
          else return data
        })
        setLoading(false)
        setSubTableData(newTable)
      }
    }

    const toggleSub = (index) => {
      const newTable = subTableData.map((data,ind) => {
        if(ind == index){
          delete data.open
          return data
        }
        else return data
      })
      setSubTableData(newTable)
    }
    return (
      <>
      <Table  celled sortable>
        <Table.Header>
          <Table.Row textAlign="center">
            <Table.HeaderCell width='2' style={{background:'#555555', color:'white'}}>{loader&&<Loader size="tiny" inverted active inline />}</Table.HeaderCell>
            <Table.HeaderCell width='3' style={{background:'#555555', color:'white'}}>Number</Table.HeaderCell>
            <Table.HeaderCell width='2' style={{background:'#555555', color:'white'}}>Name</Table.HeaderCell>
            <Table.HeaderCell width='2' style={{background:'#555555', color:'white'}}>Type</Table.HeaderCell>
            <Table.HeaderCell width='3' style={{background:'#555555', color:'white'}}>Sub Type</Table.HeaderCell>
            <Table.HeaderCell width='2' style={{background:'#555555', color:'white'}}>Requested Date</Table.HeaderCell>
            <Table.HeaderCell width='2' style={{background:'#555555', color:'white'}}>Balance</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      </Table>
      <div style={{maxHeight:'750px', overflowY:'overlay'}}>
      <Table>
    <Table.Body>
    {
      subTableData&&subTableData.map((ledger, index) => {
        const number = ledger.node.number
        return (
          <>
          <Table.Row textAlign="center" className="secondTableToggle">
            <Table.Cell width='2'>{ledger.open ?<Icon onClick={() => toggleSub(index)} name='arrow alternate circle down outline' />:<Icon onClick={() => toggleSecondTable(ledger,index)} name='arrow alternate circle right outline' />}</Table.Cell>
            <Table.Cell width='3'>{ledger.node.number}</Table.Cell>
            <Table.Cell width='2'>{ledger.node.name}</Table.Cell>
            <Table.Cell width='2'>{ledger.node.type}</Table.Cell>
            <Table.Cell width='3'>{ledger.node.subType}</Table.Cell>
            <Table.Cell width='2'>{moment(ledger.node.requestedDate).format('MM/DD/YYYY')}</Table.Cell>
            <Table.Cell width='2'>{computeAmounts(ledger.node.balance)}</Table.Cell>
          </Table.Row>
          {
            ledger.sub && ledger.open &&
              <Table.Row>
                <Table.Cell colSpan='7'>
                <Table fixed textAlign="center" columns="12" size="small">
                  <Popup
                    trigger={<Table.Cell selectable style={{background:'#555555', color:'white'}}>Jan</Table.Cell>}
                    content={computeAmounts(ledger.sub.Jan)}
                    header="January"
                    on='click'
                    position='top center'
                    flowing
                  />
                  <Popup
                    trigger={<Table.Cell selectable style={{background:'#555555', color:'white'}}>Feb</Table.Cell>}
                    content={computeAmounts(ledger.sub.Feb)}
                    header="February"
                    on='click'
                    position='top center'
                    flowing
                  />
                  <Popup
                    trigger={<Table.Cell selectable style={{background:'#555555', color:'white'}}>Mar</Table.Cell>}
                    content={computeAmounts(ledger.sub.Mar)}
                    header="March"
                    on='click'
                    flowing
                    position='top center'
                  />
                  <Popup
                    trigger={<Table.Cell selectable style={{background:'#555555', color:'white'}}>Apr</Table.Cell>}
                    content={computeAmounts(ledger.sub.Apr)}
                    header="April"
                    on='click'
                    position='top center'
                    flowing
                  />
                  <Popup
                    trigger={<Table.Cell selectable style={{background:'#555555', color:'white'}}>May</Table.Cell>}
                    content={computeAmounts(ledger.sub.May)}
                    header="May"
                    on='click'
                    position='top center'
                    flowing
                  />
                  <Popup
                    trigger={<Table.Cell selectable style={{background:'#555555', color:'white'}}>Jun</Table.Cell>}
                    content={computeAmounts(ledger.sub.Jun)}
                    header="June"
                    on='click'
                    position='top center'
                    flowing
                  />
                  <Popup
                    trigger={<Table.Cell selectable style={{background:'#555555', color:'white'}}>Jul</Table.Cell>}
                    content={computeAmounts(ledger.sub.Jul)}
                    header="July"
                    on='click'
                    flowing
                    position='top center'
                  />
                  <Popup
                    trigger={<Table.Cell selectable style={{background:'#555555', color:'white'}}>Aug</Table.Cell>}
                    content={computeAmounts(ledger.sub.Aug)}
                    header="August"
                    on='click'
                    position='top center'
                    flowing
                  />
                  <Popup
                    trigger={<Table.Cell selectable style={{background:'#555555', color:'white'}}>Sep</Table.Cell>}
                    content={computeAmounts(ledger.sub.Sep)}
                    header="September"
                    on='click'
                    position='top center'
                    flowing
                  />
                  <Popup
                    trigger={<Table.Cell selectable style={{background:'#555555', color:'white'}}>Oct</Table.Cell>}
                    content={computeAmounts(ledger.sub.Oct)}
                    header="October"
                    on='click'
                    position='top center'
                    flowing
                  />
                  <Popup
                    trigger={<Table.Cell selectable style={{background:'#555555', color:'white'}}>Nov</Table.Cell>}
                    content={computeAmounts(ledger.sub.Nov)}
                    header="November"
                    on='click'
                    flowing
                    position='top center'
                  />
                  <Popup
                    trigger={<Table.Cell selectable style={{background:'#555555', color:'white'}}>Dec</Table.Cell>}
                    content={computeAmounts(ledger.sub.Dec)}
                    header="December"
                    flowing
                    position='top center'
                    on='click'
                  />
                </Table>
                </Table.Cell>
              </Table.Row>
          }
          </>
        )
      })
    }
    </Table.Body>
  </Table>
  </div>
      </>
    )
  }
  

  return (
    <>
      {/* <div className="table-container"> */}
          <>
            <TableRender
            props={props}
              // columns={columns}
              // data={data ? data : []}
              // defaultColumn={defaultColumn}
              // getLedger={props.getLedger}
              // collapseOnDataChange={false}
              // lastPage={lastPage}
              // onChange={handleFilterChange}
              // pageCount={lastPage}
              // sortDesc={true}
              // sortId={"number"}
              // value={filterInput}
              // renderRowSubComponent={renderRowSubComponent}
            />
          </>
      {/* </div> */}
    </>
  );
}
