import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { Button, Header, Grid, Segment } from "semantic-ui-react";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import { toast } from "react-toastify";
import {LedgerTransactionsGql, ProfitLossAccounting, BalanceSheetCreateUpdate, TotalProfitLoss } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import Datetime from "react-datetime";
import {
  toastFailMsg,
  toastSuccessMsg,
} from "../../utils/common";

import { Loader } from "../../components/Loader/Loader";
import  StripedBalanceTable  from "../../components/Tables/NewBalanceTable";
import { TableBalance } from "../../components/Tables/ProfitLossData";
import { ACCOUNTING, ACCOUNTING_REPORTING } from "../../utils/constants";
import LedgerCreationAccounting from "../../components/Modals/LedgerCreationAccounting";
import "react-toastify/dist/ReactToastify.css";
// images
import backIcon from '../../assets/images/back-arrow.svg';

const ledgerClient = getClient(ACCOUNTING);
const balanceClient = getClient(ACCOUNTING_REPORTING)

class ProfitLoss extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      startDate: moment().startOf("month").format("YYYY-MM-DD"),
      endDate: moment().endOf("month").format("YYYY-MM-DD"),
      totalProfit:{},
      ledgerCache: [],
      loadingCompute:false,
      offset: 0,
      view: false,
    };
  }

  componentDidMount() {
    // this.getLedger();
    // this.getBalance()
    mixpanel.track("Manager Page Load", {
      sub: "Ledger",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getBalance();
      });
    }
  }

  getSingleLedger = (data) => {
    try {
      ledgerClient
        .query({
          query: LedgerTransactionsGql,
          variables: {
            location: this.props.selectedPrimary.node.id,
            ledgerNumber:data,
            start:this.state.startDate,
            end:this.state.endDate,
            offset: 0,
            limit: 1000,
          },
        })
        .then((res) => {
          if (res.data) {
            const Arr = get(res, "data.slLocationLedgerAccounts.data", null)
            const getName = Arr.filter(item => item._id == data)
            this.setState({singleLedger:Arr})
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  getBalance = () => {
    this.setState({loading:true})
    balanceClient
    .query({
      query: ProfitLossAccounting,
      variables: {
        locationId:this.props.selectedPrimary.node.id
      },
    })
    .then((res) => {
      this.setState({loading:false})
      this.getTotalProfit()
      const array = get(res,"data.profitLoss.edges",null)
      array.sort(
            (a, b) =>
              parseFloat(a.node.number) -
              parseFloat(b.node.number)
          )
      this.setState({
        ledgerCache: get(
          res,
          "data.profitLoss.edges",
          null
        ),
      });
    })
    .catch((error) => {
      this.setState({loading:false})
      console.log(error);
    });
}

getTotalProfit = () => {
  balanceClient
  .query({
    query: TotalProfitLoss,
    variables: {
      slLocationId:this.props.selectedPrimary.node.slLocationId
    },
  })
  .then((res) => {
    this.setState({totalProfit:res.data.totalProfitLoss})
  })
  .catch((error) => {
    console.log(error);
  });
}

Recompute = () => {
  this.setState({loadingCompute:true})
  balanceClient
  .mutate({
    mutation: BalanceSheetCreateUpdate,
    variables: {
      input: {
        locationId:this.props.selectedPrimary.node.id,
        action:this.state.ledgerCache.length ==0?"CREATE":"UPDATE",
        start:moment(this.state.startDate).format('YYYY-MM-DD'),
        end:moment(this.state.endDate).format('YYYY-MM-DD'),
        type:'income_statement'
      }
    },
  })
  .then((res) => {
    toastSuccessMsg("Success! Please allow up to 10 seconds for the fields to gather and update.");
    setTimeout(() => {
      this.loadingComputing()
   }, 10000)
  })
  .catch((error) => {
    console.log(error);
  });
}
loadingComputing = () => {
  this.getBalance()
  this.setState({loadingCompute:false})
}

  dataPush = (response) => {
    const node = response.data.createSlLedgerAccount.slLedgerAccount;
    this.setState((prevState) => ({
      ledgerCache: [...prevState.ledgerCache, node],
    }));
  };

  updateLedger = (update, ledgerId) => {
    const { ledgerCache } = this.state;
    const array = [];
    if (update === "Delete") {
      this.setState({
        ledgerCache: ledgerCache.filter((ledger) => ledger._id !== ledgerId),
      });
    } else {
      ledgerCache.forEach((ledger) => {
        if (
          ledger._id === update.data.updateSlLedgerAccount.slLedgerAccount._id
        ) {
          ledger = update.data.updateSlLedgerAccount.slLedgerAccount;
          array.push(ledger);
        } else {
          array.push(ledger);
        }
      });
      this.setState({ ledgerCache: array });
    }
  };
  hideAdd = (e) => {
    console.log(e);
  };
  success = (msg) =>
    toast.success(msg, {
      position: "top-center",
      autoClose: 30000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    computeAmounts = (amount) =>{
      const convertToNumber = parseFloat(amount)
      if(convertToNumber < 0){
        return <span>$ ({Math.abs(Number.parseFloat(amount)).toLocaleString('en')})</span>
      }
      if(convertToNumber >= 0){
        return <span>$ {Math.abs(Number.parseFloat(amount)).toLocaleString('en')}</span>
      }
      else return <span>$ 0</span>
    };
    runTable = (amount) =>{
    console.log(amount)
    };

    handleStartClick = (date) => {
      this.setState({
        startDate: moment(date._d).format("YYYY-MM-DD"),
      });
    };
    handleEndClick = (date) => {
      this.setState({
        endDate: moment(date._d).format("YYYY-MM-DD"),
      });
    };

  

  render() {
    const { loading } = this.state;
    const success = (msg) =>
    toast.success(msg, {
      position: "top-center",
      // autoClose: 15000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const failure = () =>
    toast.error("Something went wrong!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return (
      <>
      <main className="main-content" role="main">
{/* 
        <div className="row">
          <div className="col-md-12">
            <div className="page-header">
              <div className="d-flex align-items-center">
                <span className="page-back"><img src={backIcon} /></span>
                <div className="page-header-title">Renew Lease</div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="page-header">
              <div className="d-flex align-items-center">
                {/* <span className="page-back"> */}
                  {/* <img src={backIcon} alt="back" /> */}
                  
                {/* </span> */}
                <div>
                  <div className="page-header-title pl-0">Income Statement</div>
                </div>
              </div>  
              <div className="d-flex align-items-end">
                      <div className="ml-3">
                        <Header textAlign="center">Start Date</Header>
                        <Datetime
                          inputProps={{ placeholder: "Select Start Date" }}
                          defaultValue={moment().startOf("month")}
                          timeFormat={false}
                          onChange={this.handleStartClick}
                        />
                      </div>
                      <div className="ml-3">
                        <Header textAlign="center">End Date</Header>
                        <Datetime
                          inputProps={{ placeholder: "Select End Date" }}
                          defaultValue={moment().endOf("month")}
                          timeFormat={false}
                          onChange={this.handleEndClick}
                        />
                      </div>
                    </div>
              <div className="d-flex align-items-end">
              <Button
                disabled={this.state.loadingCompute}
                compact
                onClick={() => this.Recompute()}
                className="ml-3 button-hover-universal"
                >
                {
                  this.state.loadingCompute? <span>Processing...<Button style={{paddingBottom:"0px"}} compact loading></Button></span>:<span>Generate</span>
                }
                  
                </Button>
                <Button
                onClick={() => window.print()}
                compact
                  className="ml-4 noPrint noPrint-button"
                  style={{
                    backgroundImage:
                      "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                  }}
                >
                  Print
                </Button>
                {/* <Button className="ml-2" style={{ backgroundImage: "linear-gradient(110deg, #3b1c5a, #374db1 162%)", color: "white" }}>Share</Button> */}
              </div>
            </div>
          </div>
        </div>
        {/* <Segment textAlign="center">
        <Grid columns={3} stackable textAlign='center'>
            <Grid.Row verticalAlign='middle'>
            <Grid.Column>
              <Header>Revenue
                <Header.Subheader>
                {this.computeAmounts(this.state.totalProfit['Total Revenue'])}
                </Header.Subheader>
              </Header>
              </Grid.Column>
              <Grid.Column>
              <Header>Expense
                <Header.Subheader>
                {this.computeAmounts(this.state.totalProfit['Total Expense'])}
                </Header.Subheader>
              </Header>
              </Grid.Column>
              <Grid.Column>
              <Header>Net Loss/Profit
                <Header.Subheader>
                {this.computeAmounts(this.state.totalProfit['Total Net Loss'])}
                </Header.Subheader>
              </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment> */}
            {loading ? (
              <Loader text inTable />
            ) : (
              <>
                <div className="balance-sheet-table">
                <StripedBalanceTable
                    updateLedger={this.updateLedger}
                    hideAdd={this.hideAdd}
                    dataPush={this.dataPush}
                    totalItems={this.state.totalItems && this.state.totalItems}
                    getSingleLedger={this.getSingleLedger}
                    singleLedger={this.state.singleLedger}
                    user={this.props.user}
                    propertyData={
                      this.props.selectedPrimary && this.props.selectedPrimary
                    }
                    ledgerCache={this.state.ledgerCache && this.state.ledgerCache}
                    propertyId={
                      this.props.selectedPrimary.node &&
                      this.props.selectedPrimary.node.id
                    }
                  />
                  {/* <TableBalance
                  computeAmounts={this.computeAmounts}
                    updateLedger={this.updateLedger}
                    hideAdd={this.hideAdd}
                    dataPush={this.dataPush}
                    totalItems={this.state.totalItems && this.state.totalItems}
                    getLedger={this.getBalance}
                    runTable={this.runTable}
                    user={this.props.user}
                    propertyData={
                      this.props.selectedPrimary && this.props.selectedPrimary
                    }
                    ledgerCache={this.state.ledgerCache && this.state.ledgerCache}
                    propertyId={
                      this.props.selectedPrimary.node &&
                      this.props.selectedPrimary.node.id
                    }
                  /> */}
                </div>
              </>
            )}
        </main>
      </>
    );
  }
}

export default withApollo(ProfitLoss);
