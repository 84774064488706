import React, { useState } from "react";
import { withApollo } from "react-apollo";
import "../../containers/SignIn/Forgot/forgot.scss";
import "./DocModal.scss"
import { getClient } from "../../init-apollo-googleFn";
import getAuthToken from "../../store/auth/authUtility";
import { Button, Header, Image, Modal, Icon, List,Popup } from 'semantic-ui-react'
import { UploadWepayDocuments } from "../../store/person/accounting";
import {
    payVerificationDoc,
    PERSON_WEPAY
} from "../../utils";
import {
    toastFailMsg,
    toastSuccessMsg,
    parseGraphQLErrors,
} from "../../utils/common";
import * as WepayUtils from "../../utils/wepay";

const wePayClient = getClient(PERSON_WEPAY);
function DocModal(props) {
    // Use States
    const [open, setOpen] = React.useState(false)
    const [fieldValue, setFieldValue] = React.useState(null)
    const [theIssue, setIssue] = React.useState(null)
    const [viewIssues, setViewIssues] = React.useState(true)
    const [legalEntityVerification, setLegalEntityVerification] = React.useState({
        
    });
    const [file, setFile] = useState(null);
    // Reset mechanic for modal
    const resetSelection = () => {
        setFile(null)
        setFieldValue(null)
        setViewIssues(true)
    }

    // File change mechanic for upload
    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const checkWepayAccountVerification = async () => {
        if (props?.selectedPrimary.Id === undefined) return;
        if (props?.selectedPrimary.length === 0) return;
        try {
            const legalEntityVerification =
                await WepayUtils.getLegalEntityVerification(props?.selectedPrimary?.Id);
            setLegalEntityVerification(legalEntityVerification);
        } catch (error) {
            console.log("error", error);
        }
    };

    React.useEffect(() => {
        console.log(legalEntityVerification)
        if (props.selectedPrimary.length !== 0) {
            checkWepayAccountVerification();
        }
    }, [props?.selectedPrimary?.Id]);
    // Document submittal mutation requiring Location Id and Type along with the single image
    const submitDocument = (e) => {
        const formData = new FormData();
        if (file) {
            formData.append("", file);
        }
        formData.append(
            "query",
            `mutation {
                uploadWepayDocuments(documents: {
                    documentType: "${fieldValue}", 
                    locationId:"${props.selectedPrimary.node.id}",
                    legalEntityId:"${legalEntityVerification.owner.id}",
                    path: "${theIssue[theIssue.issue_type].path}"
            }){
              response
            }}`
        );

        if (formData) {
            try {
                fetch(PERSON_WEPAY, {
                    method: "POST", // *GET, POST, PUT, DELETE, etc.
                    mode: "cors", // no-cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *same-origin, omit
                    headers: {
                        Authorization: getAuthToken(),
                        // contentType: 'application/x-www-form-urlencoded'
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // no-referrer, *client
                    body: formData, // body data type must match "Content-Type" header
                })
                    .then((response) => {
                        toastSuccessMsg("Document successfully uploaded, please allow time for verification.")
                        setOpen(false)
                    })
                    .catch((error) => {
                        toastFailMsg("Something went wrong, please contact a system admin.")
                    });
            } catch (e) {
                toastFailMsg("Something went wrong, please contact a system admin.")
            }
        }
    };

    const toggleIssue = (e) => {
        setIssue(e)
        setViewIssues(false)
    }




    return (
        <Modal
            className="semanticModal"
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            size="tiny"
            open={open}
            trigger={
                <div
                    onClick={() => resetSelection()}
                    style={{
                        width: 35,
                        height: 35,
                        padding: 0,
                        backgroundColor: "#e6e6e6",
                        borderRadius: "8px !important",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    title="Entity Verification Status"
                >
                    {!legalEntityVerification?.entity_verification?.verified &&
                        !legalEntityVerification?.controller?.personal_verification?.verified ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-check-circle"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                fill="#329932"
                            />
                            <path
                                d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                                fill="#329932"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-x-circle"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                fill="#ff3232"
                            />
                            <path
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                                fill="#ff3232"
                            />
                        </svg>
                    )}
                </div>
            }
        >
            {
                viewIssues ?
                    <div class="modal-dialog-centered" role="document">
                        <div class="modal-content success-modal">
                            <Icon className={"exitIcon"} name='x' size='big' />
                            <div class="modal-header">
                                {/* <div >
                      <img src="assets/img/check-icon.svg" alt="Success icon" />
                    </div> */}
                                <h5 class="modal-title" id="forgotPasswordModalLabel">
                                    Verifications Missing
                                </h5>
                                <p class="modal-sutitle">
                                    <List divided relaxed>
                                        {legalEntityVerification?.controller?.personal_verification?.current_issues.map(issue => (
                                            <List.Item>
                                                <List.Icon name='file' size='large' verticalAlign='middle' />
                                                <List.Content>

                                                    <List.Header as='a' onClick={() => toggleIssue(issue)} className="issue-type">{issue.issue_type.replace(/_/g, ' ')}</List.Header>
                                                    <List.Description as='a' className="issue-type-message">{issue[issue.issue_type].message}</List.Description>
                                                </List.Content>
                                                {/* <List.Content floated='right'>
                                                    {issue[issue.issue_type].requested_documents && issue[issue.issue_type].requested_documents.map(item => {
                                                        return (

                                                            <List.Item>{item}</List.Item>
                                                        )
                                                    })}
                                                </List.Content> */}
                                                {/* <List.List>
                                                    {issue[issue.issue_type].requested_documents && issue[issue.issue_type].requested_documents.map(item => {
                                                        return (

                                                            <List.Item>{item}</List.Item>
                                                        )
                                                    })}
                                                </List.List> */}
                                            </List.Item>
                                        ))}
                                    </List>
                                </p>
                            </div>
                        </div>
                    </div> :
                    <div class="modal-dialog-centered" role="document">
                        <div class="modal-content success-modal">
                            <Icon className={"backIcon"} name='arrow alternate circle left outline' size='big' onClick={() => setViewIssues(true)} />
                            <Icon className={"exitIcon"} name='x' size='big' onClick={() => setOpen(false)} />
                            <div class="modal-header">
                                <div class={`success-image ${file && "successColor"}`}>
                                    <img src="assets/img/check-icon.svg" alt="Success icon" />
                                </div>
                                <h5 class="modal-title title-issue" id="forgotPasswordModalLabel">
                                    Please Upload {theIssue.issue_type.replace(/_/g, ' ')}
                                    <Popup wide trigger={<Icon style={{ marginBottom: '5px' }} size="small" name='question circle outline' />} >
                                        <List bulleted>
                                            {theIssue[theIssue.issue_type].requested_documents.map(item => {
                                                return (
                                                    <List.Item className="title-issue">{item.replace(/_/g, ' ')}</List.Item>
                                                )
                                            })}
                                        </List>
                                    </Popup>
                                </h5>
                                <p class="modal-sutitle">
                                    In order to verify your account for KYC payouts through our payment provider we need additional documents.{" "}
                                </p>
                                <div class="select">
                                    <select id="standard-select" onChange={(event) => setFieldValue(event.target.value)}>
                                        {payVerificationDoc.map((option, i) => (
                                            <option
                                                key={i}
                                                value={option}
                                            >
                                                {option.replace(/_/g, ' ')}
                                            </option>
                                        ))}
                                    </select>
                                    <span class="focus"></span>
                                </div>
                                &nbsp;
                                <div class="upload-btn-wrapper">
                                    <button class={`btnUp ${file && "successColor"}`} ><span>{file ? "File Uploaded" : "Upload a file"}</span></button>
                                    <input type="file" name="myfile" onChange={(e) => handleFileChange(e)} />
                                </div>
                                <button
                                    type="submit"
                                    class="btn modal-large-btn"
                                    onClick={() => submitDocument(false)}
                                    disabled={!file || !fieldValue || fieldValue == "Select Type"}
                                >
                                    Submit
                                </button>
                                <span className="fileName">{file && file.name}</span>
                            </div>
                        </div>
                    </div>
            }
        </Modal>
    );
}

export default withApollo(DocModal);
