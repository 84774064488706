/* eslint-disable */
import React from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";

export default function TenantsTable({
  columns,
  data,
  value,
  defaultColumn,
  onChange,
  loading,
  fetchData,
  filterTypes,
  calculateLastPage,
  startRow,
  endRow,
  lastPage,
  pageCount: controlledPageCount,
  update,
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    gotoPage,
    page,
    canPreviousPage,
    previousPage,
    nextPage,
    prepareRow,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      autoResetPage: false, // If this works for you this was the fix
      columns,
      filterTypes,
      data,
      defaultColumn,
      initialState: {
        sortBy: [
          {
            id: "node.created",
            desc: true,
          },
        ],
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  React.useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  React.useEffect(() => {
    calculateLastPage({ pageSize, controlledPageCount });
  }, [calculateLastPage, pageSize, controlledPageCount]);

  // Render the UI for your table

  return (
    <>
      <table
        // style={{
        //   width: '100%', flex: '0 0 100%', position: 'relative', minHeight: '15.625rem',
        // }}
        className="residents-fifth-step"
        {...getTableProps()}
      >
        <thead className="sticky-header-tables">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "sort-desc"
                        : "sort-asc"
                      : ""
                  }
                >
                  {column.render("Header")}
                  <br />
                  {column.canFilter ? column.render("Filter") : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.length === 0 ? (
            <td colSpan="11" style={{ textAlign: "center" }}>
              No Results Found
            </td>
          ) : (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </>
  );
}
