import React, { Component } from "react";
import { withApollo } from "react-apollo";
// import { PropertyModal } from "../../Modals/PropertyModal";
import { uploadBioPhotoGql } from "../../../store/person/person";
// import { parseGraphQLErrors } from "../../../utils/common";
// import get from "lodash/get";
// import { backgroundCheckGql } from "../../../store/person/backgroundCheck";
// import { getClient } from "../../../store/auth/init-apollo-googleFn";
import "../../../assets/fomantic/dist/semantic.css";

import mixpanel from "mixpanel-browser";
// Account Info Table
// This table will allow users to view their current account information as reported in Leasera's system.
// const bgClient = getClient(BG_CHECK_URL);
class Bio extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applications: [],
      loading: false,
      bgChecks: [],
      apploading: false,
    };
  }

  componentDidMount() {
    // this.getCurrentApplications()
    // this.getBackgroundInfo()
  }

  setModalProperty = (modalProperty) => this.setState({ modalProperty });

  uploadBioPhoto = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      const bioPhotoInput = {
        photo: e.target.result,
        name: file.name,
      };
      mixpanel.track("Renter Account Action", {
        sub: "Upload New Profile Picture Submit",
      });
      this.props.oldClient
        .mutate({
          mutation: uploadBioPhotoGql,
          variables: { input: bioPhotoInput },
        })
        .then((res) => {})
        .catch((error) => {
          alert(error);
          console.log(error);
        });
    };
  };

  // getCurrentApplications() {
  //   try {
  //     this.setState({
  //       apploading: true
  //     })
  //     appClient.query({
  //       query: allApplicationsGql
  //     }).then(response => {
  //       this.setState({
  //         applications: get(response, 'data.personApplications.edges', []),
  //         apploading: false
  //       })
  //     }).catch(error => {
  //       this.setState({
  //         apploading: false
  //       })
  //       this.props.setMessage(`Oops! There was a problem fetching application requests: ${parseGraphQLErrors(error)}`, true)
  //     });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  // getBackgroundInfo = () => {
  //   try {
  //     this.setState({
  //       loading: true,
  //     });
  //     bgClient
  //       .query({
  //         query: backgroundCheckGql,
  //       })
  //       .then((response) => {
  //         this.setState({
  //           backgroundChecksInfo: get(
  //             response,
  //             "data.backgroundChecks.edges",
  //             []
  //           ),
  //           loading: false,
  //         });
  //       })
  //       .catch((error) => {
  //         this.setState({
  //           loading: false,
  //         });
  //         this.props.setMessage(
  //           `Oops! There was a problem fetching backgroundChecks requests: ${parseGraphQLErrors(
  //             error
  //           )}`,
  //           false
  //         );
  //       });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  render() {
    const { modalProperty } = this.state;

    return (
      <div>
        {/* <Container> */}

        {/* <PropertyModal
          showLeaseLength
          property={modalProperty}
          history={this.props.history}
        /> */}
        {/* <ProfileTabs /> */}
        {/* </Container> */}
      </div>
    );
  }
}

export default withApollo(Bio);
