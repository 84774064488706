import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { transactionsGql, TransAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";
import { ACCOUNTING } from "../../utils/constants";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss";
import { Button } from "semantic-ui-react";
import moment from "moment";
import ReconcileDetails from "../../components/Modals/ReconcileDetails";

const invoiceClient = getClient(ACCOUNTING);

class JournalReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      tableData: [],
      offset: 0,
    };
  }

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Journal Report",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  getLedger = () => {
    try {
      this.setState({ loading: true });
      invoiceClient
        .query({
          query: TransAccounting,
          variables: {
            location: this.props.selectedPrimary.node.id,
            offset: this.state.offset,
            limit: 30,
          },
        })
        .then((res) => {
          if (res.data) {
            const dataToPass = get(res, "data.slLocationTransactions.data", []).map((obj, i) => {
              const { status, sourceLedger, reference } = obj.Journal;
              const formattedStatus = `${status[0].toUpperCase()}${status.slice(1, status.length)}`
              const credit = `$ ${Number.parseFloat(obj.credit).toLocaleString('en')}`
              const debit = `$ ${Number.parseFloat(obj.debit).toLocaleString('en')}`
              const desc = obj.description ? obj.description : "";
              const postedDate = moment(obj.postedDate).format("l");
              return (({LedgerAccount_number, LedgerAccount_type, LedgerAccount_subtype}) => ({"modal":<ReconcileDetails bill={obj} user={this.props.user} location={this.props} invoice updateInvoice={this.updateInvoice} />, formattedStatus, sourceLedger, LedgerAccount_number, LedgerAccount_type, LedgerAccount_subtype, postedDate, reference, desc, debit, credit}))(obj)
            });
            this.setState({
              tableData: dataToPass,
              totalItems: get(res, "data.slLocationTransactions.totalItems"),
            });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  dataPush = (response) => {
    this.setState((prevState) => ({
      tableData: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.tableData,
      ],
    }));
  };
  updateInvoice = (status, invoiceId) => {
    const { tableData } = this.state;
    const array = [];
    if (status === "Delete") {
      this.setState({
        tableData: tableData.filter(
          (invoice) => invoice._id !== invoiceId
        ),
      });
    } else {
      tableData.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      this.setState({ tableData: array });
    }
  };

  handleOffset = (x) => {
    this.setState({offset: x});
  }

  render() {
    const { loading } = this.state;

    const mainHeader = ["Number", "Status", "Ledger", "Account Number", "Account Type", "Account Subtype", "Post Date", "Reference", "Description", "Debit", "Credit"]
    // TODO: style.

    return (
      <>
        <main className="main-content balance-sheet-layout journal-report-layout" role="main">
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="page-header flex-row mb-3">
              <div className="d-flex align-items-center">
                {/* <span className="page-back"> */}
                  {/* <img src={backIcon} alt="back" /> */}
                {/* </span> */}
                <div>
                  <div className="page-header-title pl-0">Journal Report</div>
                </div>
              </div>
              <div className="d-flex align-items-end">
              
                <Button
                onClick={() => window.print()}
                compact
                  className="ml-4 noPrint noPrint-button"
                  style={{
                    backgroundImage:
                      "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                  }}
                >
                  Print
                </Button>
                {/* <Button className="ml-2" style={{ backgroundImage: "linear-gradient(110deg, #3b1c5a, #374db1 162%)", color: "white" }}>Share</Button> */}
              </div>
            </div>
          </div>
        </div>
          
            {loading ? (
              <Loader text inTable />
            ) : (
              <div className="balance-sheet-table">
                <GeneralAccountingTable
                  updateInvoice={this.updateInvoice}
                  getNewData={this.getNewData}
                  property={
                    this.props.selectedPrimary &&
                    this.props.selectedPrimary.node &&
                    this.props.selectedPrimary.node.customId
                  }
                  offset={this.state.offset}
                  handleOffset={this.handleOffset}
                  dataPush={this.dataPush}
                  user={this.props.user}
                  getLedger={this.getLedger}
                  totalItems={this.state.totalItems && this.state.totalItems}
                  propertyId={
                    this.props.selectedPrimary.node &&
                    this.props.selectedPrimary.node.id
                  }
                  mainHeader={mainHeader}
                  mainCellData={this.state.tableData}
                />
              </div>
            )}
        </main>
      </>
    );
  }
}

export default withApollo(JournalReport);