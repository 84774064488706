import React, {useEffect, useState} from "react";
import {
  Button,
  Form,
  Modal,
  Dropdown,
  Radio,
  Segment,
  Table,
  TextArea,
  Icon,
  Input,
} from "semantic-ui-react";

import {
  ChargeAccounting,
  BillCreateMutation,
  LedgerAccounting,
  VendorsAccounting,
  UpdatePurchaseOrder,
  IssuePurchaseOrder,
  BillAccounting,
  DeletePurchaseOrder,
  PurchaseOrderLineItems,
  CreatePurchaseOrder
} from "../../store/person/accounting";
import {
  maintenanceGql,
  WarehouseGql
} from "../../store/person/maintenance";
import { ACCOUNTING, INVOICE_ACCOUNTING_PAYMENT, MAINTENANCE_URL,LEASE_MANAGER,} from "../../utils/constants";
import { getClient } from "../../init-apollo-googleFn";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import get from "lodash/get";
import moment from "moment";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
import mixpanel from "mixpanel-browser";
const accountingClient = getClient(ACCOUNTING);
const paymentClient = getClient(INVOICE_ACCOUNTING_PAYMENT);
const maintenanceClient = getClient(MAINTENANCE_URL);
const leaseManager = getClient(LEASE_MANAGER);

function WorkOrderModal(props) {
  const [open, setOpen] = React.useState(false);
  const [vendor, setVendor] = React.useState(false);
  const [chargeData, setChargeData] = React.useState(false);
  const [externalId, setExternalId] = React.useState(false);
  const [accountName, setAccountName] = React.useState(false);
  const [vendorName, setVendorName] = React.useState(false);
  const [chosenLedger, setChosenLedger] = React.useState(false);
  const [wareHouseName, setWareHouseDataName] = React.useState(false)
  const [processing, setprocessing] = React.useState(0)

  const [autoIssue, setAutoIssue] = useState(false);
  const onChangeCheckbox = (event, value) => setAutoIssue(value.checked)
  

  const [issueDate, setIssueDate] = React.useState(false);
  const [deliveryDate, setDeliveryDate] = React.useState(false);
  const [maintenanceArray, setMaintenanceArray] = React.useState([]);
  const [vendorArray, setVendorArray] = React.useState([]);
  const [tenantCharges, setTenantCharges] = useState([]);
  const [wareHouseData, setWareHouseData] = React.useState(false);
  const [wareHousesArray, setWarehousesArray] = React.useState([]);
  const [POLItemArray, setPOLItemArray] = React.useState([ 
   
  ]);

  const fetchLeaseCharges = () => {
    accountingClient
      .query({
        query: ChargeAccounting,
        variables: {
          location: props.propertyId,
        },
      })
      .then((response) => {
        const chargeData = get(response, "data.chargeCodes.edges", null);
        setTenantCharges(chargeData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  // Creates the bill
  const createBill = (e) => {
    setprocessing(1)
    POLItemArray.forEach(function (element) {
      if(element.description == ""){
        element.description = " "
      }
    });
    if (props.edit) {
      try {
        mixpanel.track("Manager Accounting Action", {
          sub: "Update Work Order",
        });
        accountingClient
          .mutate({
            mutation: UpdatePurchaseOrder,
            variables: {
              purchaseOrder:props.data._id,
              input: {
                location: props.propertyId,
                externalId: externalId,
                POLineItems: POLItemArray,
                issueDate: issueDate.toString(),
              },
            },
          })
          .then((response) => {
            setOpen(false);
            setprocessing(0)
            if (response.data.updatePurchaseOrder.response.includes("error")) {
              toastFailMsg(response.data.updatePurchaseOrder.response);
            } else {
              toastSuccessMsg("Bill Edited Successfully");
              const data = response.data.updatePurchaseOrder.purchaseOrder
              setTimeout(() => {
                props.updateInvoice(data, "update")
              }, 1000);
            }
          })
          .catch((error) => {
            setprocessing(0)
            console.log(error);
          });
      } catch (e) {
        setprocessing(0)
        alert(e);
      }
    } else {
      try {
        mixpanel.track("Manager Accounting Action", {
          sub: "Create Work Order",
        });
        accountingClient
          .mutate({
            mutation: CreatePurchaseOrder,
            variables: {
              input: {
                location: props.propertyId,
                externalId: externalId,
                VendorId: vendor,
                WarehouseId:wareHouseData,
                POLineItems: POLItemArray,
                issueDate: issueDate.toString(),
                deliveryDate: deliveryDate.toString(),
                // Ledger Account ID
                InventoryReceivingAccountId:chosenLedger
              },
            },
          })
          .then((response) => {
            setprocessing(0)
            if (response.data.createPurchaseOrder.response.includes("error")) {
              toastFailMsg(response.data.createPurchaseOrder.response);
            } else {
              if(autoIssue){
                issueOrder(response.data.createPurchaseOrder.purchaseOrder._id)
              }
              toastSuccessMsg("Work Order Successfully Added");
              if(!props.fromMaintenance){
                setTimeout(() => {
                  props.getLedger("update")
                }, 1000);
              }
              setOpen(false);
            }
          })
          .catch((error) => {
            setprocessing(0)
            console.log(error);
          });
      } catch (e) {
        setprocessing(0)
        alert(e);
      }
    }
  };

  const addFunctionQuant = (item, usage) => {
    const sum = item.reduce(add, 0);
    function add(accumulator, a) {
      if(usage == "quantity"){
        return (accumulator += parseFloat(a.quantity));
      }
      if(usage == "amount"){
        return (accumulator += parseFloat(a.amount)*parseFloat(a.quantity));
      }
    }
    return sum;
  };
 
  const changeValue = (e, value) => {
    const newObj = {
      description:value.name,
      quantity:1,
      amount:value.amountDefault,
      ItemId:0,
      LedgerAccountId:value.extLedgerId
    }
    setPOLItemArray(POLItemArray => [...POLItemArray, newObj]);
  };

  const AddRow = () => {
    const newObj = {
      "description":' ',
      "ItemId": 0,
      "quantity": 0,
      "amount": 0,
      "LedgerAccountId":0
    }
    setPOLItemArray([...POLItemArray, newObj])
  }

  const issueOrder = (e) => {
    setprocessing(3)
    try {
      paymentClient
        .mutate({
          mutation: IssuePurchaseOrder,
          variables: {
            input:{
              location:  props.propertyId,
              purchaseOrder: props.fromMaintenance?e:props.data._id
            }
          },
        })
        .then((res) => {
          const data = res.data.issuePurchaseOrder.purchaseOrder
          props.fromMaintenance && props.getMaintenanceRequests("workOrder")
          setprocessing(0)
          toastSuccessMsg("Work Order Issued");
          !props.fromMaintenance &&props.updateInvoice(data, "issue")
          
        })
        .catch((error) => {
          setprocessing(0)
          console.log(error)
        });
    } catch (e) {setprocessing(0)}
  }

  const deleteOrder = () => {
    setprocessing(2)
    try {
      paymentClient
        .mutate({
          mutation: DeletePurchaseOrder,
          variables: {
            input:{
              location:  props.propertyId,
              purchaseOrder: props.data._id
            }
          },
        })
        .then((res) => {
          const data = res.data.deletePurchaseOrder.purchaseOrder
          toastSuccessMsg("Work Order Deleted Added");
          setprocessing(0)
          props.updateInvoice(props.data._id, "delete")
        })
        .catch((error) => {
          setprocessing(0)
          toastFailMsg("Something went wrong")
        });
    } catch (e) {setprocessing(0)}
  }

  // Gets the available ledgers
  const getWarehouse = () => {
    fetchLeaseCharges();
    if(props.edit) {
      setData(props.data)
    }
    if(!props.edit){
      clearState();
    }
    try {
      paymentClient
        .query({
          query: WarehouseGql,
          variables: {
            location: props.propertyId,
          },
        })
        .then((res) => {
          if(props.fromMaintenance){
            setExternalId(props.item.node.id)
            getVendors();
          }
          else {
            getMaintenance();
          }
          const warehouseData = get(res, "data.wareHouses.data", null);
          if (res.data) {
            setWarehousesArray(warehouseData);
          }
        })
        .catch((error) => {});
    } catch (e) {}
  };

  // Upon edit this sets the fields
  const setData = (data) => {
    try {
      paymentClient
        .query({
          query: PurchaseOrderLineItems,
          variables: {
            PurchaseOrderId: data._id,
          },
        })
        .then((res) => {
          const purchaseData = get(res, "data.purchaseOrderLineItems.data", null);
          const purchaseArray = purchaseData.map(item => (
              {
                "description":item.description,
                "ItemId": 0,
                "quantity": parseInt(item.quantity),
                "amount": parseFloat(item.total)/parseInt(item.quantity),
                "LedgerAccountId":0
              }
          )
          )
          setVendor(data.Vendor._id);
          setVendorName(data.Vendor.name);
          setExternalId(data.externalId);
          setIssueDate(data.issueDate);
          setDeliveryDate(data.deliveryDate);
          setWareHouseDataName(data.Warehouse.name);
          setWareHouseData(data.WarehouseId);
          if (res.data) {
            setPOLItemArray(purchaseArray);
          }
        })
        .catch((error) => {});
    } catch (e) {}
      
  };
  const clearState = () => {
    setVendor(false);
    setExternalId(false);
    setprocessing(0)
    setAccountName(false);
    setVendorName(false);
    setIssueDate(false);
    setDeliveryDate(false);
    setMaintenanceArray([]);
    setVendorArray([]);
    setWareHouseData(false);
    setWarehousesArray([]);
    setPOLItemArray([])
  };

  const wareHouseOptions = wareHousesArray &&
    wareHousesArray.map((charge) => ({
        key: charge._id,
        text: charge.name,
      value: charge._id,
    }));

  // Get all the ledger items
  const getLedger = () => {
    if(chargeData ==false){
      try {
        accountingClient
          .query({
            query: LedgerAccounting,
            variables: {
              location: props.bill ? props.location.propertyId : props.propertyId,
              offset: 0,
              limit: 1000,
            },
          })
          .then((res) => {
            const ledger = res.data.slLocationLedgerAccounts.data;
            setChargeData(ledger)
          })
          .catch((error) => {
            console.log(error)
            toastFailMsg("Unable to gather Ledgers");
          });
      } catch (e) {
        alert(e);
      }
    }
  };

  const getMaintenance = () => {
    const statusType = [
      "Submitted",
      "Processing",
      "Scheduled",
      "Entered",
      "Completed",
      "Canceled",
      "Assigned",
      "Approval Needed",
      "Approved",
    ];
    maintenanceClient
      .query({
        query: maintenanceGql,
        variables: {
          role: 'manager',
          status: statusType,
          location:props.propertyId,
        },
      })
      .then((res) => {
        getVendors();
        const maintArr = res.data.maintenanceRequest.edges
        setMaintenanceArray(maintArr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Get all the vendors
  const getVendors = () => {
    accountingClient
      .query({
        query: VendorsAccounting,
        variables: {
          location: props.propertyId,
        },
      })
      .then((res) => {
        if (res.data) {
          const array = res.data.slLocationVendors.data;
          setVendorArray(array);
        }
        getLedger()
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validation = (currentDate) => {
    return currentDate.isBefore(moment().add(1, "days"));
  };
  const validationCheck = () => {
    if(!vendor || 
      !issueDate ||
      !deliveryDate || 
      !externalId||
      !wareHouseData ||
      processing !==0||
      POLItemArray.filter(item => item.quantity !== 0).length ==0||
      POLItemArray.filter(item => item.amount !== 0||0.00).length ==0 
      ){
      return true
    }
    else return false
  };
  const removeCharge = (item) => {
    const newCharges = POLItemArray.filter((charge) => charge !== item);
    setPOLItemArray(newCharges);
  };
  let inputProps = {
    disabled: true,
};

  return (
    <Modal
      className="semanticModal"
      onClose={() => setOpen(false)}
      style={{height:"700px"}}
      onOpen={() => setOpen(true)}
      open={open}
      size="large"
      trigger={
        <Button
          compact
          icon={props.edit ?true: props.fromMaintenance?true:false}
          onClick={() => getWarehouse()}
          className="ml-3 button-hover-universal"
          size="tiny"
          id="getWarehouse"
        >
           {props.edit ? <Icon name="edit" /> : props.fromMaintenance?<Icon name="wrench"/>:<span>Create</span>}
        </Button>
      }
    >
      <Modal.Header>{props.edit ? "Edit Work Order" : props.fromMaintenance?`Create Work Order - #${props.item.node.id}`:"Create Work Order"}</Modal.Header>
      <Modal.Content style={{ margin: "15px",height:"540px" }}>
        <Form>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Vendor</label>
              <Dropdown
                style={{zIndex:'500000'}}
                onChange={(e, { value }) => setVendor(value)}
                placeholder={props.edit ? vendorName : "Vendors"}
                selection
                disabled={props.edit}
                value={props.edit && vendor}
                options={
                  vendorArray &&
                  vendorArray.map((vend) => ({
                    key: vend._id,
                    text: vend.name,
                    value: vend._id,
                  }))
                }
              />
            </Form.Field>
            <Form.Field required>
              <label>Issue Date</label>
              <Datetime
                isValidDate={validation}
                className="bills-times"
                value={issueDate && moment(issueDate).format("MM/DD/YYYY")}
                timeFormat={false}
                onChange={(e) => setIssueDate(e._d)}
              />
            </Form.Field>
            <Form.Field required>
              <label>Delivery Date</label>
              <Datetime
                className={`${props.edit &&"bills-times-disabled"} bills-times`}
                value={deliveryDate && moment(deliveryDate).format("MM/DD/YYYY")}
                timeFormat={false}
                onChange={(e) => setDeliveryDate(e._d)}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>External Id</label>
              <Dropdown
                onChange={(e, { value }) => setExternalId(value)}
                placeholder={props.edit ? externalId : props.fromMaintenance ? props.item.node.id:""}
                selection
                disabled={props.fromMaintenance}
                value={props.edit && externalId}
                options={
                  maintenanceArray &&
                  maintenanceArray.map((account) => ({
                    key: account.node.id,
                    text: account.node.id,
                    value: account.node.id,
                  }))
                }
              />
            </Form.Field>
            <Form.Field required>
              <label>Ledger</label>
              <Dropdown
                onChange={(e, { value }) => setChosenLedger(value)}
                selection
                options={
                  chargeData &&
                  chargeData.map((account) => ({
                    key: account._id,
                    text: account.name,
                    value: account._id,
                  }))
                }
              />
            </Form.Field>
            <Form.Field required className="poldata">
              <label>Warehouse</label>
              <Dropdown
                onChange={(e, { value }) => setWareHouseData(value)}
                placeholder={props.edit ? wareHouseName : ""}
                search
                selection
                disabled={props.edit}
                // value={chargeData}
                options={wareHouseOptions}
              />
            </Form.Field>
          </Form.Group>
          {/* <Form.Group widths="equal">
            <Form.Field>
              <label>Notes</label>

              <TextArea
                placeholder="Notes"
                value={notes}
                style={{ minHeight: 100 }}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Form.Field>
          </Form.Group> */}
          {/* <Form.Field> */}

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width="8">
                <Form.Field>
                <Dropdown
                  inline
                  className="dropdownWorkOrder"
                  onChange={(event, {value}) => changeValue(event, value)}
                  selection
                  // placeholder={customerName}
                  options={tenantCharges&&tenantCharges.map((val) => ({
                    key: val.node.id,
                    text: val.node.name,
                    value: val.node,
                }))}
                />
                </Form.Field>
                </Table.HeaderCell>
                <Table.HeaderCell width="4">Quantity <span style={{color:'red'}}>*</span></Table.HeaderCell>
                <Table.HeaderCell width="4">Amount <span style={{color:'red'}}>*</span></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          </Table>

          <div className="poldata" style={{ maxHeight: "200px", overflowY: "auto", width: "100%", height:'200px'}}>
            <Table celled>
              <Table.Body>
              {
                POLItemArray && POLItemArray.map((item, index) => {
                  return (
                      <>
                        <Table.Row>
                        <Table.Cell width="2">
                        <Button compact fluid onClick={() => removeCharge(item)}>Delete</Button>
                          </Table.Cell>
                          <Table.Cell width="6">
                          <Input
                              iconPosition="left"
                              fluid
                              disabled
                              placeholder={item.description}
                            >
                              <input
                                onChange={(event) => changeValue(event, index, "description")}
                              />
                            </Input>
                          </Table.Cell>
                          <Table.Cell width="4">
                          <Input
                              iconPosition="left"
                              fluid
                              placeholder={item.quantity}
                            >
                              <Icon name="hashtag" />
                              <input
                                onChange={(event) => changeValue(event, index, "quantity")}
                                disabled
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Input>
                          </Table.Cell>
                          <Table.Cell width="4">
                            <Input
                              iconPosition="left"
                              fluid
                              disabled
                              placeholder={parseFloat(item.amount).toFixed(2)}
                            >
                              <Icon name="dollar" />
                              <input
                                onChange={(event) => changeValue(event, index, "amount")}
                                disabled
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Input>
                          </Table.Cell>
                        </Table.Row>
                      </>
                    );
                })
              }
              </Table.Body>
            </Table>
          </div>
          <Table celled>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell width="9">Total</Table.HeaderCell>
                <Table.HeaderCell width="3">
                  {" "}
                  {addFunctionQuant(POLItemArray, "quantity")}
                </Table.HeaderCell>
                <Table.HeaderCell width="3">$
                  {addFunctionQuant(POLItemArray, "amount")}
                 </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Form>
      </Modal.Content>
      <Modal.Actions>
      <Button
        onClick={() => setOpen(false)}
        compact
        floated="left"
        className="ml-3"
        >
        Close
        </Button>
        <Radio onChange={(evt, data)=>onChangeCheckbox(evt, data)} toggle label="Auto Issue" />
      {
        props.edit && props.data &&props.data.status == "created" &&
        <Button
        onClick={() => issueOrder()}
        compact
        disabled={validationCheck()}
        className="ml-3 button-hover-universal"
        >
        {
          processing == 3? <span>Processing...<Button style={{paddingBottom:"0px"}} compact disabled loading></Button></span>:<span>Issue</span>
        }
        </Button>
      }
      {
        props.edit && props.data &&props.data.status == "created" &&
         <Button
        onClick={() => deleteOrder()}
        compact
        disabled={validationCheck()}
        className="ml-3 button-hover-universal"
        >
        {
          processing == 2? <span>Processing...<Button style={{paddingBottom:"0px"}} compact disabled loading></Button></span>:<span>Delete</span>
        }
        </Button>
        
      }
      {
        props.edit && props.data &&props.data.status == "created" &&
        <Button
        onClick={() => createBill()}
        compact
        disabled={validationCheck()}
        className="ml-3 button-hover-universal"
        >
        {
          processing == 1? <span>Processing...<Button style={{paddingBottom:"0px"}} compact disabled loading></Button></span>:<span>{props.edit? "Update": "Submit"}</span>
        }
        </Button>
      }
      {
        !props.edit &&
        <>
          
        <Button
        onClick={() => createBill()}
        compact
        disabled={validationCheck()}
        className="ml-3 button-hover-universal"
        >
        {
          processing == 1? <span>Processing...<Button style={{paddingBottom:"0px"}} compact disabled loading></Button></span>:<span>{props.edit? "Update": "Submit"}</span>
        }
        </Button>
        </>
      }
      </Modal.Actions>
    </Modal>
  );
}

export default WorkOrderModal;
