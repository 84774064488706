import React, { Component } from "react";
import { withApollo } from "react-apollo";
import mixpanel from "mixpanel-browser";
import { InvoiceAccounting, InvoiceInfoAccounting } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import get from "lodash/get";
import { Loader } from "../../components/Loader/Loader";
import { Table } from "../../components/Tables/CreditsData";
import { ACCOUNTING, INVOICE_ACCOUNTING  } from "../../utils/constants";
import axios from "axios";
import getAuthToken from "../../store/auth/authUtility";
import "react-toastify/dist/ReactToastify.css";
import "./Accounting.scss";
import moment from 'moment';
import { Button, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";
import InvoicesModal from "../../components/Modals/InvoicesModal";
import VoidInvoiceModal from "../../components/Modals/VoidInvoiceModal";
import ViewInvoiceDetails from "../../components/Accounting/ViewInvoiceDetails";
import { GeneralAccountingTable } from "../../components/Tables/GeneralAccountingTable";

const invoiceClient = getClient(ACCOUNTING);

class Credits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary: this.props.selectedPrimary
        ? this.props.selectedPrimary.node
        : "",
      loading: false,
      invoiceCache: [],
      offset: 0,
      old: [],
      toggled: false,
      viewInvoiceData: "",
      viewInvoicesData: "",
    };
  }

  // const [toggled, viewChange] = useState(false);
  // const [viewInvoiceData, viewDataInvoice] = useState("");
  // const [viewInvoicesData, viewDataInvoices] = useState("");

  componentDidMount() {
    this.getLedger();
    mixpanel.track("Manager Page Load", {
      sub: "Credits",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.primary) {
      this.setState({ primary: nodeData }, () => {
        this.setState({
          selectedMonthEvents: {
            ...this.state.selectedMonthEvents,
            montEvents: [],
          },
        });
        this.getLedger();
      });
    }
  }

  download = (item) => {
    const url = new URL(INVOICE_ACCOUNTING);
    url.searchParams.append("invoice_id", item._id);
    url.searchParams.append("action", "invoice");
    axios({
      method: "get",
      url: url.href,
      headers: {
        authorization: getAuthToken(),
      },
      responseType: "blob", // important
    })
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        window.open(fileURL);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  viewInvoice = (item) => {
    this.setState({viewInvoicesData: item});
    if (this.state.toggled) {
      this.setState({toggled: false});
    }
    if (!this.state.toggled) {
      this.getInvoice(item);
    }
  };

  StatusFormat = (values) => {
    if (values === "partiallyPaid") return "Partially Paid";
    if (values === "paid") return "Paid";
    if (values === "issued") return "Issued";
    if (values === "created") return "Created";
    if (values === "approved") return "Approved";
    if (values === "voided") return "Voided";
    else return "";
  };

  success = () =>
    toast.success("Success!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );

  Actions = (item) => {
    return (
      <>
        {item.status === "created" && (
          <InvoicesModal
            propertyId={this.props.selectedPrimary.node.id}
            success={this.success}
            user={this.props.user}
            props={item}
          />
        )}
        {item.status === "approved" && (
          <InvoicesModal
            propertyId={this.props.selectedPrimary.node.id}
            success={this.success}
            user={this.props.user}
            props={item}
          />
        )}
        {item.status !== "voided" && (
          <VoidInvoiceModal
            propertyId={this.props.selectedPrimary.node.id}
            updateInvoice={this.updateInvoice}
            success={this.success}
            user={this.props.user}
            props={item}
          />
        )}
        <Button icon compact className="button-hover-universal"
          onClick={()=>this.download(item)}
          >
            <Icon name='file' />
        </Button>
        <Button
          compact
          icon='world'
          className="button-hover-universal"
          onClick={()=>this.viewInvoice(item)}
          property={this.props.property}
          props={item}
        />
      </>
    )
  }

  getLedger = (data) => {
    try {
      this.setState({ loading: true });
      invoiceClient
        .query({
          query: InvoiceAccounting,
          variables: {
            location: this.props.selectedPrimary.node.id,
            credits: true,
            offset: this.state.offset,
            limit: 30,
          },
        })
        .then((res) => {
          if (res.data) {
            const dataToPass = get(res, "data.slLocationInvoices.data", []).map((obj, i) => {
              const { amount, invoiceDate, number, postedDate, status } = obj;
              const uiAmount = (Number(amount) <0 ? `$(${Math.abs(parseFloat(amount).toFixed(2)).toLocaleString("en")})` : `$${Math.abs(parseFloat(amount).toFixed(2)).toLocaleString("en")}`);
              const uiInvoiceDate = moment(invoiceDate).format("l");
              const uiPostedDate = moment(postedDate).format("l");
              const uiStatus = this.StatusFormat(status);
              const actions = this.Actions(obj);
              return {number, uiStatus, uiAmount, uiInvoiceDate, uiPostedDate, actions};
            });
            this.setState({
              invoiceCache: dataToPass,
              old: get(res, "data.slLocationInvoices.data", []),
              totalItems: get(res, "data.slLocationInvoices.totalItems"),
            });
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
        });
      } catch (e) {
      this.setState({ loading: false });
    }
  };

  getInvoice = (item) => {
    try {
      invoiceClient
        .query({
          query: InvoiceInfoAccounting,
          variables: {
            location: this.props.selectedPrimary.node.id,
            id: item._id,
          },
        })
        .then((res) => {
          if (res.data) {
            const data = res.data.slLocationInvoice;
            /* eslint-disable */
            const Check = data.payments.map((payment) => {
              /* eslint-enable */
              const check = data.payments.filter(
                (pay) => pay.externalCheckBankId === payment._id
              );
              if (check.length > 0) {
                return (payment.status = "refund");
              }
            });
            this.setState({viewInvoiceData: res.data.slLocationInvoice, toggled: true});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {}
  };

  dataPush = (response) => {
    this.setState((prevState) => ({
      invoiceCache: [
        response.data.createSlInvoice.slInvoice,
        ...prevState.invoiceCache,
      ],
    }));
  };
  updateInvoice = (status, invoiceId) => {
    const { invoiceCache } = this.state;
    const array = [];
    if (status === "Delete") {
      this.setState({
        invoiceCache: invoiceCache.filter(
          (invoice) => invoice._id !== invoiceId
        ),
      });
    } else {
      invoiceCache.forEach((invoice) => {
        if (invoice._id === invoiceId) {
          invoice.status = status;
          array.push(invoice);
        } else {
          array.push(invoice);
        }
      });
      this.setState({ invoiceCache: array });
    }
  };

  handleOffset = (x) => {
    this.setState({offset: x});
  }

  render() {
    const { loading } = this.state;
    const mainHeader = ["Number", "Status", "Amount", "Invoice", "Posted", "Actions"];
    const fail = () =>
    toast.error("No agent with this ID exists in our system", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const success = () =>
    toast.success("Success!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

      // !: Actions. Error handling.
      // TODO: Format data for user.

    return (
      <>
        <main className="main-content" role="main">
        <div className="row mb-4">
          <div className="col-md-12">
            <div className="page-header">
              <div className="d-flex align-items-center">
                {/* <span className="page-back"> */}
                  {/* <img src={backIcon} alt="back" /> */}
                  
                {/* </span> */}
                <div>
                  <div className="page-header-title">Credits
                  {this.state.toggled &&
                    <Button style={{marginLeft: '12px'}} onClick={()=>this.viewInvoice()}>Back</Button>
                  }
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-end">
              <InvoicesModal
              dataPush={this.dataPush}
              fail={fail}
              user={this.props.user}
              success={success}
              propertyId={
              this.props.selectedPrimary.node &&
              this.props.selectedPrimary.node.id
            }
            />
                <Button
                onClick={() => window.print()}
                compact
                  className="ml-4 noPrint noPrint-button"
                  style={{
                    backgroundImage:
                      "linear-gradient(110deg, #3b1c5a, #374db1 162%)",
                  }}
                >
                  Print
                </Button>
              </div>
            </div>
          </div>
        </div>
            {loading ? (
              <Loader text inTable />
            ) : (
              !this.state.toggled ?
              <>
                {/* <Table
                  updateInvoice={this.updateInvoice}
                  user={this.props.user}
                  getNewData={this.getNewData}
                  property={
                    this.props.selectedPrimary &&
                    this.props.selectedPrimary.node &&
                    this.props.selectedPrimary.node.customId
                  }
                  dataPush={this.dataPush}
                  getLedger={this.getLedger}
                  invoiceCache={
                    this.state.old && this.state.old
                  }
                  totalItems={this.state.totalItems && this.state.totalItems}
                  propertyId={
                    this.props.selectedPrimary.node &&
                    this.props.selectedPrimary.node.id
                  }
                /> */}
                <GeneralAccountingTable
                  mainHeader={mainHeader}
                  mainCellData={this.state.invoiceCache}
                />
            </>
            :
              <ViewInvoiceDetails
                property={this.props.selectedPrimary.node.name}
                invoice={this.state.viewInvoiceData}
                invoices={this.state.viewInvoicesData}
                ledgerView={this.viewInvoice}
              />
            )}
        </main>
      </>
    );
  }
}

export default withApollo(Credits);
