import React, { useEffect } from "react";
import { Button, Modal } from "semantic-ui-react";
import * as yup from "yup";
import { useFormik } from "formik";
import { MONTH_NUMS, WEPAY_URL, YEAR_NUMS } from "../../../utils/constants";
import LeaLoader from "../../PageLoader/LeaLoader";
import { getClient } from "../../../init-apollo-googleFn";
import "./LeaseModal.scss";
import { CreatePaymentMethodGql } from "../../../store/wepay/wepay";

const wePayClient = getClient(WEPAY_URL);

const validationSchema = yup.object().shape({
  number: yup
    .string()
    .max(16, "Card number should be 12 digits")
    .min(16, "Card number should be 12 digits")
    .required(),
  name: yup.string().required(),
  zip: yup.string().required(),
  cvv: yup.string().required(),
  zip: yup.string().min(5, "Zip code should be minimum 5 digits").required(),
  month: yup.string().required(),
  year: yup.string().required(),
});

const initialValues = {
  number: "5496198584584769",
  name: "test",
  zip: "98116",
  cvv: "213",
  month: "10",
  year: "25",
};

export function AddCreditCardMethod(props) {
  const [process, setProcess] = React.useState(false);
  const createCreditCardMethod = async (values, options) => {
    setProcess(true);

    const { number, name, month, year, cvv, zip } = values;
    console.log("CARD NUM: ", number);
    console.log("NAME: ", name);
    console.log("EXP MONTH: ", month);
    console.log("EXP YEAR: ", year);
    console.log("CVV: ", cvv);
    console.log("ZIP: ", zip);
    const n = number.replace(/\s+/g, "");

    try {
      await wePayClient.mutate({
        mutation: CreatePaymentMethodGql,
        variables: {
          paymentMethod: {
            type: "credit_card",
            payoutMethod: true,
            creditCard: {
              nameOnAccount: name,
              card: {
                number: n,
                expMon: month,
                expYear: year,
                cvv,
              },
              zipPostalCode: zip,
              paymentMethodIdentifier: "",
              preferredPayment: false,
            },
          },
        },
      });

      console.log("SUCCESSFULLY ADDED CARD!");
      options.resetForm();
    } catch (error) {
      console.log(error);

      if (error.message.includes("Already Exists")) {
        //exist
      }
    } finally {
      setProcess(false);
    }
  };

  const { handleSubmit, values, errors, touched, setFieldValue } = useFormik({
    validationSchema,
    initialValues,
    onSubmit: createCreditCardMethod,
  });

  const [logo, setLogo] = React.useState("");

  useEffect(() => {
    GetCardType();
  });

  const { number, name, cvv, zip, month, year } = values;

  const GetCardType = () => {
    var re = new RegExp("^4");
    if (number.match(re) != null) {
      setLogo("logo-visa.svg");
    }
    if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        number
      )
    ) {
      setLogo("master-logo.svg");
    }
    re = new RegExp("^3[47]");
    if (number.match(re) != null) {
      setLogo("logo-amex.svg");
    }
    re = new RegExp(
      "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
    );
    if (number.match(re) != null) {
      setLogo("logo-discover.svg");
    }
    re = new RegExp("^36");
    if (number.match(re) != null) {
      setLogo("logo-diners.svg");
    }
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null) {
      setLogo("logo-jcb.svg");
    }
    return "";
  };

  console.log("errors", errors);

  return (
    <Modal
      open={props.showModal}
      onClose={() => props.toggleIsShowing(false)}
      size="large"
      className="semanticModal addcredit-card-modal"
    >
      <Modal.Header>
        Add Credit Card
        <img
          src="/assets/img/close-modal.svg"
          id="close"
          alt="close"
          onClick={() => props.toggleIsShowing(false)}
        ></img>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div className="card-holder">
            <img src={logo && `/assets/img/${logo}`} alt=""></img>
            <div className="form-rw">
              <input
                type="text"
                value={
                  number.length === 15
                    ? number.replace(/\b(\d{4})(\d{6})(\d{5})\b/, "$1-$2-$3")
                    : number.toString().replace(/\d{4}(?=.)/g, "$&-")
                }
                onChange={(event) => {
                  setFieldValue(
                    "number",
                    event.target.value.replace(/[^0-9]/g, "")
                  );
                }}
                maxLength={19}
                name="number"
                id="number"
                className="form-control"
              ></input>
            </div>
            <div className="footer-data">
              <div className="left-data">
                <label>Card Holder Name</label>
                <input
                  type="text"
                  onChange={(event) => {
                    setFieldValue("name", event.target.value);
                  }}
                  value={name}
                  name="name"
                  id="name"
                  className="form-control name-field"
                ></input>
              </div>
              <div className="right-data">
                <label>Month</label>
                <select
                  style={{ border: "none" }}
                  name="month"
                  id="month"
                  defaultValue={month}
                  className="form-control-select"
                  onChange={(event) => {
                    setFieldValue("month", event.target.value);
                  }}
                >
                  {Object.keys(MONTH_NUMS).map((key, val) => {
                    return (
                      <option value={MONTH_NUMS[val]}>{MONTH_NUMS[val]}</option>
                    );
                  })}
                </select>
                {/* <input type="number" onChange={(event) => {setMonthValid(event.target.value)}} className="form-control"></input> */}
              </div>
              <div className="right-data">
                <label>Year</label>
                <select
                  style={{ border: "none" }}
                  name="year"
                  id="year"
                  defaultValue={year}
                  className="form-control-select"
                  onChange={(event) => {
                    setFieldValue("year", event.target.value);
                  }}
                >
                  {Object.keys(YEAR_NUMS).map((key, val) => {
                    return (
                      <option value={YEAR_NUMS[val]}>{YEAR_NUMS[val]}</option>
                    );
                  })}
                </select>
                {/* <input type="number" max="4"  onChange={(event) => {setYearValid(event.target.value)}} name="year" id="year" className="form-control"></input> */}
              </div>
            </div>
          </div>
          {/* <div className="form-group">
                            <label>Card Number</label>
                            <input type="text" className="form-control"></input>
                        </div>
                        <div className="form-group">
                            <label>Card Holder Name</label>
                            <input type="number" className="form-control"></input>
                        </div> */}
          <div className="form-rw">
            <div className="form-group">
              <label>Billing Zip</label>
              <input
                type="tel"
                value={zip}
                maxlength="5"
                onChange={(event) => {
                  setFieldValue("zip", event.target.value.replace(/[^0-9]/g));
                }}
                name="zip"
                id="zip"
                className="form-control"
              ></input>
            </div>
            <div className="form-group">
              <label>CVV</label>
              <input
                type="tel"
                value={cvv}
                onChange={(event) => {
                  setFieldValue("cvv", event.target.value.replace(/[^0-9]/g));
                }}
                name="cvv"
                id="cvv"
                className="form-control"
              ></input>
            </div>
          </div>
          {/* <div className="form-group">
                            <label>ZIP Code for billing address</label>
                            <input type="text" className="form-control"></input>
                        </div> */}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          disabled={Object.keys(errors).length > 0 || process}
          type="button"
          onClick={handleSubmit}
        >
          {process ? (
            <LeaLoader
              wrapperStyle={{
                position: "relative",
                left: "-10px",
                bottom: "16px",
              }}
              logoStyle={{ height: "60px", width: "60px" }}
            />
          ) : (
            "Add Credit Card"
          )}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default AddCreditCardMethod;
