import React, { useMemo, useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";
import {  toast } from "react-toastify";
import AccountingTable from "./AccountingTable";
import { multipleColumnsSearch } from "../../utils/common";
import {
  getEmitter,
} from "../../utils";
import LedgerCreationAccounting from "../Modals/LedgerCreationAccounting";
import ViewLedgerDetails from "../Accounting/ViewLedgerDetails";
import "react-toastify/dist/ReactToastify.css";
import { Link, } from "react-router-dom";
import { ROUTES } from "../../utils/constants";
const eventEmit = getEmitter();
export function Table(props) {
  const [filterInput, setFilterInput] = useState("");
  const [toggled, viewToggle] = useState(false);
  const [viewLedgerData, viewData] = useState("");

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    const filteredData = multipleColumnsSearch(value, props.ledgerCache);
    setData(filteredData);
    setFilterInput(value);
  };

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return "";
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  /* eslint-disable */
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "_id",
        Cell: ({ cell: { value } }) => <Link to={`/accounting/reconcile/${value}`}>{value}</Link>,
      },
      {
        Header: "Number",
        accessor: "number",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Type",
        accessor: "type",
      },
       {
        Header: 'Sub-Type',
        accessor: 'subtype',
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Actions",
        accessor: (item) => (
          <>
            <LedgerCreationAccounting
              updateLedger={props.updateLedger}
              propertyId={props.propertyId}
              props={item}
              user={props.user}
              success={success}
              edit={true}
            />{" "}
            {/* <Button icon compact className="button-hover-universal" size="tiny" onClick={viewLedger} props={item} ><Icon name='eye' /></Button> */}
          </>
        ),
      },
    ],
    []
  );
  /* eslint-enable */

  const viewLedger = (e, props) => {
    viewData(props);
    if (toggled) {
      viewToggle(false);
    }
    if (!toggled) {
      viewToggle(true);
    }
  };

  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState(8);

  /* eslint-disable */
  useEffect(() => {
    // const result = props.totalItems / 30;
    // setLastPage(Math.ceil(result));
    setData(props.ledgerCache);
  },[props.ledgerCache && props.ledgerCache]);

  const success = () =>
    toast.success("Success!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });


  return (
    <>
      {/* eslint-disable */}
      
      {/* eslint-enable */}
      {/* <div className="table-container"> */}
        {!toggled ? (
          <>
            {/* {props.ledgerCache
          && ( */}
            <AccountingTable
              columns={columns}
              data={data}
              defaultColumn={defaultColumn}
              getLedger={props.getLedger}
              offset={props.offset}
              onOffset={props.handleOffset}
              lastPage={lastPage}
              onChange={handleFilterChange}
              totalItems={props.totalItems}
              pageCount={lastPage}
              sortDesc={true}
              sortId={"number"}
              value={filterInput}
            />
            {/* )} */}
          </>
        ) : (
          <>
            <ViewLedgerDetails
              data={viewLedgerData.props}
              ledgerView={viewLedger}
            />
          </>
        )}
      {/* </div> */}
    </>
  );
}