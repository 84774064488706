import React from "react";
import { Container, Form, Button, Label, Icon } from "semantic-ui-react";
import "./NewPushNotification.scss";
import { decodeBase64ID } from "../../utils/base64";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getClient } from "../../init-apollo-googleFn";
import { PUSH_NOTIFICATION_URL, ROUTES } from "../../utils";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
import { sendNotificationGql } from "../../store/person/notifications";
import { PageLoader } from "../../components/Loader/PageLoader";
import Tour from 'reactour'
import { pushSteps } from "../../utils/tourSteps";
import {
  TAB_PUSH_NOTIFICATION,
  WithCommunicationMenu,
} from "../../components/Communications/CommunicationMenu";

const NOT_SELECTED = "not-selected";

const initialValues = {
  title: "",
  body: "",
  locationId: NOT_SELECTED,
  image: "",
  url: "",
  platform: NOT_SELECTED,
  dsPersonId: "",
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required().min(4).max(150),
  body: Yup.string().required().min(4).max(255),
  locationId: Yup.string(),
  image: Yup.string(),
  url: Yup.string(),
  platform: Yup.string().oneOf(["IOS", "ANDROID", "WEB", NOT_SELECTED]),
  dsPersonId: Yup.string(),
});

const pushNotificationClient = getClient(PUSH_NOTIFICATION_URL);

const NewPushNotification = ({
  primaryLocations,
  selectedPrimary,
  match,
  history,
}) => {
  const [loader, setLoader] = React.useState(false);
  const [tourOpen, setTourOpen] = React.useState(false);
  const [activePersonId, setActivePersonId] = React.useState(null);
  const [activeLocationId, setActiveLocationId] = React.useState(null);
  const formikCtx = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      setLoader(true);

      const postData = {};
      Object.keys(values).map((key) => {
        if (values[key] !== "" && values[key] !== NOT_SELECTED) {
          postData[key] = String(values[key]);
        }
      });

      try {
        const response = await pushNotificationClient.mutate({
          mutation: sendNotificationGql,
          variables: postData,
        });
        if (response.data.sendNotification?.total_device_count === 0) {
          toastFailMsg("Device not found!");
        } else {
          toastSuccessMsg(
            `${response.data.sendNotification?.total_device_count} receiver found. ${response.data.sendNotification?.success_count} receiver successfuly got notification and ${response.data.sendNotification?.failure_count} receiver failed`
          );
          actions.resetForm();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoader(false);
      }
    },
  });

  React.useMemo(() => {
    if (match.params.filter_type === undefined) {
      formikCtx.setFieldValue(
        "locationId",
        String(decodeBase64ID(selectedPrimary.node.id))
      );
    }
  }, [selectedPrimary]);

  React.useMemo(() => {
    if (match.params.filter_type === "person") {
      formikCtx.setFieldValue("dsPersonId", match.params.filter_id);
      formikCtx.setFieldValue("locationId", "");
      setActivePersonId(String(match.params.filter_id));
    }

    if (match.params.filter_type === "location") {
      formikCtx.setFieldValue(
        "locationId",
        String(decodeBase64ID(match.params.filter_id))
      );
      formikCtx.setFieldValue("dsPersonId", "");
      setActiveLocationId(String(decodeBase64ID(match.params.filter_id)));
    }
  }, [match.params]);

  const location_options = React.useCallback(() => {
    return [
      { key: "not-selected", value: "not-selected", text: "Not selected" },
      ...primaryLocations.map(({ node }) => ({
        key: node.id,
        value: String(decodeBase64ID(node.id)),
        text: node.name,
      })),
    ];
  }, [primaryLocations]);

  const platform_options = React.useCallback(() => {
    return [
      {
        key: "not-selected",
        value: "not-selected",
        text: "Not Selected",
      },
      {
        key: "ANDROID",
        value: "ANDROID",
        text: "Android",
      },
      {
        key: "IOS",
        value: "IOS",
        text: "iOS",
      },
      {
        key: "WEB",
        value: "WEB",
        text: "Web",
      },
    ];
  }, []);

  React.useMemo(() => {
    if (
      match.params.person_id !== undefined &&
      history.location.state === undefined
    ) {
      history.push({ pathname: ROUTES.pushNotification });
    }
  }, []);

  if (loader) return <PageLoader />;

  return (
    <Container className="push-notification-container">
      <Tour
          steps={pushSteps}
          isOpen={tourOpen}
          onRequestClose={() => setTourOpen(false)}
          // maskClassName="mask"
          className="helper"
          startAt={0}
          rounded={5}
        />
      <div className="header-section">
        <div className="left-container">
          <div className="head push-first-step">Push Notification Service <Icon size='small' circular name='lightbulb' onClick={() => setTourOpen(true)} /></div>
          <p>Here's the push notification dispatch form.</p>
        </div>

        {history.location.state !== undefined &&
          match.params.filter_type === "person" && (
            <div className="alert alert-info">
              <span style={{ fontWeight: "bold" }}>
                {[
                  history.location.state?.firstName,
                  history.location.state?.middleName,
                  history.location.state?.lastName,
                ].join(" ")}{" "}
              </span>
              will be the receiver for this notification.
            </div>
          )}

        {history.location.state !== undefined &&
          match.params.filter_type === "location" && (
            <div className="alert alert-info">
              <span style={{ fontWeight: "bold" }}>
                {history.location.state?.name}{" "}
              </span>
              will be the receiver location for this notification.
            </div>
          )}

        {match.params.filter_id !== undefined &&
          match.params.filter_type === "person" &&
          history.location.state === undefined && (
            <div className="alert alert-warning">
              Person filter detected but person state does not exists. Please go
              to residents > resident detail and click to "send notification"
              button
            </div>
          )}

        {match.params.filter_id !== undefined &&
          match.params.filter_type === "location" &&
          history.location.state === undefined && (
            <div className="alert alert-warning">
              Location filter detected but location state does not exists.
              Please go to properties > view property detail and click to "send
              notification" button
            </div>
          )}

        <Form className="push-notification-form">
          <Form.Field>
            <label htmlFor="title">Title*: </label>
            <Form.Input
              id="title"
              fluid
              required
              maxLength={150}
              defaultValue={formikCtx.values.title}
              placeholder="Notification title"
              onChange={formikCtx.handleChange("title")}
            />
            {formikCtx.errors?.title && (
              <Label pointing color="red" basic>
                {formikCtx.errors.title}
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label htmlFor="body">Body*:</label>
            <Form.TextArea
              id="body"
              fluid
              maxLength={255}
              required
              defaultValue={formikCtx.values.body}
              onChange={formikCtx.handleChange("body")}
              placeholder="Notification message"
              style={{ minHeight: 100 }}
            />
            {formikCtx.errors?.body && (
              <Label pointing color="red" basic>
                {formikCtx.errors.body}
              </Label>
            )}
          </Form.Field>
          {activePersonId === null && activeLocationId === null && (
            <>
              <Form.Field>
                <label htmlFor="location_id">Location</label>
                <Form.Select
                  id="location_id"
                  options={location_options()}
                  defaultValue={formikCtx.values.locationId}
                  onChange={(e: any, target) => {
                    formikCtx.setFieldValue("locationId", target.value);
                  }}
                />
                {formikCtx.errors?.locationId && (
                  <Label pointing color="red" basic>
                    {formikCtx.errors.locationId}
                  </Label>
                )}
              </Form.Field>
            </>
          )}

          <Form.Field>
            <label htmlFor="platform">Platform</label>
            <Form.Select
              id="platform"
              options={platform_options()}
              defaultValue={formikCtx.values.platform}
              onChange={(e: any, target) => {
                formikCtx.setFieldValue("platform", target.value);
              }}
            />
            {formikCtx.errors?.platform && (
              <Label pointing color="red" basic>
                {formikCtx.errors.platform}
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label htmlFor="url">Attach Url:</label>
            <Form.Input
              fluid
              id="url"
              placeholder="ex: http://leasera.com"
              defaultValue={formikCtx.values.url}
              onChange={formikCtx.handleChange("url")}
            />
            {formikCtx.errors?.url && (
              <Label pointing color="red" basic>
                {formikCtx.errors.url}
              </Label>
            )}
          </Form.Field>
          <Form.Field>
            <label htmlFor="image">Image Url:</label>
            <Form.Input
              fluid
              id="image"
              defaultValue={formikCtx.values.image}
              placeholder="direct image url ex: https://"
              onChange={formikCtx.handleChange("image")}
            />
            {formikCtx.errors?.image && (
              <Label pointing color="red" basic>
                {formikCtx.errors.image}
              </Label>
            )}
          </Form.Field>
          <Button type="button" onClick={formikCtx.handleSubmit} fluid>
            Send
          </Button>
        </Form>
      </div>

      {history.location.state !== undefined &&
        match.params.filter_type === "person" && (
          <div className="alert alert-info">
            <span style={{ fontWeight: "bold" }}>
              {[
                history.location.state?.firstName,
                history.location.state?.middleName,
                history.location.state?.lastName,
              ].join(" ")}{" "}
            </span>
            will be the receiver for this notification.
          </div>
        )}

      {history.location.state !== undefined &&
        match.params.filter_type === "location" && (
          <div className="alert alert-info">
            <span style={{ fontWeight: "bold" }}>
              {history.location.state?.name}{" "}
            </span>
            will be the receiver location for this notification.
          </div>
        )}

      {match.params.filter_id !== undefined &&
        match.params.filter_type === "person" &&
        history.location.state === undefined && (
          <div className="alert alert-warning">
            Person filter detected but person state does not exists. Please go
            to residents > resident detail and click to "send notification"
            button
          </div>
        )}

      {match.params.filter_id !== undefined &&
        match.params.filter_type === "location" &&
        history.location.state === undefined && (
          <div className="alert alert-warning">
            Location filter detected but location state does not exists. Please
            go to properties > view property detail and click to "send
            notification" button
          </div>
        )}

      <Form className="push-notification-form">
        <div className="push-second-step">
        <Form.Field >
          <label htmlFor="title">Title*: </label>
          <Form.Input
            id="title"
            fluid
            required
            maxLength={150}
            defaultValue={formikCtx.values.title}
            placeholder="Notification title"
            onChange={formikCtx.handleChange("title")}
          />
          {formikCtx.errors?.title && (
            <Label pointing color="red" basic>
              {formikCtx.errors.title}
            </Label>
          )}
        </Form.Field>
        <Form.Field>
          <label htmlFor="body">Body*:</label>
          <Form.TextArea
            id="body"
            fluid
            maxLength={255}
            required
            defaultValue={formikCtx.values.body}
            onChange={formikCtx.handleChange("body")}
            placeholder="Notification message"
            style={{ minHeight: 100 }}
          />
          {formikCtx.errors?.body && (
            <Label pointing color="red" basic>
              {formikCtx.errors.body}
            </Label>
          )}
        </Form.Field>
        </div>
        {activePersonId === null && activeLocationId === null && (
          <>
            <Form.Field className="push-third-step">
              <label htmlFor="location_id">Location</label>
              <Form.Select
                id="location_id"
                options={location_options()}
                defaultValue={formikCtx.values.locationId}
                onChange={(e: any, target) => {
                  formikCtx.setFieldValue("locationId", target.value);
                }}
              />
              {formikCtx.errors?.locationId && (
                <Label pointing color="red" basic>
                  {formikCtx.errors.locationId}
                </Label>
              )}
            </Form.Field>
          </>
        )}

        <Form.Field className="push-fourth-step">
          <label htmlFor="platform">Platform</label>
          <Form.Select
            id="platform"
            options={platform_options()}
            defaultValue={formikCtx.values.platform}
            onChange={(e: any, target) => {
              formikCtx.setFieldValue("platform", target.value);
            }}
          />
          {formikCtx.errors?.platform && (
            <Label pointing color="red" basic>
              {formikCtx.errors.platform}
            </Label>
          )}
        </Form.Field>
        <div className="push-fifth-step">

        
        <Form.Field>
          <label htmlFor="url">Attach Url:</label>
          <Form.Input
            fluid
            id="url"
            placeholder="ex: http://leasera.com"
            defaultValue={formikCtx.values.url}
            onChange={formikCtx.handleChange("url")}
          />
          {formikCtx.errors?.url && (
            <Label pointing color="red" basic>
              {formikCtx.errors.url}
            </Label>
          )}
        </Form.Field>
        <Form.Field>
          <label htmlFor="image">Image Url:</label>
          <Form.Input
            fluid
            id="image"
            defaultValue={formikCtx.values.image}
            placeholder="direct image url ex: https://"
            onChange={formikCtx.handleChange("image")}
          />
          {formikCtx.errors?.image && (
            <Label pointing color="red" basic>
              {formikCtx.errors.image}
            </Label>
          )}
        </Form.Field>
        </div>
        <Button type="button" onClick={formikCtx.handleSubmit} fluid>
          Send
        </Button>
      </Form>
      
    </Container>
  );
};

export default NewPushNotification;
