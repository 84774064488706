import React from "react";
import "./PageLoader.scss";
import LeaLoader from "../PageLoader/LeaLoader";
/**
  Loader was called by previous Quill template, replaced with Semantic Semantic Loader
  Will keep here so we can style our loaders to look the same throughout the app
**/

export function PageLoader(props) {
  return (
    <div className="container">
      <LeaLoader
        text={props.text}
        inTable={props.inTable}
        stepper={props.stepper}
      />
    </div>
  );
}
