import React, { useEffect } from 'react'
import { Button, Grid, Header, Image, Modal, Checkbox, Input } from 'semantic-ui-react'
import { CATEGORIES_VALUES, MAINTENANCE_URL, LEASES_URL } from "../../utils/constants";
import getAuthToken from "../../store/auth/authUtility";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";

function MoveInChecklist(props) {
    const [open, setOpen] = React.useState(false)
    const [Checklist, setChecklist] = React.useState(props.checklist && JSON.parse(props.checklist))
    useEffect(() => {
        console.log(Checklist)
    });
    const SubmitForm = async () => {
        const formData = new FormData();
        formData.append(
            "query",
            `mutation {
                createChecklist(input: {
                  checklist: {
                    moveInChecklist: "${JSON.stringify(Checklist).replaceAll(/"/g, "'")}", 
                    leaseId:"${props.lease.node.id}"
                  }
                }){
                  response
                }}`
        );
        if (formData) {
            await fetch(LEASES_URL, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    Authorization: getAuthToken(),
                    // contentType: "application/x-www-form-urlencoded"
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *client
                body: formData, // body data type must match "Content-Type" header
            })
                .then((result) => {
                    toastSuccessMsg(
                        "Your Checklist was successfully submitted."
                    )
                    setOpen(false)
                })
                .catch((error) => {
                    toastFailMsg("An error occurred. Please try again later.");
                });
        }


    }

    const updateHandler = (id, value) => {
        const newa = Checklist.map((item, index) => {
            if (index == id) {
                item.value = value
                return item
            }
            else return item
        })
        setChecklist(newa)
    }
    const textHandler = (a, e) => {
        const newaa = Checklist.map((item, index) => {
            if (index == a) {
                item.explain = e
                return item
            }
            else return item
        })
        setChecklist(newaa)
    }
    const Kitchen = [
        "Floor",
        "Walls",
        "Ceiling",
        "Refrigerator",
        "Range",
        "Sinks",
        "Windows",
        "Lighting",
        "Outlets",
        "Cabinets",
        "Other"
    ]
    const Living = [
        "Floor",
        "Walls",
        "Ceiling",
        "Windows",
        "Lighting",
        "Outlets",
        "Other",
    ]

    const Bedroom = [
        "Floor",
        "Walls",
        "Ceiling",
        "Windows",
        "Lighting",
        "Outlets",
        "Doors",
        "Closets",
        "Other"
    ]

    const Bathroom = [
        "Floor",
        "Walls",
        "Ceiling",
        "Windows",
        "Lighting",
        "Outlets",
        "Doors",
        "Closets",
        "Sinks",
        "Shower",
        "Bathroom Door",
        "Fan",
        "Other"
    ]

    const Other = [
        "Carbon MOnoxide Detector",
        "Smoke Alarms",
        "Air-Conditioning",
        "Water Heater",
        "Thermostat",
        "Doorbell",
        "Front Door",
        "Balcony",
        "Other",

    ]


    return (
        <Modal
            className="semanticModal"
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            size="medium"
            open={open}
            trigger={<Button
                basic
                compact
                content='Checklist'
                icon='clipboard'
                style={{ background: '#cce2ff' }}
            />}
        >
            <Modal.Header>Move In Checklist</Modal.Header>
            <Modal.Content className="checklist-modal">
                <Modal.Description>
                    <Grid>
                        <br></br>
                        <Header>Kitchen</Header>
                        {
                            Checklist && Checklist.slice(0, 10).map((item, index) => {
                                return (
                                    <Grid.Row>
                                        <Grid.Column width="4">{Kitchen[index]}</Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Good' disabled
                                            checked={item.value == true} /></Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Poor' disabled
                                            checked={item.value == false}
                                        /></Grid.Column>
                                        <Grid.Column width="8"><Input fluid disabled value={item.explain} /></Grid.Column>
                                    </Grid.Row>
                                )
                            })
                        }
                        <Header>Living Room</Header>
                        {
                            Checklist && Checklist.slice(11, 16).map((item, index) => {
                                return (
                                    <Grid.Row>
                                        <Grid.Column width="4">{Living[index]}</Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Good' disabled
                                            checked={item.value == true} /></Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Poor' disabled
                                            checked={item.value == false}
                                        /></Grid.Column>
                                        <Grid.Column width="8"><Input fluid disabled value={item.explain} /></Grid.Column>
                                    </Grid.Row>
                                )
                            })
                        }


                        <Header>Bedrooms</Header>
                        {
                            Checklist && Checklist.slice(17, 25).map((item, index) => {
                                return (
                                    <Grid.Row>
                                        <Grid.Column width="4">{Bedroom[index]}</Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Good' disabled
                                            checked={item.value == true} /></Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Poor' disabled
                                            checked={item.value == false}
                                        /></Grid.Column>
                                        <Grid.Column width="8"><Input fluid disabled value={item.explain} /></Grid.Column>
                                    </Grid.Row>
                                )
                            })
                        }

                        <Header>Bathrooms</Header>

                        {
                            Checklist && Checklist.slice(26, 38).map((item, index) => {
                                return (
                                    <Grid.Row>
                                        <Grid.Column width="4">{Bathroom[index]}</Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Good' disabled
                                            checked={item.value == true} /></Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Poor' disabled
                                            checked={item.value == false}
                                        /></Grid.Column>
                                        <Grid.Column width="8"><Input fluid disabled value={item.explain} /></Grid.Column>
                                    </Grid.Row>
                                )
                            })
                        }


                        <Header>Other</Header>
                        {
                            Checklist && Checklist.slice(39, 47).map((item, index) => {
                                return (
                                    <Grid.Row>
                                        <Grid.Column width="4">{Other[index]}</Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Good' disabled
                                            checked={item.value == true} /></Grid.Column>
                                        <Grid.Column width="2"><Checkbox label='Poor' disabled
                                            checked={item.value == false}
                                        /></Grid.Column>
                                        <Grid.Column width="8"><Input fluid disabled value={item.explain} /></Grid.Column>
                                    </Grid.Row>
                                )
                            })
                        }

                    </Grid>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => setOpen(false)}
                    positive
                >Ok</Button>
            </Modal.Actions>
        </Modal>
    )
}

export default MoveInChecklist