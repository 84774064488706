import React, { Component } from "react";
import {
  leadsGql,
  leadHistory,
  createComment,
  UpdateLead,
  deleteComment,
  updateComment,
  TourSingleLocation,
  updateTour,
  applicationCreation,
  sendApplication
} from "../../store/person/leads";
import moment from "moment";
import EmailLead from "../../components/Modals/EmailLead";
import EditComment from "../../components/Leads/EditComment";
import ToursModal from "../../components/Modals/ToursModal";
import { MoveLeadModal } from "../../components/Modals/moveLeadModal";
import GiftPointsModal from "../../components/Modals/GiftPointsModal";
import { PageLoader } from "../../components/Loader/PageLoader";
import { Loader } from "../../components/Loader/Loader";
import { getClient } from "../../init-apollo-googleFn";
import {
  disablePastDt,
  affordHousingApi,
  toastFailMsg,
} from "../../utils/common";
import { unitType, units } from "../../store/person/properties";
import {
  LEADS_URL,
  LEADS_COMMENT_URL,
  ADD_LEAD_STATUS,
  DATE_FORMAT,
  LEAD_SOURCE,
  ROUTES,
  LEADS_TOUR,
  UNIT_TYPE_API,
  UNITAPI,
  APPLICATION_CREATION_EMAIL,
  LEADS_COMMUNICATION_URL,
} from "../../utils/constants";
import "./ViewLead.scss";
import ReeValidate from "ree-validate";
import { NavLink } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Divider,
  Grid,
  Segment,
  Feed,
  TextArea,
  Input,
  Dropdown,
  Header,
  Button,
  Breadcrumb,
  Message,
  Confirm,
  Icon,
} from "semantic-ui-react";
import CreateLeads from "./CreateLead";
import mixpanel from "mixpanel-browser";
const LeadsQuery = getClient(LEADS_URL);
const tourAPI = getClient(LEADS_TOUR);
const LeadsComment = getClient(LEADS_COMMENT_URL);
const unitTypeManager = getClient(UNIT_TYPE_API);
const unitManager = getClient(UNITAPI);
const leadCommunicationClient = getClient(LEADS_COMMUNICATION_URL);

const applicationCreationEmail = getClient(APPLICATION_CREATION_EMAIL);
let unitTypeLabel = "";

class ViewLead extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.validator = new ReeValidate.Validator({
      adults: "numeric",
      children: "numeric",
      pets: "numeric",
    });

    this.state = {
      assignedTo: "",
      isShowing: false,
      sending: false,
      loading: false,
      commentLoader: false,
      modalOpen: false,
      fetchComment: false,
      commentField: "",
      commentCache: [],
      errors: this.validator.errors,
      date: "",
      tour: "",
      affordableText: "",
      affordableHousingData: [],
      lead: {
        source: "",
        test: "",
        status: "",
        assign: "",
        affordHousing: "",
        preference: {
          unitType: "",
          unit: "",
          moveInDate: "",
          adults: "",
          children: "",
          pets: "",
          location: "",
          area: {
            city: "",
            state: "",
          },
        },
      },
      showActions: false,
      unitTypeData: [],
      unitData: [],
      toastOptions: {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        newestOnTop: false,
        rtl: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      },
      editContactInfo: false,
      unitTypeText: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.fetchLead();
    mixpanel.track("Manager Page Load", {
      sub: "View Lead",
    });
  }

  toggleModal = () => {
    this.setState((prevState) => ({ isShowing: !prevState.isShowing }));
  };

  fetchLead = () => {
    this.setState({ loading: true });
    const params = this.props.match.params;
    try {
      LeadsQuery.query({
        query: leadsGql,
        variables: {
          location: params.location,
        },
      }).then((res) => {
        const lead = res.data.lead.edges;
        lead.forEach((selectedLead) => {
          if (selectedLead.node.id === params.leadId) {
            const selected = selectedLead.node;
            this.setState({ lead: selected, loading: false });
            this.setState({
              lead: { ...this.state.lead, location: params.location },
            });
            if (selectedLead.node.assign) {
              this.setState({
                assignedTo:
                  selectedLead.node.assign.firstName +
                  " " +
                  selectedLead.node.assign.lastName,
              });
              this.setState({
                lead: {
                  ...this.state.lead,
                  assign: selectedLead.node.assign.ndbId.toString(),
                },
              });
            }
            if (
              selectedLead.node.preference &&
              selectedLead.node.preference.unitType &&
              selectedLead.node.preference.unitType.id
            ) {
              this.fetchUnitNumber(selectedLead.node.preference.unitType.id);
            }
          }
        });
        this.fetchTour();
        this.fetchAffordableHousing();
        this.fetchUnitTypeData();
        this.fetchComments();
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  fetchAffordableHousing = async () => {
    const { lead } = this.state;
    lead.location &&
      affordHousingApi(lead.location).then((result) =>
        this.setState({ affordableHousingData: result })
      ); //promise resolve
  };

  fetchUnitTypeData = async () => {
    const { lead } = this.state;
    lead.location &&
      (await unitTypeManager
        .query({
          query: unitType,
          variables: { locationId: lead.location },
        })
        .then((res) => {
          const data = [];
          res.data.unitType &&
            res.data.unitType.edges.forEach((ele, index) => {
              data.push(
                ele && {
                  key: ele.node.type,
                  text: ele.node.type,
                  value: {
                    name: ele.node.type,
                    id: ele.node.id,
                    field: "unitType",
                  },
                }
              );
            });
          this.setState({ unitTypeData: data });
        })
        .catch((error) => {
          toast.error(error);
        }));
  };

  fetchTour = async () => {
    const { lead } = this.state;
    try {
      await tourAPI
        .query({
          query: TourSingleLocation,
          variables: {
            location: lead.location,
            id: lead.id,
          },
        })
        .then((res) => {
          if (res.data.tours) {
            const eventFilter = res.data.tours.edges;
            const filterArr = eventFilter.filter(
              (task) => task.node.status !== "cancel"
            );
            this.setState({ tour: filterArr });
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  fetchComments = async () => {
    this.setState({ fetchComment: true, commentLoader: true });
    const { lead } = this.state;
    try {
      await LeadsComment.query({
        query: leadHistory,
        variables: {
          leadId: lead.id,
        },
      }).then((res) => {
        if (res.data.comments) {
          this.setState({
            commentCache: res.data.comments.edges,
            fetchComment: false,
            commentLoader: false,
          });
        }
       
      });
    } catch (e) {

    }finally{
      this.setState({ fetchComment: false, commentLoader: false });
    }
  };

  submitComment = () => {
    const { commentField, lead } = this.state;
    try {
      mixpanel.track("Manager Lead Action", {
        sub: "Create Lead Comment",
      });
      LeadsComment.mutate({
        mutation: createComment,
        variables: {
          input: {
            comment: {
              message: commentField,
              leadId: lead.id,
            },
          },
        },
      }).then((res) => {
        const { user } = this.props;
        this.setState({
          commentField: "",
          commentCache: [
            {
              node: {
                created: moment().format(),
                subject: `Comment Added by ${user.firstName} ${user.lastName}`,
                message: commentField,
                id: res.data.createComment.comment.id,
                commentType: "comment",
              },
            },
            ...this.state.commentCache,
          ],
        });
        this.success("Comment added successfully");
        setTimeout(() => {
          this.fetchComments();
        }, 200);
      });
    } catch (e) {
      console.log(e);
      alert(e);
    }
  };
  tourSet = () => {
    this.setState({
      lead: { ...this.state.lead, status: "Tour" },
    });
  };

  /* function to remove the error and revalidate the data */
  removeErrorAndReValidate = (name, type) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, this.state.lead[type][name]).then(() => {
      this.setState({ errors });
    });
  };

  /* function to set the source dropdown changed value */
  handleSourceChange = (event, { value }) => {
    this.setState({
      lead: { ...this.state.lead, source: value },
    });
  };

  /* function to set the status dropdown changed value */
  handleStatusChange = (event, { value }) => {
    this.setState({
      lead: { ...this.state.lead, status: value },
    });
  };

  /* function to set the location and area parameters under preference */
  handleLocationChange = (name, value) => {
    const localLeadData = this.state.lead;
    this.setState({
      lead: {
        ...localLeadData,
        preference: {
          ...localLeadData.preference,
          area: { ...localLeadData.preference.area, [name]: value },
        },
      },
    });
  };
  // Handle new Unit Type
  handleUnitTypeChange = (event, { value }) => {
    const localLeadData = this.state.lead;
    const filteredUnitType = this.state.unitTypeData.filter(
      (ele) => ele.value.id === value.id
    );
    const textValue = filteredUnitType[0].text;
    unitTypeLabel = textValue;
    this.setState({
      lead: {
        ...localLeadData,
        preference: {
          ...localLeadData.preference,
          unitType: value.id,
          unit: "",
        },
      },
    });
    this.fetchUnitNumber(value.id);
  };

  // Handle new Unit
  handleUnitNumberChange = (event, { value }) => {
    const localLeadData = this.state.lead;
    this.setState({
      lead: {
        ...localLeadData,
        preference: { ...localLeadData.preference, unit: value.id },
      },
    });
  };

  /* function to fetch unit number data */
  fetchUnitNumber = (id) => {
    const { lead } = this.state;
    lead.location &&
      unitManager
        .query({
          query: units,
          variables: { locationId: lead.location, unitTypesId: id },
        })
        .then((res) => {
          const units = res.data.units.edges;
          const unitsData = [];
          units &&
            units.forEach((ele, index) => {
              unitsData.push(
                ele && {
                  key: ele.node.number,
                  text: ele.node.number,
                  value: {
                    name: ele.node.number,
                    id: ele.node.id,
                    field: "unitNumber",
                  },
                }
              );
            });
          this.setState({ unitData: unitsData });
        })
        .catch((error) => {
          toastFailMsg(error);
        });
  };

  /* function to set the source dropdown changed value */
  updateAffordableHousing = (event, { value }) => {
    this.setState({
      lead: { ...this.state.lead, affordHousing: value.id },
      affordableText: value.name,
    });
  };

  /* function to accepts the date selected and set it in local state after formatting change */
  handleDateChange = (event) => {
    const data = this.state.lead;
    if (event != null) {
      const dateRes = moment(event).format(DATE_FORMAT);
      this.setState({
        lead: {
          ...data,
          preference: { ...data.preference, moveInDate: dateRes },
        },
        date: event._d,
      });
    } else {
      this.setState({
        lead: { ...data, preference: { ...data.preference, moveInDate: "" } },
        date: "",
      });
    }
  };

  /* function to set the local state lead data on the basis of the fields data received */
  handlePreferenceChange = (name, e, type) => {
    const { value } = e.target;
    if (type && type === "preference") {
      const data = this.state.lead;
      this.setState(
        {
          lead: { ...data, preference: { ...data.preference, [name]: value } },
        },
        () => {
          this.removeErrorAndReValidate(name, type);
        }
      );
    } else {
      this.setState(
        {
          lead: { ...this.state.lead, [name]: value },
        },
        () => {
          this.removeErrorAndReValidate(name);
        }
      );
    }
  };

  handleChange(event) {
    this.setState({ commentField: event.target.value });
  }

  /* function that validates the data captured, if data is valid,
it calls the handlesubmit function to integrate the mutation */
  validateAndSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { lead } = this.state;
    const { preference } = lead;
    const { unitType, unit } = preference;
    const { errors } = this.validator;
    const valid = await this.validator.validateAll(lead);
    if (valid && unitType && unit) {
      this.handleSubmit();
    } else if (!unitType) {
      toastFailMsg("Select floor plan");
      this.setState({ loading: false });
    } else if (!unit) {
      toastFailMsg("Select unit");
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
      this.setState({ errors });
    }
  };

  deleteComment = (event) => {
    const { commentCache, lead } = this.state;
    try {
      mixpanel.track("Manager Lead Action", {
        sub: "Delete Lead Comment",
      });
      LeadsComment.mutate({
        mutation: deleteComment,
        variables: {
          input: {
            comment: {
              commentId: event.node.id,
              leadId: lead.id,
            },
          },
        },
      })
        .then((res) => {
          this.state.commentCache.forEach((comment) => {
            if (event.node.id === comment.node.id) {
              const arr = commentCache.filter(
                (comment) => comment.node.id !== event.node.id
              );
              this.setState({ commentCache: arr });
            }
          });
          this.success("Comment deleted successfully");
          // Nate sends back a response for instant update
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        });
    } catch (e) {
      alert(e);
    }
  };

  updateComment = (event) => {
    const { lead } = this.state;
    try {
      mixpanel.track("Manager Lead Action", {
        sub: "Update Lead Comment",
      });
      LeadsComment.mutate({
        mutation: updateComment,
        variables: {
          input: {
            comment: {
              commentId: event.existingId,
              leadId: lead.id,
              message: event.newComment,
            },
          },
        },
      })
        .then((res) => {
          this.fetchComments();
          this.success("Comment updated successfully");
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        });
    } catch (e) {
      alert(e);
    }
  };

  /* function called on mark as lost, add to waitlist, remove from waitlist
  status being passed in function as arguments */
  changeStatusAction = (status) => {
    this.setState(
      { lead: { ...this.state.lead, status: status }, loading: true },
      () => {
        this.handleSubmit();
      }
    );
  };

  fail = () =>
    toast.error("Deletion not allowed in this comment.", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  success = (msg) =>
    toast.success(msg, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  successSent = () =>
    toast.success("Lead Emailed!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  successTour = () =>
    toast.success("Tour Saved!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  // Cancel Tour
  removeEvent(i) {
    const { tour } = this.state;
    const Array = tour;
    const index = i;
    try {
      mixpanel.track("Manager Lead Action", {
        sub: "Update Tour Info",
      });
      tourAPI
        .mutate({
          mutation: updateTour,
          variables: {
            input: {
              tour: {
                id: index.id,
                status: "cancel",
                comment: "Cancel",
              },
            },
          },
        })
        .then((response) => {
          const newArray = Array.filter((item) => item.node.id !== index.id);
          this.success("Removed");
          this.handleClose();

          this.setState({
            tour: newArray,
          });
        });
    } catch (e) {
      alert(e);
      console.log(e);
    }
  }

  triggerEmail = () => {
    const { lead } = this.state;
    try {
      mixpanel.track("Manager Lead Action", {
        sub: "Send Application to Lead",
      });
      applicationCreationEmail
        .mutate({
          mutation: applicationCreation,
          variables: {
            input: {
              email: {
                leadId: lead.id,
              },
            },
          },
        })
        .then((res) => {
          this.success("Success");
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        });
    } catch (e) {
      alert(e);
    }
  };

  /* function to delete the empty field from the state */
  removeEmptyData = (field) => {
    const leadData = this.state.lead;
    delete leadData[field];
  };
  handleAssignedChange = (event, { value }) => {
    const name = value.firstName + " " + value.lastName;
    this.setState({ lead: { ...this.state.lead, assign: value.id } });
    this.setState({ assignedTo: name }, () => {});
  };
  handleClose = () => this.setState({ modalOpen: false });

  /* function to move to application form on edit application click */
  redirectToForm = () => {
    if (this.state.lead !== undefined) {
      this.props.history.push({
        pathname: "/applicationForm",
        leadData: this.state.lead,
      });
    }
  };
  sendApplication = () => {
    const { lead } = this.state;
    try {
      mixpanel.track("Manager Lead Action", {
        sub: "Send Application to Lead",
      });
      leadCommunicationClient
        .mutate({
          mutation: sendApplication,
          variables: {
            input: {
              email: {
                leadId: lead.id,
                locationName:this.props.selectedPrimary.node.name,
                unitNum:lead.preference.unit.number
              },
            },
          },
        })
        .then((res) => {
          this.success("Success");
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        });
    } catch (e) {
      alert(e);
    }
  }

  /* function to call the UpdateLead mutation */
  handleSubmit = async (properties) => {
    this.setState({ sending: true, loading: true });
    const stateFieldsArr = [
      "created",
      "primaryLocation",
      "comments",
      "waitlistedOn",
      "affordable",
    ];
    stateFieldsArr.forEach((ele) => {
      this.removeEmptyData(ele);
    });
    const { lead } = this.state;
    let selectedLead = { lead };
    if (lead.preference.unit && lead.preference.unit.id) {
      const data = {
        ...lead,
        preference: { ...lead.preference, unit: lead.preference.unit.id },
      };
      selectedLead = { lead: data };
    } else {
      const data = {
        ...lead,
        preference: { ...lead.preference, unit: lead.preference.unit },
      };
      selectedLead = { lead: data };
    }
    if (
      lead.preference &&
      lead.preference.unitType &&
      lead.preference.unitType.type
    ) {
      const data = {
        ...lead,
        preference: {
          ...lead.preference,
          unitType: lead.preference.unitType.id,
        },
      };
      selectedLead = { lead: data };
    }
    if (
      lead.preference &&
      lead.preference.unitType &&
      lead.preference.unitType.type &&
      lead.preference.unit.number
    ) {
      const data = {
        ...lead,
        preference: {
          ...lead.preference,
          unitType: lead.preference.unitType.id,
          unit: lead.preference.unit.id,
        },
      };
      selectedLead = { lead: data };
    }
    if (properties) {
      mixpanel.track("Manager Lead Action", {
        sub: "Update Lead Data Property",
      });

      await LeadsQuery.mutate({
        mutation: UpdateLead,
        variables: {
          input: {
            lead: properties,
          },
        },
      })
        .then((res) => {
          toast.success("Success!", this.state.toastOptions);
          this.setState({ sending: false, loading: false });
          this.props.history.push(
            this.props.location.isWaitlist
              ? ROUTES["waitlist"]
              : ROUTES["leads"]
          );
        })
        .catch((error) => {
          this.setState({ sending: false });
          toast.error(error, this.state.toastOptions);
        });
    } else {
      mixpanel.track("Manager Lead Action", {
        sub: "Update Lead Data",
      });
      await LeadsQuery.mutate({
        mutation: UpdateLead,
        variables: { input: selectedLead },
      })
        .then((res) => {
          toast.success("Success!", this.state.toastOptions);
          this.setState({ sending: false, loading: false });
          this.fetchLead();
        })
        .catch((error) => {
          this.setState({ sending: false });
          toast.error(error, this.state.toastOptions);
        });
    }
  };

  requirements = (pm) => {
    const { lead, affordableHousingData = [] } = this.state;
    let affordableText = this.state.affordableText;
    const getAffordableById = () =>
      affordableHousingData.find((item) => item.value.id === lead.affordable);
    if (!affordableText && getAffordableById()) {
      affordableText = getAffordableById().text;
    }
    return (
      <>
        <Segment className="requirements-preferences-box">
          <Grid columns={4}>
            <Grid.Column>
              <Header className="lead-derails-dropdown-row">
                Assigned To
                <Dropdown
                  selection
                  deburr
                  options={pm}
                  placeholder="Assigned To"
                  onChange={this.handleAssignedChange}
                  text={this.state.assignedTo ? this.state.assignedTo : ""}
                />
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header className="lead-derails-dropdown-row">
                Source
                <Dropdown
                  text={lead.source}
                  selection
                  deburr
                  placeholder="Source"
                  selectOnBlur={false}
                  options={LEAD_SOURCE}
                  onChange={this.handleSourceChange}
                />
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header className="lead-derails-dropdown-row">
                Afford Housing
                <Dropdown
                  text={affordableText}
                  placeholder="Afford Housing"
                  openOnFocus={false}
                  deburr
                  selection
                  options={this.state.affordableHousingData}
                  onChange={this.updateAffordableHousing}
                />
              </Header>
            </Grid.Column>

            <Grid.Column className="move-date">
              <Header className="margin-bottom-10">Move In Date</Header>
              <Datetime
                inputProps={{ readOnly: true, placeholder: "MM/DD/YYYY" }}
                timeFormat={false}
                isValidDate={disablePastDt}
                closeOnSelect={true}
                minDate={moment().toDate()}
                defaultValue={
                  lead.preference && lead.preference.moveInDate
                    ? moment(lead.preference.moveInDate)
                    : ""
                }
                onChange={this.handleDateChange}
              />
            </Grid.Column>

            <Grid.Column>
              <Header className="add-star">Floor Plan</Header>
              <Dropdown
                text={
                  lead.preference &&
                  lead.preference.unitType &&
                  lead.preference.unitType.type
                    ? lead.preference.unitType.type
                    : unitTypeLabel
                    ? unitTypeLabel
                    : "none"
                }
                openOnFocus={false}
                options={this.state.unitTypeData}
                selectOnBlur={false}
                placeholder="Floor Plan"
                selection
                onChange={this.handleUnitTypeChange}
              />
            </Grid.Column>

            <Grid.Column>
              <Header className="add-star">Unit Number</Header>
              <Dropdown
                text={
                  lead.preference && lead.preference.unit
                    ? lead.preference.unit.number
                    : "none"
                }
                openOnFocus={false}
                options={this.state.unitData}
                selectOnBlur={false}
                placeholder="Unit Number"
                selection
                onChange={this.handleUnitNumberChange}
              />
            </Grid.Column>

            <Grid.Column>
              <Header>Adults </Header>
              <Input
                placeholder="Adults"
                className="full-wid-input"
                name="adults"
                type="number"
                id="adults"
                defaultValue={lead.preference ? lead.preference.adults : ""}
                onChange={(event) => {
                  this.handlePreferenceChange("adults", event, "preference");
                }}
              />
            </Grid.Column>

            <Grid.Column>
              <Header>Children</Header>
              <Input
                type="number"
                name="children"
                className="full-wid-input"
                id="children"
                placeholder="Children"
                defaultValue={lead.preference ? lead.preference.children : ""}
                onChange={(event) => {
                  this.handlePreferenceChange("children", event, "preference");
                }}
              />
              {this.state.errors.has("children") && (
                <div className="error-text">
                  {this.state.errors.first("children")}
                </div>
              )}
            </Grid.Column>

            <Grid.Column>
              <Header>Location </Header>
              <Input
                className="full-wid-input"
                placeholder="Location"
                defaultValue={
                  lead.preference && lead.preference.area
                    ? lead.preference.area.state
                    : ""
                }
                onChange={(e) =>
                  this.handleLocationChange("state", e.target.value)
                }
              />
            </Grid.Column>

            <Grid.Column>
              <Header>Area </Header>
              <Input
                className="full-wid-input"
                placeholder="Area"
                defaultValue={
                  lead.preference && lead.preference.area
                    ? lead.preference.area.city
                    : ""
                }
                onChange={(e) =>
                  this.handleLocationChange("city", e.target.value)
                }
              />
            </Grid.Column>

            <Grid.Column>
              <Header>Pets </Header>
              <Input
                type="number"
                name="pets"
                id="pets"
                placeholder="Pets"
                className="full-wid-input"
                defaultValue={lead.preference ? lead.preference.pets : ""}
                onChange={(event) => {
                  this.handlePreferenceChange("pets", event, "preference");
                }}
              />
              {this.state.errors.has("pets") && (
                <div className="error-text">
                  {this.state.errors.first("pets")}
                </div>
              )}
            </Grid.Column>

            <Grid.Column>
              <div className="update-button-wrapper mt-34">
                <Button
                  className="update-button"
                  content="Update"
                  attached
                  disabled={!this.props.fetchComment}
                  onClick={(e) => this.validateAndSubmit(e)}
                  positive
                />
              </div>
            </Grid.Column>
          </Grid>
        </Segment>
      </>
    );
  };

  render() {
    const pm = this.props
      ? [
          {
            key: this.props.user.firstName,
            text: this.props.user.firstName + " " + this.props.user.lastName,
            value: {
              firstName: this.props.user.firstName,
              lastName: this.props.user.lastName,
              id: this.props.user.ndbId,
            },
          },
        ]
      : [{}];
    const { lead, tour, sending } = this.state;
    const Bread = () => (
      <Breadcrumb className="custom-breadcrumb">
        <Breadcrumb.Section link>
          {this.props.location.isWaitlist ? (
            <NavLink exact to="/leads/waitlist">
              Leads
            </NavLink>
          ) : (
            <NavLink exact to="/leads">
              Leads
            </NavLink>
          )}
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>
          {" "}
          {lead.lastName ? lead.lastName : "Lead"}
        </Breadcrumb.Section>
      </Breadcrumb>
    );

    const TopGrid = () => (
      <>
        <Bread />
        <div className="lead-profile-head">
          <Header as="h1">{`${lead.lastName}, ${lead.firstName}`}</Header>
          <Dropdown
            text={lead.status}
            openOnFocus={false}
            selection
            selectOnBlur={false}
            lazyLoad={true}
            deburr={true}
            options={ADD_LEAD_STATUS}
            onChange={this.handleStatusChange}
          />
        </div>

        <div className="lead-profile-body">
          <div className="lead-profile-body-img">
            <i aria-hidden="true" class="user icon"></i>
          </div>
          <div className="lead-profile-info">
            <Header as="h3">
              <a href={"tel:" + lead.phoneMobile} title={lead.phoneMobile}>
                <i aria-hidden="true" class="phone icon"></i>
                {lead.phoneMobile}
              </a>
            </Header>
            <Header as="h3">
              <a href={"mailto:" + lead.email} title={lead.email}>
                <i aria-hidden="true" class="mail icon"></i>
                {lead.email}
              </a>
            </Header>
            {lead.address &&
              (lead.addressOne !== null ||
                lead.addressTwo !== null ||
                lead.postcode !== null) && (
                <Header
                  as="h3"
                  icon="location arrow"
                  content={`${
                    lead.address.addressOne !== null
                      ? lead.address.addressOne
                      : ""
                  }
                  ${
                    lead.address.addressTwo !== null
                      ? lead.address.addressTwo
                      : ""
                  }
                  ${lead.address.city !== null ? lead.address.city : ""}
                ${lead.address.state !== null ? lead.address.state : ""}
                  ${
                    lead.address.postcode !== null ? lead.address.postcode : ""
                  }`}
                />
              )}
            <Header as="h4">
              Lead Created: {moment(lead.created).format("lll")}
            </Header>
          </div>
          <div className="tour-scheduled">
            {tour &&
              tour.length > 0 &&
              tour.map((tourMain) => {
                const localTime = moment(
                  tourMain.node.date + " " + tourMain.node.time,
                  "DD/MM/YYYY HH:mm A"
                );
                const local = moment.utc(localTime._i);
                const DDate = local;
                return (
                  <>
                    <Message info>
                      <Message.Header className="tour-header">
                        Tour With {tourMain.node.name}
                        {/* eslint-disable */}
                        <a
                          onClick={() => this.setState({ modalOpen: true })}
                          title="Cancel"
                          className="cancel-tour"
                        >
                          <i aria-hidden="true" class="cancel icon"></i>
                        </a>
                        {/* eslint-enable */}
                      </Message.Header>
                      <p>
                        Scheduled for{" "}
                        <b>{DDate.local().format("DD/MM/YYYY hh:mm A")}</b>
                      </p>
                    </Message>
                    <Confirm
                      className="semanticModal"
                      open={this.state.modalOpen}
                      onOpen={() => this.setState({ modalOpen: true })}
                      onCancel={() => this.handleClose()}
                      onConfirm={() => this.removeEvent(tourMain.node)}
                    />
                  </>
                );
              })}
          </div>
        </div>
        <Divider className="lead-details-divider" />
      </>
    );

    const ButtonGroup = () => (
      <Grid stackable width={2}>
        <Grid.Row stackable className="mx-0">
          <Grid.Column stackable className="full-button-wrapper">
            <ToursModal
              tourSet={this.tourSet}
              tours={tour}
              fetchComments={this.fetchComments}
              fetchTour={this.fetchTour}
              disabled={this.state.fetchComment}
              successTour={this.successTour}
              lead={lead}
              pm={this.props.user}
            />
            <EmailLead
              successSent={this.successSent}
              disabled={this.state.fetchComment}
              fetchComment={this.state.fetchComment}
              info={lead}
              pmContact={this.props.user}
            />
            <Button
              disabled={this.state.fetchComment}
              onClick={this.redirectToForm}
              className="dark-button"
              size="large"
              floated="right"
            >
              Start Application
            </Button>
            <Button
              disabled={this.state.fetchComment}
              onClick={this.sendApplication}
              className="dark-button"
              size="large"
              floated="right"
            >
              Send Application
            </Button>
            <Button
              disabled={this.state.fetchComment}
              onClick={() =>
                this.changeStatusAction(
                  lead.status === "Waitlist" ? "Prospect" : "Waitlist"
                )
              }
              className="dark-button"
              size="large"
              floated="right"
            >
              {lead.status === "Waitlist"
                ? "Remove From Waitlist"
                : "Add to Waitlist"}
            </Button>
            <Dropdown
              open={this.state.showActions}
              close={!this.state.showActions}
              icon={
                <img
                  onClick={() =>
                    this.setState({ showActions: !this.state.showActions })
                  }
                  style={{
                    backgroundColor: "#555555",
                    marginLeft: "4px",
                    borderRadius: "3px",
                    height: "36px",
                  }}
                  src="assets/img/white-icons-more.svg"
                  alt="white-icons-more"
                />
              }
            >
              <Dropdown.Menu direction="left">
                <Dropdown.Item>
                  <GiftPointsModal
                    from="ViewLead"
                    location={this.props.selectedPrimary.node.id}
                    user={this.props.user.ndbId}
                    recipient={lead.email}
                  />
                </Dropdown.Item>
                <Dropdown.Item>
                  <MoveLeadModal
                    from="ViewLead"
                    disabled={this.state.fetchComment}
                    locations={this.props.primaryLocations}
                    leadId={this.props.match.params.leadId}
                    propertyCustomId={this.props.selectedPrimary.node.customId}
                    onMoveLead={this.handleSubmit}
                    sending={sending}
                  />
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={lead.status === "Lost"}
                  disabled={this.state.fetchComment}
                  onClick={() => {
                    this.changeStatusAction("Lost");
                  }}
                >
                  Mark as Lost
                </Dropdown.Item>
                <Dropdown.Item onClick={() =>this.triggerEmail()}>
                  Application Pending
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={this.toggleModal}
                  disabled={this.props.disabled}
                >
                  Edit Contact Info
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {this.state.isShowing && (
              <CreateLeads
                disabled={this.state.fetchComment}
                onClose={this.toggleModal}
                showModal={this.state.isShowing}
                leadData={this.state.lead}
                leadId={this.props.match.params.leadId}
                propertyCustomId={this.props.match.params.location}
                fetchData={this.fetchLead}
                unitTypeData
                affordableHousingData
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );

    return (
      <>
        {this.state.loading ? (
          <PageLoader text />
        ) : (
          <>
            {/* {commentLoader && <PageLoader >Gathering Data...</PageLoader>} */}
            <TopGrid />
            <ButtonGroup />
            {this.requirements(pm)}

            <Header className="comments-head">Comments/History</Header>

            <div className="comments-textarea-wrapper">
              <TextArea
                className="comments-textarea"
                placeholder="Write a comment"
                value={this.state.commentField}
                name="commentField"
                id="commentField"
                onChange={this.handleChange}
              />

              <Button
                onClick={this.submitComment}
                disabled={
                  this.state.fetchComment ||
                  !this.state.commentField.trim().length
                }
                positive
                className="update-button"
              >
                <span class="send-icon"></span>
              </Button>
            </div>

            {this.state.commentCache.length > 0 && (
              <Feed className="history-feed">
                {this.state.fetchComment ? (
                  <Loader text size="small" active inline="centered" />
                ) : (
                  this.state.commentCache &&
                  this.state.commentCache.map((logs) => {
                    const { node } = logs;
                    if (node.subject.includes("Tour Scheduled ")) {
                      const firstSplit = node.subject.includes(
                        "Tour Scheduled "
                      )
                        ? node.subject.split("Tour Scheduled ")
                        : null;
                      const secondarySplit =
                        firstSplit[1] && firstSplit[1].replace(" at ", " ");
                      const dateObjet = moment(secondarySplit);
                      const secT = dateObjet && moment.utc(dateObjet._i);
                      var newString = `Tour Scheduled for ${secT
                        .local()
                        .format("LLL")} `;
                    }
                    return (
                      <Feed.Event>
                        <Feed.Content>
                          <Feed.Date
                            content={moment(node.created).local().format("lll")}
                          />
                          {this.props.user.ndbId ===
                            (node && node.person && node.person.id) &&
                          node.commentType === "comment" ? (
                            <span
                              title="Delete"
                              className="delete-icon"
                              onClick={() => this.deleteComment(logs)}
                            ></span>
                          ) : (
                            ""
                          )}
                          {node.person && (
                            <Feed.Summary>
                              {newString ? newString : node.subject} by{" "}
                              {node.person.firstName} {node.person.lastName}
                            </Feed.Summary>
                          )}
                          {!node.person && (
                            <Feed.Summary>
                              {newString ? newString : node.subject}
                            </Feed.Summary>
                          )}
                          {node.message.includes("<p>") ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: node.message }}
                            />
                          ) : (
                            <Feed.Extra
                              text
                              content={newString ? newString : node.message}
                            />
                          )}
                        </Feed.Content>
                        {logs.node.commentType === "comment" &&
                          this.props.user.ndbId ===
                            (node && node.person && node.person.id) && (
                            <Feed.Label>
                              <EditComment
                                comment={logs}
                                updateComment={this.updateComment}
                              />
                            </Feed.Label>
                          )}
                      </Feed.Event>
                    );
                  })
                )}
              </Feed>
            )}
          </>
        )}
      </>
    );
  }
}
export default ViewLead;
