import React, { Component } from "react";
import "./Loyalty.scss";
import { qpTooltipPopover } from "../../utils/misc";
import { getEmitter } from "../../utils";
import { STATUS, EVENTS, ACTIONS } from "react-joyride";
import "../../assets/fomantic/dist/semantic.css";
import moment from "moment";
import mixpanel from "mixpanel-browser";
import { LOYALTY } from "../../utils/constants";
import { getClient } from "../../init-apollo-googleFn";
import {
  personPointsTransactionGql,
  purchasePointsPackageGql,
  personPointsPackagesGql,
} from "../../store/person/loyalty";
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
import LeaLoader from '../../components/PageLoader/LeaLoader';

import { Table, Header, Form, Radio, Icon} from "semantic-ui-react";
import Tour from 'reactour'
import { loyaltySteps } from "../../utils/tourSteps"; 

const eventEmit = getEmitter();
// import PointsIcon from '../../assets/'
const loyaltyClient = getClient(LOYALTY);
class Loyalty extends Component {
  state = {
    run: false,
    loading: true,
    ledger: [],
    isTourOpen:false,
    packages: [],
    offers: [],
    selectedPrimary: this.props.selectedPrimary
      ? this.props.selectedPrimary.node
      : "",
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  handleChange = (e, { value }) => this.setState({ value });
  handleClickStart = (e) => {
    e.preventDefault();

    this.setState({
      stepIndex: 0,
      run: true,
    });
  };

  handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      action === "close"
    ) {
      this.setState({ run: false });
    }
  };

  componentDidMount() {
    qpTooltipPopover();
    this.getPoints();
    this.getPackages();
    mixpanel.track("Manager Page Load", {
      sub: "Loyalty",
    });
  }
  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.selectedPrimary
      ? prevProps.selectedPrimary.node
      : "";
    if (nodeData !== this.state.selectedPrimary) {
      this.setState({ selectedPrimary: nodeData }, () => {
        this.getPoints();
      });
    }
  }
  purchasePoints = () => {
    this.setState({ isLoading: true });
    mixpanel.track("Manager Loyalty Action", {
      sub: "Purchase Loyalty Points Package",
    });
    loyaltyClient
      .mutate({
        mutation: purchasePointsPackageGql,
        variables: {
          package: {
            packageId: this.state.value,
            locationId:
              this.props.selectedPrimary && this.props.selectedPrimary.node.id,
          },
        },
      })
      .then((res) => {
        toastSuccessMsg(
          "Thank you for purchasing! Your transaction has been successfully completed."
        );
        eventEmit.emit("PurchasePoints");
        this.getPoints();
      })
      .catch(() => {
        toastFailMsg(
          "Something went wrong with the transaction, please contact system admin."
        );
      });
  };

  getPoints() {
    this.setState({ loading: true });
    try {
      loyaltyClient
        .query({
          query: personPointsTransactionGql,
          variables: {
            locationId:
              this.props.selectedPrimary && this.props.selectedPrimary.node.id,
          },
        })
        .then((response) => {
          const array = response.data.locationLedger.edges;
          array.reverse();
          this.setState({ ledger: array, loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    } catch (e) {
      console.log(e);
    }
  }

  getPackages() {
    try {
      loyaltyClient
        .query({
          query: personPointsPackagesGql,
        })
        .then((response) => {
          const array = response.data.packages.edges;
          this.setState({ packages: array, loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <>
        <main className="main-content p-2 market-points-wrap" role="main">
          <h3 className="loyalty-first-step">Loyalty <Icon size='small' circular name='lightbulb' onClick={() => this.setState({ isTourOpen: true })} /></h3>
          <h6>Here’s the summary of your REM points</h6>
          <div className="row mt-3 points-status loyalty-second-step">
            <div className="left-data">
              <div className="text-warning">
                <img src="/assets/img/points-icon.svg" alt="points"></img>
              </div>
              <div className="congrats-text">
                <h4>
                  {this.props &&
                    this.props.selectedPrimary &&
                    this.props.selectedPrimary.node &&
                    this.props.selectedPrimary.node.pointsBalance}{" "}
                  Points
                </h4>
                <span>REM points in your wallet</span>
              </div>
            </div>
            <div className="right-data"></div>
          </div>
          <div className="rowScroll activity-status loyalty-third-step">
            <h2 className="heading heading-md">Points history</h2>
            {this.state.loading ?
              <LeaLoader wrapperStyle={{position: 'static', height: '50px', width: '50px', margin: '0 auto'}} logoStyle={{height: '80px', width: '80px'}}/>
            :
              <>
                <Table celled>
                  <Table.Header>
                    <Table.Row textAlign="center">
                      <Table.Cell width="4">Created</Table.Cell>
                      <Table.Cell width="7">Message</Table.Cell>
                      <Table.Cell width="3">Type</Table.Cell>
                      <Table.Cell width="2">Amount</Table.Cell>
                    </Table.Row>
                  </Table.Header>
                </Table>
                <div style={{ maxHeight: "250px", overflowY: "overlay" }}>
                  <Table>
                    <Table.Body>
                        {this.state.ledger.map((Value) => {
                          return (
                            <>
                              <Table.Row>
                                <Table.Cell width="4" textAlign="center">
                                  <span className="date-txt">
                                    {moment(Value.node.createdAt).format("LL")}
                                  </span>
                                </Table.Cell>
                                <Table.Cell width="7" textAlign="center">
                                  <span className="date-txt">
                                    {Value.node.customMessage}
                                  </span>
                                </Table.Cell>
                                <Table.Cell width="3" textAlign="center">
                                  {Value.node.actionType === "points_purchased" && (
                                    <span className="date-txt">
                                      Points Purchased
                                    </span>
                                  )}
                                  {Value.node.actionType === "gifted_by_pm" && (
                                    <span className="date-txt">Points Gifted</span>
                                  )}
                                </Table.Cell>
                                <Table.Cell width="2" textAlign="center">
                                  {Value.node.transactionType === "debit" ? (
                                    <>
                                      <p className="text-success">
                                        {Value.node.points}
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p className="text-danger">
                                        {Value.node.points}
                                      </p>
                                    </>
                                  )}
                                </Table.Cell>
                              </Table.Row>
                            </>
                          );
                        })}
                    </Table.Body>
                  </Table>
                </div>
              </>
            }
          </div>
          <br></br>
          <br></br>
          <h2 className="heading heading-md">Loyalty Point Packages</h2>

          <div className="mt-3 points-status loyalty-purchase loyalty-fourth-step">
            <div className="left-data pr-5">
              <div className="text-warning">
                <img src="/assets/img/points-icon.svg" alt="points"></img>
              </div>
              <div className="congrats-text">
                <h4>Buy More Loyalty Points</h4>
                <span>
                  Unlock the benefits and reward your renters with points!
                  Purchase point packages that can then be gifts to leads,
                  applicants, and current renter.
                </span>
              </div>
            </div>
            <div className="d-flex">
              <button
                type="button"
                data-toggle="modal"
                data-target="#InstructionsModal"
              >
                Learn More
              </button>
              <button
                type="button"
                data-toggle="modal"
                style={{ background: "white", color: "black" }}
                data-target="#CredentialsModal"
                onClick={() => this.setState({ value: "" })}
              >
                Buy Now
              </button>
            </div>
          </div>

          <div
            className="modal fade loyalty-modal"
            id="CredentialsModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body buy-loyalty-cards">
                  <div className="modal-title mb-4">Buy Loyalty Points</div>
                  <Form>
                    {this.state.packages.map((Ppackage) => {
                      return (
                        <Form.Field>
                          <div
                            className="card-row"
                            onClick={() =>
                              this.setState({ value: Ppackage.node.id })
                            }
                          >
                            <div className="block d-inline-flex">
                              <Radio
                                label={
                                  Ppackage.node.name +
                                  " - " +
                                  Ppackage.node.points
                                }
                                name="radioGroup"
                                value={Ppackage.node.id}
                                checked={this.state.value === Ppackage.node.id}
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="block">
                              <span className="bar" />
                              <span>${Ppackage.node.price}.00</span>
                            </div>
                          </div>
                        </Form.Field>
                      );
                    })}
                    <Form.Field>
                      <div className="card-row">
                        <Header>
                          Want to buy more?
                          <Header.Subheader>
                            Please contact us at <span>info@leasera.com </span>{" "}
                            if you would like to purchase more!
                          </Header.Subheader>
                        </Header>
                      </div>
                    </Form.Field>
                  </Form>
                  <button
                    className="btn btn-gradient w-100 mt-4"
                    disabled={!this.state.value}
                    data-dismiss="modal"
                    onClick={() => this.purchasePoints()}
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade loyalty-modal"
            id="InstructionsModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-center">
                  <img src="/assets/img/gift-points-icon.svg" alt="points" />
                  <div className="modal-title">Loyalty Points Benefits</div>
                  <div className="modal-description">
                    Loyalty points are a way of saying thank you while
                    supporting amazing goals and activities. Our network enables
                    us to serve each other the way that community was meant to.
                  </div>
                  <div className="modal-hr"></div>

                  <div className="list-content-wrap">
                    <div className="list-content-item">
                      <div className="list-count">1</div>
                      <div className="list-description">
                        Earn loyalty for anything from rental pre-payments to
                        filling out surveys offered by REM or it's partners.
                      </div>
                    </div>
                    <div className="list-content-item">
                      <div className="list-count">2</div>
                      <div className="list-description">
                        Use your points to discount or fully purchase services
                        and products all across the REM network.
                      </div>
                    </div>
                    <div className="list-content-item">
                      <div className="list-count">3</div>
                      <div className="list-description">
                        Gift points to other users or donate your points value
                        to those in need.
                      </div>
                    </div>
                    <div className="list-content-item">
                      <div className="list-count">4</div>
                      <div className="list-description">
                        Buy additional points to reach special points
                        accelerators and bonuses when available.
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-gradient btn-block w-100"
                    type="button"
                    data-toggle="modal"
                    data-dismiss="modal"
                    data-target="#CredentialsModal"
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Tour
          steps={loyaltySteps}
          isOpen={this.state.isTourOpen}
          onRequestClose={() => this.setState({ isTourOpen: false })}
          // maskClassName="mask"
          className="helper"
          startAt={0}
          rounded={5}
        />
      </>
    );
  }
}

export default Loyalty;
