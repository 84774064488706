import React, { Component } from "react";
import {  Button, Modal, Icon, Table } from "semantic-ui-react";
import { ReconcileTransaction } from "../../store/person/accounting";
import { getClient } from "../../init-apollo-googleFn";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { INVOICE_ACCOUNTING_PAYMENT } from "../../utils/constants";
import mixpanel from "mixpanel-browser";
const reconcileClient = getClient(INVOICE_ACCOUNTING_PAYMENT);
function ReconcileModal(props) {
    const [open, setOpen] = React.useState(false)

    const confirmReconcile = () => {
        try {
          mixpanel.track("Accounting Action", {
            sub: "Reconcile Account",
          });
          reconcileClient
            .mutate({
              mutation: ReconcileTransaction,
              variables: {
                input: {
                    location: props.location,
                    transactionId: props.ledger._id,
                    reconcileId: true
                },
              },
            })
            .then((res) => {
              props.update()
              
            })
            .catch((error) => {
            });
        } catch (e) {
          console.log(e);
        }
    }
    
  
    return (
      <Modal
        className="semanticModal modal-ui modal-radius overflow-y-visible"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={
            <Button
            icon
                compact
                className="ml-3 button-hover-universal"
                id="getLedger"
                size="tiny"
            >
                <Icon name='checkmark'/>
            </Button>
        }
      >
        <Modal.Header>Reconcile Ledger</Modal.Header>
        <Modal.Content >
          <Modal.Description>
            <Table celled>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Total Debits</Table.HeaderCell>
                    <Table.HeaderCell>Total Credits</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>
                <Table.Row>
                    <Table.Cell>$ {props.ledger.debit}</Table.Cell>
                    <Table.Cell>$ {props.ledger.credit}</Table.Cell>
                    <Table.Cell>$ {Number(props.ledger.debit) + Number(props.ledger.credit)}</Table.Cell>
                </Table.Row>
                </Table.Body>
            </Table>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Confirm"
            onClick={() => confirmReconcile()}
            positive
          />
        </Modal.Actions>
      </Modal>
    )
  }
  
  export default ReconcileModal
  
