import React from "react";
import startCase from "lodash/startCase";
import { Button, Icon } from "semantic-ui-react";
import { useEffect } from "react";

const Amenities = ({ options, onChange, onAddNew }) => {
  const [isLoading, setIsLoading] = React.useState();
  const [addingNew, setAddingNew] = React.useState(false);
  const [customAmenity, setCustomAmenity] = React.useState("");
  const [allNewAmenities, setAllNewAmenities] = React.useState([]);

  const onSave = async () => {
    if (onAddNew && customAmenity) {
      setIsLoading(true);
      await onAddNew(startCase(customAmenity));
      setIsLoading();
      setAddingNew(false);
      setAllNewAmenities([customAmenity.toLowerCase(), ...allNewAmenities]);
      setCustomAmenity("");
    }
  };

  return (
    <div className="white-block amenites-box">
      <div className="head">Amenities</div>
      {options.map((am, index) => (
        <div className="radio-container" key={`${am.name}-${Math.random()}`}>
          <label className="custom-checkbox">
            {am.name}
            <input
              type="checkbox"
              name={`amenities[${index}]`}
              value={am.name}
              checked={
                am.selected || allNewAmenities.includes(am.name.toLowerCase())
              }
              onChange={(event) => onChange(event, index)}
            />
            <span className="checkmark" />
          </label>
        </div>
      ))}
      {addingNew ? (
        <div>
          <div className="form-group">
            <div className="has-float-label grey-input">
              <input
                className="form-control"
                id="customAmenity"
                name="customAmenity"
                type="text"
                placeholder="Custom Amenity"
                autoComplete="nope"
                value={customAmenity}
                onChange={(event) => setCustomAmenity(event.target.value)}
              />
              <label htmlFor="customAmenity">Custom Amenity</label>
            </div>
          </div>
          <div className="custom-btn-wrapper">
            <Button
              type="button"
              className="btn cancel-btn ml-2"
              onClick={() => setAddingNew(false)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="cursor-pointer save-btn"
              loading={isLoading}
              onClick={onSave}
            >
              <span className="text-priamry">Save</span>
            </Button>
          </div>
        </div>
      ) : (
        <Button
          type="button"
          className="cursor-pointer link-btn"
          icon
          onClick={() => setAddingNew(true)}
        >
          <Icon name="add" className="text-priamry" />
          <span className="text-priamry">Add new</span>
        </Button>
      )}
    </div>
  );
};

export default Amenities;
